import { Flex, Icon, MenuItem, Text } from "@chakra-ui/react"
import { MdEmojiPeople } from "react-icons/md"
import { useNavigate } from "react-router-dom"

const ACooptionMenuItem = () => {
  const navigate = useNavigate()

  return (
    <MenuItem
      minH="48px"
      background="primary.300"
      _hover={{ background: "primary.400" }}
      _focus={{ background: "primary.400" }}
      onClick={() => navigate("/cooption")}
    >
      <Flex>
        <Flex align="center" justify="center" w={6}>
          <Icon as={MdEmojiPeople} />
        </Flex>
        <Text ml={2} fontSize={16}>
          Parrainer un confrère
        </Text>
      </Flex>
    </MenuItem>
  )
}

export default ACooptionMenuItem

import axiosInstance from "./axiosInstance"

export type PodcastType = {
  id: number
  attributes: {
    title: string
    illustrationUrl: string
  }
}

export const getAllPodcasts = async () => {
  const { data } = await axiosInstance.get<{ data: PodcastType[] }>(
    `/explorer-podcasts?sort=order`
  )
  return data.data
}

export type PodcastItemType = {
  title: string
  duration: string
  url: string
}

export type PoscastInfoType = {
  authorName: string
  description: string
  image: string
  link: string
  title: string
}

export type PodcastItemsType = {
  info: PoscastInfoType
  items: PodcastItemType[]
}

export const getPodcastItems = async (podcastId: string | undefined) => {
  if (podcastId === null) return
  const { data } = await axiosInstance.get<PodcastItemsType>(
    `/podcast/getRSSFeed?podcastId=${podcastId}`
  )
  return data
}

import { Button, ListItem, ListItemProps } from "@chakra-ui/react"
import { ElementRef, forwardRef } from "react"

interface AAutocompleteOptionProps extends ListItemProps {
  "aria-selected"?: "true" | "false"
}
const AAutocompleteOption = forwardRef<
  ElementRef<typeof ListItem>,
  AAutocompleteOptionProps
>(({ "aria-selected": ariaSelected, ...props }, ref) => (
  <ListItem
    ref={ref}
    as={Button}
    type="button"
    isActive={ariaSelected === "true"}
    width="100%"
    justifyContent="flex-start"
    py={3}
    aria-selected={ariaSelected}
    bg="white"
    {...props}
  />
))

AAutocompleteOption.displayName = "AAutocompleteOption"

export default AAutocompleteOption

import { TriangleUpIcon } from "@chakra-ui/icons"
import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { MediaType } from "../../../api/media"
import formatDuration from "../../../utils/formatDuration"
import getDifficulty from "../../../utils/getDifficulty"

interface IProps {
  media: MediaType
  size: "small" | "large"
}

const VideoItem: React.FC<IProps> = ({ media, size }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <div>
      <Box
        w={size === "small" ? 265 : 420}
        minW={size === "small" ? 265 : 420}
        h={230}
        borderRadius={9}
        overflow="hidden"
        pos="relative"
        cursor="pointer"
        onClick={onOpen}
      >
        <Image src={media.cover.url} w="100%" h="100%" objectFit="cover" />
        <Text
          bg="secondary.500"
          borderRadius={20}
          pos="absolute"
          top={2}
          left={2}
          py={1}
          px={3}
        >
          {getDifficulty(media.difficulty)}
        </Text>
        <Flex
          pos="absolute"
          left={0}
          bottom={0}
          color="white"
          p={2}
          w="100%"
          bgGradient="linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Text fontSize={20} fontWeight={700}>
              {media.title}
            </Text>
            <Text fontSize={16}>
              {media?.author?.displayName} - {formatDuration(media.duration)}
            </Text>
          </Box>
          <Center bg="white" borderRadius="50%" minW={26} h={26}>
            <TriangleUpIcon
              transform="rotate(90deg)"
              color="black"
              fontSize={14}
              ml={0.5}
            />
          </Center>
        </Flex>
      </Box>
      {media.media && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay bg="blackAlpha.400" />
          <ModalContent maxW="724">
            <Box
              pos="relative"
              borderRadius={9}
              bg="white"
              w={724}
              h={469}
              py="32px"
              px="54px"
            >
              <ModalCloseButton aria-label="Fermer" />
              <Heading
                fontSize={22}
                fontWeight="bold"
                textAlign="center"
                mb={7}
              >
                {media.title}
              </Heading>
              <video
                src={media.media.url}
                controls
                onContextMenu={(e) => e.preventDefault()}
                controlsList="nodownload"
              ></video>
            </Box>
          </ModalContent>
        </Modal>
      )}
    </div>
  )
}

export default VideoItem

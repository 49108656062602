import { Box, Flex, Image, Text } from "@chakra-ui/react"
import { ExerciceType } from "api/patient"
import React from "react"

interface TrainingExerciceKineCardProps {
  exercice: ExerciceType
  side: "left" | "right" | null
  selectedSeries: string | null
  setSelectedSeries: (series: string | null) => void
}

const TrainingExerciceKineCard = ({
  exercice,
  side,
  selectedSeries,
  setSelectedSeries,
}: TrainingExerciceKineCardProps) => {
  return (
    <Flex
      flex={1}
      border={"1px"}
      borderRadius={"10px"}
      justify={"space-between"}
      direction={"column"}
      p={2}
      gap={"10px"}
      pos={"relative"}
      minW={"300px"}
      maxW={"300px"}
      my={2}
      onClick={() =>
        setSelectedSeries(
          selectedSeries === `${exercice.id}__${side}`
            ? null
            : `${exercice.id}__${side}`
        )
      }
      cursor={"pointer"}
      borderColor={
        selectedSeries === `${exercice.id}__${side}`
          ? "primary.500"
          : "common.200"
      }
    >
      <Flex w={"full"} gap={"10px"}>
        <Flex
          w={"64px"}
          height={"64px"}
          minW={"64px"}
          maxW={"64px"}
          borderRadius={"8px"}
          overflow={"hidden"}
        >
          <Image
            src={
              exercice.osteoSelection?.bunnyCDN?.preview ??
              exercice.trainingVideoUploaded?.bunnyCDN.preview
            }
            alt={
              exercice.osteoSelection?.title ??
              exercice.trainingVideoUploaded?.title
            }
            width="100%"
            height="100%"
            objectFit="cover"
          />
        </Flex>
        <Flex direction={"column"}>
          <Text fontSize={14} fontWeight={700}>
            {exercice.osteoSelection?.group[0].title ??
              exercice.trainingVideoUploaded?.title}
          </Text>
          <Text fontSize={12}>
            niveau{" "}
            {exercice.osteoSelection?.level ??
              exercice.trainingVideoUploaded?.level}
          </Text>
          <Flex gap={2}>
            {side !== null && (
              <Box
                bg={"primary.200"}
                border={"none"}
                borderRadius={"4px"}
                p={1}
              >
                <Text fontSize={12}>
                  {side === "left"
                    ? "Côté gauche"
                    : side === "right"
                    ? "Côté droit"
                    : ""}
                </Text>
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w={"full"}
        bg={"common.200"}
        justify={"space-around"}
        gap={2}
        fontSize={12}
        borderRadius={"4px"}
        px={2}
      >
        <Flex align={"center"} gap={1}>
          <Text fontWeight={700} fontSize={14} maxW={"35px"}>
            {exercice.series}
          </Text>
          <Flex gap={1}>
            <Text>séries</Text>
          </Flex>
        </Flex>
        {exercice.repetitions && (
          <Flex align={"center"} gap={1}>
            <Text fontWeight={700} fontSize={14} maxW={"35px"}>
              {exercice.repetitions}
            </Text>
            <Flex gap={1}>
              <Text>répétitions</Text>
            </Flex>
          </Flex>
        )}
        {exercice.duration && (
          <Flex align={"center"} gap={1}>
            <Text fontWeight={700} fontSize={14} maxW={"35px"}>
              {exercice.duration}
            </Text>
            <Flex gap={1}>
              <Text>sec</Text>
            </Flex>
          </Flex>
        )}
        {exercice.weight && (
          <Flex align={"center"} gap={1}>
            <Text fontWeight={700} fontSize={14} maxW={"35px"}>
              {exercice.weight}
            </Text>
            <Flex gap={1}>
              <Text fontWeight={700}>kg</Text>
              <Text>charge</Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default TrainingExerciceKineCard

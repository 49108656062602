import axiosInstance from "./axiosInstance"
import axiosInstanceWithoutAuth from "./axiosInstanceWithoutAuth"

export const getThirdPartyAppDetails = async (token: string) => {
  const { data } = await axiosInstanceWithoutAuth.get(
    `/external/details?token=${token}`
  )
  return data
}

interface SaveProfileSelectionParams {
  token: string
  centerId?: number
}

export const saveProfileSelection = async (
  params: SaveProfileSelectionParams
) => {
  const { token, centerId } = params
  const { data } = await axiosInstance.post(
    `/external/configure-profile?token=${token}`,
    { centerId }
  )
  return data
}

import { Box, Flex, Image, Spinner, Text, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { curryIncludesInsensitive } from "utils/includesInsensitive"
import {
  getAppointmentReason,
  SelectedExerciceType,
  TrainingVideoGroupsType,
  VideoGroupCategoryType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
  GetAppointmentReasonParams,
  AppointmentReasonTypePaginated,
  getUploadedVideosForAppointmentReason,
  getLikedVideosForAppointmentReason,
} from "api/appointmentReason"
import {
  PATHOLOGIES_MOCK,
  ZonePathologieType,
  getZones,
} from "pages/NewAppointment/newAppointment.mock"
import arrowBack from "../../../../assets/arrow_back.svg"

import SelectedExercices from "./components/SelectedExercices/SelectedExercices"
import Reason from "pages/NewAppointment/components/SelectExercices/components/Reason"
import BabyMediaTags from "pages/NewAppointment/components/SelectReason/components/BabyMediaTags/BabyMediaTags"
import SelectedBabyMediaTags from "pages/NewAppointment/components/SelectReason/components/SelectedBabyMediaTags/SelectedBabyMediaTags"
import { BabyMediaTag } from "api/media"
import { getCommonPrograms, getMyPrograms, ProgramType } from "api/program"
import ZonesPathologies from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/ZonesPathologies"
import TabExercices from "pages/Exercices/components/ExercicesLists/components/TabExecices/TabExercices"
import FilterExercices from "pages/Exercices/components/ExercicesLists/components/FilterExercices/FilterExercices"
import FilterPrograms from "pages/Exercices/components/ExercicesLists/components/FilterPrograms/FilterPrograms"
import VideoGroup from "pages/Exercices/components/ExercicesLists/components/VideoGroup/VideoGroup"
import ProgramCard from "pages/Exercices/components/ExercicesLists/components/ProgramCard/ProgramCard"
import { niceDisplayVideoGroup } from "utils/niceDisplayVideoGroup"
import AddEditableProgramModal from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/AddEditableProgramModal"
import AUploadedExerciceCard from "components/AUploadedExerciceCard/AUploadedExerciceCard"
import AddProgramModal from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/AddProgramModal"
import { useNavigate } from "react-router-dom"
import useJobIsKine from "hooks/useIsJobKine"
import { useMutation, useQuery } from "@tanstack/react-query"
import ATablePagination from "components/Table/ATablePagination/ATablePagination"
import { useDebounce } from "use-debounce"

import {
  sortByJob,
  sortByTimeUsed,
} from "pages/NewProgram/components/SelectProgramExercices"

export const VIDEOS_TAB = 0
export const PROGRAMME_TAB = 1
export const FAVORIS_TAB = 2

// COMPONENTS
interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  reason: ZonePathologieType | undefined
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  selectedUploadedExercices: SelectedUploadedExerciceType[]
  setSelectedUploadedExercices: React.Dispatch<
    React.SetStateAction<SelectedUploadedExerciceType[]>
  >
  selectedVideoOrder: SelectedVideoOrderType[]
  setSelectedVideoOrder: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
  reasonType: "zones" | "pathologies" | null
  setReasonType: (reason: "zones" | "pathologies" | null) => void
  setReason: React.Dispatch<
    React.SetStateAction<ZonePathologieType | undefined>
  >
  isBabySwitchOn: boolean
  setIsBabySwitchOn: React.Dispatch<React.SetStateAction<boolean>>
  selectedBabyMediaTag: BabyMediaTag | null
  setSelectedBabyMediaTag: React.Dispatch<
    React.SetStateAction<BabyMediaTag | null>
  >
  babyMediaTags: BabyMediaTag[]
  isForKine?: boolean
  treatmentTitle?: string
  treatmentId?: string
  patientId?: number
}

const SelectExercices: React.FC<IProps> = ({
  setStep,
  reasonType,
  setReasonType,
  selectedExercices,
  setSelectedExercices,
  selectedUploadedExercices,
  setSelectedUploadedExercices,
  selectedVideoOrder,
  setSelectedVideoOrder,
  reason,
  setReason,
  isBabySwitchOn,
  setIsBabySwitchOn,
  selectedBabyMediaTag,
  setSelectedBabyMediaTag,
  babyMediaTags,
  isForKine = false,
  treatmentTitle,
  treatmentId,
  patientId,
}) => {
  const [search, setSearch] = useState("")
  const isJobKine = useJobIsKine()
  // const [suggestedProgramme, setSuggestedProgramme] = useState<VideoType[]>([])
  const [videoGroups, setVideoGroups] = useState<TrainingVideoGroupsType[]>([])
  const [categories, setCategories] = useState<VideoGroupCategoryType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null)
  const toast = useToast()

  const [restrictionFilter, setRestrictionFilter] = useState<string | null>(
    null
  )
  const [programs, setPrograms] = useState<ProgramType[]>([])
  const [commonPrograms, setCommonPrograms] = useState<ProgramType[]>([])
  const [selectedProgram, setSelectedProgram] = useState<ProgramType | null>(
    null
  )
  const [likedVideoGroups, setLikedVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])

  const [selectedTab, setSelectedTab] = useState(VIDEOS_TAB)

  const [appointmentReason, setAppointmentReason] = useState<
    undefined | AppointmentReasonTypePaginated
  >(undefined)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const navigate = useNavigate()
  const [debouncedSearch] = useDebounce(search, 500)

  const [selectedClinicalPicture, setSelectedClinicalPicture] = useState<
    string | null
  >(null)

  const onReturn = () => {
    if (treatmentId && patientId) {
      navigate(`/patients/${patientId}/treatment/${treatmentId}`)
    }
    if (setStep) {
      setStep(0)
    }
  }

  const restrictions = videoGroups.reduce(
    (acc, { restrictions }) => [
      ...acc,
      ...(restrictions ?? []).reduce(
        (restrictionsAcc, { name }) =>
          acc.includes(name) ? restrictionsAcc : [...restrictionsAcc, name],
        [] as string[]
      ),
    ],
    [] as string[]
  )

  const { mutate, isLoading: isAppointmentReasonLoading } = useMutation({
    mutationKey: ["appointmentReason", reason?.id, 1],
    mutationFn: (params: GetAppointmentReasonParams) =>
      getAppointmentReason(params),
    onSuccess: (response) => {
      if (reason?.id) {
        setAppointmentReason(response.data)
      } else {
        setAppointmentReason(undefined)
      }
      setVideoGroups(
        niceDisplayVideoGroup(
          response.data.trainingVideoGroups.sort(
            isJobKine ? sortByJob : sortByTimeUsed
          )
        )
      )

      setPageCount(response.data.pagination.pageCount)
      setLikedVideoGroups(
        response.data.trainingVideoGroups.filter(
          (trainingVideoGroup) =>
            trainingVideoGroup.osteosLiking &&
            trainingVideoGroup.osteosLiking.length > 0
        )
      )
    },
  })

  const { data: uploadedVideos } = useQuery({
    queryKey: ["uploadedVideos", reason?.id ?? -1],
    queryFn: () => getUploadedVideosForAppointmentReason(reason?.id ?? -1),
  })

  const { data: likedVideos } = useQuery({
    queryKey: ["likedVideos", reason?.id ?? -1],
    queryFn: () => getLikedVideosForAppointmentReason(reason?.id ?? -1),
  })

  // Effet pour déclencher la mutation quand les params changent
  useEffect(() => {
    setPage(1)
    mutate({
      reasonId: reason?.id ?? -1,
      page: 1,
    })
  }, [reason?.id])
  useEffect(() => {
    mutate({
      reasonId: reason?.id ?? -1,
      page,
    })
  }, [page])

  useEffect(() => {
    if (reason?.id) setPageCount(0)
  }, [reason?.id])

  const fetchMyPrograms = async () => {
    const programsResponse = await getMyPrograms()
    const commonProgramsResponse = await getCommonPrograms()
    setPrograms(programsResponse)
    setCommonPrograms(commonProgramsResponse)
  }

  useEffect(() => {
    fetchMyPrograms()
  }, [])

  useEffect(() => {
    const listOfCategories = videoGroups
      .map((videoGroup) => videoGroup.category)
      .filter((elt) => elt !== null)

    const uniqueCategories = listOfCategories.filter(
      (category, index) => listOfCategories.indexOf(category) === index
    )

    setCategories(uniqueCategories)
  }, [videoGroups])

  useEffect(() => {
    // reset filters
    setCategoryFilter(null)
  }, [reason])

  useEffect(() => {
    setPage(1)
    mutate({
      reasonId: reason?.id ?? -1,
      page: 1,
      search: debouncedSearch,
    })
  }, [debouncedSearch])

  const filterWithCategoriesRestrictionsAndSearch = (
    list: TrainingVideoGroupsType[]
  ) => {
    let filteredList = list
    if (categoryFilter !== null) {
      filteredList = filteredList.filter(
        ({ category }) => category === categoryFilter
      )
    }
    if (restrictionFilter !== null) {
      filteredList = filteredList.filter(({ restrictions }) =>
        restrictions.some(({ name }) => name === restrictionFilter)
      )
    }
    if (selectedClinicalPicture !== null) {
      filteredList = filteredList.filter(
        ({ clinicalPictures }) =>
          clinicalPictures?.some(
            ({ id }) => id === Number(selectedClinicalPicture)
          ) ?? false
      )
    }
    return filteredList
  }

  const filterPrograms = (list: ProgramType[]) => {
    const newList = list.filter(
      (program) =>
        program.trainingVideoPrograms &&
        program.trainingVideoPrograms.length > 0
    )
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = newList
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (reason) {
      filteredList = filteredList.filter(({ appointmentReasons }) =>
        appointmentReasons.find(
          (currentReason) => currentReason.id === reason.id
        )
      )
    }
    return filteredList
  }

  const [sortedFilteredVideoGroups, setSortedFilteredVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])

  const [sortedFilteredPrograms, setSortedFilteredPrograms] = useState<
    ProgramType[]
  >([])
  const [sortedCommonPrograms, setSortedCommonPrograms] = useState<
    ProgramType[]
  >([])

  useEffect(() => {
    setSortedFilteredVideoGroups(
      filterWithCategoriesRestrictionsAndSearch(videoGroups)
    )
  }, [
    videoGroups,
    categoryFilter,
    restrictionFilter,
    search,
    selectedClinicalPicture,
  ])

  useEffect(() => {
    const filteredPrograms = filterPrograms(programs)
    setSortedFilteredPrograms(filteredPrograms)
  }, [search, reason, programs])

  useEffect(() => {
    const filteredProgram = filterPrograms(commonPrograms)
    setSortedCommonPrograms(filteredProgram)
  }, [search, reason, commonPrograms])

  return (
    <Box p="16px" width="full" pb={"120px"}>
      <Flex>
        <Flex direction="column" pos="sticky" top={4} h="fit-content">
          <Flex gap={4} align={"center"} mb={6}>
            <Flex
              cursor="pointer"
              onClick={onReturn}
              p="8px"
              bgColor="primary.200"
              w="fit-content"
              display="block"
              rounded="9px"
              pos="sticky"
              top={4}
            >
              <Image src={arrowBack} width="24px" height="24px" />
            </Flex>
            {isForKine && (
              <Text fontWeight={700} fontSize={16}>
                {treatmentTitle}
              </Text>
            )}
          </Flex>
          {!isForKine && (
            <Reason
              reason={reasonType}
              setReason={setReasonType}
              isBabySwitchOn={isBabySwitchOn}
            />
          )}
          {isBabySwitchOn ? (
            <Flex direction={"column"}>
              <BabyMediaTags
                selectedBabyMediaTag={selectedBabyMediaTag}
                setSelectedBabyMediaTag={setSelectedBabyMediaTag}
                babyMediaTags={babyMediaTags}
              />
              {selectedBabyMediaTag && (
                <SelectedBabyMediaTags
                  selectedBabyMediaTag={selectedBabyMediaTag}
                  setSelectedBabyMediaTag={setSelectedBabyMediaTag}
                />
              )}
            </Flex>
          ) : (
            <>
              <Flex alignItems={"center"}>
                <Flex justifyContent="center" w="full">
                  {reasonType === "zones" && (
                    <ZonesPathologies
                      datas={getZones(isJobKine)}
                      selectedData={reason}
                      setSelectedData={setReason}
                      isForKine={isForKine}
                      reason={appointmentReason}
                      selectedClinicalPicture={selectedClinicalPicture}
                      setSelectedClinicalPicture={setSelectedClinicalPicture}
                    />
                  )}
                  {reasonType === "pathologies" && !isForKine && (
                    <ZonesPathologies
                      datas={PATHOLOGIES_MOCK}
                      selectedData={reason}
                      setSelectedData={setReason}
                    />
                  )}
                </Flex>
              </Flex>
            </>
          )}
        </Flex>

        <Box flex={1} px="60px" position="relative">
          <TabExercices
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isPageExercies
            isForKine={isForKine}
            appointmentReason={reason?.id}
          />
          {selectedTab === 0 && (
            <FilterExercices
              categories={categories}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              setSearch={setSearch}
              restrictions={restrictions}
              restrictionFilter={restrictionFilter}
              setRestrictionFilter={setRestrictionFilter}
            />
          )}
          {selectedTab === 1 && <FilterPrograms setSearch={setSearch} />}

          <Flex justifyContent="flex-start" flexWrap="wrap" gap="16px" mt={4}>
            {selectedTab === 0 && (
              <Flex flexDir={"column"} gap={"16px"} w="full">
                {uploadedVideos?.length &&
                uploadedVideos?.length > 0 &&
                isForKine ? (
                  <Box>
                    <Text fontWeight={700} fontSize={16} mb={4}>
                      Vos exercices
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="16px"
                      w="full"
                    >
                      {uploadedVideos.map((video, i) => (
                        <AUploadedExerciceCard
                          key={`uploaded_video_${video.id}`}
                          video={video}
                          selectedExercices={selectedUploadedExercices}
                          setSelectedExercices={setSelectedUploadedExercices}
                          setSelectedVideoOrder={setSelectedVideoOrder}
                          isForKine={isForKine}
                          appointmentReason={reason?.id}
                        />
                      ))}
                    </Flex>
                  </Box>
                ) : null}
                {isAppointmentReasonLoading ? (
                  <Flex
                    flex={1}
                    px="60px"
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Spinner color="primary.500" />
                  </Flex>
                ) : (
                  <Box w="full">
                    {uploadedVideos?.length &&
                    uploadedVideos?.length > 0 &&
                    sortedFilteredVideoGroups.length > 0 ? (
                      <Text fontWeight={700} fontSize={16} mb={4}>
                        Autres exercices
                      </Text>
                    ) : null}
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="16px"
                      w="full"
                    >
                      {sortedFilteredVideoGroups.map((videoGroup, index) => (
                        <VideoGroup
                          key={`video_group_${videoGroup.id}_${index}`}
                          videoGroup={videoGroup}
                          selectedExercices={selectedExercices}
                          setSelectedExercices={setSelectedExercices}
                          setSelectedVideoOrder={setSelectedVideoOrder}
                          isForKine={isForKine}
                          isLiked={
                            likedVideos?.some(
                              (likedVideo) => likedVideo.id === videoGroup.id
                            ) ?? false
                          }
                        />
                      ))}
                    </Flex>
                    {selectedTab === 0 && (
                      <Box mt={4}>
                        <ATablePagination
                          pageCount={pageCount}
                          onPageChange={setPage}
                          page={page}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Flex>
            )}

            {selectedTab === 1 && (
              <Box w="full" pb={16}>
                {sortedFilteredPrograms.length > 0 && (
                  <Box>
                    <Text fontWeight={700} fontSize={16}>
                      Vos programmes
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedFilteredPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                        />
                      ))}
                    </Flex>
                  </Box>
                )}
                {sortedCommonPrograms.length > 0 && (
                  <Box>
                    <Text fontWeight={700} fontSize={16}>
                      Autres programmes
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedCommonPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                        />
                      ))}
                    </Flex>
                  </Box>
                )}
              </Box>
            )}
            {selectedTab === 2 &&
              likedVideos?.map((videoGroup) => (
                <VideoGroup
                  key={`liked_video_group_${videoGroup.id}`}
                  videoGroup={videoGroup}
                  reason={reason}
                  isLiked={true}
                />
              ))}
          </Flex>
        </Box>

        {(selectedExercices || selectedUploadedExercices) && (
          <SelectedExercices
            selectedExercices={selectedExercices}
            setSelectedExercices={setSelectedExercices}
            selectedUploadedExercices={selectedUploadedExercices}
            setSelectedUploadedExercices={setSelectedUploadedExercices}
            selectedVideoOrder={selectedVideoOrder}
            setSelectedVideoOrder={setSelectedVideoOrder}
            setStep={setStep}
            showOnlyDuration={!isForKine}
          />
        )}
      </Flex>
      {selectedProgram && !isForKine && (
        <AddProgramModal
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          selectedExercices={selectedExercices}
          setSelectedExercices={setSelectedExercices}
          setSelectedVideoOrder={setSelectedVideoOrder}
        />
      )}
      {selectedProgram && isForKine && (
        <AddEditableProgramModal
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          setSelectedExercices={setSelectedExercices}
          setSelectedUploadedExercices={setSelectedUploadedExercices}
          setSelectedVideoOrder={setSelectedVideoOrder}
        />
      )}
    </Box>
  )
}

export default SelectExercices

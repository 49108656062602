import React from "react"
import { SVGProps } from "../type"

const Viceral: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <circle
        cx="24"
        cy="24"
        r="22.5"
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
      ></circle>
    </svg>
  )
}

export default Viceral

import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  Box,
  Heading,
  Flex,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
  Input,
} from "@chakra-ui/react"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  endTeleconsultation,
  getTeleconsultationById,
  TeleconsultationType,
} from "api/teleconsultation"
import TeleconsultationScreen from "components/Teleconsultation/TeleconsultationScreen"
import AButton from "components/AButton"
import useToast from "hooks/useToast"

const CurrentTeleconsultation: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const toast = useToast()
  const navigate = useNavigate()
  const { data: teleconsultation, isLoading } = useQuery<
    TeleconsultationType,
    Error
  >(["teleconsultation", id], () => getTeleconsultationById(id!), {
    enabled: !!id,
  })

  const [privateNote, setPrivateNote] = useState("")
  const [recalculedPrice, setRecalculedPrice] = useState<number | undefined>(
    undefined
  )
  const endTeleconsultationMutation = useMutation(endTeleconsultation)
  const {
    isOpen: isFinishedModalOpen,
    onOpen: onFinishedModalOpen,
    onClose: onFinishedModalClose,
  } = useDisclosure()

  useEffect(() => {
    if (teleconsultation) {
      setRecalculedPrice(teleconsultation.price)
    }
  }, [teleconsultation])

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" color="primary.500" />
      </Flex>
    )
  }

  if (!teleconsultation || recalculedPrice === undefined) {
    return (
      <Box p={5}>
        <Heading as="h1" size="xl">
          Téléconsultation non trouvée
        </Heading>
      </Box>
    )
  }
  const handleFinishTeleconsultation = () => {
    endTeleconsultationMutation.mutate(
      {
        id: teleconsultation.id,
        privateNote,
        price: recalculedPrice,
      },
      {
        onSuccess: () => {
          toast({
            title: "Téléconsultation terminée",
            status: "success",
          })
          navigate(`/patients/${teleconsultation.patient.id}`)
        },
      }
    )
  }
  return (
    <Flex
      justify="space-between"
      align="center"
      minH="100vh"
      direction="column"
      pt={4}
    >
      {recalculedPrice !== undefined && (
        <TeleconsultationScreen
          teleconsultation={teleconsultation}
          privateNote={privateNote}
          recalculedPrice={recalculedPrice}
          setPrivateNote={setPrivateNote}
          setRecalculedPrice={setRecalculedPrice}
        />
      )}
      <Flex
        bg="primary.200"
        w="full"
        justify="center"
        align="center"
        h="80px"
        position="fixed"
        bottom={0}
      >
        <AButton
          text="Terminer la téléconsultation"
          variant="kine"
          onClick={onFinishedModalOpen}
        />
      </Flex>
      <Modal
        isOpen={isFinishedModalOpen}
        onClose={onFinishedModalClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW={500} p={4}>
          <ModalHeader>Terminer la téléconsultation</ModalHeader>
          <ModalBody>
            Cela mettra fin à la téléconsultation et{" "}
            <b>un lien de paiement sera envoyé</b> au patient. <br />
            <Text mt={4} mb={2}>
              Montant de la consultation :
            </Text>
            <Flex align={"center"} justify={"justify-end"} w={"50%"} gap={4}>
              <Input
                value={recalculedPrice}
                name="price"
                onChange={(e: any) => setRecalculedPrice(e.target.value)}
                bgColor="common.100"
                border={"none"}
                w={"70px"}
                color={"black"}
                fontWeight={"bold"}
              />
              <Text fontWeight={"bold"} color={"common.500"}>
                euros
              </Text>
            </Flex>
            .
          </ModalBody>
          <ModalFooter gap={4}>
            <AButton
              text="Annuler"
              variant="tertiary"
              py={3}
              px={27}
              onClick={onFinishedModalClose}
            />
            <AButton
              text="Terminer"
              variant="primary"
              py={3}
              px={4}
              onClick={handleFinishTeleconsultation}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default CurrentTeleconsultation

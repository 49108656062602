import DiscloseTempPassword from "components/DiscloseTempPassword"
import { useFormikContext } from "formik"

interface InvitePatientProps {
  isOpen: boolean
  onClose: () => void
  password: string
}
const DiscloseNewPatientTempPassword = ({
  isOpen,
  onClose,
  password,
}: InvitePatientProps) => {
  const { values } = useFormikContext<{
    email: string
    lastname: string
    firstname: string
  }>()
  const { lastname, firstname } = values
  const displayName = `${firstname} ${lastname}`
  const title = "Patient créé"
  const text = `Le patient ${displayName} a été créé.`

  return (
    <DiscloseTempPassword
      isOpen={isOpen}
      onClose={onClose}
      password={password}
      patient={values}
      title={title}
      text={text}
      closeText="Créer une consultation"
    />
  )
}

export default DiscloseNewPatientTempPassword

import {
  Box,
  Flex,
  Image,
  MenuItem,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useRef } from "react"
import Logout from "../../../assets/logout.svg"
import ALogoutModal from "components/ALogoutModal"

const ALogoutMenuItem = () => {
  const cancelRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <MenuItem
      minH="40px"
      background="primary.300"
      _hover={{ background: "primary.400" }}
      _focus={{ background: "primary.400" }}
      onClick={onOpen}
    >
      <Flex alignItems="center">
        <Box w={6}>
          <Image src={Logout} w={3.5} ml={1} />
        </Box>
        <Text ml={2} fontSize={16}>
          Se déconnecter
        </Text>
      </Flex>

      <ALogoutModal cancelRef={cancelRef} isOpen={isOpen} onClose={onClose} />
    </MenuItem>
  )
}

export default ALogoutMenuItem

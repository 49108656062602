import {
  Box,
  Flex,
  Heading,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { MediaType } from "../../../api/media"
import AButton from "../../../components/AButton"
import formatDuration from "../../../utils/formatDuration"

interface IProps {
  media: MediaType
  size: "small" | "large"
}

const AudioItem: React.FC<IProps> = ({ media, size }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <div>
      <Box
        w={size === "small" ? 265 : 420}
        minW={size === "small" ? 265 : 420}
        h={230}
        borderRadius={9}
        overflow="hidden"
        pos="relative"
        cursor="pointer"
        bg="primary.200"
        onClick={onOpen}
        p={4}
      >
        <Flex mb={3}>
          <Image
            src={media.author.avatar.formats.thumbnail.url}
            w={8}
            borderRadius={8}
            h={8}
            objectFit="cover"
            mr={2}
          />
          <Text fontSize={20} fontWeight={700}>
            {media.author.displayName}
          </Text>
        </Flex>

        <Text fontSize={16} fontWeight={700} lineHeight={1.5} mb={4}>
          {media.title}
        </Text>
        <Text fontSize={16} fontWeight={700}>
          {formatDuration(media.duration)}
        </Text>

        <AButton
          text="Lire l'audio"
          variant="tertiary"
          pos="absolute"
          bottom={4}
          left={4}
          w="calc(100% - 32px)"
        />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent maxW="724">
          <Box
            pos="relative"
            borderRadius={9}
            bg="white"
            w={724}
            h={160}
            py="32px"
            px="54px"
          >
            <ModalCloseButton aria-label="Fermer" />
            <Heading
              as="p"
              fontSize={22}
              fontWeight="bold"
              textAlign="center"
              mb={7}
            >
              {media.title}
            </Heading>
            <Flex justifyContent="center">
              <audio src={media.media.url} controls></audio>
            </Flex>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default AudioItem

import { useMutation } from "@tanstack/react-query"
import { createStripeAccount } from "api/teleconsultation"

import AButton from "components/AButton"
import useToast from "hooks/useToast"
import React, { useEffect, useState } from "react"

export default function StripeConnectButton() {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const toast = useToast()

  const createStripeAccountMutation = useMutation(createStripeAccount, {
    onSuccess: (data) => {
      setLoading(false)
      if (data.url) window.location.href = data.url
    },
    onError: () => {
      setLoading(false)
    },
  })

  useEffect(() => {
    if (error) {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue",
      })
    }
  }, [error, toast])

  return (
    <div className="container">
      <AButton
        isLoading={loading}
        onClick={async () => {
          setLoading(true)
          setError(false)
          createStripeAccountMutation.mutate()
        }}
        text="Créer mon compte Stripe"
        variant="primary"
      />
    </div>
  )
}

import { CloseIcon, SearchIcon } from "@chakra-ui/icons"
import {
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react"
import AButton from "components/AButton"
import { DEBOUNCE_DELAY } from "constants/transitions"
import { ChangeEvent, useRef } from "react"
import { useDebouncedCallback } from "use-debounce"

interface AInputSearchProps extends InputProps {
  setSearch: (value: string) => void
  canClear?: boolean
  inputGroupProps?: InputGroupProps
  setDisplayFilters?: (value: boolean) => void
  displayFilters?: boolean
}

const AInputSearch = ({
  setSearch,
  canClear,
  inputGroupProps,
  setDisplayFilters,
  displayFilters,
  ...props
}: AInputSearchProps) => {
  const debouncedSearch = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    DEBOUNCE_DELAY
  )

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <InputGroup {...(inputGroupProps ?? {})}>
      <Input
        ref={inputRef}
        type="text"
        border="none"
        borderRadius={"9px"}
        fontFamily="Montserrat"
        bg="common.100"
        fontSize={14}
        onChange={debouncedSearch}
        {...props}
      />
      <InputLeftElement pointerEvents="none" mt={0}>
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <InputRightElement mt={0} w="fit-content" px={2}>
        {canClear && (
          <IconButton
            opacity={inputRef.current?.value ? 1 : 0}
            size="sm"
            aria-label="Réinitialiser"
            onClick={() => {
              setSearch("")
              if (inputRef.current) {
                inputRef.current.value = ""
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {displayFilters !== undefined && setDisplayFilters !== undefined && (
          <AButton
            text="Filtres"
            variant={displayFilters ? "primary" : "tertiary"}
            onClick={() => {
              setDisplayFilters(!displayFilters)
            }}
            bg={displayFilters ? "primary.500" : "transparent"}
            color={displayFilters ? "white" : "common.700"}
            _hover={{
              bg: displayFilters ? "primary.600" : "primary.200",
            }}
            fontWeight={400}
            px={4}
            py={2}
            fontSize={12}
            h="fit-content"
          />
        )}
      </InputRightElement>
    </InputGroup>
  )
}

export default AInputSearch

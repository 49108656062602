import { useEffect, useState } from "react"
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react"
import * as Yup from "yup"
import { getMe, login } from "../../api/user"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Field, Form, Formik } from "formik"
import { Link as RouterLink, useSearchParams } from "react-router-dom"
import ALogo from "../../components/ALogo"
import ASubmitButton from "../../components/ASubmitButton"
import { getThirdPartyAppDetails, saveProfileSelection } from "api/thirdParty"
import getMeQueryKeys from "constants/queryKeys/getMe"
import axiosInstance from "api/axiosInstance"
import userHasNoPersonalAccount from "utils/user/userHasNoPersonalAccount"
import AUserAvatar from "components/AAvatar/AUserAvatar"
import ACenterAvatar from "components/AAvatar/ACenterAvatar"
import { useAppContext } from "AppContext"
import AInputPassword from "components/AInputPassword"

interface MyFormValues {
  email: string
  password: string
}

const handleRedirect = (callbackUrl: string, jwt: string, email: string) => {
  window.location.replace(`${callbackUrl}?jwt=${jwt}&email=${email}`)
}

const ThirdPartyLogin = () => {
  const loginMutation = useMutation(login)
  const initialValues: MyFormValues = { email: "", password: "" }
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")
  const callbackUrl = searchParams.get("callbackUrl")
  const [jwt, setJwt] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const isMissingParams = !token || !callbackUrl
  const [userState, setUserState] = useState<
    "noAccount" | "multipleAccount" | null
  >(null)
  const { setJwt: setSessionJwt } = useAppContext()

  // we set the jwt in the session storage to null to avoid havin header / footer and a bearer token
  useEffect(() => {
    setSessionJwt(null)
  }, [setSessionJwt])

  // we check if the token is valid
  const { data: thirdPartyApp, error: thirdPartyAppDetailsError } = useQuery(
    ["thirdPartyLogin"],
    () => getThirdPartyAppDetails(token!),
    {
      enabled: Boolean(token),
      retry: false,
    }
  )
  const isCorrectCallbackUrl =
    thirdPartyApp &&
    thirdPartyApp.whitelist &&
    thirdPartyApp.whitelist.find((whiteListedUrl: string) =>
      callbackUrl?.includes(whiteListedUrl)
    )

  const { data: user } = useQuery(getMeQueryKeys.me(jwt!), getMe, {
    retry: false,
    enabled: Boolean(jwt),
  })

  const saveProfileSelectionMutation = useMutation(saveProfileSelection, {
    onSuccess: () => handleRedirect(callbackUrl!, jwt!, email!),
  })

  useEffect(() => {
    if (!user) return

    const hasPersonalAccount: boolean = !userHasNoPersonalAccount(user)
    const centersLength = (user?.osteo.centers ?? []).length

    // If no account we display an error messsage
    if (!hasPersonalAccount && centersLength === 0)
      return setUserState("noAccount")

    // If multiple account we display a selection
    if ((hasPersonalAccount && centersLength > 0) || centersLength > 1)
      return setUserState("multipleAccount")

    // If only one personal account we save the profile selection
    if (hasPersonalAccount)
      return saveProfileSelectionMutation.mutate({ token: token! })

    // If only one center we save the profile selection
    if (centersLength === 1)
      return saveProfileSelectionMutation.mutate({
        token: token!,
        centerId: user.osteo.centers![0].id,
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, callbackUrl, jwt, token])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Ceci n'est pas un email")
          .required("Ce champ est obligatoire"),
        password: Yup.string()
          .min(8, "Le mot de passe doit faire 8 caractères minimum")
          .required("Ce champ est obligatoire"),
      })}
      onSubmit={({ email, password }, { setSubmitting }) => {
        loginMutation.mutate(
          { identifier: email, password },
          {
            onSuccess: ({ data }) => {
              const { jwt } = data
              axiosInstance.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${jwt}`
              setEmail(email)
              setJwt(jwt)
              setSubmitting(false)
            },
            onError: () => {
              setSubmitting(false)
            },
          }
        )
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <Flex
            flex={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            h="100vh"
          >
            <Flex w="67%" maxW={500} flexDirection="column" alignItems="center">
              <ALogo mt={0} />
              <Heading
                as="h1"
                fontSize={28}
                color="tertiary.500"
                whiteSpace="nowrap"
                mb={12}
              >
                Connexion tierce à Andrew® Pro
              </Heading>

              {userState === "multipleAccount" ? (
                <Box>
                  <Text>
                    Vous possédez plusieurs comptes. Veuillez sélectionner celui
                    avec lequel vous souhaitez vous connecter.{" "}
                  </Text>
                  <Flex flexDir="column" mt={6} gap={4}>
                    {!userHasNoPersonalAccount(user!) && (
                      <Flex
                        as="button"
                        alignItems="center"
                        p={2}
                        border="1px solid"
                        borderColor="primary.500"
                        borderRadius="10px"
                        cursor="pointer"
                        _hover={{ bg: "primary.500", color: "white" }}
                        onClick={() =>
                          saveProfileSelectionMutation.mutate({ token: token! })
                        }
                      >
                        <AUserAvatar user={user!} />
                        <Text ml={2} fontSize={18}>
                          {user?.osteo.firstname} {user?.osteo.lastname}
                        </Text>
                      </Flex>
                    )}
                    {(user?.osteo.centers ?? []).map((center) => (
                      <Flex
                        as="button"
                        alignItems="center"
                        key={center.id}
                        p={2}
                        border="1px solid"
                        borderColor="primary.500"
                        borderRadius="10px"
                        cursor="pointer"
                        _hover={{ bg: "primary.500", color: "white" }}
                        onClick={() =>
                          saveProfileSelectionMutation.mutate({
                            token: token!,
                            centerId: center.id,
                          })
                        }
                      >
                        <ACenterAvatar avatar={center.avatar} />
                        <Text ml={2} fontSize={18}>
                          {center.name}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </Box>
              ) : (
                <>
                  {" "}
                  <Box w="100%" mb={5}>
                    <Field name="email">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel mb="8px" fontSize={14}>
                            Email pro
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="Email pro"
                            bg="white"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box w="100%">
                    <Field name="password">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                        >
                          <FormLabel mb="8px" fontSize={14}>
                            Mot de passe (min 8 caractères)
                          </FormLabel>
                          <AInputPassword
                            autoComplete="current-password"
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors.password}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Flex justifyContent="flex-end" w="100%" mt={4}>
                    <Link
                      as={RouterLink}
                      to={`/forgot-password`}
                      color="tertiary.500"
                      textDecor="underline"
                      fontWeight={700}
                      fontSize={14}
                    >
                      Mot de passe oublié ?
                    </Link>
                  </Flex>
                </>
              )}
            </Flex>
            <>
              {isMissingParams && (
                <Text mt={10} color="danger.400" fontWeight={700}>
                  Des paramètres nécessaires à la connexion tierce sont
                  manquants.
                </Text>
              )}

              {!isMissingParams && !isCorrectCallbackUrl && (
                <Text mt={10} color="danger.400" fontWeight={700}>
                  L'url de redirection n'est pas correcte.
                </Text>
              )}

              {!isMissingParams && thirdPartyAppDetailsError && (
                <Text mt={10} color="danger.400" fontWeight={700}>
                  Le token de connexion est invalide.
                </Text>
              )}

              {userState === "noAccount" && (
                <Text mt={10} color="danger.400" fontWeight={700}>
                  Vous ne disposez pas d'un compte sur l'application Andrew Pro.
                </Text>
              )}
            </>
            {userState !== "multipleAccount" && (
              <ASubmitButton
                text="Se connecter"
                px={109}
                py={3}
                mt={10}
                isDisabled={
                  isMissingParams ||
                  Boolean(thirdPartyAppDetailsError) ||
                  saveProfileSelectionMutation.isLoading ||
                  !isCorrectCallbackUrl
                }
              />
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default ThirdPartyLogin

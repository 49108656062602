import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import React from "react"
import { SelectedDiscoverType } from "api/appointmentReason"
import SelectedDiscoverCard from "./components/SelectedDiscoverCard"
import { EducationalContentType } from "api/educationalContent"

type CombinedContentType = SelectedDiscoverType | EducationalContentType

function isSelectedDiscoverType(obj: any): obj is SelectedDiscoverType {
  return "type" in obj && "media" in obj
}

interface IProps<T extends CombinedContentType> {
  selectedDiscovers: T[]
  setSelectedDiscovers: React.Dispatch<React.SetStateAction<T[]>>
}

function SelectedDiscovers<T extends CombinedContentType>({
  selectedDiscovers,
  setSelectedDiscovers,
}: IProps<T>) {
  return (
    <Box w="full">
      <Box pos="sticky" top={4} maxH={"calc(100vh - 120px)"} overflow="auto">
        <Box
          p={4}
          borderRadius={9}
          bg={"primary.200"}
          opacity={"1"}
          w={348}
          ml={6}
        >
          {selectedDiscovers.length > 6 && (
            <Box
              pos="absolute"
              transform="translateY(calc(-100% - 30px))"
              p={4}
              bg="secondary.400"
              borderRadius={9}
              w="100%"
              left={0}
            >
              <Text fontSize={14}>
                Nous vous recommandons de limiter le nombre de contenus
                sélectionnés à 6 contenus maximum.
              </Text>
            </Box>
          )}
          <Heading ml={2} fontSize={18} mb={2}>
            Contenus sélectionnés ({selectedDiscovers.length || 0})
          </Heading>
          {selectedDiscovers.length > 6 && (
            <Text fontSize={14} color="primary.700" ml={2}>
              Vous avez dépassé la limite de 6 contenus maximum recommandés.
            </Text>
          )}
          <Flex
            flexDir="column"
            gap={2}
            mt={4}
            as="ul"
            aria-label="Liste des contenus sélectionnés"
          >
            {selectedDiscovers.length > 0 ? (
              selectedDiscovers.map((selectedDiscover, i) => (
                <SelectedDiscoverCard
                  key={selectedDiscover.id}
                  selectedDiscover={selectedDiscover}
                  setSelectedDiscovers={setSelectedDiscovers}
                  position={i + 1}
                  size={selectedDiscovers.length}
                  isDisabled={false}
                  isSelectedDiscoverType={isSelectedDiscoverType(
                    selectedDiscover
                  )}
                />
              ))
            ) : (
              <Text ml={3} fontSize={12}>
                Aucun contenu sélectionné
              </Text>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default SelectedDiscovers

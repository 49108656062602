import { useDisclosure } from "@chakra-ui/react"
import AButton from "components/AButton"
import NewExerciceModal from "pages/NewProgram/components/NewExerciceModal"
import React from "react"
import { FaPlus } from "react-icons/fa"

interface UploadExerciceProps {
  appointmentReason?: number
}

const UploadExercice = ({ appointmentReason }: UploadExerciceProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <AButton
        text="Télécharger un exercice"
        variant="custom"
        onClick={onOpen}
        ml={3}
        leftIcon={<FaPlus />}
        bg="rgba(0, 0, 0, 0.08)"
        color="common.900"
        borderRadius={"4px"}
        _hover={{
          bg: "rgba(0, 0, 0, 0.20)",
        }}
        _focus={{
          bg: "rgba(0, 0, 0, 0.20)",
        }}
        py="4px"
        px="8px"
      />
      <NewExerciceModal
        isOpen={isOpen}
        onClose={onClose}
        appointmentReason={appointmentReason}
      />
    </>
  )
}
export default UploadExercice

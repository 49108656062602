import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import React, { useEffect } from "react"
import arrowBack from "../../assets/arrow-back.svg"
import {
  useNavigate,
  useSearchParams,
  Link as RouterLink,
} from "react-router-dom"
import { getTopic } from "../../api/topics"
import { MediaAuthorType, MediaDifficutType, MediaType } from "../../api/media"
import AuthorFilter from "./components/AuthorFilter"
import DifficultyFilter from "./components/DifficultyFilter"
import VideoItem from "../Explorer/components/VideoItem"
import AuthorDescription from "./components/AuthorDescription"
import PodcastItem from "../Explorer/components/PodcastItem"

const Topic = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const id = searchParams.get("id")
  const { data, isLoading } = useQuery(["getTopic", id], () =>
    getTopic(id || "")
  )
  const [displayedMedias, setDisplayedMedias] = React.useState<MediaType[]>([])

  const isAudioOnlyMedia = displayedMedias.every(
    (media) => media.type === "audio" || media.type === "podcast"
  )

  const [authorFilter, setAuthorFilter] = React.useState<number | null>(null)
  const [difficultyFilter, setDifficultyFilter] =
    React.useState<MediaDifficutType | null>(null)

  useEffect(() => {
    setDisplayedMedias(data?.medias || [])
  }, [data])

  useEffect(() => {
    const filteredMedias =
      data?.medias.filter((media) => {
        if (authorFilter && media.author.id !== authorFilter) {
          return false
        }
        if (difficultyFilter && media.difficulty !== difficultyFilter) {
          return false
        }
        return true
      }) ?? []
    setDisplayedMedias(filteredMedias)
  }, [authorFilter, difficultyFilter, data?.medias])

  const authors = data?.medias?.reduce(
    (acc: MediaAuthorType[], media: MediaType) =>
      acc.some((current) => current.id === media.author.id)
        ? acc
        : [...acc, media.author],
    []
  )

  let difficulties: MediaDifficutType[] = []

  if (data?.medias?.find((media) => media.difficulty === "easy"))
    difficulties = [...difficulties, "easy"]
  if (data?.medias?.find((media) => media.difficulty === "normal"))
    difficulties = [...difficulties, "normal"]
  if (data?.medias?.find((media) => media.difficulty === "hard"))
    difficulties = [...difficulties, "hard"]

  if (id === null) navigate("/")

  if (isLoading)
    return (
      <Center h="calc(100vh - 140px)">
        <Spinner color="primary.500" />
      </Center>
    )
  return (
    <Box>
      <Link as={RouterLink} to="/explorer">
        <Flex alignItems="center">
          <Image src={arrowBack} mr={2} />
          <Text>Retour</Text>
        </Flex>
      </Link>

      <Heading as="h1" mt={7} mb={8} fontSize={28} color="tertiary.500">
        {data?.title}
      </Heading>
      {displayedMedias.length === 0 && <Text>Aucune donnée disponible</Text>}
      {authors && authors.length === 1 && (
        <AuthorDescription author={authors[0]} />
      )}
      {authors && authors.length > 1 && (
        <Flex gap={4} mb={6}>
          {authors?.map((author) => (
            <AuthorFilter
              key={author.id}
              author={author}
              setAuthorFilter={setAuthorFilter}
              isSelected={author.id === authorFilter}
            />
          ))}
        </Flex>
      )}
      {authorFilter &&
        authors?.find((author) => author.id === authorFilter) && (
          <AuthorDescription
            author={authors?.find((author) => author.id === authorFilter)!}
          />
        )}
      {difficulties && difficulties.length > 1 && (
        <Flex gap={4} mb={6}>
          {difficulties?.map((difficulty) => (
            <DifficultyFilter
              key={difficulty}
              difficulty={difficulty}
              setDifficultyFilter={setDifficultyFilter}
              isSelected={difficulty === difficultyFilter}
            />
          ))}
        </Flex>
      )}
      <Flex
        flexWrap="wrap"
        direction={isAudioOnlyMedia ? "column" : "row"}
        gap={4}
      >
        {displayedMedias?.map((media) => {
          if (media.type === "audio" || media.type === "podcast")
            return <PodcastItem key={media.id} media={media} />
          return <VideoItem key={media.id} media={media} size="large" />
        })}
      </Flex>
    </Box>
  )
}

export default Topic

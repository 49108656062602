import React from "react"
import { SVGProps } from "../type"

const Migraine: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="40"
      fill="none"
      viewBox="0 0 51 40"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M14 3.526c-12 2.4-13 16-12 22.5v9.5c16.4-14.78 37.5-5.492 46 1l.5-2.5c1.2-7.6.5-14.5 0-17C46.5.526 29 .526 14 3.526z"
      ></path>
    </svg>
  )
}

export default Migraine

import { Box, Flex, IconButton, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import { CloseIcon } from "@chakra-ui/icons"

interface IProps {
  title: string
  url: string
}
const SuggestedVideo: React.FC<IProps> = ({ title, url }) => {
  const [isShown, setIsShown] = useState(true)

  if (!isShown) return null
  return (
    <Flex
      w={"582px"}
      borderRadius={"8px"}
      bg={"tertiary.200"}
      textAlign={"center"}
      direction={"column"}
      position={"relative"}
      p={4}
      alignItems={"center"}
      gap={4}
      mb={8}
    >
      <IconButton
        aria-label="close suggested video"
        icon={<CloseIcon />}
        size={"sm"}
        position={"absolute"}
        right={2}
        top={3}
        onClick={() => setIsShown(false)}
        background={"none"}
        _hover={{
          background: "none",
        }}
        width={"26px"}
        height={"29px"}
      />
      <Flex alignItems={"center"} gap="24px">
        <Box borderRadius={"8px"} overflow={"hidden"} w={"280px"} h={"157.5px"}>
          <iframe
            width="280"
            height="157.5"
            src={url}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
        <Text fontSize={"16px"} flex={1}>
          {title}
        </Text>
      </Flex>
    </Flex>
  )
}

export default SuggestedVideo

import { MAX_AVATAR_SIZE, MAX_AVATAR_SIZE_VALUE } from "constants/avatar"

const validateAvatarSize = {
  name: "avatar",
  message: `Le fichier est trop volumineux (max ${MAX_AVATAR_SIZE})`,
  test: (avatar?: File) => {
    return (avatar?.size ?? 0) < MAX_AVATAR_SIZE_VALUE
  },
} as any

export default validateAvatarSize

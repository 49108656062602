import ReactPaginate, { ReactPaginateProps } from "react-paginate"
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import styles from "./ATablePagination.module.css"

// HELPERS
const zeroToOneBaseIndex = (zeroBasedPage: number) => zeroBasedPage + 1
const oneToZeroBaseIndex = (oneBasedPage: number) =>
  Math.max(0, oneBasedPage - 1)

// COMPONENTS
interface ATablePaginationProps {
  page: number // starts at 1
  pageCount: number
  onPageChange: (selected: number) => void
}
const ATablePagination = ({
  pageCount,
  onPageChange,
  page,
}: ATablePaginationProps) => {
  const handlePageChange: ReactPaginateProps["onPageChange"] = ({
    selected,
  }) => {
    onPageChange(zeroToOneBaseIndex(selected))
  }

  const forcePageOrUndefined =
    pageCount > 0 ? oneToZeroBaseIndex(page) : undefined

  if (pageCount < 2) {
    return null
  }

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<ChevronRightIcon aria-hidden w={6} h={6} />}
      onPageChange={handlePageChange}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      previousLabel={<ChevronLeftIcon aria-hidden w={6} h={6} />}
      pageClassName={styles.page}
      pageLinkClassName={styles.pageLink}
      previousClassName={styles.previous}
      previousLinkClassName={styles.previousLink}
      nextClassName={styles.next}
      nextLinkClassName={styles.nextLink}
      breakClassName={styles.break}
      breakLinkClassName={styles.breakLink}
      containerClassName={styles.container}
      activeClassName={styles.active}
      nextAriaLabel="Suivant"
      previousAriaLabel="Précédent"
      forcePage={forcePageOrUndefined}
    />
  )
}

export default ATablePagination

const getChartData = (data: any[] | undefined, labels: string[]) => {
  const chartDataCumuled = labels.map((label) => ({
    name: label,
    data:
      data?.map((item, index) =>
        data?.slice(0, index + 1).reduce((acc, curr) => acc + curr.value, 0)
      ) || [],
  }))

  return chartDataCumuled
}

export default getChartData

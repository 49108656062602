import {
  AppointmentReasonType,
  SelectedExerciceType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
  TrainingVideoUploadedType,
  VideoType,
} from "api/appointmentReason"
import axiosInstance from "api/axiosInstance"
import { TrainingVideoExerciceType } from "api/trainingVideoExerciceType"

export type TrainingVideoPrograms = {
  id: number
  trainingVideo?: VideoType
  trainingVideoUploaded?: TrainingVideoUploadedType
  duration: number | null
  series: number
  repetitions: number | null
  restDuration: number
  type: TrainingVideoExerciceType
  side: "both" | "left" | "right" | null
  canUseWeight?: boolean
  weight: number | null
  recommendation: string | null
}

export type ProgramType = {
  id: number
  title: string
  appointmentReasons: AppointmentReasonType[]
  level: number
  trainingVideoPrograms: TrainingVideoPrograms[]
}

export type CreateProgramType = {
  program: {
    title: string
    appointmentReasons: number[]
    level: number
  }
  videoPrograms: SelectedExerciceType[]
  videoUploadedPrograms: SelectedUploadedExerciceType[]
  videoOrder: SelectedVideoOrderType[]
}

export const getMyPrograms = async () => {
  const { data } = await axiosInstance.get<ProgramType[]>(
    `/programs/getMyPrograms`
  )
  return data
}

export const createProgram = async (data: CreateProgramType) => {
  const response = await axiosInstance.post<ProgramType[]>(
    `/programs/createProgram`,
    { ...data }
  )
  return response.data
}

export const getCommonPrograms = async () => {
  const { data } = await axiosInstance.get<ProgramType[]>(`/common-program/all`)
  return data
}

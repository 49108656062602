import { AddIcon } from "@chakra-ui/icons"
import { Button, ButtonProps } from "@chakra-ui/react"
import React from "react"

const theme = {
  active: {
    bg: "white",
    color: "common.500",
    _hover: {
      bg: "primary.100",
      color: "common.500",
    },
  },
  default: {
    bg: "primary.300",
    color: "common.500",
    _hover: {
      bg: "primary.100",
      color: "common.500",
    },
  },
}

interface IProps extends ButtonProps {
  size?: "md" | "lg"
  text: string
  state?: "default" | "active"
}

const ASelectableButton: React.FC<IProps> = ({
  size = "md",
  text,
  state = "default",
  ...props
}) => {
  return (
    <Button
      bg={theme[state].bg}
      color={theme[state].color}
      _hover={{
        bg: theme[state]._hover.bg,
        color: theme[state]._hover.color,
      }}
      borderRadius="4px"
      py={size === "md" ? "6px" : "8px"}
      w="full"
      {...props}
      fontSize={14}
    >
      <span>{text}</span>
    </Button>
  )
}

export default ASelectableButton

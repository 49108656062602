import { TopicCategoryEnum } from "../api/topics"

export default function getCategory(code: TopicCategoryEnum): string {
  switch (code) {
    case "mind":
      return "L'esprit"
    case "body":
      return "Le corps"
    case "podcast":
      return "Podcasts"
    default:
      return "L’esprit"
  }
}

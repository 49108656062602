import {
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  Spinner,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
interface ANewFeaturesModalProps {
  isOpen: boolean
  onClose: () => void
  queryData: {
    data?: any
    isLoading: boolean
    isError: boolean
  }
}

const ANewFeaturesModal = ({
  isOpen,
  onClose,
  queryData,
}: ANewFeaturesModalProps) => {
  const { data, isLoading, isError } = queryData
  const navigate = useNavigate()
  if (isError || !data) return null
  const url = data.attributes.image.data[0].attributes.url

  const hasLink = data.attributes.link !== null

  const redirect = () => {
    if (!hasLink || isLoading) {
      return
    }
    onClose()
    navigate(data.attributes.link)
  }

  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW={724}
        borderRadius={8}
        bg="primary.100"
        color="secondary.900"
        aria-label="Nouveautés"
        cursor={hasLink ? "pointer" : "auto"}
        onClick={() => redirect()}
      >
        {isLoading ? (
          <Spinner color="primary.500" />
        ) : (
          <Image
            w="full"
            h="auto"
            src={url}
            alt="Nouveautés"
            zIndex={0}
            borderRadius={6}
          />
        )}
      </ModalContent>
    </Modal>
  )
}

export default ANewFeaturesModal

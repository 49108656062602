import { AppointmentReasonType, VideoBunnyCDNType } from "api/appointmentReason"
import axiosInstance from "./axiosInstance"

export type EducationalContentType = {
  id: number
  name: string
  appointmentReasons: AppointmentReasonType[]
  groups: EducationalGroupContentType[]
  video_BunnyID: string
  bunnyCDN?: VideoBunnyCDNType
  onlyForPro: boolean
}

export type EducationalGroupContentType = {
  id: number
  name: string
  content: EducationalContentType[]
}

export const getAllEducationalContent = async () => {
  const { data } = await axiosInstance.get<EducationalContentType[]>(
    `/educational-group-content/all`
  )
  return data
}

export const getAllProfessionalEducationalContent = async () => {
  const { data } = await axiosInstance.get<EducationalContentType[]>(
    `/educational-group-content/all-professional`
  )
  return data
}

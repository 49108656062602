import { Center, Flex, Heading, Text } from "@chakra-ui/react"
import React from "react"

const TeleconsultationPaymentSuccess = () => {
  return (
    <Center>
      <Flex
        direction="column"
        align="center"
        justify="center"
        gap={4}
        marginTop={10}
      >
        <Heading as="h1" size="xl" color="tertiary.500">
          Paiement effectué avec succès
        </Heading>
        <Text>Votre paiement a été effectué avec succès.</Text>
        <Text>Vous pouvez fermer cet onglet.</Text>
        <Text>Merci pour votre confiance.</Text>
      </Flex>
    </Center>
  )
}

export default TeleconsultationPaymentSuccess

import React from "react"
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
} from "@chakra-ui/react"
import useToast from "../../hooks/useToast"
import * as Yup from "yup"
import AButton from "../../components/AButton"
import { MeType, resetPassword } from "../../api/user"
import { useMutation } from "@tanstack/react-query"
import { Field, Form, Formik } from "formik"
import { useSearchParams } from "react-router-dom"
import Cover from "../../assets/andrew-cover.png"
import ALogo from "../../components/ALogo"
import AInputPassword from "components/AInputPassword"

interface MyFormValues {
  password: string
  passwordConfirmation: string
}
const ResetPassword = () => {
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const resetPasswordMutation = useMutation(resetPassword)
  const toast = useToast()
  const initialValues: MyFormValues = { password: "", passwordConfirmation: "" }
  const [searchParams] = useSearchParams()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(8, "Le mot de passe doit faire 8 caractères minimum")
          .matches(
            new RegExp(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!&$*?_\-()+#=~@%,]{8,}$/
            ),
            `Pas si vite ! Votre mot de passe doit contenir au minimum 8 caractères, dont au moins :
1 majuscule ;
1 minuscule ;
1 chiffre.
Voici les caractères spéciaux autorisés : ! & $ * ? - _ ( ) + # = @ %`
          )
          .required("Ce champ est obligatoire"),
        passwordConfirmation: Yup.string()
          .min(8, "Le mot de passe doit faire 8 caractères minimum")
          .oneOf(
            [Yup.ref("password"), null],
            "Les mots de passe ne correspondent pas"
          ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { password, passwordConfirmation } = values
        if (password !== passwordConfirmation) {
          toast({
            status: "error",
            title: "Les mots de passe ne correspondent pas",
          })
          setSubmitting(false)
          return
        }

        const code = searchParams.get("code")
        if (!code) return
        resetPasswordMutation.mutate(
          { code, password, passwordConfirmation },
          {
            onSuccess: (data: MeType) => {
              toast({
                status: "success",
                title: "Votre mot de passe a bien été modifié",
              })
              setSubmitting(false)
              setFormSubmitted(true)
            },
            onError: () => {
              setSubmitting(false)
              toast({
                status: "error",
                title: "Une erreur s'est produite",
              })
            },
          }
        )
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <Flex alignItems="center">
            <Flex
              flex={1}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Flex
                w={{ base: "calc(100vw - 48px)", lg: "67%" }}
                flexDirection="column"
                alignItems="center"
              >
                <ALogo
                  mb={{ base: 8, lg: 63 }}
                  mt={{ base: 12, lg: 0 }}
                  hidePro
                />
                {formSubmitted ? (
                  <Heading
                    mb={{ base: 8, lg: 238 }}
                    maxW={440}
                    textAlign="center"
                    color="tertiary.500"
                    fontSize={22}
                  >
                    Mot de passe modifié. Vous pouvez maintenant vous connecter.
                  </Heading>
                ) : (
                  <div>
                    <Heading
                      mb={12}
                      maxW={440}
                      textAlign="center"
                      color="tertiary.500"
                      fontSize={22}
                    >
                      Veuillez définir un nouveau un mot de passe
                    </Heading>

                    <Box w="100%" mb={5}>
                      <Field name="password">
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                          >
                            <FormLabel mb="8px" fontSize={14}>
                              Mot de passe (8 caractère minimum, incluant au
                              moins une minuscule, majuscule et un chiffre)
                            </FormLabel>
                            <AInputPassword
                              autoComplete="new-password"
                              {...field}
                            />
                            <FormErrorMessage whiteSpace="pre-wrap">
                              {form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box w="100%">
                      <Field name="passwordConfirmation">
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            isInvalid={
                              form.errors.passwordConfirmation &&
                              form.touched.passwordConfirmation
                            }
                          >
                            <FormLabel mb="8px" fontSize={14}>
                              Confirmation du mot de passe
                            </FormLabel>
                            <AInputPassword {...field} />
                            <FormErrorMessage>
                              {form.errors.passwordConfirmation}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </div>
                )}
              </Flex>

              {formSubmitted ? (
                <Text
                  fontSize={16}
                  mt={{ base: 12, lg: 152 }}
                  textAlign="center"
                >
                  Votre mot de passe a bien été modifié.
                  <br />
                  Vous pouvez désormais fermer cette page et vous connecter avec
                  votre nouveau mot de passe.
                </Text>
              ) : (
                <AButton
                  type="submit"
                  text="Modifier le mot de passe"
                  px={{ base: 16, lg: 109 }}
                  py={3}
                  mt={{ base: 12, lg: 152 }}
                  isLoading={isSubmitting}
                />
              )}
            </Flex>
            <Box flex={1} display={{ base: "none", lg: "block" }}>
              <Image
                src={Cover}
                alt="Andrew"
                w="100%"
                h="100vh"
                objectFit="cover"
              />
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPassword

import React from "react"
import { SelectedDiscoverType } from "api/appointmentReason"
import ABabyDiscoverCardBase from "../ABabyDiscoverCardBase/ABabyDiscoverCardBase"
import { MediaType } from "api/media"

interface IProps {
  media: MediaType
  selectedDiscovers: SelectedDiscoverType[]
  setSelectedDiscovers: React.Dispatch<
    React.SetStateAction<SelectedDiscoverType[]>
  >
}

const ABabyDiscoverCard: React.FC<IProps> = ({
  media,
  selectedDiscovers,
  setSelectedDiscovers,
}) => {
  const isSelected = selectedDiscovers.some(
    (discover) => discover.id === media.id
  )

  const onAdd = () => {
    setSelectedDiscovers((prev) => [
      ...prev,
      {
        ...media,
      },
    ])
  }

  return (
    <ABabyDiscoverCardBase
      media={media}
      isDisabled={isSelected}
      onAdd={onAdd}
    />
  )
}

export default ABabyDiscoverCard

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import useToast from "../hooks/useToast"
import { Field, Form, Formik, FormikProps } from "formik"
import { useMutation } from "@tanstack/react-query"
import React, { RefObject } from "react"
import { OsteoResponseType, updateOsteo } from "../api/osteo"
import { uploadAvatar } from "api/upload"
import { MeType, UserOsteoType } from "../api/user"
import * as Yup from "yup"
import { useAppContext } from "AppContext"
import userHasNoPersonalAccount from "utils/user/userHasNoPersonalAccount"
import validateAddress from "utils/validators/validateAddress"
import AAdressField from "components/Field/AAdressField"
import AUserAvatarField from "components/Field/AUserAvatarField"
import validateAvatarSize from "utils/validators/validateAvatarSize"
import { AVATAR_LABEL } from "constants/avatar"
import { ProfileForm, ReminderDataType } from "pages/Profile/Profile"

// CONSTANTS
const telephoneRequiredSchema = Yup.string()
  .required("Ce champ est obligatoire")
  .matches(
    new RegExp(/([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/),
    "Ceci n'est pas un numéro de téléphone"
  )

const telephoneSchema = Yup.string().matches(
  new RegExp(/([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/),
  "Ceci n'est pas un numéro de téléphone"
)
interface IProps {
  user: MeType | null
  setUser: React.Dispatch<React.SetStateAction<MeType | null>>
  formRef: RefObject<FormikProps<ProfileForm>>
  reminderData: ReminderDataType
  setIsFormLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const EditProfil: React.FC<IProps> = ({
  user,
  setUser,
  formRef,
  reminderData,
  setIsFormLoading,
}) => {
  const { currentScope } = useAppContext()
  const uploadAvatarMutation = useMutation(uploadAvatar)
  const updateOsteoMutation = useMutation(updateOsteo)
  const toast = useToast()
  const initialAvatar = user?.osteo?.avatar?.url
  const hasNoPersonalAccount = userHasNoPersonalAccount(user)

  const isAcademieProfileOnly =
    currentScope?.type === "academy" && hasNoPersonalAccount
  const isCenterProfileOnly =
    currentScope?.type === "center" && hasNoPersonalAccount

  const formatData = (data: OsteoResponseType): UserOsteoType => ({
    ...data,
    avatar: { url: data.avatar?.data?.attributes?.url },
  })

  const validationSchema =
    isAcademieProfileOnly || isCenterProfileOnly
      ? Yup.object().shape({
          lastname: Yup.string().required("Nom requis"),
          firstname: Yup.string().required("Prénom requis"),
          email: Yup.string()
            .email("Ceci n'est pas un email")
            .required("Email requis"),
          avatar: Yup.mixed().test(validateAvatarSize),
          telephone: telephoneSchema,
        })
      : Yup.object().shape({
          lastname: Yup.string().required("Nom requis"),
          firstname: Yup.string().required("Prénom requis"),
          email: Yup.string()
            .email("Ceci n'est pas un email")
            .required("Email requis"),
          avatar: Yup.mixed().test(validateAvatarSize),
          urlDoctolib: Yup.string(),
          telephone: telephoneRequiredSchema,
          mainAddress: Yup.object().test(validateAddress),
          urlGoogleMyBusiness: Yup.string().matches(
            new RegExp(/\/review$/),
            `Le lien n'est pas valide`
          ),
        })

  return (
    <Flex direction="column">
      <Box flex={1} bg="white" borderRadius={9} p={8} mr={6}>
        <Formik
          innerRef={formRef}
          initialValues={{
            avatar: initialAvatar ?? "",
            firstname: user?.osteo?.firstname ?? "",
            lastname: user?.osteo?.lastname ?? "",
            urlDoctolib: user?.osteo?.urlDoctolib ?? "",
            telephone: user?.osteo?.telephone ?? "",
            email: user?.email ?? "",
            mainAddress: user?.osteo?.mainAddress,
            secondaryAddress: user?.osteo?.secondaryAddress,
            urlGoogleMyBusiness: user?.osteo?.urlGoogleMyBusiness ?? "",
          }}
          validationSchema={validationSchema}
          onSubmit={({ avatar, ...values }, { setSubmitting }) => {
            if (!user?.osteo?.id) return
            //setSubmitting(true)
            setIsFormLoading(true)
            if (avatar && avatar !== initialAvatar) {
              let file = new FormData()
              file.append("files", avatar)
              uploadAvatarMutation.mutate(file, {
                onError: () => {
                  //setSubmitting(false)
                  setIsFormLoading(false)
                  toast({
                    status: "error",
                    title: "Une erreur s'est produite",
                  })
                },
                onSuccess: (fileId: string) => {
                  updateOsteoMutation.mutate(
                    {
                      id: user?.osteo?.id?.toString(),
                      data: {
                        ...values,
                        avatar: fileId,
                      },
                    },
                    {
                      onSuccess: (data: OsteoResponseType) => {
                        //setSubmitting(false)
                        setIsFormLoading(false)

                        setUser(
                          (prev) =>
                            prev && {
                              ...prev,
                              osteo: {
                                ...prev.osteo,
                                ...formatData(data),
                              },
                            }
                        )
                        toast({
                          status: "success",
                          title: "Modifications enregistrées",
                        })
                      },
                      onError: () => {
                        //setSubmitting(false)
                        setIsFormLoading(false)
                        toast({
                          status: "error",
                          title: "Une erreur s'est produite",
                        })
                      },
                    }
                  )
                },
              })
            } else {
              updateOsteoMutation.mutate(
                {
                  id: user?.osteo?.id?.toString(),
                  data: { ...values, ...reminderData },
                },
                {
                  onSuccess: (data: OsteoResponseType) => {
                    //setSubmitting(false)
                    setIsFormLoading(false)
                    setUser(
                      (prev) =>
                        prev && {
                          ...prev,
                          osteo: { ...prev.osteo, ...formatData(data) },
                        }
                    )
                    toast({
                      status: "success",
                      title: "Modifications enregistrées",
                    })
                  },
                  onError: () => {
                    //setSubmitting(false)
                    setIsFormLoading(false)
                    toast({
                      status: "error",
                      title: "Une erreur s'est produite",
                    })
                  },
                }
              )
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Flex justifyContent="space-between" alignItems="center" mb={6}>
                <AUserAvatarField
                  name="avatar"
                  user={user}
                  label={AVATAR_LABEL}
                />
              </Flex>

              <Flex gap={4} mb={6}>
                <Field name="lastname">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.lastname && form.touched.lastname}
                    >
                      <FormLabel fontSize={14}>Nom *</FormLabel>
                      <Input
                        {...field}
                        placeholder="Nom de famille"
                        bg="common.100"
                      />
                      <FormErrorMessage>
                        {form.errors.lastname}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="firstname">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={
                        form.errors.firstname && form.touched.firstname
                      }
                    >
                      <FormLabel fontSize={14}>Prénom *</FormLabel>
                      <Input {...field} placeholder="Prénom" bg="common.100" />
                      <FormErrorMessage>
                        {form.errors.firstname}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>
              <Flex mb={6}>
                <Field name="telephone">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      w="50%"
                      pr={2}
                      isInvalid={
                        form.errors.telephone && form.touched.telephone
                      }
                    >
                      <FormLabel fontSize={14}>Numéro de téléphone *</FormLabel>
                      <Input
                        {...field}
                        bg="common.100"
                        placeholder="06..."
                      />{" "}
                      <FormErrorMessage>
                        {form.errors.telephone}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>
              <Flex mb={6}>
                <Field name="email">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel fontSize={14}>Mail *</FormLabel>
                      <Input
                        {...field}
                        placeholder="Email"
                        bg="common.100"
                        disabled
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>
              {!isAcademieProfileOnly && !isCenterProfileOnly && (
                <>
                  <Flex mb={6}>
                    <Field name="urlDoctolib">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.urlDoctolib && form.touched.urlDoctolib
                          }
                        >
                          <FormLabel fontSize={14}>
                            Url du site de réservation (exemple: doctolib)
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="URL Doctolib"
                            bg="common.100"
                          />
                          <FormErrorMessage>
                            {form.errors.urlDoctolib}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Flex gap={4} mb={6}>
                    <AAdressField
                      formControlProps={{ w: "50%", pr: 2 }}
                      formLabelProps={{ fontSize: 14 }}
                      name="mainAddress"
                      label="Adresse du lieu d'exercice *"
                    />
                  </Flex>
                  <Flex mb={6}>
                    <AAdressField
                      formControlProps={{ w: "50%", pr: 2 }}
                      formLabelProps={{ fontSize: 14 }}
                      name="secondaryAddress"
                      label="Adresse secondaire"
                    />
                  </Flex>
                  <Flex>
                    <Field name="urlGoogleMyBusiness">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.urlGoogleMyBusiness &&
                            form.touched.urlGoogleMyBusiness
                          }
                          mb={6}
                        >
                          <Flex justify={"start"} position={"relative"}>
                            <FormLabel>
                              <Text>
                                Url de partage pour{" "}
                                <Tooltip
                                  label="Obtenir mon lien"
                                  placement="right"
                                >
                                  <Link
                                    target="_blank"
                                    href="https://support.google.com/business/answer/3474122?hl=fr"
                                    textDecoration="underline"
                                  >
                                    avis Google My Business
                                  </Link>
                                </Tooltip>
                              </Text>
                            </FormLabel>
                          </Flex>
                          <Input
                            {...field}
                            bg="common.100"
                            placeholder="https://g.page/r/CeATmWfwbkmWEAI/review"
                          />
                          <FormErrorMessage>
                            {form.errors.urlGoogleMyBusiness}
                          </FormErrorMessage>
                          <Tooltip label="Obtenir mon lien" placement="right">
                            <Link
                              target="_blank"
                              href="https://youtu.be/tLzuMAhj_2I"
                              textDecor="underline"
                              display={"flex"}
                              alignItems={"center"}
                              gap={2}
                              mt={2}
                            >
                              Ou consultez notre vidéo explicative pour obtenir
                              votre lien
                            </Link>
                          </Tooltip>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Box>
      <Box w={210}></Box>
    </Flex>
  )
}

export default EditProfil

import { CopyIcon, InfoIcon } from "@chakra-ui/icons"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Divider,
  Tag,
  Text,
  Flex,
} from "@chakra-ui/react"
import { PatientType } from "api/patient"
import AButton from "components/AButton"
import useCopy from "hooks/useCopy"
import { useRef } from "react"

interface DisclosePatientTempPasswordProps {
  isOpen: boolean
  onClose: () => void
  password: string
  patient?: PatientType
  title: string
  text: string
  closeText: string
}
const DiscloseTempPassword = ({
  isOpen,
  onClose,
  password,
  patient,
  title,
  text,
  closeText,
}: DisclosePatientTempPasswordProps) => {
  const defaultAction = useRef(null)
  const email = patient?.account?.email

  const copy = useCopy(password)

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      leastDestructiveRef={defaultAction}
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {title}
        </AlertDialogHeader>
        <AlertDialogBody textAlign="center">
          <Text>{text}</Text>
          <Text>
            Vous pouvez dès à présent lui communiquer son{" "}
            <Text as="span" fontWeight="bold">
              mot de passe temporaire :
            </Text>
          </Text>
          <Flex mt={4} align="center" justify="center">
            <Tag
              as="button"
              colorScheme="orange"
              onClick={copy}
              userSelect="none"
            >
              {password.substring(0, Math.round(password.length / 2))}
              &nbsp;
              {password.substring(Math.round(password.length / 2))}
              <CopyIcon ml={2} />
            </Tag>
          </Flex>
          <Divider my={4} />
          <Text fontStyle="italic" fontSize={14}>
            <InfoIcon w={4} h={4} mr={2} />
            Un email lui sera également envoyé à l&apos;adresse
          </Text>
          <Text fontStyle="italic" fontWeight="bold" fontSize={14}>
            {email}
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="center">
          <AButton
            ref={defaultAction}
            ml={3}
            text={closeText}
            onClick={onClose}
          />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DiscloseTempPassword

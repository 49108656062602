import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react"
import { CurrencyCode, StripeProductType } from "api/subscriptions"
import { useAppContext } from "AppContext"
import AButton from "components/AButton"
import ASeatsButtonGroup from "components/ASeatsButtonGroup"
import { Dispatch, RefObject, SetStateAction } from "react"
import formatCurrency from "utils/price/formatCurrency"
import formatPrice from "utils/price/formatPrice"
import { getLocalizedCenterPerSeatPrice } from "utils/price/getCenterPrice"
import getCurrencyCodeForCountry from "utils/price/getCurrencyCodeForCountry"

interface AdjustCenterGroupSubscriptionDialogProps {
  cancelRef: RefObject<HTMLButtonElement>
  isOpen: boolean
  onClose: () => void
  onSubscribe: (priceId: string) => void
  seats: number
  setSeats: Dispatch<SetStateAction<number>>
  minSeats: number
  isLoading: boolean
  currentStripeProduct: StripeProductType
}
const AdjustCenterGroupSubscriptionDialog = ({
  cancelRef,
  isOpen,
  onClose,
  onSubscribe,
  seats,
  setSeats,
  minSeats,
  isLoading,
  currentStripeProduct,
}: AdjustCenterGroupSubscriptionDialogProps) => {
  const { user } = useAppContext()
  const currentInterval = user?.businessStripeSubscription?.plan.interval

  const currencyCode = getCurrencyCodeForCountry(
    user?.osteo.mainAddress?.country
  ) as CurrencyCode
  const currencyPrice = currentStripeProduct.allPrices?.find(
    (price) => price.recurring.interval === currentInterval
  )

  if (!currencyPrice) return null

  const interval = currencyPrice?.recurring.interval
  const priceId = currencyPrice.id
  const pricePerSeat = getLocalizedCenterPerSeatPrice(
    currentStripeProduct,
    currencyCode
  )

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="primary.100" p={12} pb={6} minW="764">
          <AlertDialogHeader
            fontSize={22}
            fontWeight={700}
            fontFamily="Montserrat"
            color="tertiary.500"
            textAlign="center"
          >
            Ajustez le nombre de thérapeutes
            <br />
            utilisant Andrew® Pro dans votre centre
          </AlertDialogHeader>

          <AlertDialogBody pb={6}>
            <Flex mt={10} mb={4} justify="center">
              <ASeatsButtonGroup
                seats={seats}
                setSeats={setSeats}
                minSeats={minSeats}
              />
            </Flex>
            <Text color="danger.800" fontSize={16} align="center">
              Pour réduire le nombre de thérapeutes utilisant Andrew® Pro dans
              votre centre, désactivez d'abord le(s) compte(s) inutilisé(s) dans
              le menu « Centre & collaborateurs »
            </Text>
            <Heading as="p" fontSize={18} fontWeight={700} textAlign="center">
              {formatPrice(seats * pricePerSeat)}{" "}
              {formatCurrency(currencyPrice.currency)} / mois
            </Heading>
            {interval === "year" && (
              <Text fontSize={16} align="center">
                facturé annuellement, soit{" "}
                {formatPrice(seats * pricePerSeat * 12)}{" "}
                {formatCurrency(currencyPrice.currency)} par an
              </Text>
            )}
          </AlertDialogBody>

          <AlertDialogFooter
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <AButton
              text="Finaliser la modification"
              type="button"
              onClick={() => onSubscribe(priceId)}
              isLoading={isLoading}
              variant="primary"
            />
            <Flex justifyContent="center" mt={2}>
              <Text
                maxW={416}
                fontSize={16}
                textAlign="center"
                fontWeight={700}
              >
                Votre abonnement actuel sera modifié et vous ne paierez que la
                différence du montant déjà réglé
              </Text>
            </Flex>
            <AButton
              text="Annuler"
              ref={cancelRef}
              onClick={onClose}
              variant="tertiary"
              mt={8}
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default AdjustCenterGroupSubscriptionDialog

import React from "react"
import { SVGProps } from "../type"

const Epaule: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="48"
      fill="none"
      viewBox="0 0 35 48"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M4 24C6 8.4 23.833 2.833 32.5 2c.8 36.4-20.333 44.167-31 43.5 0-.667.5-5.9 2.5-21.5z"
      ></path>
    </svg>
  )
}

export default Epaule

import axiosInstance from "./axiosInstance"

export type ModalType = {
  id: number
  name: string
  image: {
    id: number
    url: string
  }
}
export type ResponseModalType = {
  data: {
    modals: ModalType[]
  }
}

export const getAllModals = async () => {
  const { data } = await axiosInstance.get<ResponseModalType>(
    `/modal?populate=image`
  )
  return data.data
}

export const getOneModal = async (id: number) => {
  const { data } = await axiosInstance.get(`/modal?id=${id}&populate=image`)
  return data.data
}

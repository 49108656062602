import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react"
import arrowBack from "../../../../assets/arrow_back.svg"
import { TraininScheduleType } from "../../../../api/patient"
import React, { useRef, useState } from "react"
import { SCHEDULE_DAYS_MOCK } from "./selectSchedule.mock"
import ScheduleDay from "./components/ScheduleDay"
import AButton from "../../../../components/AButton"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Field, Form, Formik, FormikProps } from "formik"
import * as Yup from "yup"
import { MeType } from "api/user"

export type ReminderDataType = { dateTime: string; text: string }

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  trainingSchedule: TraininScheduleType[]
  setTrainingSchedule: React.Dispatch<
    React.SetStateAction<TraininScheduleType[]>
  >
  handleNewAppointment: (reminderData?: ReminderDataType | undefined) => void
  isSubmitting: boolean
  user: MeType | null
  isAppointmentForKine?: boolean
}

const SelectSchedule: React.FC<IProps> = ({
  setStep,
  trainingSchedule,
  setTrainingSchedule,
  handleNewAppointment,
  isSubmitting,
  user,
  isAppointmentForKine = false,
}) => {
  const [isReminderActive, setIsReminderActive] = useState(true)
  const formRef = useRef<FormikProps<ReminderDataType>>(null)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  const dateInputRef = useRef<HTMLInputElement>(null)

  const handleSubmitForm = () => {
    if (formRef.current && isReminderActive) {
      formRef.current.handleSubmit()
    } else {
      handleNewAppointment()
    }
  }

  const getDateWithDelay = (delay: number) => {
    const currentDate = new Date()
    const newDate = new Date(currentDate.setDate(currentDate.getDate() + delay))

    const formattedDate =
      newDate.toISOString().split(":")[0] +
      ":" +
      newDate.toISOString().split(":")[1]

    return formattedDate
  }

  const getBack = () => {
    isAppointmentForKine ? setStep(3) : setStep(2)
  }

  return (
    <Box p="16px">
      <Flex
        mb={6}
        cursor="pointer"
        onClick={getBack}
        p="8px"
        bgColor="primary.200"
        w="fit-content"
        display="block"
        rounded="9px"
      >
        <Image src={arrowBack} />
      </Flex>
      <Flex justifyContent="center" gap="100px" pb={"100px"}>
        <Flex direction="column">
          <Heading as="h2" color="black" fontSize={24} mb="12px">
            Notifications de rappels d’exercices
          </Heading>
          <Flex alignItems="center" gap={2}>
            <InfoOutlineIcon />
            <Text>
              Prévoyez avec votre patient, un planning de rappel pour effectuer
              ses exercices
            </Text>
          </Flex>
          {SCHEDULE_DAYS_MOCK.map((day) => (
            <ScheduleDay
              day={day}
              key={day.value}
              state={
                trainingSchedule.find(
                  (schedule) => schedule.weekday === day.value
                )
                  ? "selected"
                  : "default"
              }
              setTrainingSchedule={setTrainingSchedule}
              times={
                trainingSchedule.find(
                  (schedule) => schedule.weekday === day.value
                )?.times || []
              }
            />
          ))}
        </Flex>
        <Flex direction="column">
          <Heading as="h2" color="black" fontSize={24} mb="12px">
            Notification personnalisée
          </Heading>
          <Flex alignItems="center" gap={2}>
            <InfoOutlineIcon />
            <Text>
              Prévoyez une notification personnalisée (post accouchement, rappel
              de rendez-vous, post compétition..)
            </Text>
          </Flex>
          <Switch
            as="div"
            whiteSpace="nowrap"
            colorScheme="orange"
            isChecked={isReminderActive}
            onChange={() => setIsReminderActive(!isReminderActive)}
            height="fit-content"
            py="18px"
            display="flex"
            alignItems="center"
            borderRadius="xl"
            fontWeight={"bold"}
            w="fit-content"
          >
            Activé
          </Switch>
          {isReminderActive && (
            <Formik
              validationSchema={Yup.object().shape({
                dateTime: Yup.date().required("Date et heure d'envoi requises"),
                text: Yup.string()
                  .max(1000, "Maximum 1000 caractères")
                  .required("Texte requis"),
              })}
              initialValues={{
                dateTime:
                  user && user.osteo.reminderDelay
                    ? getDateWithDelay(user.osteo.reminderDelay)
                    : "",
                text:
                  user && user.osteo.reminderText
                    ? user.osteo.reminderText
                    : "Comment allez-vous depuis votre dernière consultation ? Rendez-vous avec votre thérapeute pour faire le point.",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                handleNewAppointment(values)
                setSubmitting(false)
              }}
              innerRef={formRef}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Flex alignItems="center" gap="16px">
                    <Field name="dateTime">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.dateTime && form.touched.dateTime
                          }
                        >
                          <Flex
                            justify={"space-between"}
                            align={"center"}
                            mb={2}
                          >
                            <FormLabel fontWeight={700}>
                              Date et heure d'envoi *
                            </FormLabel>
                            <AButton
                              text="Fermer le calendrier"
                              px={2}
                              py={1}
                              fontSize="12px"
                              fontFamily="Montserrat"
                              variant="custom"
                              borderRadius={"8px"}
                              color="black"
                              border="none"
                              bg="white"
                              cursor="pointer"
                              onClick={() => {
                                if (dateInputRef.current)
                                  dateInputRef.current.blur()
                              }}
                              isDisabled={!isCalendarOpen}
                            />
                          </Flex>

                          <Input
                            {...field}
                            type="datetime-local"
                            bg="common.100"
                            placeholder="YYYY-MM-DD HH:MM"
                            ref={dateInputRef}
                            onFocus={() => setIsCalendarOpen(true)}
                            onBlur={() => setIsCalendarOpen(false)}
                            // onChange={(e) => {
                            //   const newDate = e.target.value
                            //   if (remindDate === null) {
                            //     setRemindDate(newDate)
                            //   } else {
                            //     const prevDay = remindDate
                            //       .split("T")[0]
                            //       .slice(-2)
                            //     const newDay = newDate.split("T")[0].slice(-2)
                            //     if (prevDay !== newDay) {
                            //       e.target.blur()
                            //     }
                            //     setRemindDate(newDate)
                            //   }
                            //   field.onChange(e)
                            // }}
                          />
                          <FormErrorMessage>
                            {form.errors.dateTime}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>

                  <Field name="text">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.text && form.touched.text}
                      >
                        <FormLabel mt={6} fontWeight={700}>
                          Texte *
                        </FormLabel>
                        <Textarea
                          {...field}
                          bg="common.100"
                          placeholder="Votre message"
                          height="223px"
                        />
                        <FormErrorMessage>{form.errors.text}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    w="full"
                    gap={2}
                    fontFamily="Montserrat"
                    bg="primary.200"
                    py={6}
                    position="fixed"
                    bottom={0}
                    left={0}
                    zIndex={90}
                  >
                    <AButton
                      text="Programmer"
                      px={120}
                      py={3}
                      fontFamily="Montserrat"
                      type="submit"
                      isLoading={isSubmitting}
                    />
                  </Flex>
                </Form>
              )}
            </Formik>
          )}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={100}
      >
        <AButton
          text={"Créer la consultation"}
          px={120}
          py={3}
          fontFamily="Montserrat"
          onClick={() => {
            handleSubmitForm()
          }}
          isDisabled={isSubmitting}
        />
      </Flex>
    </Box>
  )
}

export default SelectSchedule

import { Checkbox } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  CenterGroupOsteo,
  GetCenterGroupOsteosResponse,
  linkCenterGroupOsteoCenter,
  unlinkCenterGroupOsteoCenter,
} from "api/centerGroup"
import { useAppContext, useCenterAdminCurrentScope } from "AppContext"
import getMeQueryKeys from "constants/queryKeys/getMe"
import useToast from "hooks/useToast"

interface CollaboratorCenterCheckboxProps {
  collaborator: CenterGroupOsteo
  center: CenterType
  queryKey: readonly unknown[]
}
const CollaboratorCenterCheckbox = ({
  collaborator,
  center,
  queryKey,
}: CollaboratorCenterCheckboxProps) => {
  const currentScope = useCenterAdminCurrentScope()
  const { user, jwt } = useAppContext()
  const toast = useToast()
  const queryClient = useQueryClient()

  const isCollaboratorMe = collaborator.id === user?.osteo.id

  const linkCollaboratorCenterMutation = useMutation({
    mutationFn: async () => {
      return linkCenterGroupOsteoCenter({
        centerId: center.id,
        centerGroupId: currentScope.centerGroupId,
        osteoId: collaborator.id,
      })
    },
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      })

      // Snapshot the previous value
      const prevPage =
        queryClient.getQueryData<GetCenterGroupOsteosResponse>(queryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(
        queryKey,
        (CollaboratorsPage?: GetCenterGroupOsteosResponse) => {
          if (CollaboratorsPage) {
            return {
              ...CollaboratorsPage,
              result: (CollaboratorsPage.result || []).reduce(
                (acc, collab) =>
                  collab.id === collaborator.id
                    ? [
                        ...acc,
                        {
                          ...collab,
                          centers: [...(collab.centers ?? []), center],
                        },
                      ]
                    : [...acc, collab],
                [] as CenterGroupOsteo[]
              ),
            }
          }
        }
      )

      // Return a context object with the snapshotted value
      return { prevPage }
    },
    onSuccess: () => {
      toast({
        status: "success",
        title: "Collaborateur associé au centre",
      })
      if (isCollaboratorMe) {
        queryClient.invalidateQueries({ queryKey: getMeQueryKeys.me(jwt!) })
      }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context?.prevPage)
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
  })
  const unlinkCollaboratorCenterMutation = useMutation({
    mutationFn: async () => {
      return unlinkCenterGroupOsteoCenter({
        centerId: center.id,
        centerGroupId: currentScope.centerGroupId,
        osteoId: collaborator.id,
      })
    },
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      })

      // Snapshot the previous value
      const prevPage =
        queryClient.getQueryData<GetCenterGroupOsteosResponse>(queryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(
        queryKey,
        (CollaboratorsPage?: GetCenterGroupOsteosResponse) => {
          if (CollaboratorsPage) {
            return {
              ...CollaboratorsPage,
              result: (CollaboratorsPage.result || []).reduce(
                (acc, collab) =>
                  collab.id === collaborator.id
                    ? [
                        ...acc,
                        {
                          ...collab,
                          centers: (collab.centers ?? []).filter(
                            ({ id }) => id !== center.id
                          ),
                        },
                      ]
                    : [...acc, collab],
                [] as CenterGroupOsteo[]
              ),
            }
          }
        }
      )

      // Return a context object with the snapshotted value
      return { prevPage }
    },
    onSuccess: () => {
      toast({
        status: "success",
        title: "Collaborateur dissocié du centre",
      })
      if (isCollaboratorMe) {
        queryClient.invalidateQueries({ queryKey: getMeQueryKeys.me(jwt!) })
      }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context?.prevPage)
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
  })

  const onLink = () => {
    linkCollaboratorCenterMutation.mutate()
  }

  const onUnlink = () => {
    unlinkCollaboratorCenterMutation.mutate()
  }

  const collaboratorCenterIdsHash = (collaborator.centers ?? []).reduce(
    (acc, { id }) => ({ ...acc, [id]: id }),
    {} as { [id: string]: string }
  )
  const isChecked = collaboratorCenterIdsHash[center.id] !== undefined
  const isIndeterminate = collaborator?.centers === undefined
  const isDisabled = isChecked && (collaborator?.centers?.length ?? 0) < 2
  return (
    <Checkbox
      colorScheme="orange"
      borderColor="primary.500"
      alignItems="start"
      isChecked={isChecked}
      isIndeterminate={isIndeterminate}
      isDisabled={isDisabled}
      onChange={(e) => {
        const nextChecked = e.target.checked
        return nextChecked ? onLink() : onUnlink()
      }}
      aria-label={
        isChecked ? "Exerce dans le centre" : "N'exerce pas dans le centre"
      }
    />
  )
}

export default CollaboratorCenterCheckbox

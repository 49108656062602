import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
// import Router from 'next/router'
import { useCookies } from "react-cookie"
import _services from "./services"

const RgpdContext = React.createContext()

const RgpdProvider = ({
  children,
  scrollAutoConsent,
  changePageAutoConsent,
}) => {
  const [cookies, setCookie, removeCookies] = useCookies(["rgpd"])
  const [services, setServices] = useState([])
  const [bannerStatus, setBannerStatus] = useState("hidden")
  const [customStatus, setCustomStatus] = useState("hidden")
  const [scrollProgress, setScrollProgress] = useState(0)
  const marginWithBanner = bannerStatus === "visible" ? 52 : 0

  const updateCookies = () => {
    const json = services.map(
      (item) => `{"id":"${item.id}","status":"${item.status}"}`
    )
    setCookie("rgpd", `[${json.join(",")}]`, {
      path: "/",
      domain: process.env.FRONT_DOMAIN,
    })
  }

  const getServiceById = (serviceId) => {
    const service = services.filter((e) => e.id === serviceId)
    return service[0] || {}
  }
  const getPendingServices = () =>
    services.filter((e) => e.status === "pending")

  /* allow / deny service by ID */
  const allowServiceById = (serviceId) => {
    const newServices = services.map((service) =>
      service.id === serviceId ? { ...service, status: "allowed" } : service
    )
    setServices(newServices)
  }

  const denyServiceById = (serviceId) => {
    const cookiesToRemove = services.reduce(
      (a, e) =>
        e.id === serviceId && e.cookies && e.status === "allowed"
          ? e.cookies
          : a,
      null
    )
    const newService = services.map((service) =>
      service.id === serviceId ? { ...service, status: "denied" } : service
    )
    setServices(newService)
    if (cookiesToRemove) {
      cookiesToRemove.map((c) => removeCookies(c))
      setTimeout(() => window.location.reload(), 500)
    }
  }

  /* allow / deny all service */
  const allowAllPendingServices = () => {
    const newService = services.map((service) => ({
      ...service,
      status: service.status === "pending" ? "allowed" : service.status,
    }))
    setServices(newService)
  }
  const allowAllServices = () => {
    const newService = services.map((service) => ({
      ...service,
      status: "allowed",
    }))
    setServices(newService)
  }
  const denyAllServices = () => {
    const newService = services.map((service) => ({
      ...service,
      status: "denied",
    }))
    setServices(newService)
  }

  /* show / hide / toggle custom services */
  const hideCustomServices = () => {
    setCustomStatus("hidden")
  }
  const showCustomServices = () => {
    setCustomStatus("visible")
  }
  const toggleCustomServices = () => {
    setCustomStatus(customStatus === "visible" ? "hidden" : "visible")
  }

  /* change le status en allow si la position du scroll depasse la moitié de la hauteur du body */
  const updateWindowScroll = () => {
    if (scrollProgress > 1) return

    const winHeight = window.innerHeight / 2
    const bodyHeight =
      document.body.offsetHeight - window.innerHeight > winHeight
        ? winHeight
        : document.body.offsetHeight - window.innerHeight
    const top =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0)
    const newProgress = top / bodyHeight

    if (newProgress >= 1) {
      allowAllPendingServices()
    }

    setScrollProgress(newProgress)
  }

  /* initialise les services */
  useEffect(() => {
    if (cookies.rgpd) {
      try {
        const newServices = _services.map((service) => {
          const { status } = cookies.rgpd.reduce(
            (a, s) => (s.id === service.id ? s : a),
            { status: null }
          )
          return status ? { ...service, status } : service
        })
        setServices(newServices)
      } catch (error) {
        console.warn("rgpd's cookies not valid. reinitialised.")
      }
    } else setServices(_services)
  }, [])

  /* à chaque fois que le service est actualisé : */
  useEffect(() => {
    const pendingServiceLength = getPendingServices().length
    const handlerScroll = () => updateWindowScroll()
    const handlerRouteChange = () => allowAllPendingServices()

    /* cache le menu s'il n'y a plus de status pending dans les services */
    if (pendingServiceLength === 0) setBannerStatus("hidden")
    else {
      /* s'il reste des service en pending, on affiche la banner */
      setBannerStatus("visible")

      /* event sur le changement de page et le scroll */
      if (pendingServiceLength) {
        if (scrollAutoConsent) window.addEventListener("scroll", handlerScroll)
        // if (changePageAutoConsent)
        //   Router.events.on('routeChangeStart', handlerRouteChange)
      }
    }

    /* sauvegarde les changements des services en local */
    updateCookies()

    return () => {
      window.removeEventListener("scroll", handlerScroll)
      // Router.events.off('routeChangeStart', handlerRouteChange)
    }
  }, [services])

  return (
    <RgpdContext.Provider
      value={{
        services,
        getServiceById,
        allowServiceById,
        denyServiceById,
        allowAllServices,
        denyAllServices,
        bannerStatus,
        setBannerStatus,
        customStatus,
        hideCustomServices,
        showCustomServices,
        toggleCustomServices,
        scrollProgress,
        marginWithBanner,
      }}
    >
      {children}
    </RgpdContext.Provider>
  )
}

RgpdProvider.propTypes = {
  scrollAutoConsent: PropTypes.bool,
  changePageAutoConsent: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

RgpdProvider.defaultProps = {
  scrollAutoConsent: false,
  changePageAutoConsent: false,
}

export default RgpdContext
export { RgpdProvider }

import { Box, Flex, Spinner, Text } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { curryIncludesInsensitive } from "utils/includesInsensitive"
import {
  AppointmentReasonTypePaginated,
  getAppointmentReason,
  GetAppointmentReasonParams,
  getLikedVideosForAppointmentReason,
  getUploadedVideosForAppointmentReason,
  TrainingVideoGroupsType,
  VideoGroupCategoryType,
  VideoType,
} from "api/appointmentReason"
import {
  PATHOLOGIES_MOCK,
  ZonePathologieType,
  getZones,
} from "pages/NewAppointment/newAppointment.mock"
import VideoGroup from "./components/VideoGroup/VideoGroup"
import { getCommonPrograms, getMyPrograms, ProgramType } from "api/program"
import Reason from "pages/Exercices/components/ExercicesLists/components/Reason"
import ZonesPathologies from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/ZonesPathologies"
import TabExercices from "pages/Exercices/components/ExercicesLists/components/TabExecices/TabExercices"
import FilterPrograms from "pages/Exercices/components/ExercicesLists/components/FilterPrograms/FilterPrograms"
import ProgramCard from "pages/Exercices/components/ExercicesLists/components/ProgramCard/ProgramCard"
import AddProgramModal from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/AddProgramModal"
import AUploadedExerciceCard from "components/AUploadedExerciceCard/AUploadedExerciceCard"
import useJobIsKine from "hooks/useIsJobKine"
import { useMutation, useQuery } from "@tanstack/react-query"
import ATablePagination from "components/Table/ATablePagination/ATablePagination"
import { niceDisplayVideoGroup } from "utils/niceDisplayVideoGroup"
import {
  sortByJob,
  sortByTimeUsed,
} from "pages/NewProgram/components/SelectProgramExercices"

export const VIDEOS_TAB = 0
export const PROGRAMME_TAB = 1
export const FAVORIS_TAB = 2

// COMPONENTS
interface IProps {
  reason?: ZonePathologieType
  setReason: React.Dispatch<
    React.SetStateAction<ZonePathologieType | undefined>
  >
  reasonType: "zones" | "pathologies" | null
  setReasonType: (reason: "zones" | "pathologies" | null) => void
  isForKine?: boolean
}

const ExercicesLists: React.FC<IProps> = ({
  reason,
  reasonType,
  setReasonType,
  setReason,
  isForKine,
}) => {
  const [search, setSearch] = useState("")
  const [suggestedProgramme, setSuggestedProgramme] = useState<VideoType[]>([])
  const [videoGroups, setVideoGroups] = useState<TrainingVideoGroupsType[]>([])
  const [categories, setCategories] = useState<VideoGroupCategoryType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null)
  const isJobKine = useJobIsKine()
  const [restrictionFilter, setRestrictionFilter] = useState<string | null>(
    null
  )
  const [programs, setPrograms] = useState<ProgramType[]>([])
  const [commonPrograms, setCommonPrograms] = useState<ProgramType[]>([])
  const [selectedProgram, setSelectedProgram] = useState<ProgramType | null>(
    null
  )

  const [selectedTab, setSelectedTab] = useState(VIDEOS_TAB)
  const [sortedCommonPrograms, setSortedCommonPrograms] = useState<
    ProgramType[]
  >([])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [appointmentReason, setAppointmentReason] = useState<
    undefined | AppointmentReasonTypePaginated
  >(undefined)

  const [selectedClinicalPicture, setSelectedClinicalPicture] = useState<
    string | null
  >(null)

  useEffect(() => {
    const filteredProgram = filterPrograms(commonPrograms)
    setSortedCommonPrograms(filteredProgram)
  }, [search, reason, commonPrograms])

  const { mutate, isLoading: isAppointmentReasonLoading } = useMutation({
    mutationKey: ["appointmentReason", reason?.id, 1],
    mutationFn: (params: GetAppointmentReasonParams) =>
      getAppointmentReason(params),
    onSuccess: (response) => {
      if (reason?.id) {
        setAppointmentReason(response.data)
      } else {
        setAppointmentReason(undefined)
      }
      setVideoGroups(
        niceDisplayVideoGroup(
          response.data.trainingVideoGroups.sort(
            isJobKine ? sortByJob : sortByTimeUsed
          )
        )
      )

      setPageCount(response.data.pagination.pageCount)
    },
  })

  const { data: uploadedVideos } = useQuery({
    queryKey: ["uploadedVideos", reason?.id ?? -1],
    queryFn: () => getUploadedVideosForAppointmentReason(reason?.id ?? -1),
  })

  const { data: likedVideos } = useQuery({
    queryKey: ["likedVideos", reason?.id ?? -1],
    queryFn: () => getLikedVideosForAppointmentReason(reason?.id ?? -1),
  })

  // Effet pour déclencher la mutation quand les params changent
  useEffect(() => {
    setPage(1)
    mutate({
      reasonId: reason?.id ?? -1,
      page: 1,
    })
  }, [reason?.id])
  useEffect(() => {
    mutate({
      reasonId: reason?.id ?? -1,
      page,
    })
  }, [page])

  useEffect(() => {
    if (reason?.id) setPageCount(0)
  }, [reason?.id])

  const fetchMyPrograms = async () => {
    const programsResponse = await getMyPrograms()
    const commonProgramsResponse = await getCommonPrograms()
    setPrograms(programsResponse)
    setCommonPrograms(commonProgramsResponse)
  }

  useEffect(() => {
    fetchMyPrograms()
  }, [])

  useEffect(() => {
    const listOfCategories = videoGroups
      .map((videoGroup) => videoGroup.category)
      .filter((elt) => elt !== null)

    const uniqueCategories = listOfCategories.filter(
      (category, index) => listOfCategories.indexOf(category) === index
    )

    setCategories(uniqueCategories)
  }, [videoGroups])

  const filterWithCategoriesRestrictionsAndSearch = (
    list: TrainingVideoGroupsType[]
  ) => {
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = list
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (categoryFilter !== null) {
      filteredList = filteredList.filter(
        ({ category }) => category === categoryFilter
      )
    }
    if (restrictionFilter !== null) {
      filteredList = filteredList.filter(({ restrictions }) =>
        restrictions.some(({ name }) => name === restrictionFilter)
      )
    }
    if (selectedClinicalPicture !== null) {
      filteredList = filteredList.filter(
        ({ clinicalPictures }) =>
          clinicalPictures?.some(
            ({ id }) => id === Number(selectedClinicalPicture)
          ) ?? false
      )
    }
    return filteredList
  }

  const filterPrograms = (list: ProgramType[]) => {
    const newList = list.filter(
      (program) =>
        program.trainingVideoPrograms &&
        program.trainingVideoPrograms.length > 0
    )
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = newList
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (reason) {
      filteredList = filteredList.filter(({ appointmentReasons }) =>
        appointmentReasons.find(
          (currentReason) => currentReason.id === reason.id
        )
      )
    }
    return filteredList
  }

  const [sortedFilteredVideoGroups, setSortedFilteredVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])

  const [sortedFilteredPrograms, setSortedFilteredPrograms] = useState<
    ProgramType[]
  >([])

  useEffect(() => {
    setSortedFilteredVideoGroups(
      filterWithCategoriesRestrictionsAndSearch(videoGroups)
    )
  }, [
    videoGroups,
    categoryFilter,
    restrictionFilter,
    search,
    selectedClinicalPicture,
  ])

  useEffect(() => {
    const filteredPrograms = filterPrograms(programs)
    setSortedFilteredPrograms(filteredPrograms)
  }, [search, reason, programs])

  const hideClinicalPictures = selectedTab === 1

  return (
    <Flex direction="column" gap="16px" position="relative" width="full">
      <TabExercices
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isPageExercies
        isForKine={isForKine}
        appointmentReason={reason?.id}
      />
      {/* <Flex gap="93px"> */}
      <Flex gap={"10px"}>
        <Box flex={1} position="relative">
          {selectedTab === 1 && <FilterPrograms setSearch={setSearch} />}

          <Flex justifyContent="flex-start" flexWrap="wrap" gap="16px">
            {selectedTab === 0 &&
              (isAppointmentReasonLoading ? (
                <Flex
                  flexDir={"column"}
                  alignItems="center"
                  w="full"
                  h="calc(100vh - 200px)"
                  justifyContent="center"
                >
                  <Spinner color="primary.500" />
                </Flex>
              ) : (
                <Flex flexDir={"column"} gap={"16px"} w="full">
                  {uploadedVideos?.length && uploadedVideos?.length > 0 ? (
                    <Box>
                      <Text fontWeight={700} fontSize={16} mb={4}>
                        Vos exercices
                      </Text>
                      <Flex
                        justifyContent="flex-start"
                        flexWrap="wrap"
                        gap="16px"
                        w="full"
                      >
                        {uploadedVideos.map((video, i) => (
                          <AUploadedExerciceCard
                            key={`uploaded_video_${video.id}`}
                            video={video}
                            appointmentReason={reason?.id}
                          />
                        ))}
                      </Flex>
                    </Box>
                  ) : null}
                  <Box w="full">
                    {uploadedVideos?.length &&
                    uploadedVideos?.length > 0 &&
                    sortedFilteredVideoGroups.length > 0 ? (
                      <Text fontWeight={700} fontSize={16} mb={4}>
                        Autres exercices
                      </Text>
                    ) : null}
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="16px"
                      w="full"
                    >
                      {sortedFilteredVideoGroups.map((videoGroup, i) => (
                        <VideoGroup
                          key={`group_${videoGroup.id}_${i}`}
                          videoGroup={videoGroup}
                          isLiked={
                            likedVideos?.some(
                              (likedVideo) => likedVideo.id === videoGroup.id
                            ) ?? false
                          }
                        />
                      ))}
                    </Flex>
                    {selectedTab === 0 && (
                      <Box mt={4}>
                        <ATablePagination
                          pageCount={pageCount}
                          onPageChange={setPage}
                          page={page}
                        />
                      </Box>
                    )}
                  </Box>
                </Flex>
              ))}
            {selectedTab === 1 && (
              <Box w="full" pb={16}>
                {sortedFilteredPrograms.length > 0 && (
                  <Box>
                    <Text fontWeight={700} fontSize={16}>
                      Vos programmes
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedFilteredPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                          isLooking
                        />
                      ))}
                    </Flex>
                  </Box>
                )}
                {sortedCommonPrograms.length > 0 && (
                  <Box>
                    <Text fontWeight={700} fontSize={16}>
                      Autres programmes
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedCommonPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                          isLooking
                        />
                      ))}
                    </Flex>
                  </Box>
                )}
              </Box>
            )}

            {selectedTab === 2 &&
              likedVideos?.map((videoGroup, i) => (
                <VideoGroup
                  key={`liked_group_${videoGroup.id}_${i}`}
                  videoGroup={videoGroup}
                  reason={reason}
                  isLiked
                />
              ))}
          </Flex>
        </Box>
        <Flex direction="column" pos="sticky" top={4} pl="24px" h="fit-content">
          <Box
            position="absolute"
            h="calc(100vh)"
            left="0px"
            w="1px"
            top="-16px"
            bg="primary.200"
          ></Box>
          {!isForKine && (
            <Reason reason={reasonType} setReason={setReasonType} />
          )}
          <Flex alignItems={"center"}>
            <Flex justifyContent="center" w="full">
              {reasonType === "zones" && (
                <ZonesPathologies
                  datas={getZones(isJobKine)}
                  selectedData={reason}
                  isForKine={isForKine}
                  setSelectedData={setReason}
                  reason={appointmentReason}
                  hideClinicalPictures={hideClinicalPictures}
                  selectedClinicalPicture={selectedClinicalPicture}
                  setSelectedClinicalPicture={setSelectedClinicalPicture}
                />
              )}
              {reasonType === "pathologies" && !isForKine && (
                <ZonesPathologies
                  datas={PATHOLOGIES_MOCK}
                  selectedData={reason}
                  setSelectedData={setReason}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {selectedProgram && (
        <AddProgramModal
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
        />
      )}
    </Flex>
  )
}

export default ExercicesLists

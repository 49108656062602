import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { EducationalContentType } from "api/educationalContent"
import { PatientAppointmentType } from "api/patient"
import AEditButton from "components/AEditButton"
import EducationalContentCard from "components/Treatment/EducationalContentCard"
import React from "react"
import { useNavigate } from "react-router-dom"

interface IProps {
  selectedEducationalContents: EducationalContentType[]
  appointment: PatientAppointmentType
  patientId: string | undefined
}

const AppointmentEducationalContents: React.FC<IProps> = ({
  selectedEducationalContents,
  appointment,
  patientId,
}) => {
  const navigate = useNavigate()

  const onEdit = () => {
    navigate(
      `/patients/${patientId}/appointments/${appointment.id}/edit-educational-content`
    )
  }

  return (
    <Box mb={6}>
      <Box mt={6} p={6} bg="white" borderRadius={9} position="relative">
        <AEditButton
          pos="absolute"
          top={2}
          right={2}
          onClick={onEdit}
          ariaLabel="Modifier les contenus éducatifs sélectionnés"
        />
        <Heading as="h3" fontSize={18} mb={5}>
          Contenu Éducatif sélectionnés
        </Heading>
        <Flex direction={"row"} gap={"16px"} flexWrap="wrap">
          {selectedEducationalContents.length > 0 &&
            selectedEducationalContents.map((educationalContent, index) => (
              <EducationalContentCard
                content={educationalContent}
                key={index}
              />
            ))}
          {selectedEducationalContents.length === 0 && (
            <Text>Aucun contenu éducatif sélectionné</Text>
          )}
        </Flex>
      </Box>
    </Box>
  )
}

export default AppointmentEducationalContents

import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
} from "@chakra-ui/react"
import AButton from "components/AButton"

interface PaymentSuccessModalProps {
  isOpen: boolean
  onClose: () => void
}
const PaymentSuccessModal = ({ isOpen, onClose }: PaymentSuccessModalProps) => {
  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW={429} borderRadius={8} p={8}>
        <ModalCloseButton aria-label="Fermer" />
        <ModalHeader mb={2} p={0} fontSize={18}>
          <Text fontSize={18} fontWeight="bold" mb={4}>
            Vous êtes prêts !
          </Text>
        </ModalHeader>
        <ModalBody p={0} mb={2}>
          <Text>
            Votre compte Stripe est prêt, vous pouvez désormais créer votre
            première téléconsultation
          </Text>
        </ModalBody>

        <ModalFooter>
          <Flex justifyContent="flex-end">
            <AButton onClick={onClose} text="Terminer" />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PaymentSuccessModal

import React from "react"
import { SVGProps } from "../type"

const AvantPied: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      width="41"
      height="17"
      viewBox="0 0 41 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12.7098V2C1 1.44772 1.44772 1 2 1H33.2556C33.6389 1 33.9885 1.21912 34.1556 1.56412L39.4575 12.5114C39.7361 13.0866 39.4109 13.7719 38.7892 13.92L30.2134 15.9632C30.111 15.9876 30.0054 15.9956 29.9004 15.9871L1.91877 13.7065C1.3997 13.6642 1 13.2306 1 12.7098Z"
        fill={fill || "#E4FBEA"}
        fillOpacity={"0.8"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="2"
      />
    </svg>
  )
}

export default AvantPied

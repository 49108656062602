import { Box, Heading, Image } from "@chakra-ui/react"
import React from "react"
import { TopicCategoryEnum } from "../../../api/topics"
import getCategory from "../../../utils/getCategory"

interface IProps {
  img: string
  category: TopicCategoryEnum
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<TopicCategoryEnum | null>
  >
}

const FilterItem: React.FC<IProps> = ({
  img,
  category,
  setSelectedCategory,
}) => {
  return (
    <Box
      pos="relative"
      borderRadius={9}
      overflow="hidden"
      onClick={() =>
        setSelectedCategory((prev) => (prev === category ? null : category))
      }
      cursor="pointer"
    >
      <Image w="100%" h="100%" objectFit="cover" src={img} />
      <Heading
        as="p"
        color="white"
        fontSize={16}
        fontWeight={700}
        bottom={0}
        py={2}
        textAlign="center"
        w="100%"
        pos="absolute"
        bgGradient="linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))"
      >
        {getCategory(category)}
      </Heading>
    </Box>
  )
}

export default FilterItem

import { useQuery } from "@tanstack/react-query"
import { AcademieOsteoType, getStudents } from "api/academie"
import { CenterCurrentScope, useAppContext } from "AppContext"
import AAutocompleteMultiple from "components/AAutocompleteMultiple/AAutocompleteMultiple"
import { DEBOUNCE_DELAY } from "constants/transitions"
import studentsQueryKeys from "constants/queryKeys/students"
import { useState } from "react"
import { useDebounce } from "use-debounce"
import { Flex } from "@chakra-ui/react"
import centerOsteosQueryKeys from "constants/queryKeys/centerOsteos"
import { getCenterOsteos } from "api/center"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"

// CONSTANTS
const SHOW_DISABLED = false
const PAGE = 1
const PER_PAGE = 50

const renderItem = ({ firstname, lastname }: AcademieOsteoType) =>
  `${firstname} ${lastname}`

const getKey = ({ id }: AcademieOsteoType) => id.toString()

// COMPONENTS
interface OsteoFilterProps {
  selectedItems: AcademieOsteoType[]
  setSelectedItems: (items: AcademieOsteoType[]) => void
}
const OsteoFilter = ({ selectedItems, setSelectedItems }: OsteoFilterProps) => {
  const [search, setSearch] = useState("")

  const [debouncedSearch] = useDebounce(search, DEBOUNCE_DELAY)

  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()

  const { currentScope } = useAppContext()
  const { data: students, isLoading: isLoadingStudents } = useQuery(
    studentsQueryKeys.list(
      currentScope?.id!,
      SHOW_DISABLED,
      debouncedSearch,
      PAGE
    ),
    () =>
      getStudents({
        academieId: currentScope?.id!,
        showDisabled: SHOW_DISABLED,
        search: debouncedSearch,
        page: PAGE,
        perPage: PER_PAGE,
      }),
    {
      enabled: currentScope?.type === "academy",
    }
  )
  const { data: centerOsteos, isLoading: isLoadingCenterOsteos } = useQuery(
    centerOsteosQueryKeys.list(
      (currentScope as CenterCurrentScope)?.id,
      debouncedSearch,
      PAGE
    ),
    () =>
      getCenterOsteos({
        centerId: (currentScope as CenterCurrentScope).id,
        search: debouncedSearch,
        page: PAGE,
        perPage: PER_PAGE,
      }),
    {
      retry: retryCollaboratorUnauthorized,
      onError: onCollaboratorUnauthorized,
      enabled: currentScope?.type === "center",
    }
  )

  if (currentScope?.id === undefined) {
    return null
  }

  const { items, totalItems, isLoading } =
    currentScope.type === "center"
      ? {
          items: centerOsteos?.result ?? [],
          totalItems: centerOsteos?.count?.totalItems ?? 0,
          isLoading: isLoadingCenterOsteos,
        }
      : {
          items: students?.result ?? [],
          totalItems: students?.count?.totalItems ?? 0,
          isLoading: isLoadingStudents,
        }

  return (
    <Flex alignItems="center" gap={8}>
      <AAutocompleteMultiple
        bg="white"
        labelText="Filtrer par thérapeute"
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        items={items}
        inputValue={search}
        setInputValue={setSearch}
        renderItem={renderItem}
        getKey={getKey}
        isLoading={isLoading}
        moreItems={Math.max(0, totalItems - PER_PAGE)}
      />
    </Flex>
  )
}

export default OsteoFilter

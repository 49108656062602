import React from "react"
import { SVGProps } from "../type"

const Pubalgie: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="35"
      fill="none"
      viewBox="0 0 11 35"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M8.538 5.6C7.705 2.485 2.835.834 3.38 4.796c1.74 5.312-1.13 13.783-.962 22.34.134 6.846 3.152 5.791 4.644 4.408l1.522-7.275c.332-4.925.786-15.553-.046-18.669z"
      ></path>
    </svg>
  )
}

export default Pubalgie

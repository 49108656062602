import { Skeleton, SkeletonProps } from "@chakra-ui/react"
import React from "react"

interface IProps extends SkeletonProps {}

const ASkeleton: React.FC<IProps> = ({ children, ...props }) => {
  return (
    <Skeleton startColor="primary.200" endColor="primary.300" {...props}>
      {children}
    </Skeleton>
  )
}

export default ASkeleton

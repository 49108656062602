import {
  GEOLOCATION_OPTIONS,
  useGeolocationContext,
} from "components/Geolocation/GeolocationContext"

// HOOKS
const useStudentGeolocationOnClick = () => {
  const {
    isLoading,
    setLoading,
    can,
    error,
    isImprovingAccuracy,
    shouldWait,
    isStudent,
    onGeolocationNotSupported,
    onSuccess,
    onError,
  } = useGeolocationContext()

  const onClick = () => {
    setLoading(true)
    if (!("geolocation" in navigator)) {
      onGeolocationNotSupported()
      return
    }

    navigator.geolocation.getCurrentPosition(
      onSuccess,
      onError,
      GEOLOCATION_OPTIONS
    )
  }

  return {
    can,
    error,
    isLoading,
    isImprovingAccuracy,
    isStudent,
    shouldWait,
    onClick,
  }
}

export default useStudentGeolocationOnClick

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react"
import { ComponentProps, ReactNode, useRef } from "react"
import AButton from "./AButton"

interface AAlertDialogConfirmProps {
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  onConfirm?: () => void
  headerContent: ReactNode
  bodyContent: ReactNode
  cancelText?: string
  destructiveActionText: string
  destructiveActionVariant?: ComponentProps<typeof AButton>["variant"]
}
const AAlertDialogConfirm = ({
  isOpen,
  isLoading,
  onClose,
  onConfirm,
  headerContent,
  bodyContent,
  cancelText = "Annuler",
  destructiveActionText,
  destructiveActionVariant = "destructive",
}: AAlertDialogConfirmProps) => {
  const cancelRef = useRef(null)

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent maxW={724}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {headerContent}
          </AlertDialogHeader>

          <AlertDialogBody>{bodyContent}</AlertDialogBody>

          <AlertDialogFooter justifyContent="flex-end">
            <AButton
              text={cancelText}
              ref={cancelRef}
              onClick={onClose}
              variant="tertiary"
            />
            <AButton
              text={destructiveActionText}
              type="submit"
              onClick={onConfirm}
              isLoading={isLoading}
              ml={3}
              variant={destructiveActionVariant}
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default AAlertDialogConfirm

import { useInView, useMotionValue, useSpring } from "framer-motion"
import { useEffect, useRef } from "react"

interface AnimatedNumberProps {
  end: number
  decimals?: number
}

const AnimatedNumber = ({ end, decimals = 0 }: AnimatedNumberProps) => {
  const ref = useRef<HTMLSpanElement>(null)
  const start = useMotionValue(0)
  const spring = useSpring(start, {
    damping: 50,
    stiffness: 120,
  })
  const isInView = useInView(ref, { once: true })

  useEffect(() => {
    if (isInView) {
      start.set(end)
    }
  }, [start, isInView, end])

  useEffect(
    () =>
      spring.onChange((latest) => {
        if (ref.current) {
          ref.current.textContent = Intl.NumberFormat("fr-FR").format(
            latest.toFixed(decimals)
          )
        }
      }),
    [spring, decimals]
  )

  return end === 0 ? <span>0</span> : <span ref={ref} />
}

export default AnimatedNumber

import React from "react"
import { SVGProps } from "../type"

const Lombaires: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="23"
      fill="none"
      viewBox="0 0 47 23"
    >
      <rect
        width="43"
        height="19"
        x="2"
        y="2"
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        rx="4"
      ></rect>
    </svg>
  )
}

export default Lombaires

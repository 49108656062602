import React from "react"
import { SVGProps } from "../type"

const Ncb: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="83"
      height="203"
      fill="none"
      viewBox="0 0 83 203"
    >
      <path
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        fillOpacity="0.6"
        strokeWidth="3"
        d="M8.001 20.5c-7.2-2-6-12.833-4.5-18C9.102-.3 10.501 5 10.5 8c.8 8.4 27 8.204 41.5 12.5 10.8 3.2 14.5 33.334 15 48 3.6 9.2 6.167 35.834 7 48 3 7.5 8.5 24.9 6.5 34.5-2 9.6-1.833 24.667-1.5 31 .167 4.5.4 14.3 0 17.5-.4 3.2-3.5 1.334-5 0l-2.5-16V151L67 129l-4.5-35.5c-3-14.166-9.3-45.2-10.5-56-1.2-10.8-15.833-11.833-23-11-4-1.166-13.799-4-20.999-6z"
      ></path>
    </svg>
  )
}

export default Ncb

import { TreatmentType } from "api/treatment"
import React, { useMemo } from "react"
import { Flex, Text } from "@chakra-ui/react"
import RightTabKine from "components/Treatment/RightTabKine"
import { CheckupType, PatientAppointmentType, PatientType } from "api/patient"
import AppointmentMedias from "pages/Appointment/components/AppointmentMedias"
import Recommendation from "pages/Appointment/components/Recommendation"
import Training from "pages/Appointment/components/Training"
import AppointmentStats from "pages/Appointment/components/AppointmentStats"
import { isAfter, isBefore } from "date-fns"
import addTwoMonths from "utils/addTwoMonth"
import ScrollableSelectTab from "components/Treatment/ScrollableSelectTab"
import AppointmentEducationalContents from "components/Treatment/AppointmentEducationalContents"
import Stats from "pages/Patient/components/Stats"

interface TreatmentsContainerProps {
  treatments: TreatmentType[]
  currentTreatment: TreatmentType
  currentAppointment: PatientAppointmentType
  setCurrentAppointment: React.Dispatch<
    React.SetStateAction<PatientAppointmentType | null>
  >
  patient: PatientType
}
const TreatmentsContainer = ({
  treatments,
  currentTreatment,
  currentAppointment,
  setCurrentAppointment,
  patient,
}: TreatmentsContainerProps) => {
  const appointmentCheckups = useMemo(() => {
    return (
      currentTreatment.patient?.checkups?.filter(
        (checkup: CheckupType) =>
          isAfter(
            new Date(checkup.createdAt),
            new Date(currentAppointment.meetingDate)
          ) &&
          isBefore(
            new Date(checkup.createdAt),
            addTwoMonths(new Date(currentAppointment.meetingDate))
          )
      ) || []
    )
  }, [currentTreatment, currentAppointment])

  return (
    <Flex justify={"space-between"} gap={"20px"}>
      <Flex direction={"column"} w={"full"} maxW="calc(100% - 380px)">
        <Text fontWeight={"bold"} fontSize={"18px"}>
          {currentTreatment.title}
        </Text>
        {currentTreatment.patient.id && (
          <ScrollableSelectTab
            tabs={currentTreatment.appointments}
            otherProps={{
              patientId: currentTreatment.patient.id,
              treatmentId: currentTreatment.id,
            }}
          />
        )}
        <Recommendation
          appointment={currentAppointment}
          setAppointment={setCurrentAppointment}
          type="internalNote"
        />
        <Recommendation
          appointment={currentAppointment}
          setAppointment={setCurrentAppointment}
          type="secondaryInternalNote"
        />
        <Recommendation
          appointment={currentAppointment}
          setAppointment={setCurrentAppointment}
          type="osteoAdvice"
        />
        {currentAppointment.trainingPlaylist?.exercices?.length !== 0 &&
          currentTreatment.patient.id && (
            <Training
              appointment={currentAppointment}
              patientId={currentTreatment.patient.id.toString()}
              setAppointment={setCurrentAppointment}
            />
          )}

        <AppointmentMedias
          selectedMedias={currentAppointment.explorerMedias ?? []}
          appointment={currentAppointment}
          patientId={currentTreatment.patient.id?.toString()}
        />

        <AppointmentEducationalContents
          selectedEducationalContents={
            currentAppointment.educationalContents ?? []
          }
          appointment={currentAppointment}
          patientId={currentTreatment.patient.id?.toString()}
        />

        <Flex direction={"column"} w={"full"}>
          <Stats patient={patient} isInPatientKinePage={true} />
        </Flex>
      </Flex>
      {currentTreatment.patient.id && (
        <RightTabKine
          patient={currentTreatment.patient}
          treatments={treatments}
          currentTreatment={currentTreatment}
        />
      )}
    </Flex>
  )
}

export default TreatmentsContainer

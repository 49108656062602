import axiosInstance from "api/axiosInstance"

export type TrainingVideoExerciceType = {
  id: number
  title: string
  key: string
}
export const getAllTrainingVideoExerciceType = async () => {
  const { data } = await axiosInstance.get<TrainingVideoExerciceType[]>(
    `/training-video-exercice-type/all`
  )
  return data
}

import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useSearchParams } from "react-router-dom"
import { createCenterGroup } from "api/centerGroup"
import { useAppContext } from "AppContext"
import { subscribeSuccessful } from "utils/facebookPixelEvents"
import { sendinblueTrack } from "utils/sendinBlueEvents"
import useToast from "hooks/useToast"
import getMeQueryKeys from "constants/queryKeys/getMe"
import { SESSION_ID_KEY } from "constants/subscription"
import { getCustomer } from "api/subscriptions"

// HOOKS
const useStoreSuccessPaymentSessionCenter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const toast = useToast()
  const { user, jwt, setCenterAdminCurrentScope, currentScope } =
    useAppContext()
  const sessionId = searchParams.get(SESSION_ID_KEY)

  const queryClient = useQueryClient()

  const clearSessionId = () =>
    setSearchParams((prevParams) => {
      prevParams.delete(SESSION_ID_KEY)
      return prevParams
    })

  const { data } = useQuery(
    /**
     * different query key not to overlap with individual query,
     * @see src/hooks/useStoreSuccessPaymentSession/individual.ts
     */
    ["getCenterGroupCustomer"],
    () => getCustomer(sessionId!),
    {
      retry: false,
      staleTime: Infinity,
      enabled: Boolean(sessionId),
      onError: () => {
        toast({
          status: "error",
          title: "Une erreur s'est produite",
        })
      },
    }
  )
  const customerId = data?.customer_id

  const { isLoading, isSuccess } = useQuery(
    ["createCenterGroup"],
    () =>
      createCenterGroup({
        sessionId: sessionId!,
        stripeCustomerId: customerId!,
      }),
    {
      retry: false,
      staleTime: Infinity,
      enabled: Boolean(sessionId) && Boolean(user) && Boolean(customerId),
      onError: () => {
        toast({
          status: "error",
          title: "Une erreur s'est produite",
        })
      },
      onSuccess: async ({ id, centers }) => {
        const firstCenter = centers[0]

        // do nothing if already set
        if (
          currentScope?.type === "center" &&
          currentScope.centerGroupId === id
        ) {
          return
        }
        if (user?.email) {
          sendinblueTrack("start_trial", {
            email: user?.email,
            HAS_USED_TRIAL: true,
          })
        }
        subscribeSuccessful()

        // set current scope admin centerGroup
        setCenterAdminCurrentScope({
          type: "center",
          id: firstCenter.id,
          centerGroupId: id,
        })
        await queryClient.invalidateQueries({
          queryKey: getMeQueryKeys.me(jwt!),
        })
      },
    }
  )

  return { sessionId, isLoading, isSuccess, clearSessionId }
}

export default useStoreSuccessPaymentSessionCenter

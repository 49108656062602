import React from "react"
import { SVGProps } from "../type"

const Adducteur: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      width="15"
      height="61"
      viewBox="0 0 15 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.15841 1.03911L1.76456 2.3055C1.31704 2.43448 1.01781 2.85533 1.04296 3.32038L3.11765 41.6897V59C3.11765 59.5523 3.56536 60 4.11765 60H9.08871C9.60511 60 10.0366 59.6068 10.0844 59.0926L12 38.5L13.9438 2.05326C13.9744 1.48086 13.5185 1 12.9452 1H6.43535C6.34166 1 6.24843 1.01317 6.15841 1.03911Z"
        fill={fill || "#E4FBEA"}
        fillOpacity={"0.8"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="2"
      />
    </svg>
  )
}

export default Adducteur

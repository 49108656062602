import { Flex, Text } from "@chakra-ui/react"
import { StatsType } from "api/stats"
import GlobalStats from "components/Stats/GlobalStats"
import PersonalStats from "components/Stats/PersonalStats"

interface StatisticsProps {
  stats: StatsType | undefined
}
const Statistics = ({ stats }: StatisticsProps) => {
  const isLoading = stats === undefined

  return (
    <Flex mb={10} w={"full"}>
      {isLoading ? (
        <Text as="b" fontSize={"2xl"}>
          Chargement des statistiques ...
        </Text>
      ) : (
        <Flex direction={"column"} gap={12}>
          <GlobalStats globalStats={stats.globalStats} />
          <PersonalStats personalStats={stats.personalStats} />
        </Flex>
      )}
    </Flex>
  )
}
export default Statistics

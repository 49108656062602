import { Box, Flex, Spinner, Text } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"

import {
  AppointmentReasonTypePaginated,
  getAppointmentReason,
  GetAppointmentReasonParams,
  getLikedVideosForAppointmentReason,
  getUploadedVideosForAppointmentReason,
  SelectedExerciceType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
  TrainingVideoGroupsType,
  VideoGroupCategoryType,
} from "api/appointmentReason"
import {
  PATHOLOGIES_MOCK,
  ZonePathologieType,
  getZones,
} from "pages/NewAppointment/newAppointment.mock"

import Reason from "pages/NewAppointment/components/SelectExercices/components/Reason"
import ZonesPathologies from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/ZonesPathologies"
import TabExercices from "pages/Exercices/components/ExercicesLists/components/TabExecices/TabExercices"
import VideoGroup from "pages/Exercices/components/ExercicesLists/components/VideoGroup/VideoGroup"
import SelectedExercices from "pages/NewAppointment/components/SelectExercices/components/SelectedExercices/SelectedExercices"
import FilterExercices from "pages/Exercices/components/ExercicesLists/components/FilterExercices/FilterExercices"
import useJobIsOsteo from "hooks/useJobIsOsteo"
import AUploadedExerciceCard from "components/AUploadedExerciceCard/AUploadedExerciceCard"
import useJobIsKine from "hooks/useIsJobKine"
import { useMutation, useQuery } from "@tanstack/react-query"
import ATablePagination from "components/Table/ATablePagination/ATablePagination"
import { useDebounce } from "use-debounce"

export const sortByTimeUsed = (
  a: TrainingVideoGroupsType,
  b: TrainingVideoGroupsType
) => {
  if (a.timeUsed < b.timeUsed) {
    return 1
  }
  if (a.timeUsed > b.timeUsed) {
    return -1
  }
  return 0
}

export const sortByJob = (
  a: TrainingVideoGroupsType,
  b: TrainingVideoGroupsType
) => {
  const hasOsteoA = a.jobs?.some((job) => job.key === "osteo") ?? false
  const hasOsteoB = b.jobs?.some((job) => job.key === "osteo") ?? false

  // Si l'un a kine et l'autre non, celui avec kine va en premier
  if (hasOsteoA && !hasOsteoB) return 1
  if (!hasOsteoA && hasOsteoB) return -1

  // Si les deux ont le même statut (kine ou non-kine), on trie par ordre alphabétique
  return a.title.localeCompare(b.title)
}

export const VIDEOS_TAB = 0
export const PROGRAMME_TAB = 1
export const FAVORIS_TAB = 2

// COMPONENTS
interface IProps {
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  selectedUploadedExercices: SelectedUploadedExerciceType[]
  setSelectedUploadedExercices: React.Dispatch<
    React.SetStateAction<SelectedUploadedExerciceType[]>
  >
  selectedVideoOrder: SelectedVideoOrderType[]
  setSelectedVideoOrder: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
  reasonType: "zones" | "pathologies" | null
  setReasonType: (reason: "zones" | "pathologies" | null) => void
  reason: ZonePathologieType | undefined
  setReason: React.Dispatch<
    React.SetStateAction<ZonePathologieType | undefined>
  >
}

const SelectProgramExercices: React.FC<IProps> = ({
  selectedExercices,
  setSelectedExercices,
  selectedUploadedExercices,
  setSelectedUploadedExercices,
  selectedVideoOrder,
  setSelectedVideoOrder,
  reason,
  setReason,
  reasonType,
  setReasonType,
}) => {
  const isJobOsteo = useJobIsOsteo()
  const isJobKine = useJobIsKine()
  const [search, setSearch] = useState("")
  const [videoGroups, setVideoGroups] = useState<TrainingVideoGroupsType[]>([])
  const [categories, setCategories] = useState<VideoGroupCategoryType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null)

  const [restrictionFilter, setRestrictionFilter] = useState<string | null>(
    null
  )

  const [selectedClinicalPicture, setSelectedClinicalPicture] = useState<
    string | null
  >(null)

  const restrictions = videoGroups.reduce(
    (acc, { restrictions }) => [
      ...acc,
      ...(restrictions ?? []).reduce(
        (restrictionsAcc, { name }) =>
          acc.includes(name) ? restrictionsAcc : [...restrictionsAcc, name],
        [] as string[]
      ),
    ],
    [] as string[]
  )

  const [appointmentReason, setAppointmentReason] = useState<
    undefined | AppointmentReasonTypePaginated
  >(undefined)

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [debouncedSearch] = useDebounce(search, 500)

  const { mutate, isLoading: isAppointmentReasonLoading } = useMutation({
    mutationKey: ["appointmentReason", reason?.id, 1],
    mutationFn: (params: GetAppointmentReasonParams) =>
      getAppointmentReason(params),
    onSuccess: (response) => {
      if (reason?.id) {
        setAppointmentReason(response.data)
      } else {
        setAppointmentReason(undefined)
      }
      setVideoGroups(
        niceDisplayVideoGroup(
          response.data.trainingVideoGroups.sort(
            isJobKine ? sortByJob : sortByTimeUsed
          )
        )
      )

      setPageCount(response.data.pagination.pageCount)
    },
  })

  const { data: uploadedVideos } = useQuery({
    queryKey: ["uploadedVideos", reason?.id ?? -1],
    queryFn: () => getUploadedVideosForAppointmentReason(reason?.id ?? -1),
  })

  const { data: likedVideos } = useQuery({
    queryKey: ["likedVideos", reason?.id ?? -1],
    queryFn: () => getLikedVideosForAppointmentReason(reason?.id ?? -1),
  })

  // Effet pour déclencher la mutation quand les params changent
  useEffect(() => {
    setPage(1)
    mutate({
      reasonId: reason?.id ?? -1,
      page: 1,
    })
  }, [reason?.id])
  useEffect(() => {
    mutate({
      reasonId: reason?.id ?? -1,
      page,
    })
  }, [page])

  useEffect(() => {
    if (reason?.id) setPageCount(0)
  }, [reason?.id])

  useEffect(() => {
    const listOfCategories = videoGroups
      .map((videoGroup) => videoGroup.category)
      .filter((elt) => elt !== null)

    const uniqueCategories = listOfCategories.filter(
      (category, index) => listOfCategories.indexOf(category) === index
    )

    setCategories(uniqueCategories)
  }, [videoGroups])

  useEffect(() => {
    // reset filters
    setCategoryFilter(null)
  }, [reason])

  const filterWithCategoriesRestrictionsAndSearch = (
    list: TrainingVideoGroupsType[]
  ) => {
    let filteredList = list
    if (categoryFilter !== null) {
      filteredList = filteredList.filter(
        ({ category }) => category === categoryFilter
      )
    }
    if (restrictionFilter !== null) {
      filteredList = filteredList.filter(({ restrictions }) =>
        restrictions.some(({ name }) => name === restrictionFilter)
      )
    }
    if (selectedClinicalPicture !== null) {
      filteredList = filteredList.filter(
        ({ clinicalPictures }) =>
          clinicalPictures?.some(
            ({ id }) => id === Number(selectedClinicalPicture)
          ) ?? false
      )
    }
    return filteredList
  }

  const niceDisplayVideoGroup = (groups: TrainingVideoGroupsType[]) => {
    let temp: null | TrainingVideoGroupsType = null
    const sortedGroups: TrainingVideoGroupsType[] = []
    groups.map((group) => {
      const videosCount = group.videos.filter(
        ({ limitedMode }) => limitedMode === false
      ).length
      if (videosCount > 1) return sortedGroups.push({ ...group })
      if (temp !== null) {
        sortedGroups.push({ ...temp }, { ...group })
        temp = null
        return null
      }
      temp = group
    })
    return sortedGroups
  }

  const [sortedFilteredVideoGroups, setSortedFilteredVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])

  const [selectedTab, setSelectedTab] = useState(VIDEOS_TAB)

  useEffect(() => {
    setSortedFilteredVideoGroups(
      niceDisplayVideoGroup(
        filterWithCategoriesRestrictionsAndSearch(
          videoGroups.sort(sortByTimeUsed)
        )
      )
    )
  }, [
    videoGroups,
    categoryFilter,
    restrictionFilter,
    search,
    selectedClinicalPicture,
  ])

  useEffect(() => {
    setPage(1)
    mutate({
      reasonId: reason?.id ?? -1,
      page: 1,
      search: debouncedSearch,
    })
  }, [debouncedSearch])

  return (
    <Box p="16px" width="full">
      <Flex>
        <Flex direction="column" pos="sticky" top={4} h="fit-content">
          {isJobOsteo && (
            <Reason
              reason={reasonType}
              setReason={setReasonType}
              isBabySwitchOn={false}
            />
          )}
          <Flex alignItems={"center"}>
            <Flex justifyContent="center" w="full">
              {reasonType === "zones" && (
                <ZonesPathologies
                  datas={getZones(isJobKine)}
                  selectedData={reason}
                  setSelectedData={setReason}
                  isForKine={!isJobOsteo}
                  reason={appointmentReason}
                  selectedClinicalPicture={selectedClinicalPicture}
                  setSelectedClinicalPicture={setSelectedClinicalPicture}
                />
              )}
              {reasonType === "pathologies" && isJobOsteo && (
                <ZonesPathologies
                  datas={PATHOLOGIES_MOCK}
                  selectedData={reason}
                  setSelectedData={setReason}
                />
              )}
            </Flex>
          </Flex>
        </Flex>

        <Box flex={1} px="60px" position="relative">
          <TabExercices
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isCreatingProgram
            isForKine={!isJobOsteo}
            appointmentReason={reason?.id}
          />
          {selectedTab === 0 && (
            <FilterExercices
              categories={categories}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              setSearch={setSearch}
              restrictions={restrictions}
              restrictionFilter={restrictionFilter}
              setRestrictionFilter={setRestrictionFilter}
            />
          )}

          {selectedTab === 0 && uploadedVideos && uploadedVideos?.length > 0 ? (
            <Box>
              <Text fontWeight="bold" mt={8} mb={4}>
                Vos vidéos
              </Text>
              <Flex justifyContent="flex-start" flexWrap="wrap" gap={4}>
                {uploadedVideos.map((video) => (
                  <AUploadedExerciceCard
                    key={`video_${video.id}`}
                    video={video}
                    selectedExercices={selectedUploadedExercices}
                    setSelectedExercices={setSelectedUploadedExercices}
                    setSelectedVideoOrder={setSelectedVideoOrder}
                    isForKine={!isJobOsteo}
                    appointmentReason={reason?.id}
                  />
                ))}
              </Flex>
            </Box>
          ) : null}

          {isAppointmentReasonLoading ? (
            <Flex
              flex={1}
              px="60px"
              position="relative"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner color="primary.500" />
            </Flex>
          ) : (
            <Box>
              <Text fontWeight="bold" mt={8} mb={4}>
                Exercices
              </Text>
              <Flex justifyContent="flex-start" flexWrap="wrap" gap={4}>
                {selectedTab === 0 &&
                  sortedFilteredVideoGroups.map((videoGroup, i) => (
                    <VideoGroup
                      key={`group_${videoGroup.id}_${i}`}
                      videoGroup={videoGroup}
                      selectedExercices={selectedExercices}
                      setSelectedExercices={setSelectedExercices}
                      setSelectedVideoOrder={setSelectedVideoOrder}
                      isForKine={!isJobOsteo}
                      isLiked={
                        likedVideos?.some(
                          (likedVideo) => likedVideo.id === videoGroup.id
                        ) ?? false
                      }
                    />
                  ))}
                {selectedTab === 2 &&
                  likedVideos?.map((videoGroup) => (
                    <VideoGroup
                      key={`liked_video_group_${videoGroup.id}`}
                      videoGroup={videoGroup}
                      reason={reason}
                      isLiked={true}
                    />
                  ))}
              </Flex>
              {selectedTab === 0 && (
                <Box mt={4}>
                  <ATablePagination
                    pageCount={pageCount}
                    onPageChange={setPage}
                    page={page}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>

        {selectedExercices && (
          <SelectedExercices
            selectedExercices={selectedExercices}
            setSelectedExercices={setSelectedExercices}
            selectedUploadedExercices={selectedUploadedExercices}
            setSelectedUploadedExercices={setSelectedUploadedExercices}
            selectedVideoOrder={selectedVideoOrder}
            setSelectedVideoOrder={setSelectedVideoOrder}
            isCreatingProgram
            showOnlyDuration={isJobOsteo ?? false}
          />
        )}
      </Flex>
    </Box>
  )
}

export default SelectProgramExercices

import { MeType } from "api/user"
import { CurrentScope } from "AppContext"

const userHasCenterInCurrentScope = (
  user: MeType | null,
  currentScope: CurrentScope | null
) =>
  currentScope?.type === "center" &&
  user?.osteo.centers?.some((center) => center.id === currentScope.id)

export default userHasCenterInCurrentScope

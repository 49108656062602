import axiosInstance from "api/axiosInstance"

export type MessageTemplateType = {
  id: number
  name: string
  subject: string
  text: string
}

export const getMessageTemplates = async () => {
  const response = await axiosInstance.get<MessageTemplateType[]>(
    `/message-template/getTemplates`
  )
  return response.data
}

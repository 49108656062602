import { MeType } from "api/user"
import { CurrentScope } from "AppContext"

const getCurrentAcademy = ({
  currentScope,
  user,
}: {
  currentScope: CurrentScope | null
  user: MeType | null
}) =>
  currentScope?.type !== "academy"
    ? undefined
    : user?.osteo.academies?.find((academy) => academy.id === currentScope.id)

export default getCurrentAcademy

import React, { ReactElement } from "react"
import { Flex, Text, IconProps, FlexProps } from "@chakra-ui/react"

export type VariantType = "redesign" | "teleconsultation"

const theme = {
  redesign: {
    bg: "rgba(255, 255, 255, 0.40)",
    color: "common.600",
    borderRadius: "4px",
    _hover: {
      bg: "rgba(255, 255, 255, 0.70)",
      color: "common.600",
    },
    _focus: {
      bg: "rgba(255, 255, 255, 0.70)",
      color: "white",
    },
    px: "12px",
    py: "10px",
  },
  teleconsultation: {
    bg: "rgba(0, 0, 0, 0.08)",
    color: "common.900",
    borderRadius: "4px",
    _hover: {
      bg: "rgba(0, 0, 0, 0.20)",
    },
    _focus: {
      bg: "rgba(0, 0, 0, 0.20)",
    },
    py: "4px",
    px: "8px",
  },
}

const getTheme = (variant: VariantType) => {
  return theme[variant]
}

interface ANewButtonProps extends FlexProps {
  icon?: ReactElement<IconProps>
  text: string
  variant?: VariantType
  as?: React.ElementType
  to?: string
}

const ANewButton: React.FC<ANewButtonProps> = ({
  icon,
  text,
  variant = "redesign",
  as,
  to,
  ...rest
}) => {
  return (
    <Flex
      {...getTheme(variant)}
      as={as || "button"}
      to={to}
      align="center"
      justify="start"
      gap="8px"
      {...rest}
    >
      {icon}
      <Text>{text}</Text>
    </Flex>
  )
}

export default ANewButton

import { Container, Flex, Heading, Image } from "@chakra-ui/react"
import { useAppContext } from "AppContext"
import AndrewThinking from "assets/illustrations/andrew-interrogateur.png"

const CollaboratorDeactivated = () => {
  const { currentScope } = useAppContext()
  return (
    <Container maxW={500} minH="100vh" p={0}>
      <Flex
        backgroundColor={"primary.100"}
        direction={"column"}
        alignItems="center"
        h="100%"
      >
        <Image
          src={AndrewThinking}
          w={159}
          h={214}
          alt="Andrew Interrogateur"
        />
        <Heading
          as="h1"
          color={"tertiary.500"}
          fontSize={28}
          fontWeight={700}
          mt={10}
          mb={3}
          textAlign="center"
        >
          Compte Centre désactivé
        </Heading>
        {currentScope !== null && currentScope.type === "center" && (
          <>
            <Heading
              as="h2"
              fontSize={18}
              fontWeight={400}
              mb={2}
              textAlign="center"
            >
              Contactez l'administrateur du centre
            </Heading>
            <Heading as="h2" fontSize={18} fontWeight={400} textAlign="center">
              En attendant, vous pouvez utiliser votre compte individuel si vous
              en avez un.
            </Heading>
          </>
        )}
      </Flex>
    </Container>
  )
}

export default CollaboratorDeactivated

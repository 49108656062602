import { StripeProductType } from "api/subscriptions"

const groupKineStripeProductsById = (subscriptions?: StripeProductType[]) => {
  const subscriptionsByMetadataId = (subscriptions || []).reduce<{
    [key: string]: StripeProductType
  }>((acc, product) => {
    const productId = product?.metadata?.id
    const hasVariants = product?.allPrices && product?.allPrices?.length > 1

    if (hasVariants && product.allPrices) {
      return productId === undefined
        ? acc
        : {
            ...acc,
            [`${product.metadata.id}_${
              product.allPrices[0].recurring.interval === "year"
                ? "yearly"
                : "monthly"
            }`]: { ...product, price: product.allPrices[0] },
            [`${product.metadata.id}_${
              product.allPrices[1].recurring.interval === "year"
                ? "yearly"
                : "monthly"
            }`]: { ...product, price: product.allPrices[1] },
          }
    }
    return productId === undefined
      ? acc
      : {
          ...acc,
          [productId]: product,
        }
  }, {})

  return subscriptionsByMetadataId
}

export default groupKineStripeProductsById

import React from "react"
import { SVGProps } from "../type"

const Hanches: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="79"
      fill="none"
      viewBox="0 0 50 79"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M7 17.5c2.4-4.4 4.668-11.167 5.5-14C28.003 15.5 46 54.5 47 61c.8 5.2 1 10.5 1 12.5-5.2-8.4-32.833-10.833-46-11-1-12 2-39.5 5-45z"
      ></path>
    </svg>
  )
}

export default Hanches

import { AppointmentType } from "api/appointments"
import { PatientAppointmentType, PatientType } from "api/patient"
import axiosInstance from "./axiosInstance"

export interface TreatmentType {
  id: number
  title: string
  osteo: OsteoType
  patient: PatientType
  appointments: PatientAppointmentType[]
}

export const getTreatmentByPatientId = async (patientId: number) => {
  const { data } = await axiosInstance.get<TreatmentType[]>(
    `/treatment/all/patient/${patientId}`
  )
  return data
}

export const getTreatmentById = async (treatmentId: number) => {
  const { data } = await axiosInstance.get<TreatmentType>(
    `/treatment/${treatmentId}`
  )
  return data
}

import { VideoGroupCategoryType } from "../api/appointmentReason"

export default function getVideoGroupCategory(
  category: VideoGroupCategoryType
): string {
  switch (category) {
    case "souplesse":
      return "Souplesse"
    case "renfo":
      return "Renforcement"
    case "mobilite":
      return "Mobilité"
    case "nerf":
      return "Nerf"
  }
}

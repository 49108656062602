import { CheckupType } from "api/patient"
import { format } from "date-fns"
import sortDates from "utils/sortDates"

const displaySortedCheckups = (checkups: CheckupType[]) =>
  checkups
    ?.sort((checkupA, checkupB) =>
      sortDates(new Date(checkupA.createdAt), new Date(checkupB.createdAt))
    )
    ?.map((checkup) => format(new Date(checkup.createdAt), "dd/MM/yyyy")) || []

export default displaySortedCheckups

import { MeType } from "api/user"
import { CurrentScope } from "AppContext"

const getCurrentCenter = ({
  currentScope,
  user,
}: {
  currentScope: CurrentScope | null
  user: MeType | null
}) =>
  currentScope?.type !== "center"
    ? undefined
    : user?.osteo.centers?.find((center) => center.id === currentScope.id)

export default getCurrentCenter

import React from "react"
import { SVGProps } from "../type"

const IschioJambiers: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      width="32"
      height="77"
      viewBox="0 0 32 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.87557 20.6438L11.6148 1.66537C11.7564 1.2665 12.1339 1 12.5571 1H22.4941C22.9826 1 23.3996 1.35289 23.4804 1.83464L30.9862 46.5997C30.9954 46.6544 31 46.7097 31 46.7651V75C31 75.5523 30.5523 76 30 76H2.07175C1.49223 76 1.03405 75.5089 1.07414 74.9308L4.82032 20.9092C4.8266 20.8187 4.84519 20.7293 4.87557 20.6438Z"
        fill={fill || "#E4FBEA"}
        fillOpacity={"0.8"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="2"
      />
    </svg>
  )
}

export default IschioJambiers

import {
  CANADA_CURRENCY_CODE,
  DEFAULT_CURRENCY_CODE,
} from "constants/currencies"

const CANADA_CODE = "CA"

const SUPPORTED_FOREIGN_CURRENCIES = {
  [CANADA_CODE]: CANADA_CURRENCY_CODE,
} as { [countryCode: string]: string }

const getCurrencyCodeForCountry = (countryCode?: string) => {
  if (!countryCode) {
    return DEFAULT_CURRENCY_CODE
  }
  return SUPPORTED_FOREIGN_CURRENCIES[countryCode] ?? DEFAULT_CURRENCY_CODE
}

export default getCurrencyCodeForCountry

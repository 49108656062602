import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
} from "@chakra-ui/react"
import ATimePicker from "components/ATimePicker"
import { useField } from "formik"
import { ComponentProps } from "react"
import { TimePickerValue } from "react-time-picker"

interface ATimePickerFieldProps
  extends Omit<ComponentProps<typeof ATimePicker>, "value" | "onChange"> {
  name: string
  isRequired?: FormControlProps["isRequired"]
}
const ATimePickerField = ({
  name,
  isRequired,
  ...props
}: ATimePickerFieldProps) => {
  const [{ value }, { error, touched }, { setValue }] =
    useField<TimePickerValue>({
      name,
    })

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={error !== undefined && touched}
    >
      <ATimePicker value={value} onChange={setValue} {...props} />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default ATimePickerField

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import useToast from "../hooks/useToast"
import React from "react"
import { useAppContext } from "../AppContext"
import AButton from "./AButton"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { useMutation } from "@tanstack/react-query"
import { createInvitation } from "../api/invitation"
import getCurrentScopeParams from "utils/getCurrentScopeParams"

interface IProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
}

const AInvite: React.FC<IProps> = ({ isOpen, onClose, cancelRef }) => {
  const createInvitationMutation = useMutation(createInvitation)
  const { user, currentScope } = useAppContext()
  const toast = useToast()

  const createInvitationExtraParams = getCurrentScopeParams(currentScope)

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent maxW={724} borderRadius={8} p={8}>
        <AlertDialogHeader mb={8} p={0} fontSize={18}>
          Veuillez entrer l'email du patient à inviter
        </AlertDialogHeader>
        <Formik
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Ceci n'est pas un email")
              .required("Email requis"),
          })}
          initialValues={{
            email: "",
          }}
          onSubmit={({ email }, { setSubmitting }) => {
            if (!user?.osteo.id) return

            createInvitationMutation.mutate(
              {
                patientEmail: email,
                osteoId: user?.osteo.id,
                ...createInvitationExtraParams,
              },
              {
                onError: (data: any) => {
                  setSubmitting(false)
                  toast({
                    status: "error",
                    title: data.response.data.error.message,
                  })
                },
                onSuccess: (data: any) => {
                  setSubmitting(false)
                  toast({
                    status: "success",
                    title: "Invitation envoyée avec succès",
                  })
                  onClose()
                },
              }
            )
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Field name="email">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel mt={6}>Mail *</FormLabel>
                    <Input {...field} placeholder="Email" bg="common.100" />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <AlertDialogFooter justifyContent="flex-end" p={0} mt={14}>
                <AButton
                  ref={cancelRef}
                  onClick={onClose}
                  text="Annuler"
                  variant="tertiary"
                  py={3}
                  px={27}
                />
                <AButton
                  ml={3}
                  text="Enregistrer les modifications"
                  py={3}
                  px={4}
                  type="submit"
                  isLoading={isSubmitting}
                />
              </AlertDialogFooter>
            </Form>
          )}
        </Formik>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default AInvite

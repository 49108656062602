import React from "react"
import { SVGProps } from "../type"

const EpineCalcaneenne: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="25"
      fill="none"
      viewBox="0 0 14 25"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M1.5 6.5C1.5.9 7.833 1.834 11 3l.5 19-2 1c-3.596 1.798-6.333-2.5-7-4-.333-1.833-1-6.9-1-12.5z"
      ></path>
    </svg>
  )
}

export default EpineCalcaneenne

import {
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react"
import { useState } from "react"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"

interface AInputPasswordProps extends InputProps {}
const AInputPassword = (props: AInputPasswordProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  return (
    <InputGroup>
      <Input
        {...props}
        placeholder="Mot de passe"
        bg="white"
        type={showPassword ? "text" : "password"}
      />
      <InputRightElement
        children={
          showPassword ? (
            <Icon
              fontSize={20}
              as={AiFillEye}
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <Icon
              fontSize={20}
              as={AiFillEyeInvisible}
              onClick={() => setShowPassword(true)}
            />
          )
        }
      />
    </InputGroup>
  )
}

export default AInputPassword

import React from "react"
import { SVGProps } from "../type"

const Crane: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="76"
      fill="none"
      viewBox="0 0 57 76"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M17.5 3.5c-12 2.4-13 16-12 22.5v9.5c-3.6 3.6-3.5 6.833-3 8l9.5 18C24.4 84.3 40.5 70 47 60l6.5-11c4.4-9.2.5-12.167-2-12.5L52 34c1.2-7.6.5-14.5 0-17C50 .5 32.5.5 17.5 3.5z"
      ></path>
    </svg>
  )
}

export default Crane

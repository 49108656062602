import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  IconButton,
  Input,
  List,
  ListItem,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from "@chakra-ui/react"
import AAutocompleteOption from "components/AAutocompleteMultiple/AAutocompleteOption"
import { useCombobox, useMultipleSelection } from "downshift"
import { ComponentProps, ElementType } from "react"
import getHighlightData from "utils/getHighlightData"

// COMPONENTS
const DefaultLabelComponent = (props: ComponentProps<"label">) => (
  <label {...props} />
)

interface AAutocompleteMultipleProps<T> {
  bg?: string
  width?: string
  border?: string
  labelText?: string
  labelProps?: any
  LabelComponent?: ElementType<any>
  placeholder?: string
  selectedItems: T[]
  setSelectedItems: (nextSelectedItems: T[]) => void
  items: T[]
  inputValue: string
  setInputValue?: (nextInputValue: string) => void
  renderItem: (item: T) => string
  getKey: (item: T) => string
  isLoading?: boolean
  isInvalid?: boolean
  moreItems?: number
}
const AAutocompleteMultiple = <T extends unknown>({
  bg = "white",
  width = "auto",
  border = "none",

  labelText,
  labelProps = {},
  LabelComponent = DefaultLabelComponent,
  placeholder,
  selectedItems,
  setSelectedItems,
  items,
  inputValue,
  setInputValue,
  renderItem,
  getKey,
  isLoading,
  isInvalid,
  moreItems,
}: AAutocompleteMultipleProps<T>) => {
  const availableItems = items.filter((item) => {
    const itemKey = getKey(item)
    return selectedItems.every(
      (selectedItem) => getKey(selectedItem) !== itemKey
    )
  })
  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            setSelectedItems(newSelectedItems ?? [])
            break
          default:
            break
        }
      },
    })
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
  } = useCombobox({
    items: availableItems,
    inputValue,
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            ...(changes.selectedItem
              ? { isOpen: true, highlightedIndex: 0 }
              : {}),
          }
        default:
          return changes
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (!newSelectedItem) return
          setSelectedItems([...selectedItems, newSelectedItem])

          break
        case useCombobox.stateChangeTypes.InputChange:
          if (!setInputValue) return
          setInputValue(newInputValue ?? "")
          break
        default:
          break
      }
    },
  })

  return (
    <>
      <LabelComponent {...labelProps} {...getLabelProps()}>
        {labelText}
      </LabelComponent>
      <Flex
        bg={bg}
        border={border}
        width={width}
        direction="column"
        borderRadius="md"
        outline="1px solid"
        outlineColor={isInvalid ? "#E53E3E" : "transparent"}
        _hover={{ outlineColor: isInvalid ? "#E53E3E" : "gray.300" }}
        _focusWithin={{ outlineColor: isInvalid ? "#E53E3E" : "#3182ce" }}
      >
        <Flex borderRadius="inherit" overflow="hidden">
          <Flex
            alignItems="center"
            flexWrap="wrap"
            minH={10}
            p={selectedItems.length > 0 ? 2 : 0}
            gap={2}
          >
            {selectedItems.map((selectedItem, index) => (
              <Tag
                variant="outline"
                colorScheme="teal"
                key={`selected-item-${getKey(selectedItem)}`}
                {...getSelectedItemProps({ selectedItem, index })}
              >
                <TagLabel>{renderItem(selectedItem)}</TagLabel>
                <TagCloseButton
                  aria-label="Retirer l'option"
                  onClick={(e) => {
                    e.stopPropagation()
                    removeSelectedItem(selectedItem)
                  }}
                />
              </Tag>
            ))}
          </Flex>
          <Flex grow={1}>
            {(setInputValue || !selectedItems.length) && (
              <Input
                type="text"
                placeholder={placeholder}
                borderRadius={0}
                border="none"
                _invalid={{ borderColor: "" }}
                _focus={{ border: "none", boxShadow: "none" }}
                fontFamily="Montserrat"
                bg={bg}
                height="100%"
                fontSize={14}
                minH={10}
                minWidth={48}
                {...getInputProps(
                  getDropdownProps({ preventKeyAction: isOpen })
                )}
              />
            )}
            <IconButton
              {...getToggleButtonProps()}
              aria-label="Ouvrir le menu"
              variant="ghost"
              colorScheme="blackAlpha"
              icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              height="100%"
            />
          </Flex>
        </Flex>
        <Box width="100%" height={0} position="relative">
          <List
            {...getMenuProps()}
            position="absolute"
            zIndex="dropdown"
            top={2}
            right={0}
            left={0}
            maxH={96}
            overflow="auto"
            bg="white"
            display={!isOpen ? "none" : undefined}
            borderRadius="md"
            boxShadow="base"
          >
            {isOpen && (
              <>
                {!isLoading && availableItems.length === 0 ? (
                  <ListItem bg="white" py={3} px={4}>
                    Aucun résultat
                  </ListItem>
                ) : (
                  <>
                    {availableItems.map((item, index) => {
                      const text = renderItem(item)
                      const {
                        startHighlightIndex,
                        endHighlightIndex,
                        hasHighlight,
                      } = getHighlightData({ text, inputValue })

                      return (
                        <AAutocompleteOption
                          key={getKey(item)}
                          {...getItemProps({ item, index })}
                        >
                          <Flex alignItems="center">
                            {hasHighlight ? (
                              <>
                                <Text
                                  fontSize={14}
                                  mr={1}
                                  fontWeight={700}
                                  margin={0}
                                  whiteSpace="pre"
                                >
                                  {text.substring(0, startHighlightIndex)}
                                </Text>
                                <Text
                                  fontSize={14}
                                  mr={1}
                                  fontWeight={700}
                                  color="primary.500"
                                  margin={0}
                                  whiteSpace="pre"
                                >
                                  {text.substring(
                                    startHighlightIndex,
                                    endHighlightIndex
                                  )}
                                </Text>
                                <Text
                                  fontSize={14}
                                  mr={1}
                                  fontWeight={700}
                                  margin={0}
                                  whiteSpace="pre"
                                >
                                  {text.substring(endHighlightIndex)}
                                </Text>
                              </>
                            ) : (
                              <Text
                                fontSize={14}
                                mr={1}
                                fontWeight={700}
                                margin={0}
                                whiteSpace="pre"
                              >
                                {text}
                              </Text>
                            )}
                          </Flex>
                        </AAutocompleteOption>
                      )
                    })}
                    {(moreItems ?? 0) > 0 && (
                      <ListItem bg="white" fontSize="sm" py={1} px={4}>
                        {moreItems} autres résultats
                      </ListItem>
                    )}
                  </>
                )}
              </>
            )}
          </List>
        </Box>
      </Flex>
    </>
  )
}

export default AAutocompleteMultiple

import { Box, Flex, Link, Text } from "@chakra-ui/react"

const AFooter = () => {
  return (
    <Box bg="primary.200" px={12} py={4}>
      <Flex
        justifyContent="center"
        alignItems="center"
        direction="column"
        py={4}
        gap={4}
      >
        <Flex>
          <Text>Une question ? Contactez la hotline Andrew : </Text>
          <Link fontWeight="bold" href="telto:07 89 34 42 76" ml={2}>
            07 89 34 42 76
          </Link>
        </Flex>
        <Flex>
          <Link href="/cgs" fontWeight="bold" fontSize="sm">
            CGS
          </Link>
        </Flex>
      </Flex>
    </Box>
  )
}

export default AFooter

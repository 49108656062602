import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Checkbox,
  Center,
} from "@chakra-ui/react"
import AButton from "components/AButton"
import { SCHEDULE_DAYS_MOCK } from "pages/NewAppointment/components/SelectSchedule/selectSchedule.mock"
import { ReactNode, useState } from "react"

interface DuplicateDayPopoverProps {
  children: ReactNode
  currentDay: number
  onDuplicate: (targets: number[]) => void
}

const DuplicateDayPopover = ({
  children,
  currentDay,
  onDuplicate,
}: DuplicateDayPopoverProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [targets, setTargets] = useState<number[]>([])

  const onClose = () => {
    setIsOpen(false)
    setTargets([])
  }

  return (
    <Popover isOpen={isOpen} onOpen={() => setIsOpen(true)} onClose={onClose}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent w="auto" px={5}>
        <PopoverHeader border="none" fontSize={18}>
          Copier les heures à
        </PopoverHeader>
        <PopoverBody p={0}>
          {SCHEDULE_DAYS_MOCK.map(({ label, value }) => (
            <Checkbox
              key={value}
              px={2}
              display="flex"
              _hover={{ bg: "common.100" }}
              spacing={0}
              flexDirection="row-reverse"
              justifyContent="space-between"
              width="100%"
              isDisabled={value === currentDay}
              isChecked={targets.includes(value) || value === currentDay}
              onChange={(e) => {
                const nextChecked = e.target.checked
                return setTargets((prevTargets) =>
                  nextChecked
                    ? [...prevTargets, value]
                    : prevTargets.filter((id) => id !== value)
                )
              }}
            >
              {label}
            </Checkbox>
          ))}
          <Center w="100%">
            <AButton
              my={5}
              text="Appliquer"
              onClick={() => onDuplicate(targets)}
            />
          </Center>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default DuplicateDayPopover

import React from "react"
import { SVGProps } from "../type"

const ChevillePied: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="57"
      fill="none"
      viewBox="0 0 38 57"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M34 3c-4.8 2.8-16 1.167-21 0 3.2 12 .333 25.333-1.5 30.5-6 8.8-8.833 14.333-9.5 16 .4 8 21.5 5.333 32 3 2.4-2.8 2.333-19.833 2-28-2.4-8-2.333-17.667-2-21.5z"
      ></path>
    </svg>
  )
}

export default ChevillePied

import axiosInstance from "./axiosInstance"
import { MediaType } from "./media"

export type TopicCategoryEnum = "mind" | "body" | "podcast"

export type TopicListType = {
  id: number
  attributes: {
    key: string
    order: number
    title: string
    type: TopicCategoryEnum
    infoUrl: string
    illustration: {
      data: {
        id: number
        attributes: {
          alternativeText: string
          formats: {
            medium: {
              url: string
            }
          }
        }
      }
    }
  }
}

export const getTopics = async () => {
  const { data } = await axiosInstance.get<{ data: TopicListType[] }>(
    `/explorer-topics?sort=order&populate=illustration`
  )
  return data.data
}

export type TopicType = {
  id: number
  key: string
  title: string
  type: TopicCategoryEnum
  medias: MediaType[]
}

export const getTopic = async (id: string) => {
  const { data } = await axiosInstance.get<TopicType>(
    `/topic/getTopic?topicId=${id}`
  )
  return data
}

export type TopicFullListType = {
  id: number
  medias: MediaType[]
  attributes: {
    key: string
    order: number
    title: string
    type: TopicCategoryEnum
    infoUrl: string
    illustration: {
      data: {
        id: number
        attributes: {
          alternativeText: string
          formats: {
            medium: {
              url: string
            }
          }
        }
      }
    }
  }
}

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  FormControl,
  Textarea,
} from "@chakra-ui/react"
import useToast from "../../../hooks/useToast"
import { useMutation } from "@tanstack/react-query"
import { Field, Form, Formik } from "formik"
import React from "react"
import { updateAppointment } from "../../../api/appointments"
import { PatientAppointmentType } from "../../../api/patient"
import AButton from "../../../components/AButton"

interface IProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
  appointment?: PatientAppointmentType
  setAppointment?: React.Dispatch<
    React.SetStateAction<PatientAppointmentType | null>
  >
  type: "osteoAdvice" | "internalNote" | "secondaryInternalNote"
}

const title = {
  osteoAdvice: "Modifier le conseil de la consultation envoyé au patient",
  internalNote: "Modifier la note complémentaire (non visible par le patient)",
  secondaryInternalNote:
    "Modifier la note supplémentaire (non visible par le patient)",
}

const EditRecommandation: React.FC<IProps> = ({
  isOpen,
  onClose,
  cancelRef,
  appointment,
  setAppointment,
  type,
}) => {
  const updateAppointmentMutation = useMutation(updateAppointment)
  const toast = useToast()

  if (!appointment) return null
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent maxW={724} borderRadius={8} p={8}>
        <AlertDialogHeader mb={8} p={0} fontSize={18}>
          {title[type]}
        </AlertDialogHeader>

        <Formik
          initialValues={{ [type]: appointment[type] }}
          onSubmit={(values, { setSubmitting }) => {
            if (!appointment || !appointment.id) return

            updateAppointmentMutation.mutate(
              { id: appointment?.id?.toString(), data: values },
              {
                onSuccess: (data: PatientAppointmentType) => {
                  if (!setAppointment) return

                  setSubmitting(false)
                  setAppointment({
                    ...appointment,
                    osteoAdvice: data.osteoAdvice,
                    internalNote: data.internalNote,
                    secondaryInternalNote: data.secondaryInternalNote,
                  })
                  onClose()
                  toast({
                    status: "success",
                    title: "Modifications enregistrées",
                  })
                },
                onError: () => {
                  setSubmitting(false)
                  toast({
                    status: "error",
                    title: "Une erreur s'est produite",
                  })
                },
              }
            )
          }}
        >
          {(props) => (
            <Form>
              <Field name={type}>
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Écrivez vos notes ici"
                      bg="common.100"
                    />
                  </FormControl>
                )}
              </Field>
              <AlertDialogFooter justifyContent="flex-end" p={0} mt={14}>
                <AButton
                  ref={cancelRef}
                  onClick={onClose}
                  text="Annuler"
                  variant="tertiary"
                  py={3}
                  px={27}
                />
                <AButton
                  ml={3}
                  text="Enregistrer les modifications"
                  py={3}
                  px={4}
                  type="submit"
                  isLoading={props.isSubmitting}
                />
              </AlertDialogFooter>
            </Form>
          )}
        </Formik>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default EditRecommandation

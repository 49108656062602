import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react"
import AButton from "components/AButton"
import ASeatsButtonGroup from "components/ASeatsButtonGroup"
import { Dispatch, RefObject, SetStateAction } from "react"
import AndrewThinking from "assets/illustrations/andrew-interrogateur.png"
import AndrewHero from "assets/illustrations/andrew-superheros.png"
import formatPrice from "utils/price/formatPrice"
import { CheckIcon } from "@chakra-ui/icons"
import groupStripeProductsById from "utils/groupStripeProductsById"
import { useAppContext } from "AppContext"
import { MeType } from "api/user"
import { CurrencyCode, StripeProductType } from "api/subscriptions"
import { getLocalizedCenterPerSeatPrice } from "utils/price/getCenterPrice"
import formatCurrency from "utils/price/formatCurrency"
import getCurrencyCodeForCountry from "utils/price/getCurrencyCodeForCountry"

// HELPERS
const getNextStripeProductMetadataId = (user: MeType) => {
  const currentInterval = user.stripeSubscription?.plan.interval // on devrait forcément avoir une souscription individuelle
  const nextStripeProductMetadataId = `business_${currentInterval}ly`
  return nextStripeProductMetadataId
}

interface MoveToBusinessSubscriptionProps {
  cancelRef: RefObject<HTMLButtonElement>
  isOpen: boolean
  onClose: () => void
  onSubscribe: (priceId: string) => void
  seats: number
  setSeats: Dispatch<SetStateAction<number>>
  isLoading: boolean
  stripeProducts: StripeProductType[]
}
const MoveToBusinessSubscriptionDialog = ({
  cancelRef,
  isOpen,
  onClose,
  onSubscribe,
  seats,
  setSeats,
  isLoading,
  stripeProducts,
}: MoveToBusinessSubscriptionProps) => {
  const { user } = useAppContext()
  const stripeProductsByMetadataId = groupStripeProductsById(stripeProducts)
  const currentInterval = user?.stripeSubscription?.plan.interval

  const nextStripeProduct = stripeProductsByMetadataId.kine_business

  const currencyCode = getCurrencyCodeForCountry(
    user?.osteo.mainAddress?.country
  ) as CurrencyCode
  const currencyPrice = nextStripeProduct?.allPrices?.find(
    (price) => price.recurring.interval === currentInterval
  )

  if (!currencyPrice) return null
  //TODO: finir les changement d'abo
  const interval = currencyPrice?.recurring.interval
  const priceId = currencyPrice.id
  const pricePerSeat = getLocalizedCenterPerSeatPrice(
    nextStripeProduct,
    currencyCode
  )

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="primary.100" p={12} pb={6} minW="764">
          <AlertDialogHeader
            fontSize={22}
            fontWeight={700}
            fontFamily="Montserrat"
            color="tertiary.500"
            textAlign="center"
          >
            Passez au forfait Centre
          </AlertDialogHeader>

          <AlertDialogBody pb={6}>
            <Flex width="100%">
              <Image
                position="absolute"
                left={8}
                w={86}
                h={117}
                src={AndrewThinking}
                alt="Andrew Interrogateur"
              />
              <Flex direction="column" ml={24}>
                <Text fontSize={18} fontWeight={700} color="tertiary.500">
                  Plusieurs thérapeutes collaborent sur votre lieu de travail ?
                </Text>
                <List spacing={2}>
                  <ListItem textAlign="left">
                    <ListIcon as={CheckIcon} color="secondary.800" />
                    Invitez vos collaborateurs à vous rejoindre.
                  </ListItem>
                  <ListItem textAlign="left">
                    <ListIcon as={CheckIcon} color="secondary.800" />
                    Partagez les informations de suivi de vos patients entre
                    thérapeutes de votre centre.
                  </ListItem>
                </List>
              </Flex>
            </Flex>
            <Flex direction="column" mt={10} mb={4} align="center">
              <Text fontSize={16}>
                Indiquez le nombre de thérapeutes qui utiliseront Andrew® Pro
                dans votre centre.
              </Text>
              <Text fontSize={16}>
                Vous pourrez modifier ce nombre et ajuster votre forfait à tout
                moment.
              </Text>
              <ASeatsButtonGroup seats={seats} setSeats={setSeats} />
            </Flex>
            <Heading as="p" fontSize={18} fontWeight={700} textAlign="center">
              {formatPrice(seats * pricePerSeat)}{" "}
              {formatCurrency(currencyPrice.currency)} / mois
            </Heading>
            {interval === "year" && (
              <Text fontSize={16} align="center">
                facturé annuellement, soit{" "}
                {formatPrice(seats * pricePerSeat * 12)}{" "}
                {formatCurrency(currencyPrice.currency)} par an
              </Text>
            )}
          </AlertDialogBody>

          <AlertDialogFooter
            flexDirection="column"
            alignItems="center"
            justifyContent="start"
          >
            <AButton
              text="Passer au forfait Centre"
              type="button"
              isLoading={isLoading}
              variant="primary"
              onClick={() => onSubscribe(priceId)}
            />
            <Flex justifyContent="center" mt={2}>
              <Text
                maxW={416}
                fontSize={16}
                textAlign="center"
                fontWeight={700}
              >
                Votre abonnement actuel sera modifié et vous ne paierez que la
                différence du montant déjà réglé
              </Text>
            </Flex>
            <Image
              src={AndrewHero}
              position="absolute"
              right={8}
              w={87}
              h={107}
              alt="Andrew Super héros"
            />
            <AButton
              text="Annuler"
              ref={cancelRef}
              onClick={onClose}
              variant="tertiary"
              mt={8}
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default MoveToBusinessSubscriptionDialog

import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import useToast from "../../../hooks/useToast"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useContext, useEffect, useState } from "react"
import {
  getSubscriptions,
  StripeProductType,
  createCheckoutSession,
  createCenterGroupCheckoutSession,
  CurrencyCode,
  getPartner,
  PartnerType,
  getAllSubscriptions,
} from "../../../api/subscriptions"
import AButton from "../../../components/AButton"
import Cover from "../../../assets/andrew-cover.png"
import { useAppContext } from "../../../AppContext"
import RgpdContext from "../../../components/Rgpd/RgpdContext"
import { subscribeStart } from "../../../utils/facebookPixelEvents"
import ALogoutButton from "../../../components/ALogoutButton"
import { sendinblueTrack } from "../../../utils/sendinBlueEvents"
import ALogo from "../../../components/ALogo"
import StripeProductCard, {
  SubscriptionContentType,
} from "../../../components/StripeProductCard"
import CenterSubscribeDialog from "pages/Subscribe/components/CenterSubscribeDialog"
import groupStripeProductsById from "../../../utils/groupStripeProductsById"
import { useCookies } from "react-cookie"
import getCurrencyCodeForCountry from "utils/price/getCurrencyCodeForCountry"
import groupKineStripeProductsById from "utils/groupKineStripeProductsById"

// CONSTANTS
const DURATION_TAB_INDICES = {
  monthly: 0,
  yearly: 1,
} as const

const TYPE_TAB_INDICES = {
  individual: 0,
  center: 1,
} as const

const CONTENT_INDIVIDUAL_CLASSIC: SubscriptionContentType = {
  titleEnd: "Classique",
  list: [
    "Accès à l’algorithme de prescription d’exercices.",
    "Suivi et analyse de vos patients.",
    "Accès complet à tous les contenus de l'Explorer.",
    "Nombre de patients illimité.",
  ],
}

const CONTENT_CENTER_CLASSIC: SubscriptionContentType = {
  titleEnd: "Classique",
  list: [
    "Suivi des patients partagé entre tous les thérapeutes du centre.",
    "Communication à l’intégralité de la patientèle du centre.",
    "Gestion des collaborateurs.",
    "Accès à l’algorithme de prescription d’exercices.",
    "Suivi et analyse de vos patients.",
    "Accès complet aux contenus de l’Explorer.",
    "Nombre de patients illimité.",
  ],
}

const CONTENT_TELECONSULTATION_MONTHLY: SubscriptionContentType = {
  titleEnd: "Téléconsultation",
  list: ["Module téléconsultation.", "Module télépaiement."],
  refund: "29,90€",
}

const CONTENT_TELECONSULTATION_YEARLY: SubscriptionContentType = {
  titleEnd: "Téléconsultation",
  list: ["Module téléconsultation.", "Module télépaiement."],
  refund: "19,90€",
}

// COMPONENTS
const SubscribeKine = () => {
  // start with yearly
  const [durationTabIndex, setDurationTabIndex] = useState<number>(
    DURATION_TAB_INDICES.yearly
  )
  const [typeTabIndex, setTypeTabIndex] = useState<number>(
    TYPE_TAB_INDICES.individual
  )
  const [selectedProduct, setSelectedProduct] =
    useState<StripeProductType | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data, isLoading } = useQuery<StripeProductType[]>(
    ["getSubscriptions"],
    getSubscriptions
  )

  const onTabChange = (index: number) => {
    setDurationTabIndex(index)
    setSelectedProduct(null)
  }

  const subscriptionsByMetadataId = groupKineStripeProductsById(data)
  const individualMonthly = subscriptionsByMetadataId["kine_monthly"]
  const invidividualTeleconsultationMonthly =
    subscriptionsByMetadataId["kine_teleconsultation_monthly"]
  const individualYearly = subscriptionsByMetadataId["kine_yearly"]
  const invidividualTeleconsultationYearly =
    subscriptionsByMetadataId["kine_teleconsultation_yearly"]
  const businessMonthly = subscriptionsByMetadataId["kine_business_monthly"]
  const businessTeleconsultationMonthly =
    subscriptionsByMetadataId["kine_business_teleconsultation_monthly"]
  const businessYearly = subscriptionsByMetadataId["kine_business_yearly"]
  const businessTeleconsultationYearly =
    subscriptionsByMetadataId["kine_business_teleconsultation_yearly"]
  const businessProducts = [
    businessMonthly,
    businessYearly,
    businessTeleconsultationMonthly,
    businessTeleconsultationYearly,
  ]

  const toast = useToast()
  const createCheckoutSessionMutation = useMutation(createCheckoutSession)
  const createCenterGroupCheckoutSessionMutation = useMutation(
    createCenterGroupCheckoutSession
  )
  const { user, referralId } = useAppContext()
  const { marginWithBanner } = useContext(RgpdContext)

  const moveToAnnual = () => {
    setDurationTabIndex(DURATION_TAB_INDICES.yearly)
    setSelectedProduct(businessYearly)
  }

  const [cookies] = useCookies(["partner"])
  const [affiliationCookie] = useCookies(["affiliation"])
  const affiliationNameUrl = affiliationCookie.affiliation

  const subscribe = (product: StripeProductType, seats?: number) => {
    if (user?.email && product.price.id) {
      const mutation = businessProducts
        .map((p) => p.price.id)
        .includes(product.price.id)
        ? createCenterGroupCheckoutSessionMutation
        : createCheckoutSessionMutation

      const currencyCode = getCurrencyCodeForCountry(
        user.osteo.mainAddress?.country
      ) as CurrencyCode
      const priceId = product.price.id
      let stripeCode =
        partner?.name !== undefined ? partner?.stripeCode : undefined

      if (!shouldApplyPromo()) {
        stripeCode = undefined
      }

      mutation.mutate(
        {
          priceId: priceId,
          email: user.email,
          quantity: seats,
          stripeCode,
          affiliationNameUrl,
          clientReferenceId: referralId ?? undefined,
        },
        {
          onError: () => {
            toast({
              status: "error",
              title: "Une erreur s'est produite",
            })
          },
          onSuccess: async ({ id, url }: { id: string; url: string }) => {
            subscribeStart()
            window.location.href = url
          },
        }
      )
    }
  }

  useEffect(() => {
    if (user?.email) {
      sendinblueTrack("view_subscriptions", {
        email: user?.email,
      })
    }
  }, [user])

  const formatSubscriptionName = (subscriptionMetadataId: string): string => {
    if (subscriptionMetadataId.includes("teleconsultation")) {
      return "Forfait téléconsultation"
    }
    return "Forfait classique"
  }

  const { data: partner, isLoading: partnerIsLoading } = useQuery<PartnerType>(
    ["getStripePartnerCode", cookies.partner],
    () => getPartner(cookies.partner),
    {
      enabled: !!cookies.partner,
      retry: false,
      onError: () => {
        //
      },
    }
  )

  const shouldApplyPromo = () => {
    if (!partner) return false
    if (partner?.subscriptions.length === 0) {
      return true
    }

    if (partner?.subscriptions.length === 1) {
      return (
        selectedProduct?.metadata.id === partner?.subscriptions[0].metadataId
      )
    }

    return partner?.subscriptions.some(
      (subscription) => subscription.metadataId === selectedProduct?.metadata.id
    )
  }

  const handleClose = () => {
    onClose()
    setSelectedProduct(null)
  }

  const { data: allSubcriptions } = useQuery(
    ["getAllSubscriptions"],
    getAllSubscriptions
  )
  const nbrSubscriptions =
    partner?.name !== undefined ? partner?.subscriptions?.length : 0

  if (isLoading)
    return (
      <Center flex={1} height="100vh">
        <Spinner color="primary.500" />
      </Center>
    )
  return (
    <>
      <Flex justifyContent="center">
        <Box flex={1} mb={marginWithBanner} ml={84} mr={16}>
          <ALogo w="60%" />
          <Box>
            {user?.osteo.sessionId && (
              <Heading
                as="h1"
                fontSize={22}
                fontWeight={700}
                color="tertiary.500"
                mb={55}
              >
                Vos informations de paiement ne sont pas à jour, pour accéder de
                nouveau à votre compte, merci de mettre à jour vos informations
                bancaires
              </Heading>
            )}

            {!user?.osteo.sessionId && (
              <Flex mb={12} flexDir="column" gap={2}>
                <Heading
                  as="h2"
                  fontWeight={700}
                  color="common.900"
                  fontSize={22}
                  display="inline-block"
                >
                  Démarrer votre essai gratuit de 15 jours
                </Heading>
                <Text color="common.900" fontSize={18} fontWeight={400}>
                  Sans engagement
                </Text>
              </Flex>
            )}
            <Heading
              as="h2"
              textAlign="center"
              fontWeight={700}
              fontSize={18}
              maxW={"200px"}
              mx="auto"
              lineHeight="26px"
            >
              Vous travaillez seul ou en équipe ?
            </Heading>
            <Flex
              justifyContent="center"
              bg="secondary.800"
              borderRadius={10}
              p={2}
              w="230px"
              mx="auto"
              mb={10}
              mt={4}
            >
              <Box
                fontWeight={700}
                color={
                  typeTabIndex === TYPE_TAB_INDICES.individual
                    ? "black"
                    : "white"
                }
                py={2}
                px={4}
                bg={
                  typeTabIndex === TYPE_TAB_INDICES.individual
                    ? "white"
                    : "none"
                }
                borderRadius={10}
                cursor="pointer"
                onClick={() => setTypeTabIndex(TYPE_TAB_INDICES.individual)}
                w="full"
                textAlign="center"
              >
                Seul
              </Box>
              <Box
                fontWeight={700}
                color={
                  typeTabIndex === TYPE_TAB_INDICES.center ? "black" : "white"
                }
                py={2}
                px={4}
                bg={typeTabIndex === TYPE_TAB_INDICES.center ? "white" : "none"}
                borderRadius={10}
                cursor="pointer"
                onClick={() => setTypeTabIndex(TYPE_TAB_INDICES.center)}
                w="full"
                textAlign="center"
              >
                Équipe
              </Box>
            </Flex>
            <Tabs
              index={durationTabIndex}
              mt={2}
              align="center"
              variant="soft-rounded"
              colorScheme="common"
              onChange={onTabChange}
            >
              <TabList
                width="fit-content"
                bg="secondary.800"
                mb={durationTabIndex === DURATION_TAB_INDICES.monthly ? 8 : 10}
                borderRadius={10}
                p={2}
                w="230px"
              >
                <Tab
                  color="white"
                  _selected={{ color: "black", bg: "white" }}
                  borderRadius={10}
                >
                  Mensuelle
                </Tab>
                <Tab
                  color="white"
                  borderRadius={10}
                  _selected={{ color: "black", bg: "white" }}
                >
                  Annuelle
                </Tab>
              </TabList>
              {durationTabIndex === DURATION_TAB_INDICES.monthly && (
                <Box mb={10}>
                  <Heading
                    as="h3"
                    textAlign="center"
                    fontWeight={700}
                    fontSize={18}
                  >
                    <Text as="span" fontWeight={400}>
                      Économisez{" "}
                    </Text>
                    <Text as="span" color="primary.500" fontWeight={700}>
                      jusqu'à -25%{" "}
                    </Text>
                    <Text as="span" fontWeight={400}>
                      avec la facturation annuelle
                    </Text>
                  </Heading>
                </Box>
              )}
              {partner && (
                <>
                  {(nbrSubscriptions === 0 ||
                    nbrSubscriptions === allSubcriptions.length) && (
                    <Text mb={4} fontWeight="bold">
                      Le partenariat avec {partner.name} est valable sur
                      l'ensemble des abonnements. <br />
                      Une promotion sera automatiquement appliquée lors de
                      l'étape suivante.
                    </Text>
                  )}

                  {nbrSubscriptions === 1 && (
                    <Text mb={4} fontWeight="bold">
                      Le partenariat avec {partner.name} n'est valable que sur
                      l'abonnement{" "}
                      {formatSubscriptionName(
                        partner.subscriptions[0].metadataId
                      )}
                      . <br />
                      Sélectionnez-le et une promotion sera automatiquement
                      appliquée lors de l'étape suivante.
                    </Text>
                  )}

                  {(nbrSubscriptions === 2 || nbrSubscriptions === 3) && (
                    <Text mb={4} fontWeight="bold">
                      Le partenariat avec {partner.name} est valable sur les
                      abonnements suivants :{" "}
                      {partner.subscriptions.map((subscription, index) => (
                        <span key={subscription.id}>
                          {formatSubscriptionName(subscription.metadataId)}
                          {index !== partner.subscriptions.length - 1 && ", "}
                        </span>
                      ))}
                      . <br />
                      Sélectionnez-en un et une promotion sera automatiquement
                      appliquée lors de l'étape suivante.
                    </Text>
                  )}
                </>
              )}

              <TabPanels>
                <TabPanel p={0}>
                  <Flex gap={6}>
                    <StripeProductCard
                      product={
                        typeTabIndex === TYPE_TAB_INDICES.individual
                          ? individualMonthly
                          : businessMonthly
                      }
                      isSelected={
                        selectedProduct === individualMonthly ||
                        selectedProduct === businessMonthly
                      }
                      onSelect={setSelectedProduct}
                      variant={
                        typeTabIndex === TYPE_TAB_INDICES.individual
                          ? "individual"
                          : "center"
                      }
                      isConnected={!!user?.osteo.sessionId}
                      isGroup={typeTabIndex === TYPE_TAB_INDICES.center}
                      onOpen={onOpen}
                      subscribe={subscribe}
                      content={
                        typeTabIndex === TYPE_TAB_INDICES.individual
                          ? CONTENT_INDIVIDUAL_CLASSIC
                          : CONTENT_CENTER_CLASSIC
                      }
                      isKine
                    />
                    <StripeProductCard
                      product={
                        typeTabIndex === TYPE_TAB_INDICES.individual
                          ? invidividualTeleconsultationMonthly
                          : businessTeleconsultationMonthly
                      }
                      isSelected={
                        selectedProduct ===
                          invidividualTeleconsultationMonthly ||
                        selectedProduct === businessTeleconsultationMonthly
                      }
                      onSelect={setSelectedProduct}
                      variant={
                        typeTabIndex === TYPE_TAB_INDICES.individual
                          ? "individual"
                          : "center"
                      }
                      isConnected={!!user?.osteo.sessionId}
                      onOpen={onOpen}
                      subscribe={subscribe}
                      isGroup={typeTabIndex === TYPE_TAB_INDICES.center}
                      content={
                        durationTabIndex === DURATION_TAB_INDICES.monthly
                          ? CONTENT_TELECONSULTATION_MONTHLY
                          : CONTENT_TELECONSULTATION_YEARLY
                      }
                      isKine
                    />
                  </Flex>
                </TabPanel>
                <TabPanel p={0}>
                  <Flex gap={6}>
                    <StripeProductCard
                      product={
                        typeTabIndex === TYPE_TAB_INDICES.individual
                          ? individualYearly
                          : businessYearly
                      }
                      isSelected={selectedProduct === businessYearly}
                      onSelect={setSelectedProduct}
                      variant={
                        typeTabIndex === TYPE_TAB_INDICES.individual
                          ? "individual"
                          : "center"
                      }
                      isGroup={typeTabIndex === TYPE_TAB_INDICES.center}
                      isConnected={!!user?.osteo.sessionId}
                      onOpen={onOpen}
                      subscribe={subscribe}
                      content={
                        typeTabIndex === TYPE_TAB_INDICES.individual
                          ? CONTENT_INDIVIDUAL_CLASSIC
                          : CONTENT_CENTER_CLASSIC
                      }
                      isKine
                    />
                    <StripeProductCard
                      product={
                        typeTabIndex === TYPE_TAB_INDICES.individual
                          ? invidividualTeleconsultationYearly
                          : businessTeleconsultationYearly
                      }
                      isSelected={
                        selectedProduct === businessTeleconsultationYearly ||
                        selectedProduct === invidividualTeleconsultationYearly
                      }
                      onSelect={setSelectedProduct}
                      variant={
                        typeTabIndex === TYPE_TAB_INDICES.individual
                          ? "individual"
                          : "center"
                      }
                      isGroup={typeTabIndex === TYPE_TAB_INDICES.center}
                      isConnected={!!user?.osteo.sessionId}
                      onOpen={onOpen}
                      subscribe={subscribe}
                      content={
                        durationTabIndex === DURATION_TAB_INDICES.monthly
                          ? CONTENT_TELECONSULTATION_MONTHLY
                          : CONTENT_TELECONSULTATION_YEARLY
                      }
                      isKine
                    />
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>

          {!user?.osteo.sessionId && (
            <Flex justifyContent="center" mt={4}>
              <Text
                maxW={404}
                fontSize={14}
                textAlign="center"
                fontWeight={700}
              >
                Vous ne serez débité•e qu&apos;à la fin de votre période
                d&apos;essai.
                <br /> Vous pourrez annuler sans frais à tout moment si vous
                n&apos;êtes pas satisfait•e.
              </Text>
            </Flex>
          )}

          <ALogoutButton
            mt={8}
            bg="none"
            _hover={{ bg: "none", textDecor: "underline" }}
            _focus={{ bg: "none", textDecor: "underline" }}
            ml="50%"
            transform="translateX(-50%)"
          />
        </Box>
        <Box flex={1} display={{ base: "none", lg: "block" }}>
          <Image
            src={Cover}
            alt="Andrew"
            w="100%"
            minH="100vh"
            h="100%"
            objectFit="cover"
          />
        </Box>
      </Flex>
      {selectedProduct &&
        businessProducts
          .map((p) => p.price.id)
          .includes(selectedProduct.price.id) && (
          <CenterSubscribeDialog
            moveToAnnual={
              selectedProduct.price.id === businessMonthly.price.id
                ? moveToAnnual
                : undefined
            }
            isOpen={isOpen}
            isLoading={createCheckoutSessionMutation.isLoading}
            onClose={handleClose}
            onSubscribe={subscribe}
            product={selectedProduct}
          />
        )}
    </>
  )
}

export default SubscribeKine

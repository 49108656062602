import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import React from "react"
import { useParams, Link as RouterLink } from "react-router-dom"
import { getPodcastItems } from "../../api/podcast"
import arrowBack from "../../assets/arrow-back.svg"
import PodcastItem from "../Explorer/components/PodcastItem"
import AuthorPresentation from "./components/AuthorPresentation"

const Podcast = () => {
  const { id } = useParams()
  const { data, isLoading } = useQuery(["getPodcast", id], () =>
    getPodcastItems(id)
  )

  if (isLoading)
    return (
      <Center h="calc(100vh - 140px)">
        <Spinner color="primary.500" />
      </Center>
    )

  return (
    <Box>
      <Link as={RouterLink} to="/podcasts">
        <Flex alignItems="center">
          <Image src={arrowBack} mr={2} />
          <Text>Retour</Text>
        </Flex>
      </Link>

      <Heading as="h1" mt={7} mb={8} fontSize={28} color="tertiary.500">
        {data?.info.title}
      </Heading>
      {data && <AuthorPresentation author={data.info} />}

      <Flex gap={2} flexWrap="wrap">
        {data?.items.map((item) => (
          <PodcastItem media={item} />
        ))}
      </Flex>
    </Box>
  )
}

export default Podcast

import axiosInstance from "./axiosInstance"

export interface CenterOsteo extends OsteoType {
  account: {
    id: string
    email: string
  }
  active: boolean
}

export type GetCenterOsteosParams = PaginationParamsType & {
  centerId: CenterType["id"]
  search: string
}

export type GetCenterOsteosResponse = {
  result: CenterOsteo[]
  count: PaginationType
}

export const getCenterOsteos = async ({
  centerId,
  perPage,
  ...params
}: GetCenterOsteosParams) => {
  const response = await axiosInstance.post<GetCenterOsteosResponse>(
    "/centers/osteos",
    {
      center_id: centerId,
      per_page: perPage,
      ...params,
    }
  )
  return response.data
}

import { ViewIcon } from "@chakra-ui/icons"
import { Box, Flex, Image, Link, Text } from "@chakra-ui/react"
import React from "react"
import { PoscastInfoType } from "../../../api/podcast"
import AButton from "../../../components/AButton"

interface IProps {
  author: PoscastInfoType
}

const AuthorPresentation: React.FC<IProps> = ({ author }) => {
  return (
    <Box mb={6}>
      <Flex gap={4} alignItems="center" mb={2}>
        <Image w={16} h={16} borderRadius="50%" src={author?.image} />
        <Box>
          <Text fontSize={18} fontWeight="bold">
            {author.authorName}
          </Text>
          <Text
            fontSize={14}
            lineHeight={1.2}
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{ __html: author?.description }}
          />
        </Box>
      </Flex>
      {author.link && (
        <Link
          target="_blank"
          href={author.link}
          textDecoration="none"
          _hover={{ textDecoration: "none" }}
        >
          <AButton text="Lien" variant="tertiary" leftIcon={<ViewIcon />} />
        </Link>
      )}
    </Box>
  )
}

export default AuthorPresentation

import axiosInstance from "./axiosInstance"

export interface AcademieOsteoType extends OsteoType {
  account: {
    id: string
    email: string
  }
  active: boolean
}

export interface AcademieDetailsType extends AcademieType {
  locations: AcademieLocationType[]
}

export type GetAcademieParams = {
  academieId: number
}
export const getAcademie = async ({ academieId }: GetAcademieParams) => {
  const response = await axiosInstance.post<AcademieDetailsType>(
    `/academies/details/`,
    {
      academie_id: academieId,
    }
  )
  return response.data
}

export type UpdateAcademieParams = {
  academieId: number
  name: string
  url: string
}
export const updateAcademie = async ({
  academieId,
  name,
  url,
}: UpdateAcademieParams) => {
  const response = await axiosInstance.post<AcademieDetailsType>(
    "/academies/update-academy",
    {
      academie_id: academieId,
      name,
      url,
    }
  )
  return response.data
}

export interface UpdateAcademieLocationRestrictionParams {
  academieId: number
  locationRestriction: boolean
}
export const updateAcademieLocationRestriction = async ({
  academieId,
  locationRestriction,
}: UpdateAcademieLocationRestrictionParams) => {
  const response = await axiosInstance.post<AcademieDetailsType>(
    "/academies/update-location-restriction",
    {
      academie: academieId,
      location_restriction: locationRestriction.toString(),
    }
  )
  return response.data
}

export interface AddLocationParams {
  name: string
  address: AddressType
  academieId: number
}
export const addLocation = async ({
  academieId,
  ...params
}: AddLocationParams) => {
  const response = await axiosInstance.post<AcademieLocationType>(
    "/academies/new-location",
    {
      academie: academieId,
      ...params,
    }
  )
  return response.data
}

export interface EditLocationParams extends AddLocationParams {
  academieLocationId: number
}
export const editLocation = async ({
  academieId,
  academieLocationId,
  ...params
}: EditLocationParams) => {
  const response = await axiosInstance.post<AcademieLocationType>(
    "/academies/update-location",
    {
      academie: academieId,
      academie_location_id: academieLocationId,
      ...params,
    }
  )
  return response.data
}

export type GetStudentsParams = PaginationParamsType & {
  academieId: number
  showDisabled: boolean
  search: string
}

export type GetStudentsResponse = {
  result: AcademieOsteoType[]
  count: PaginationType
}

export const getStudents = async ({
  academieId,
  showDisabled,
  perPage,
  ...params
}: GetStudentsParams) => {
  const response = await axiosInstance.post<GetStudentsResponse>(
    "/academies/students",
    {
      academie_id: academieId,
      show_disabled: showDisabled,
      per_page: perPage,
      ...params,
    }
  )
  return response.data
}

export type AddStudentParams = {
  email: string
  firstname: string
  lastname: string
  academieId: number
}
export const addStudent = async ({
  academieId,
  ...params
}: AddStudentParams) => {
  const response = await axiosInstance.post<AcademieOsteoType>(
    "/academies/student/new",
    {
      academie_id: academieId,
      ...params,
    }
  )
  return response.data
}

export type UpdateStudentStatusParams = {
  newStatus: "enable" | "disable"
  academieId: number
  studentId: number
}
export const updateStudentStatus = async ({
  academieId,
  newStatus,
  studentId,
}: UpdateStudentStatusParams) => {
  const response = await axiosInstance.put<AcademieOsteoType>(
    "/academies/student/status",
    {
      academie_id: academieId,
      new_status: newStatus,
      student_id: studentId,
    }
  )
  return response.data
}

import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { PatientAppointmentType } from "api/patient"
import React from "react"

interface TrainingExerciceOsteoCardsProps {
  appointment: PatientAppointmentType
}

const TrainingExerciceOsteoCards = ({
  appointment,
}: TrainingExerciceOsteoCardsProps) => {
  return (
    <Box>
      {appointment.trainingPlaylist?.exercices.map((exercice) => (
        <Flex key={exercice.id} mb={4} mt={4} alignItems="center">
          <Image
            src={
              exercice.osteoSelection?.bunnyCDN?.preview ??
              exercice.trainingVideoUploaded?.bunnyCDN.preview
            }
            mr={4}
            borderRadius={9}
            w={143}
          />
          <Box>
            <Heading as="p" fontSize={18} color="primary.500" mb={2}>
              {exercice.osteoSelection?.group[0]?.title ??
                exercice.trainingVideoUploaded?.title}
            </Heading>
            <Text fontWeight={700}>
              Niveau{" "}
              {exercice.osteoSelection?.level ??
                exercice.trainingVideoUploaded?.level}
            </Text>
            <Text>
              <b>Durée</b> {exercice.duration} sec
            </Text>
          </Box>
        </Flex>
      ))}
    </Box>
  )
}

export default TrainingExerciceOsteoCards

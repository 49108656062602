import { useMutation } from "@tanstack/react-query"
import { centerGroupCustomerPortal } from "api/subscriptions"
import AButton from "components/AButton"

interface CenterGroupSubscriptionButtonProps {
  centerGroup?: CenterGroupType
  isLoading?: boolean
}

const CenterGroupSubscriptionButton = ({
  centerGroup,
  isLoading,
}: CenterGroupSubscriptionButtonProps) => {
  const centerGroupCustomerPortalMutation = useMutation(
    centerGroupCustomerPortal
  )

  return (
    <AButton
      text="Gérer mon abonnement"
      variant="tertiary"
      px={8}
      width={347}
      isLoading={isLoading}
      isDisabled={!isLoading && !centerGroup?.sessionId}
      onClick={() =>
        centerGroupCustomerPortalMutation.mutate(
          {
            sessionId: centerGroup!.sessionId!,
            centerGroupId: centerGroup!.id,
          },
          {
            onSuccess: (data) => {
              window.location.href = data
            },
          }
        )
      }
    />
  )
}

export default CenterGroupSubscriptionButton

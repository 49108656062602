import {
  Flex,
  Heading,
  Text,
  Link,
  UnorderedList,
  ListItem,
  Box,
  Divider,
} from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"

export default function TwoParty() {
  return (
    <Flex direction={"column"}>
      <Heading as="h1" color={"tertiary.800"} mb={14}>
        Conditions Générales de Services
      </Heading>
      <p>
        <strong>Bienvenue sur Andrew</strong>
      </p>
      <Text mb={6} mt={6} fontSize={18}>
        TOUTE INSCRIPTION SUR L’APPLICATION PAR LE PARTENAIRE IMPLIQUE
        L’ACCEPTATION SANS RESERVE PAR CELUI-CI DES PRESENTES CONDITIONS
        GENERALES DE SERVICES.
      </Text>
      <p>&nbsp;</p>
      <p>
        <strong>PREAMBULE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        La soci&eacute;t&eacute; ANDREWAPP, soci&eacute;t&eacute; par actions
        simplifi&eacute;e, immatricul&eacute;e au RCS de LYON sous le n&deg; 952
        463 636 et dont le si&egrave;ge social est situ&eacute; au 32 RUE DU
        B&OElig;UF 69005 (ci-apr&egrave;s &laquo; ANDREWAPP&raquo;) &eacute;dite
        et exploite l&rsquo;application Andrew accessible &agrave;
        l&rsquo;adresse suivante :{" "}
        <strong>
          <Link
            as={RouterLink}
            to="/"
            variant="raw"
            aria-label="Aller à l'accueil du site Andrew"
          >
            https://www.andrewapp.fr
          </Link>
        </strong>{" "}
        (ci-apr&egrave;s &laquo; l&rsquo;Application &raquo;).&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        L&rsquo;Application permet &agrave; des th&eacute;rapeutes partenaires
        d&rsquo;ANDREWAPP (ci-apr&egrave;s les &laquo; Partenaires &raquo;) de
        cr&eacute;er un parcours d&rsquo;exercices &agrave; destination de leurs
        patients, utilisateurs de l&rsquo;Application et
        pr&eacute;c&eacute;demment re&ccedil;us en consultation par les
        Partenaires (ci-apr&egrave;s les &laquo; Patients &raquo;).&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 1. DEFINITIONS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Les termes, mentionn&eacute;s ci-dessous, ont dans les pr&eacute;sentes
        Conditions G&eacute;n&eacute;rales de Services, la signification
        suivante&nbsp;:&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4} spacing={4}>
        <ListItem>
          &laquo;&nbsp;<strong>Abonnement</strong>&nbsp;&raquo;&nbsp;:
          d&eacute;signe la souscription par le Partenaire aux Services
          propos&eacute;s par ANDREWAPP et tels que d&eacute;taill&eacute;s dans
          les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Service.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Anomalie&nbsp;&raquo;&nbsp;: </strong>
          d&eacute;signe toute difficult&eacute; de fonctionnement de
          l&rsquo;Application r&eacute;p&eacute;titive et reproductible.&nbsp;
        </ListItem>
        <ListItem>
          &laquo; <strong>Anomalie Bloquante</strong> &raquo; d&eacute;signe un
          dysfonctionnement qui emp&ecirc;che l&apos;utilisation de tout ou
          partie des fonctionnalit&eacute;s essentielles de
          l&rsquo;Application.&nbsp;&nbsp;&nbsp;
        </ListItem>
        <ListItem>
          &laquo; <strong>Anomalie Non Bloquante</strong> &raquo; d&eacute;signe
          tous dysfonctionnements autres que ceux d&eacute;finis dans les
          Anomalies Bloquantes, notamment ceux qui emp&ecirc;chent
          l&apos;utilisation normale de tout ou partie des
          fonctionnalit&eacute;s non essentielles de l&rsquo;Application ou qui
          peuvent &ecirc;tre contourn&eacute;s.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Application&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe l&rsquo;application Andrew accessible pour les
          Partenaires par la souscription d&rsquo;un Abonnement aupr&egrave;s
          d&rsquo;ANDREWAPP. L&rsquo;Application est en acc&egrave;s gratuit et
          librement t&eacute;l&eacute;chargeable pour les Utilisateurs autres
          que les Partenaires ;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Backoffice&nbsp;&raquo;&nbsp;</strong>:
          d&eacute;signe l&rsquo;interface professionnelle
          r&eacute;serv&eacute;e aux Partenaires et accessible sur le Site
          d&rsquo;Andrew &agrave; l&rsquo;adresse suivante&nbsp;:{" "}
          <strong>
            <Link
              to="https://pro.andrewapp.fr"
              target="_blank"
              variant="raw"
              as={RouterLink}
              aria-label="Visiter le site Andrew Pro"
            >
              www.pro.andrewapp.fr
            </Link>
          </strong>
          &nbsp;;&nbsp;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Compte&nbsp;Utilisateur&nbsp;&raquo;</strong>
          &nbsp;: d&eacute;signe l&rsquo;espace &agrave; partir duquel les
          Utilisateurs acc&egrave;dent &agrave; l&rsquo;Application.
          L&rsquo;acc&egrave;s se fait via les Identifiants.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Compte Partenaire&nbsp;&raquo;&nbsp;</strong>:
          d&eacute;signe l&rsquo;espace &agrave; partir duquel les Partenaires
          acc&egrave;dent au Backoffice. L&rsquo;acc&egrave;s se fait via les
          Identifiants.&nbsp;&nbsp;
        </ListItem>
        <ListItem>
          <strong>
            &laquo;&nbsp;Conditions G&eacute;n&eacute;rales de
            Services&nbsp;&raquo; ou &laquo;&nbsp;CGS&nbsp;&raquo;&nbsp;:
          </strong>{" "}
          d&eacute;signe les pr&eacute;sentes conditions contractuelles
          accept&eacute;es par les Partenaires lors de leur inscription sur
          l&rsquo;Application et encadrant l&rsquo;utilisation de
          l&rsquo;Application ;
        </ListItem>
        <ListItem>
          <strong>
            &laquo;&nbsp;Conditions G&eacute;n&eacute;rales
            d&rsquo;Utilisation&nbsp;&raquo; ou
            &laquo;&nbsp;CGU&nbsp;&raquo;&nbsp;:
          </strong>{" "}
          d&eacute;signe les conditions contractuelles accept&eacute;es par les
          Utilisateurs lors de leur inscription sur l&rsquo;Application et
          encadrant leur utilisation de l&rsquo;Application&nbsp;;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Contenu&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe l&rsquo;ensemble des photographies, textes,
          vid&eacute;o, fichiers, audio, podcast ou tout autre contenu
          accessibles &agrave; partir de l&rsquo;Application&nbsp;;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Jours Ouvr&eacute;s&nbsp;&raquo;&nbsp;: </strong>
          d&eacute;signe les jours habituellement travaill&eacute;s en France,
          c&rsquo;est-&agrave;-dire du lundi au vendredi, &agrave;
          l&rsquo;exclusion des jours f&eacute;ri&eacute;s, entre 9 h et 18
          heures .&nbsp;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Patient&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe le patient auquel le Partenaire propose un suivi
          post-consultation via l&rsquo;Application ;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Partenaire&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe les ost&eacute;opathes r&eacute;f&eacute;renc&eacute;s
          sur l&rsquo;Application Andrew&nbsp;et acceptant les pr&eacute;sentes
          Conditions G&eacute;n&eacute;rales de Services ;
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Prestataire de Services de paiement</strong>
          &nbsp;&raquo; ou &laquo;&nbsp;<strong>PSP</strong>
          &nbsp;&raquo;&nbsp;: d&eacute;signe la soci&eacute;t&eacute;,
          d&eacute;tentrice d&rsquo;un agr&eacute;ment bancaire, fournissant des
          services de paiement &agrave; ANDREWAPP afin de leur permettre
          d&rsquo;encaisser les paiements des Partenaires. Le Prestataire de
          Services de Paiement de l&rsquo;Op&eacute;rateur est Stripe Payments
          Europe Ltd., immatricul&eacute;e sous le num&eacute;ro 985420235 (DUNS
          number) dont le si&egrave;ge social est C/O A &amp; L Goodbody, Ifsc,
          North Wall Quay, Dublin, D01 H104, Ireland.&nbsp;&nbsp;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Profil du Partenaire&nbsp;&raquo;&nbsp;</strong>:
          d&eacute;signe le profil du Partenaire tel qu&rsquo;accessible par les
          Utilisateurs dans l&rsquo;Application et param&eacute;trable par le
          Partenaire depuis le Backoffice ;&nbsp;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Profil Patient&nbsp;&raquo;&nbsp;</strong>:
          d&eacute;signe le profil Patient cr&eacute;&eacute; par un Partenaire
          dans son Backoffice ;&nbsp;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Identifiants&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe l&rsquo;adresse email et le mot de passe
          renseign&eacute;s par les Partenaires lors de l&rsquo;inscription,
          puis lors de chaque connexion &agrave; leur Compte Partenaire sur
          l&rsquo;Application&nbsp;;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Parties&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe ensemble, d&rsquo;une part la soci&eacute;t&eacute;
          ANDREWAPP et d&rsquo;autre part les Partenaires ayant accept&eacute;
          les pr&eacute;sentes CGS. Au singulier, d&eacute;signe un seule des
          deux Parties&nbsp;;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Services&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe l&rsquo;ensemble des fonctionnalit&eacute;s et services
          propos&eacute;s par ANDREWAPP aux Partenaires via l&rsquo;Application.
          Ils comprennent le Service de suivi du Patient et le Service de prise
          de rendez-vous en ligne ;&nbsp;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Site&nbsp;&raquo;&nbsp;</strong>: d&eacute;signe
          le site internet d&rsquo;Andrew accessible &agrave; partir de
          l&rsquo;URL{" "}
          <strong>
            <Link
              to="/"
              variant="raw"
              as={RouterLink}
              aria-label="Aller à l'accueil du site Andrew"
            >
              www.andrewapp.fr
            </Link>
          </strong>{" "}
          et &agrave; partir duquel les Partenaires peuvent cr&eacute;er un
          Compte Partenaire et se connecter au Backoffice&nbsp;;&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 2. OBJET ET ACCEPTATION DES CGS</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Services ont
        pour objet de fixer les dispositions contractuelles relatives aux droits
        et obligations respectifs d&rsquo;ANDREWAPP et des Partenaires dans le
        cadre de l&rsquo;utilisation de l&rsquo;Application Andrew et des
        services qui y sont propos&eacute;s.
      </p>
      <p>&nbsp;</p>
      <p>
        Ainsi, les Partenaires s&rsquo;engagent &agrave; lire attentivement les
        pr&eacute;sentes CGS et sont invit&eacute;s &agrave; les
        t&eacute;l&eacute;charger, les imprimer et &agrave; en conserver une
        copie.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Il est pr&eacute;cis&eacute; que les pr&eacute;sentes CGS, disponibles
        dans le footer du Backoffice au moyen d&rsquo;un lien hypertexte,
        peuvent ainsi &ecirc;tre consult&eacute;es &agrave; tout moment.
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire reconna&icirc;t avoir re&ccedil;u d&rsquo;ANDREWAPP toutes
        les informations et conseils lui permettant de bien conna&icirc;tre la
        teneur des Services, d&rsquo;appr&eacute;cier leur ad&eacute;quation
        &agrave; ses besoins et ainsi d&rsquo;accepter les Conditions
        G&eacute;n&eacute;rales de Services en connaissance de cause.
      </p>
      <p>&nbsp;</p>
      <p>
        Les pr&eacute;sentes CGS, qui excluent tout lien de subordination, ne
        conf&egrave;rent en aucun cas au Partenaire la qualit&eacute; de
        salari&eacute;, mandataire, agent ou repr&eacute;sentant
        d&rsquo;ANDREWAPP. Les Parties d&eacute;clarent en outre que les
        pr&eacute;sentes CGS ne peuvent en aucun cas &ecirc;tre
        consid&eacute;r&eacute;es comme un acte constitutif de personne morale
        ou d&rsquo;une entit&eacute; juridique quelconque, et que toute forme
        &laquo; d&rsquo;affectio societatis &raquo; est formellement exclue de
        leurs relations.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 3. MODIFICATION DES CGS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP se r&eacute;serve la possibilit&eacute; de modifier &agrave;
        tout moment les pr&eacute;sentes CGS.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Ces modifications seront notifi&eacute;es au Partenaire sur un support
        durable au moins trente (30) jours avant l&rsquo;entr&eacute;e en
        vigueur des changements. En cas de modifications substantielles des
        pr&eacute;sentes, il convient de distinguer les hypoth&egrave;ses
        suivantes&nbsp;:
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Soit le Partenaire consent auxdites modifications substantielles,
          auquel cas celles-ci entreront automatiquement en vigueur &agrave; la
          date pr&eacute;vue dans la notification,
        </ListItem>
        <ListItem>
          Soit le Partenaire refuse les modifications substantielles, auquel
          cas&nbsp;il pourra r&eacute;silier son Abonnement avant
          l&rsquo;expiration du d&eacute;lai de pr&eacute;avis. Cette
          r&eacute;siliation prend effet dans les quinze (15) jours suivant la
          r&eacute;ception de la notification par le Partenaire de sa
          d&eacute;cision de r&eacute;silier son Abonnement.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Le Partenaire accepte express&eacute;ment que son silence
        cons&eacute;cutif &agrave; l&rsquo;information donn&eacute;e au sujet de
        la modification des pr&eacute;sentes CGS pendant un d&eacute;lai de
        quinze (15) jours soit consid&eacute;r&eacute; comme une acceptation des
        modifications apport&eacute;es.
      </p>
      <p>&nbsp;</p>
      <p>
        Ce d&eacute;lai de pr&eacute;avis est susceptible de ne pas
        s&rsquo;appliquer si&nbsp;:&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          ANDREWAPP est assujettie &agrave; une obligation l&eacute;gale ou
          r&eacute;glementaire de changer ses conditions g&eacute;n&eacute;rales
          d&rsquo;une mani&egrave;re qui ne lui permet pas de respecter ce
          d&eacute;lai de pr&eacute;avis&nbsp;;&nbsp;
        </ListItem>
        <ListItem>
          ANDREWAPP doit exceptionnellement changer ses conditions
          g&eacute;n&eacute;rales pour faire face &agrave; un danger imminent et
          impr&eacute;vu afin de prot&eacute;ger l&rsquo;Application et/ou les
          Utilisateurs contre la fraude, des logiciels malveillants, des spams
          ou tout risque en mati&egrave;re de cybers&eacute;curit&eacute;.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>
        <br />
        <br />
      </p>
      <p>
        <strong>ARTICLE 4. MODALITES D&rsquo;ACCES AUX SERVICES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          4.1. Modalit&eacute;s d&rsquo;inscription des Partenaires
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Pour avoir acc&egrave;s au Backoffice et &ecirc;tre
        r&eacute;f&eacute;renc&eacute; sur l&rsquo;Application, le Partenaire
        devra&nbsp;notamment :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Disposer de l&rsquo;ensemble des qualifications et autorisations
          requises pour exercer la profession d&rsquo;ost&eacute;opathe en
          conformit&eacute; avec la r&egrave;glementation en vigueur au jour de
          son inscription, puis tout long de l&rsquo;utilisation de
          l&rsquo;Application.&nbsp;
        </ListItem>
        <ListItem>
          Se rendre sur le Site d&rsquo;Andrew, accessible &agrave; partir de
          l&rsquo;URL&nbsp;:{" "}
          <strong>
            <Link
              to="/"
              variant="raw"
              as={RouterLink}
              aria-label="Aller à l'accueil du site Andrew"
            >
              www.andrewapp.fr
            </Link>
          </strong>{" "}
          ;&nbsp;
        </ListItem>
        <ListItem>
          Renseigner les informations suivantes&nbsp;:&nbsp;
          <UnorderedList pl={4}>
            <ListItem>Nom et pr&eacute;nom</ListItem>
            <ListItem>Email</ListItem>
            <ListItem>Num&eacute;ro de t&eacute;l&eacute;phone</ListItem>
            <ListItem>Adresse de lieu d&rsquo;exercice</ListItem>
            <ListItem>URL de son profil Doctolib</ListItem>
            <ListItem>Mot de passe</ListItem>
            <ListItem>Informations bancaires&nbsp;</ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          Accepter les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de
          Services et prendre connaissance de la Politique de
          confidentialit&eacute;.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Service
        entreront en vigueur sous r&eacute;serve de l&rsquo;acceptation par
        ANDREWAPP de l&rsquo;inscription du Partenaire.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de refus de l&rsquo;inscription du Partenaire par ANDREWAPP,
        cette derni&egrave;re informera le Partenaire par e-mail.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP se r&eacute;serve le droit de refuser l&rsquo;inscription
        d&rsquo;un Partenaire ne satisfaisant pas aux exigences des
        pr&eacute;sentes, notamment au regard des conditions de qualifications
        et comp&eacute;tences professionnelles pr&eacute;cit&eacute;es.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire pourra b&eacute;n&eacute;ficier des Services via son
        Compte Partenaire dans le Backoffice accessible sur le Site &agrave;
        l&rsquo;adresse suivante{" "}
        <strong>
          <Link
            to="https://pro.andrewapp.fr"
            target="_blank"
            as={RouterLink}
            aria-label="Visiter le site Andrew Pro"
          >
            www.pro.andrewapp.fr
          </Link>
        </strong>
        .&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En tout &eacute;tat de cause, pour pouvoir b&eacute;n&eacute;ficier des
        Services, le Partenaire s&rsquo;engage &agrave; fournir &agrave;
        ANDREWAPP une adresse de courrier &eacute;lectronique valide et
        fonctionnelle.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          4.2. Modalit&eacute;s de s&eacute;lection des Partenaires&nbsp;
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        L&rsquo;Application &eacute;tant d&eacute;di&eacute;e au suivi de
        consultations ost&eacute;opathiques, les Partenaires reconnaisent
        &ecirc;tre inform&eacute;s que la d&eacute;tention des
        comp&eacute;tences et qualit&eacute;s professionnelles requises par la
        profession d&rsquo;ost&eacute;opathe est une condition essentielle
        &agrave; l&rsquo;acc&egrave;s &agrave; l&rsquo;Application par les
        Partenaires.
      </p>
      <p>&nbsp;</p>
      <p>
        Ainsi, un th&eacute;rapeute qui souhaite s&rsquo;inscrire sur
        l&rsquo;Application en tant que Partenaire&nbsp;s&rsquo;engage
        &agrave;&nbsp;:&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Se conformer aux exigences des pr&eacute;sentes CGS&nbsp;;&nbsp;
        </ListItem>
        <ListItem>
          Fournir et maintenir &agrave; jour l&rsquo;ensemble des informations
          demand&eacute;es par ANDREWAPP et permettant de v&eacute;rifier son
          statut et sa conformit&eacute; aux obligations l&eacute;gales. Il
          garantit la v&eacute;racit&eacute; des informations transmises et
          informera ANDREWAPP de toute modification ou difficult&eacute;
          quelconque rencontr&eacute;e quant &agrave; la fourniture de ses
          informations ou son statut&nbsp;;&nbsp;
        </ListItem>
        <ListItem>
          Dans le cadre de l&rsquo;usage de l&rsquo;Application, respecter
          &agrave; tout moment les obligations impos&eacute;es aux Partenaires
          au titre des obligations d&eacute;ontologiques et l&eacute;gales qui
          leur sont impos&eacute;es par la r&eacute;glementation en vigueur et
          les r&egrave;gles reconnues par la profession&nbsp;;
        </ListItem>
        <ListItem>
          Respecter les r&egrave;gles d&rsquo;usage en mati&egrave;re de
          communication en ligne, tout particuli&egrave;rement &agrave;
          l&rsquo;&eacute;gard du Patient et de tout repr&eacute;sentant
          d&rsquo;ANDREWAPP.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP se r&eacute;serve la possibilit&eacute; de v&eacute;rifier la
        v&eacute;racit&eacute; des informations professionnelles fournies par
        les Partenaires, notamment par la mise en &oelig;uvre de tout
        proc&eacute;d&eacute; d&rsquo;authentification n&eacute;cessaire pour
        acc&eacute;der &agrave; la solution.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 5. SERVICES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.1. Service de suivi du Patient</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le Service de suivi du Patient permet aux Partenaires de r&eacute;aliser
        un suivi post-consultation de leurs Patients.
      </p>
      <p>&nbsp;</p>
      <p>
        Aussi, d&egrave;s la premi&egrave;re consultation, les Partenaires
        peuvent, depuis leur Backoffice&nbsp;:&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Cr&eacute;er des Profils Patients pour permettre &agrave; leurs
          Patients pr&eacute;alablement re&ccedil;us en consultation et
          souhaitant b&eacute;n&eacute;ficier de l&rsquo;Application, de
          recevoir un lien d&rsquo;activation et de cr&eacute;er leur Compte
          Utilisateur. Le Partenaire est invit&eacute; &agrave; compl&eacute;ter
          les informations suivantes relatives &agrave; son Patient&nbsp;:
        </ListItem>
      </UnorderedList>
      <UnorderedList pl={4}>
        <ListItem>Nom et pr&eacute;nom&nbsp;;&nbsp;</ListItem>
        <ListItem>Email&nbsp;;</ListItem>
        <ListItem>Code postal&nbsp;(facultatif);&nbsp;</ListItem>
        <ListItem>
          Num&eacute;ro de t&eacute;l&eacute;phone (facultatif);
        </ListItem>
      </UnorderedList>
      <p>
        Le Partenaire est enti&egrave;rement responsable de l&rsquo;exactitude
        des donn&eacute;es de ses Patients renseign&eacute;es lors de la
        cr&eacute;ation d&rsquo;un Profil Patient, puis lors du suivi de ses
        Patients dans le Backoffice&nbsp;;&nbsp;
      </p>
      <UnorderedList pl={4}>
        <ListItem>
          Saisir des informations relatives &agrave; la pathologie et au
          parcours de soin du Patient d&egrave;s la phase de consultation.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        L&rsquo;acc&egrave;s aux autres services compris dans le Service de
        suivi du Patient, pour un Patient donn&eacute;, est conditionn&eacute;
        &agrave; l&rsquo;acceptation des Conditions G&eacute;n&eacute;rales
        d&rsquo;Utilisation par ledit Patient.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Aussi, d&egrave;s acceptation des CGU par le Patient et, ainsi,
        cr&eacute;ation de son Compte Utilisateur, le Partenaire peut, depuis
        son Backoffice&nbsp;:&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Sugg&eacute;rer des Contenus choisis aupr&egrave;s du Patient
          Utilisateur sur la base d&rsquo;un catalogue de Contenus
          propos&eacute;s dans l&rsquo;Application&nbsp;;
        </ListItem>
        <ListItem>
          Proposer des Contenus sp&eacute;cifiques et un planning
          d&rsquo;exercices aupr&egrave;s du Patient re&ccedil;u en
          consultation&nbsp;;
        </ListItem>
        <ListItem>
          D&eacute;livrer des conseils de suivi ou des rappels aupr&egrave;s des
          Patients re&ccedil;us en consultation&nbsp;;
        </ListItem>
        <ListItem>
          Adapter les Contenus propos&eacute;s &agrave; chaque Patient au fil
          des consultations en cabinet et des r&eacute;ponses formul&eacute;es
          par le Patient lors des questionnaires de suivi&nbsp;;&nbsp;
        </ListItem>
        <ListItem>
          R&eacute;aliser un suivi de l&rsquo;&eacute;volution des
          sympt&ocirc;mes du Patient suite &agrave; la consultation et &agrave;
          la r&eacute;alisation des exercices conseill&eacute;s&nbsp;;
        </ListItem>
        <ListItem>
          R&eacute;aliser un suivi de l&rsquo;&eacute;volution du stress de
          l&rsquo;Utilisateur.
        </ListItem>
      </UnorderedList>
      <p>
        <br />
      </p>
      <p>
        L&rsquo;Application permet aux Partenaires de se voir proposer des
        Contenus qu&rsquo;ils peuvent proposer aupr&egrave;s de leurs Patients
        Utilisateurs de l&rsquo;Application.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Les Contenus sont propos&eacute;s sur la base du catalogue contenu au
        sein de l&rsquo;Application et selon les champs renseign&eacute;s par le
        Partenaire au sein du Profil Patient concern&eacute;.
      </p>
      <p>&nbsp;</p>
      <p>
        Pour se voir proposer des Contenus sp&eacute;cifiques du catalogue, le
        Partenaire peut renseigner les informations suivantes au sein du Profil
        Patient sur la base des informations recueillies en
        consultation&nbsp;:&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>Symptomatologie&nbsp;;</ListItem>
        <ListItem>Zone de plainte&nbsp;;</ListItem>
        <ListItem>Motif de la consultation.</ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Le Contenu sp&eacute;cifique sera accessible par le Patient depuis
        l&rsquo;Application pendant une dur&eacute;e de trois (3) mois.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire est invit&eacute; &agrave; prendre connaissance des
        Contenus sugg&eacute;r&eacute;s en int&eacute;gralit&eacute; et de
        mani&egrave;re attentive afin de v&eacute;rifier que ces Contenus sont
        adapt&eacute;s &agrave; l&rsquo;Utilisateur auquel il souhaite les
        proposer, &agrave; la lumi&egrave;re du diagnostic r&eacute;alis&eacute;
        en consultation et des particularit&eacute;s de chaque Patient.
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire est seul responsable de la r&eacute;alisation des
        consultations d&rsquo;ost&eacute;opathie et de la coh&eacute;rence des
        contenus propos&eacute;s par l&rsquo;interm&eacute;diaire de
        l&rsquo;Application avec le diagnostic effectu&eacute; en
        consultation.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        L&rsquo;utilisation de l&rsquo;Application ne dilue, ne modifie ou
        n&rsquo;att&eacute;nue la responsabilit&eacute; et les obligations du
        Partenaire vis-&agrave;-vis de ses Patients Utilisateurs de
        l&rsquo;Application.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.2. Service de prise de rendez-vous en ligne</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le Service de prise de rendez-vous en ligne permet aux Patients de
        prendre rendez-vous avec les diff&eacute;rents Partenaires accessibles
        via l&rsquo;Application au sein de l&rsquo;encart Doctolib.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Pour permettre &agrave; ses Patients de prendre rendez-vous en ligne et
        g&eacute;rer ces rendez-vous, le Partenaire doit&nbsp;:
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Disposer d&rsquo;un compte Doctolib permettant aux Patients de
          r&eacute;server des rendez-vous en ligne&nbsp;;
        </ListItem>
        <ListItem>
          Communiquer, lors de son inscription, l&rsquo;URL de son profil
          Doctolib, dans le champ d&eacute;di&eacute; &agrave; cet effet
        </ListItem>
      </UnorderedList>
      <p>
        Pour prendre rendez-vous en ligne avec un Partenaire via
        l&rsquo;Application, l&rsquo;Utilisateur doit choisir le centre
        th&eacute;rapeutique ou cabinet aupr&egrave;s duquel il souhaite prendre
        rendez-vous. Les crit&egrave;res de r&eacute;f&eacute;rencement
        applicables par la suite sont ceux d&eacute;finis par Doctolib. Les
        r&egrave;gles de r&eacute;f&eacute;rencement applicables sur le site de
        Doctolib sont consultables &agrave; l&rsquo;adresse suivante&nbsp;:{" "}
        <strong>
          <Link
            to="https://www.doctolib.fr"
            variant="raw"
            target="_blank"
            as={RouterLink}
            aria-label="Visiter le site doctolib"
          >
            www.doctolib.fr
          </Link>
        </strong>
        &nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 6. CONNEXION AU BACKOFFICE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire peut se connecter &agrave; son Compte Partenaire dans le
        Backoffice en renseignant ses Identifiants.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Les deux &eacute;l&eacute;ments constituant ses Identifiants sont
        l&rsquo;adresse email d&rsquo;inscription et le mot de passe
        renseign&eacute; lors de l&rsquo;inscription.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire sera seul responsable de l&apos;utilisation de ses
        Identifiants ou des actions faites par l&apos;interm&eacute;diaire de
        son compte.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En aucun cas, ANDREWAPP ne saurait &ecirc;tre tenue responsable en cas
        d&rsquo;usurpation de l&rsquo;identit&eacute; d&rsquo;un Partenaire.
        Tout acc&egrave;s et actions effectu&eacute;s &agrave; partir du Compte
        d&rsquo;un Partenaire seront pr&eacute;sum&eacute;s &ecirc;tre
        effectu&eacute;s par ce Partenaire.
      </p>
      <p>&nbsp;</p>
      <p>
        Toute perte, d&eacute;tournement, ou utilisation non autoris&eacute;e
        des Identifiants d&rsquo;un Partenaire et leurs cons&eacute;quences
        rel&egrave;vent de sa seule responsabilit&eacute;.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cas o&ugrave; un Partenaire divulguerait ou utiliserait ses
        Identifiants de fa&ccedil;on contraire &agrave; leur destination,
        ANDREWAPP pourra alors suspendre l&rsquo;acc&egrave;s &agrave;
        l&rsquo;Application, ce dernier &eacute;tant tenu d&rsquo;en avertir
        ANDREWAPP sans d&eacute;lai, par message &eacute;lectronique
        adress&eacute; &agrave; l&rsquo;adresse suivante&nbsp;:
        contact@andrewapp.fr&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 7. MODALITES FINANCIERES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Lors de l&rsquo;inscription, le Partenaire a le choix de souscrire un
        Abonnement mensuel ou annuel, selon les conditions tarifaires
        d&eacute;taill&eacute;es en{" "}
        <strong>
          <em>Annexe 1</em>
        </strong>{" "}
        &laquo;&nbsp;Conditions tarifaires&nbsp;&raquo;.
      </p>
      <p>&nbsp;</p>
      <p>
        Le paiement s&rsquo;effectue au moment de l&rsquo;inscription, par carte
        bancaire (CB, Visa, MasterCard, American Express) via le pestataire de
        service de paiement Stripe, aupr&egrave;s duquel le Partenaire renseigne
        directement ses coordonn&eacute;es bancaires,
      </p>
      <p>&nbsp;</p>
      <p>
        En acceptant les pr&eacute;sentes CGS, le Partenaire accepte le
        pr&eacute;l&egrave;vement correspondant au prix d&rsquo;Abonnement tel
        que d&eacute;taill&eacute; en{" "}
        <strong>
          <em>Annexe 1</em>
        </strong>{" "}
        &laquo;&nbsp;Conditions tarifaires &raquo; et conform&eacute;ment au
        mandat de pr&eacute;l&egrave;vement accept&eacute; aupr&egrave;s du PSP
      </p>
      <p>&nbsp;</p>
      <p>
        La dur&eacute;e de l&apos;Abonnement se renouvelle automatiquement par
        tacite reconduction pour une dur&eacute;e identique (mensuelle ou
        annuelle suivant l&rsquo;Abonnement choisi) sauf r&eacute;siliation par
        l&apos;Abonn&eacute; dans les conditions pr&eacute;cis&eacute;es
        &agrave; l&apos;Article 15 ci-apr&egrave;s.
      </p>
      <p>&nbsp;</p>
      <p>
        Le Paiement s&rsquo;effectue par l&rsquo;interm&eacute;diaire du
        Prestataire de Services de paiement (PSP). Pour pouvoir payer et
        b&eacute;n&eacute;ficier des Services le Partenaire devra accepter les
        Conditions G&eacute;n&eacute;rales du PSP.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Il est entendu entre les Parties que les pr&eacute;sentes CGS et les
        conditions g&eacute;n&eacute;rales du PSP sont des contrats
        interd&eacute;pendants. Ainsi ANDREWAPP pourra librement, de plein droit
        et sans d&eacute;lai, mettre fin aux pr&eacute;sentes dans
        l&rsquo;hypoth&egrave;se o&ugrave; le PSP mettrait fin &agrave; ses
        relations contractuelles avec le Partenaire.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de d&eacute;faut ou de retard de paiementapr&egrave;s mise en
        demeure rest&eacute;e sans r&eacute;ponse dans un d&eacute;lai de trente
        (30) jours, ANDREWAPP se r&eacute;serve la possibilit&eacute;
        d&rsquo;activer sa proc&eacute;dure de recouvrement.&nbsp;
      </p>
      <p>
        Dans ce cas, le Partenaire sera de plein droit d&eacute;biteur, &agrave;
        l&apos;&eacute;gard d&rsquo;ANDREWAPP, d&apos;une indemnit&eacute;
        forfaitaire pour frais de recouvrement d&rsquo;un montant de 40 euros.
        Lorsque des frais de recouvrement expos&eacute;s sont sup&eacute;rieurs
        au montant de cette indemnit&eacute; forfaitaire, ANDREWAPP pourra
        demander une indemnisation compl&eacute;mentaire, sur justification.
      </p>
      <p>&nbsp;</p>
      <p>
        Le d&eacute;faut de paiement du Partenaire entra&icirc;ne
        automatiquement une suspension des Services jusqu&rsquo;au
        r&egrave;glement complet des sommes dues et ce par carte bancaire,
        virement bancaire ou pr&eacute;l&egrave;vement automatique.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 8. OBLIGATIONS D&rsquo;ANDREWAPP</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP s&rsquo;engage &agrave; r&eacute;aliser les Services dans le
        respect des r&egrave;gles applicables, telles que r&eacute;sultant des
        r&egrave;gles de l&rsquo;art, normes europ&eacute;ennes, lois,
        d&eacute;crets, arr&ecirc;t&eacute;s et textes l&eacute;gislatifs,
        r&eacute;glementaires ou administratifs nationaux, locaux ou
        professionnels.
      </p>
      <p>&nbsp;</p>
      <p>
        Il est express&eacute;ment convenu entre les Parties qu&rsquo;ANDREWAPP
        est soumise &agrave; une obligation g&eacute;n&eacute;rale de moyens et
        qu&rsquo;elle n&rsquo;est tenue par aucune obligation de r&eacute;sultat
        ou de moyens renforc&eacute;s d&rsquo;aucune sorte.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.1. H&eacute;bergement - disponibilit&eacute;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP s&rsquo;engage &agrave; mettre tous les moyens en &oelig;uvre
        pour assurer une continuit&eacute; d&rsquo;acc&egrave;s et
        d&rsquo;utilisation de l&rsquo;Application.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre,&nbsp;ANDREWAPP h&eacute;berge les donn&eacute;es de
        l&rsquo;Application aupr&egrave;s d&rsquo;un h&eacute;bergeur de
        donn&eacute;es de sant&eacute; certifi&eacute; HDS dans les conditions
        pr&eacute;cis&eacute;es en{" "}
        <strong>
          <em>Annexe 3</em>
        </strong>{" "}
        des pr&eacute;sentes.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP attire toutefois l&rsquo;attention des Partenaires sur le fait
        que les protocoles actuels de communication via Internet ne permettent
        pas d&rsquo;assurer de mani&egrave;re certaine et continue la
        transmission des &eacute;changes &eacute;lectroniques et Contenus.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP ne saurait &ecirc;tre tenue responsable des perturbations,
        coupures et anomalies qui ne sont pas de son fait et qui affecteraient,
        par exemple, les transmissions par le r&eacute;seau Internet et plus
        g&eacute;n&eacute;ralement par le r&eacute;seau de communication,
        quelles qu&rsquo;en soient l&rsquo;importance et la dur&eacute;e.
      </p>
      <p>&nbsp;</p>
      <p>
        Par ailleurs, conform&eacute;ment aux dispositions des{" "}
        <strong>
          <Link
            to="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000033219601/"
            target="_blank"
            variant="raw"
            as={RouterLink}
            aria-label="Lire l'article L.111-7 sur le site legifrance"
          >
            articles L.111-7 et suivants du Code de la consommation
          </Link>
        </strong>
        , ANDREWAPP s&rsquo;engage &agrave; apporter une information claire
        transparente et loyale sur les modalit&eacute;s de son intervention.
        <strong>&nbsp;&nbsp;&nbsp;</strong>
      </p>
      <p>
        <strong>8.2. Maintenance&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP s&rsquo;engage &agrave; mettre tout en &oelig;uvre pour faire
        effectuer les corrections techniques &agrave; apporter &agrave;
        l&rsquo;Application concernant les &eacute;ventuelles Anomalies,
        notamment celles impactant la s&eacute;curit&eacute; informatique de
        l&rsquo;Application.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre, il est pr&eacute;cis&eacute; que les Anomalies sont
        r&eacute;pertori&eacute;es en fonction de la nature des
        dysfonctionnements constat&eacute;s&nbsp;:
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          ANDREWAPP s&apos;engage &agrave; corriger toute Anomalie Bloquante ou
          mettre en place une solution de contournement, dans un d&eacute;lai de
          trois (3) Jours Ouvr&eacute;s &agrave; compter de sa notification par
          le Partenaire.&nbsp;
        </ListItem>
        <ListItem>
          ANDREWAPP s&rsquo;engage &agrave; corriger toute Anomalie Non
          Bloquante dans sept (7) Jours Ouvr&eacute;s &agrave; compter de sa
          notification par le Partenaire.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Les Partenaires peuvent remonter &agrave; ANDREWAPP, tel que
        pr&eacute;vu &agrave; l&rsquo;Article 16 &laquo;&nbsp;Assistance &ndash;
        Support Partenaire&nbsp;&raquo;.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Toute intervention r&eacute;sultant d&rsquo;une mauvaise utilisation par
        le Partenaire de l&rsquo;Application ou des Services pourra donner lieu
        &agrave; une facturation sp&eacute;cifique.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.3. Evolution de l&rsquo;Application</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Il est express&eacute;ment convenu entre les Parties que
        l&rsquo;Application et les Services objets des pr&eacute;sentes pourront
        faire l&rsquo;objet d&rsquo;&eacute;volutions d&eacute;cid&eacute;es par
        ANDREWAPP en vue de s&rsquo;adapter aux &eacute;volutions des
        technologies ou pour optimiser ses Services.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Dans ce cas, le Partenaire accepte sans r&eacute;serve que lui soient
        appliqu&eacute;es lesdites &eacute;volutions apr&egrave;s en avoir
        &eacute;t&eacute; pr&eacute;alablement inform&eacute; par ANDREWAPP.
        Dans l&rsquo;hypoth&egrave;se o&ugrave; le Partenaire
        n&rsquo;accepterait pas lesdites &eacute;volutions, il disposera alors
        de la possibilit&eacute; de r&eacute;silier son Abonnement selon les
        modalit&eacute;s pr&eacute;vues &agrave; l&rsquo;Article 15 des
        pr&eacute;sentes.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.4. S&eacute;curit&eacute;&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP s&rsquo;engage &agrave; tout mettre en &oelig;uvre pour&nbsp;:
      </p>
      <UnorderedList pl={4}>
        <ListItem>
          Assurer une s&eacute;curit&eacute; logique et physique de ses
          syst&egrave;mes d&rsquo;information&nbsp;;
        </ListItem>
        <ListItem>
          R&eacute;duire au minimum le risque d&rsquo;une infraction de
          s&eacute;curit&eacute;.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP s&rsquo;engage ainsi &agrave; prot&eacute;ger les Contenus ou
        Donn&eacute;es fournis par le Partenaire.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.5. Conservation des donn&eacute;es</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP respecte les dur&eacute;es l&eacute;gales de conservation des
        donn&eacute;es du Partenaire.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En application de l&rsquo;article L. 123-22 du Code de commerce, les
        donn&eacute;es du Partenaire seront ainsi conserv&eacute;es pendant dix
        (10) ans &agrave; des fins probatoires et cinq (5) ans pour prouver
        l&rsquo;existence de la relation contractuelle en application de
        l&rsquo;article 2224 du Code civil.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de r&eacute;siliation de l&rsquo;Abonnement, les modalit&eacute;s
        de suppression des Contenus sont d&eacute;taill&eacute;es &agrave;
        l&rsquo;Article 15.&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 9. OBLIGATIONS DU PARTENAIRE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9.1. Obligations g&eacute;n&eacute;rales</strong>
      </p>
      <p>
        Lors de l&rsquo;utilisation de l&rsquo;Application, chaque Partenaire
        s&rsquo;engage &agrave; ne pas porter atteinte &agrave; l&rsquo;ordre
        public et &agrave; se conformer aux lois et r&egrave;glements en
        vigueur, &agrave; respecter les droits des tiers et les dispositions des
        pr&eacute;sentes CGS.
      </p>
      <p>Chaque Partenaire a pour obligation de :</p>
      <UnorderedList pl={4}>
        <ListItem>
          Se comporter de fa&ccedil;on loyale et raisonnable &agrave;
          l&rsquo;&eacute;gard d&rsquo;ANDREWAPP, des Utilisateurs et des tiers
          ;&nbsp;
        </ListItem>
        <ListItem>
          &Ecirc;tre honn&ecirc;te et sinc&egrave;re dans les informations
          fournies&nbsp;;
        </ListItem>
        <ListItem>
          Utiliser l&rsquo;Application conform&eacute;ment &agrave; son objet
          tel que d&eacute;crit dans les pr&eacute;sentes CGS&nbsp;;
        </ListItem>
        <ListItem>
          Ne pas d&eacute;tourner la finalit&eacute; de l&rsquo;Application pour
          commettre des crimes, d&eacute;lits ou contraventions
          r&eacute;prim&eacute;s par le code p&eacute;nal ou par toute autre
          disposition l&eacute;gale ou r&eacute;glementaire ;
        </ListItem>
        <ListItem>
          Respecter les droits de propri&eacute;t&eacute; intellectuelle
          d&rsquo;ANDREWAPP et des tiers portant sur les &eacute;l&eacute;ments
          de l&rsquo;Application et les Contenus ;&nbsp;
        </ListItem>
        <ListItem>
          Ne pas chercher &agrave; porter atteinte au sens des{" "}
          <strong>
            <Link
              to="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000030939438/"
              variant="raw"
              target="_blank"
              as={RouterLink}
              aria-label="Lire l'article 323-1 sur le site lefifrance"
            >
              articles 323-1 et suivants du code p&eacute;nal
            </Link>
          </strong>{" "}
          aux syst&egrave;mes de traitements automatis&eacute;s de
          donn&eacute;es mis en &oelig;uvre sur l&rsquo;Application, notamment
          par l&rsquo;interm&eacute;diaire de pratiques telles que le
          webscraping ;
        </ListItem>
        <ListItem>
          Ne pas diffuser des donn&eacute;es ayant pour effet de diminuer, de
          d&eacute;sorganiser, de ralentir ou d&rsquo;interrompre le
          fonctionnement normal de l&rsquo;Application.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>9.2. Obligations sp&eacute;cifiques&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        L&rsquo;utilisation de l&rsquo;Application est strictement restreinte
        aux Partenaires disposant de la qualit&eacute; d&rsquo;ost&eacute;opathe
        au sens de la r&egrave;glementation fran&ccedil;aise en vigueur.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire s&rsquo;engage notamment &agrave; disposer de
        l&rsquo;ensemble des qualifications et autorisations requises pour
        exercer la profession d&rsquo;ost&eacute;opathe en conformit&eacute;
        avec la r&egrave;glementation en vigueur au jour de son inscription,
        puis tout long de l&rsquo;utilisation de l&rsquo;Application.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Si ces conditions venaient &agrave; ne plus &ecirc;tre remplies pour
        quelque cause que ce soit, le Partenaire s&rsquo;engage &agrave; en
        informer ANDREWAPP sans d&eacute;lai par email &agrave; l&rsquo;adresse
        suivante&nbsp;: contact@andrewapp.fr. Dans cette hypoth&egrave;se,
        ANDREWAPP se r&eacute;serve le droit de suspendre le Compte Partenaire
        &agrave; tout moment.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        De mani&egrave;re g&eacute;n&eacute;rale, le Partenaire s&rsquo;engage
        &agrave; utiliser l&rsquo;Application conform&eacute;ment aux
        obligations d&eacute;ontologiques et l&eacute;gales qui lui sont
        impos&eacute;es par la r&eacute;glementation en vigueur et les
        r&egrave;gles reconnues par la profession.&nbsp; Aussi, le Partenaire
        s&rsquo;engage &agrave; ne pas utiliser l&rsquo;Application dans le
        cadre d&rsquo;une activit&eacute; &ndash; qu&rsquo;elle soit
        m&eacute;dicale, th&eacute;rapeutique ou de bien-&ecirc;tre &ndash; que
        la r&eacute;glementation en vigueur exclut du champ de la pratique
        ost&eacute;opathique.&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire s&rsquo;engage d&egrave;s lors &agrave; utiliser
        l&rsquo;Application uniquement dans le cadre de la relation avec les
        Utilisateurs effectivement re&ccedil;us en consultation et dans le
        strict respect du secret m&eacute;dical applicable.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre, le Partenaire s&rsquo;engage &agrave; ne proposer
        l&rsquo;Application et &agrave; g&eacute;n&eacute;rer un Profil Patient
        et un lien d&rsquo;inscription, uniquement pour les Patients souhaitant
        devenir Utilisateur de l&rsquo;Application.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En acceptant les pr&eacute;sentes CGS, le Partenaire confirme disposer
        d&rsquo;une assurance responsabilit&eacute; civile professionnelle
        m&eacute;dicale souscrite aupr&egrave;s d&rsquo;une compagnie
        d&rsquo;assurance notoirement solvable et s&rsquo;engage &agrave; la
        maintenir &agrave; jour pendant toute la dur&eacute;e
        d&rsquo;utilisation de l&rsquo;Application.&nbsp;
      </p>
      <p>&nbsp;&nbsp;&nbsp;</p>
      <p>
        <strong>ARTICLE 10. RESPONSABILITE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>10.1. Principes g&eacute;n&eacute;raux&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>ANDREWAPP d&eacute;cline toute responsabilit&eacute; notamment :</p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          En cas d&rsquo;impossibilit&eacute; d&rsquo;acc&eacute;der
          temporairement &agrave; l&rsquo;Application pour des op&eacute;rations
          de maintenance technique ou d&rsquo;actualisation des informations
          publi&eacute;es.&nbsp;
        </ListItem>
        <ListItem>
          En cas de dysfonctionnements ou d&rsquo;interruptions des
          r&eacute;seaux de transmission ou du mat&eacute;riel informatique du
          Partenaire ou de l&rsquo;Utilisateur de l&rsquo;Application ;&nbsp;
        </ListItem>
        <ListItem>
          En cas d&rsquo;attaque virale, intrusion illicite dans un
          syst&egrave;me de traitement automatis&eacute; de donn&eacute;es ;
        </ListItem>
        <ListItem>
          En cas d&rsquo;utilisation anormale ou d&rsquo;une exploitation
          illicite de l&rsquo;Application par un Utilisateur, un Partenaire ou
          un tiers ;
        </ListItem>
        <ListItem>
          Relativement au contenu des sites internet tiers vers lesquels
          renvoient des liens hypertextes pr&eacute;sents sur
          l&rsquo;Application ;
        </ListItem>
        <ListItem>
          En cas de non-respect des pr&eacute;sentes CGS imputable au Partenaire
          ;
        </ListItem>
        <ListItem>
          En cas de retard ou d&rsquo;inex&eacute;cution de ses obligations,
          lorsque la cause du retard ou de l&rsquo;inex&eacute;cution est
          li&eacute;e &agrave; un cas de force majeure telle qu&rsquo;elle est
          d&eacute;finie &agrave; l&rsquo;Article 11 des pr&eacute;sentes CGS ;
        </ListItem>
        <ListItem>
          En cas de cause &eacute;trang&egrave;re non imputable &agrave;
          ANDREWAPP ;
        </ListItem>
        <ListItem>
          En cas d&rsquo;agissement illicite d&rsquo;un Partenaire, ou
          d&rsquo;inex&eacute;cution contractuelle dont un Partenaire se serait
          rendu coupable ;&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        En cas d&rsquo;utilisation anormale ou d&rsquo;une exploitation illicite
        de l&rsquo;Application, le Partenaire est alors seul responsable des
        dommages caus&eacute;s aux tiers et des cons&eacute;quences des
        r&eacute;clamations ou actions qui pourraient en d&eacute;couler.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En aucun cas la responsabilit&eacute; d&rsquo;ANDREWAPP ne pourra
        &ecirc;tre recherch&eacute;e, quel que soit le type d&rsquo;action
        intent&eacute;e, pour un dommage indirect d&rsquo;aucune sorte par
        exemple, et sans que la liste soit exhaustive, tout pr&eacute;judice
        financier ou commercial, perte de b&eacute;n&eacute;fice, trouble
        commercial, manque &agrave; gagner, pr&eacute;judice d&rsquo;un tiers,
        ou action intent&eacute;e par un tiers contre le Partenaire ainsi que
        leurs cons&eacute;quences, li&eacute; aux pr&eacute;sentes ou &agrave;
        leur ex&eacute;cution. Le Partenaire est seul responsable de tout
        pr&eacute;judice, direct ou indirect, mat&eacute;riel ou
        immat&eacute;riel caus&eacute; par lui-m&ecirc;me ou un de ses
        pr&eacute;pos&eacute;s &agrave; ANDREWAPP ou &agrave; des tiers du fait
        de son utilisation des Services.
      </p>
      <p>&nbsp;</p>
      <p>
        En tout &eacute;tat de cause, il est express&eacute;ment convenu entre
        les Parties que si la responsabilit&eacute; d&rsquo;ANDREWAPP
        &eacute;tait retenue dans le cadre de l&rsquo;ex&eacute;cution des
        pr&eacute;sentes CGS, celle-ci serait limit&eacute;e &agrave; tous
        pr&eacute;judices directs et toutes demandes confondues, aux sommes
        vers&eacute;es par le Partenaire &agrave; ANDREWAPP (&agrave; titre de
        paiement des Services) au cours des trois (3) derniers mois
      </p>
      <p>&nbsp;</p>
      <p>
        Les Parties conviennent express&eacute;ment qu&rsquo;ANDREWAPP peut
        faire appel &agrave; des prestataires ext&eacute;rieurs pour
        ex&eacute;cuter tout ou partie des Services. Dans ce cas, le Partenaire
        sera inform&eacute; sur simple demande des garanties et niveau de
        service propos&eacute;s par le prestataire ext&eacute;rieur qui lui
        seront opposables. En tout &eacute;tat de cause, ANDREWAPP demeurera
        l&rsquo;interlocuteur unique du Partenaire en cas d&rsquo;appel &agrave;
        des sous-traitants et n&rsquo;engagera sa responsabilit&eacute; que
        s&rsquo;il est d&eacute;montr&eacute; qu&rsquo;il a commis une faute
        grave ou que le prestataire ext&eacute;rieur n&rsquo;a pas
        respect&eacute; les garanties et niveau de service propos&eacute;s. Le
        plafond de responsabilit&eacute; pr&eacute;vu &agrave;
        l&rsquo;alin&eacute;a pr&eacute;c&eacute;dent trouvera &eacute;galement
        &agrave; s&rsquo;appliquer dans ce cas.
      </p>
      <p>&nbsp;</p>
      <p>
        Il est express&eacute;ment convenu entre les Parties que les
        stipulations de la pr&eacute;sente clause continueront &agrave;
        s&rsquo;appliquer m&ecirc;me en cas de r&eacute;solution des
        pr&eacute;sentes constat&eacute;e par une d&eacute;cision de justice
        devenue d&eacute;finitive.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>IMPORTANT</strong>
      </p>
      <p>
        ANDREWAPP d&eacute;cline toute responsabilit&eacute; quant au
        caract&egrave;re adapt&eacute; des Contenus propos&eacute;s par les
        Partenaires via l&rsquo;Application et des conseils
        d&eacute;livr&eacute;s pour un Patient donn&eacute;.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Il est clairement entendu que le caract&egrave;re adapt&eacute;
        d&rsquo;un Contenu doit &ecirc;tre &eacute;valu&eacute; pour chaque
        Patient par son th&eacute;rapeute Partenaire en lien avec la
        consultation r&eacute;alis&eacute;e pr&eacute;c&eacute;demment en
        cabinet et les consultations de suivi.&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre, le Partenaire reste seul responsable des contenus
        qu&rsquo;il souhaite recommander &agrave; ses Patients Utilisateurs de
        l&rsquo;Application, laquelle ne saurait constituer une aide au
        diagnostic, ni &agrave; la prescription.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          10.2. Statut d&rsquo;h&eacute;bergeur au sens de la LCEN
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire reconna&icirc;t qu&rsquo;ANDREWAPP peut avoir la
        qualit&eacute; d&rsquo;h&eacute;bergeur au sens de l&rsquo;article 6 I
        2&deg; de la loi du 21 juin 2004 pour la confiance dans
        l&rsquo;&eacute;conomie num&eacute;rique dite LCEN.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre, ANDREWAPP se r&eacute;serve la possibilit&eacute; de retirer
        tout Contenu qu&rsquo;elle consid&egrave;rera comme manifestement
        illicite au sens de l&rsquo;article 6 I 2&deg; de la LCEN.
      </p>
      <p>&nbsp;</p>
      <p>
        La notification des Contenus manifestement illicites par un Utilisateur,
        un Partenaire ou tout autre tiers doit se faire par :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Courrier &eacute;lectronique &agrave; l&rsquo;adresse
          :contact@andrewapp.fr ; ou au 07 89 34 42 76
        </ListItem>
        <ListItem>
          Courrier en recommand&eacute; avec avis de r&eacute;ception &agrave; :
          ANDREWAPP 32 rue du B&oelig;uf 69005 LYON]
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Conform&eacute;ment &agrave; l&rsquo;article 6 I 5&deg; de la LCEN, la
        notification, pour &ecirc;tre valide, doit reprendre les
        &eacute;l&eacute;ments suivants :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>La date de la notification ;</ListItem>
        <ListItem>
          Si le notifiant est une personne physique : ses nom, pr&eacute;noms,
          profession, domicile, nationalit&eacute;, date et lieu de naissance
          ;&nbsp;
        </ListItem>
        <ListItem>
          Si le requ&eacute;rant est une personne morale : sa forme, sa
          d&eacute;nomination, son si&egrave;ge social et l&apos;organe qui la
          repr&eacute;sente l&eacute;galement ; &eacute;tant
          pr&eacute;cis&eacute; que la condition relative &agrave; la fourniture
          de l&rsquo;identit&eacute; du notifiant est r&eacute;put&eacute;e
          acquise si celui-ci est un utilisateur inscrit du service de
          communication au public et qu&rsquo;il est connect&eacute; au moment
          de proc&eacute;der &agrave; la notification et que
          l&rsquo;h&eacute;bergeur a recueilli les &eacute;l&eacute;ments
          n&eacute;cessaires &agrave; son identification ;
        </ListItem>
        <ListItem>
          Le nom et le domicile du destinataire ou, s&apos;il s&apos;agit
          d&apos;une personne morale, sa d&eacute;nomination et son si&egrave;ge
          social ;
        </ListItem>
        <ListItem>
          La description des faits litigieux et leur localisation pr&eacute;cise
          ; &eacute;tant pr&eacute;cis&eacute; que ces conditions sont
          r&eacute;put&eacute;es satisfaites d&egrave;s lors que le service
          permet de proc&eacute;der pr&eacute;cis&eacute;ment &agrave; cette
          notification par un dispositif technique directement accessible depuis
          ledit contenu litigieux ;&nbsp;
        </ListItem>
        <ListItem>
          Les motifs pour lesquels le contenu doit &ecirc;tre retir&eacute;,
          comprenant la mention des dispositions l&eacute;gales et des
          justifications de faits ; &eacute;tant pr&eacute;cis&eacute; que cette
          condition est r&eacute;put&eacute;e satisfaite d&egrave;s lors que le
          service de communication au public en ligne permet de proc&eacute;der
          &agrave; la notification par un dispositif technique proposant
          d&rsquo;int&eacute;grer la cat&eacute;gorie d&rsquo;infraction
          &agrave; laquelle peut &ecirc;tre rattach&eacute; ce contenu litigieux
          ;
        </ListItem>
        <ListItem>
          La copie de la correspondance adress&eacute;e &agrave; l&apos;auteur
          ou &agrave; l&apos;&eacute;diteur des informations ou activit&eacute;s
          litigieuses demandant leur interruption, leur retrait ou leur
          modification, ou la justification de ce que l&apos;auteur ou
          l&apos;&eacute;diteur n&apos;a pu &ecirc;tre contact&eacute;, sauf
          exception pr&eacute;vue pour certaines infractions par l&rsquo;article
          6 I 5&deg; de la LCEN.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>10.3. Litige entre Utilisateur et Partenaire</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Il est pr&eacute;cis&eacute; que tout litige survenant entre un
        Utilisateur et un Partenaire devra &ecirc;tre trait&eacute; entre eux.
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire, en utilisant l&rsquo;Application, reconna&icirc;t
        qu&rsquo;il engage &eacute;galement l&rsquo;image de marque
        d&rsquo;ANDREWAPP. Le Partenaire reconna&icirc;t donc que ses
        agissements, qui ne respecteraient pas l&rsquo;ensemble des obligations
        stipul&eacute;es aux pr&eacute;sentes CGS, peuvent avoir un effet
        pr&eacute;judiciable pour ANDREWAPP.
      </p>
      <p>&nbsp;</p>
      <p>
        Lorsque l&rsquo;Utilisateur contacte ANDREWAPP par email &agrave;
        l&rsquo;adresse contact@andrewapp.fr ou par t&eacute;l&eacute;phone au
        07 89 34 42 76 afin de formuler une r&eacute;clamation &agrave; propos
        d&rsquo;un Partenaire, ANDREWAPP r&eacute;percutera la
        r&eacute;clamation sous 24 heures ouvr&eacute;es par email aupr&egrave;s
        du Partenaire concern&eacute; qui sera seul responsable du traitement du
        litige.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En tout &eacute;tat de cause, la Partie concern&eacute;e par la
        r&eacute;clamation de l&rsquo;Utilisateur s&rsquo;engage &agrave;
        r&eacute;pondre &agrave; cette r&eacute;clamation dans les meilleurs
        d&eacute;lais.
      </p>
      <p>&nbsp;</p>
      <p>
        Soucieuse de l&rsquo;image de son Application, ANDREWAPP invite les
        Parties &agrave; faire leurs meilleurs efforts pour r&eacute;soudre
        amiablement tout litige dans lesquels ils seraient
        impliqu&eacute;s.&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 11. FORCE MAJEURE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        La responsabilit&eacute; d&rsquo;ANDREWAPP ne pourra pas &ecirc;tre mise
        en &oelig;uvre si la non-ex&eacute;cution ou le retard dans
        l&rsquo;ex&eacute;cution de l&rsquo;une de ses obligations
        d&eacute;crites dans les pr&eacute;sentes CGS d&eacute;coule d&rsquo;un
        cas de force majeure.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Il y a force majeure en mati&egrave;re contractuelle lorsqu&rsquo;un
        &eacute;v&egrave;nement &eacute;chappant au contr&ocirc;le du
        d&eacute;biteur, qui ne pouvait &ecirc;tre raisonnablement pr&eacute;vu
        lors de la conclusion des CGS et dont les effets ne peuvent &ecirc;tre
        &eacute;vit&eacute;s par des mesures appropri&eacute;es, emp&ecirc;che
        l&rsquo;ex&eacute;cution de son obligation par le d&eacute;biteur.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Si l&rsquo;emp&ecirc;chement est temporaire, l&rsquo;ex&eacute;cution de
        l&rsquo;obligation est suspendue &agrave; moins que le retard qui en
        r&eacute;sulterait ne justifie la r&eacute;solution des CGS. Si
        l&rsquo;emp&ecirc;chement est d&eacute;finitif, les CGS sont
        r&eacute;solues de plein droit et les Parties sont
        lib&eacute;r&eacute;es de leurs obligations dans les conditions
        pr&eacute;vues aux articles 1351 et 1351-1 du Code civil.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de survenance d&rsquo;un &eacute;v&egrave;nement rev&ecirc;tant
        les caract&eacute;ristiques de la force majeure, ANDREWAPP
        s&rsquo;efforcera d&rsquo;informer le Partenaire d&egrave;s que
        possible.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 12. PROPRIETE INTELLECTUELLE&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          12.1. Titularit&eacute; des droits de propri&eacute;t&eacute;
          intellectuelle des Partenaires&nbsp;
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cadre de l&rsquo;utilisation de l&rsquo;Application, les
        Partenaires sont susceptibles de pr&eacute;senter des photographies,
        marques, logos, dessins et autres mod&egrave;les leur appartenant ou
        appartenant &agrave; des tiers.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Tout Partenaire d&eacute;posant un Contenu par
        l&rsquo;interm&eacute;diaire de l&rsquo;Application garantit qu&rsquo;il
        a le droit de faire une repr&eacute;sentation de tous les Contenus et en
        tiendra ANDREWAPP indemne.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En tout &eacute;tat de cause, ANDREWAPP ne saurait en aucun cas
        &ecirc;tre tenue responsable d&rsquo;un acte de contrefa&ccedil;on,
        compte tenu de sa simple qualit&eacute; d&rsquo;h&eacute;bergeur des
        Contenus publi&eacute;s par les Partenaires.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En vue de l&rsquo;ex&eacute;cution des pr&eacute;sentes CGS, tout
        Partenaire c&egrave;de une licence gratuite et non exclusive &agrave;
        l&rsquo;ANDREWAPP, pour utiliser, reproduire, repr&eacute;senter,
        adapter et mettre &agrave; disposition du public lesdits Contenus
        incluant notamment les cr&eacute;ations prot&eacute;g&eacute;es par le
        droit d&apos;auteur ainsi que tout signe distinctif visible en vue de
        l&rsquo;ex&eacute;cution des pr&eacute;sentes CGS. Cette licence sera
        valable pendant toute la dur&eacute;e de l&rsquo;utilisation de
        l&rsquo;Application en vertu des pr&eacute;sentes et dans le monde
        entier.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Cette licence n&rsquo;emporte aucun droit de sous-licence &agrave; une
        tierce partie. ANDREWAPP ne pourra n&eacute;anmoins pas &ecirc;tre tenue
        responsable de la copie du Contenu par tout Utilisateur naviguant sur
        l&rsquo;Application.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          12.2. Titularit&eacute; des droits de propri&eacute;t&eacute;
          intellectuelle d&rsquo;ANDREWAPP
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire reconnait les droits de propri&eacute;t&eacute;
        intellectuelle d&rsquo;ANDREWAPP sur l&rsquo;Application, ses
        composantes et les Contenus y aff&eacute;rent et renonce &agrave;
        contester ces droits sous quelle que forme que ce soit.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Les marques, logos, slogans, graphismes, photographies, animations,
        vid&eacute;os, solutions logicielles et textes et tout autre Contenu sur
        l&rsquo;Application, &agrave; l&rsquo;exception des Contenus de tiers,
        identifi&eacute;s comme tel, et des Contenus de Partenaires, sont la
        propri&eacute;t&eacute; intellectuelle exclusive d&rsquo;ANDREWAPP et ne
        peuvent &ecirc;tre reproduits, utilis&eacute;s ou
        repr&eacute;sent&eacute;s sans autorisation expresse sous peine de
        poursuites judiciaires.
      </p>
      <p>&nbsp;</p>
      <p>
        Toute repr&eacute;sentation ou reproduction, totale ou partielle, de
        l&rsquo;Application et de son contenu, par quel que
        proc&eacute;d&eacute; que ce soit, sans l&rsquo;autorisation
        pr&eacute;alable expresse d&rsquo;ANDREWAPP, est interdite et
        constituera une contrefa&ccedil;on sanctionn&eacute;e par les articles
        L.335-2 et suivants et les articles L.713-1 et suivants du code de la
        propri&eacute;t&eacute; intellectuelle.
      </p>
      <p>&nbsp;</p>
      <p>
        En particulier, ANDREWAPP interdit express&eacute;ment en tant que
        producteur de base de donn&eacute;es :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          L&rsquo;extraction, par transfert permanent ou temporaire de la
          totalit&eacute; ou d&rsquo;une partie qualitativement ou
          quantitativement substantielle du contenu de sa base de donn&eacute;es
          sur un autre support, par tout moyen et sous quelque forme que ce soit
          ;
        </ListItem>
        <ListItem>
          La r&eacute;utilisation, par la mise &agrave; la disposition au public
          de la totalit&eacute; ou d&rsquo;une partie qualitativement ou
          quantitativement substantielle du contenu de la base, quelle
          qu&rsquo;en soit la forme ;&nbsp;
        </ListItem>
        <ListItem>
          La reproduction, l&rsquo;extraction ou la r&eacute;utilisation, par
          tout moyen, y compris les m&eacute;thodes assimilables au scraping des
          contenus (photographies, description etc&hellip;) publi&eacute;s par
          ANDREWAPP.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        L&rsquo;acceptation des pr&eacute;sentes CGS vaut reconnaissance par le
        Partenaire des droits de propri&eacute;t&eacute; intellectuelle
        d&rsquo;ANDREWAPP et engagement &agrave; les respecter.
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP accorde une licence personnelle, non-exclusive et non cessible
        au Partenaire l&rsquo;autorisant &agrave; utiliser l&rsquo;Application
        et les informations qu&rsquo;elle contient conform&eacute;ment aux
        pr&eacute;sentes CGS.
      </p>
      <p>&nbsp;</p>
      <p>
        Toute autre exploitation de l&rsquo;Application et de son contenu est
        exclue du domaine de la pr&eacute;sente licence et ne pourra &ecirc;tre
        effectu&eacute;e sans l&rsquo;autorisation pr&eacute;alable expresse de
        d&rsquo;ANDREWAPP.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 13. PROTECTION DES DONNEES PERSONNELLES&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cadre du traitement de donn&eacute;es &agrave; caract&egrave;re
        personnel, ANDREWAPP s&rsquo;engage &agrave; se conformer aux lois et
        r&egrave;glements en vigueur encadrant les traitements de donn&eacute;es
        &agrave; caract&egrave;re personnel, en ce compris la Loi &laquo;
        Informatique, fichiers et libert&eacute;s &raquo; du 6 janvier 1978
        modifi&eacute;e par la loi du 20 juin 2018 et le R&egrave;glement (UE)
        2016/679 du 27 avril 2016 relatif &agrave; la protection des personnes
        physiques &agrave; l&apos;&eacute;gard du Traitement des Donn&eacute;es
        &agrave; Caract&egrave;re Personnel et &agrave; la libre circulation de
        ces donn&eacute;es (le &laquo;&nbsp;RGPD&nbsp;&raquo;).
      </p>
      <p>&nbsp;</p>
      <p>
        Les conditions relatives au traitement des donn&eacute;es personnelles
        dans le cadre des Services sont pr&eacute;cis&eacute;es en{" "}
        <strong>
          <em>Annexe 2</em>
        </strong>{" "}
        &ndash; Accord sur la protection des donn&eacute;es.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 14. RECLAMATIONS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Toute question ou r&eacute;clamation concernant l&rsquo;utilisation ou
        le fonctionnement de l&rsquo;Application peut &ecirc;tre formul&eacute;e
        selon les modalit&eacute;s suivantes :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Par courrier &eacute;lectronique &agrave; l&rsquo;adresse suivante :{" "}
          <strong>
            <a href="mailto:support@andrewapp.fr">contact@andrewapp.fr</a>
          </strong>
          &nbsp;&nbsp;
        </ListItem>
        <ListItem>
          Par courrier AndrewApp 32 rue du B&oelig;uf 69005 Lyon
        </ListItem>
        <ListItem>
          Par t&eacute;l&eacute;phone au :&nbsp; 07 89 34 42 76 du Lundi au
          Vendredi de 9h &agrave; 12h et de 14h &agrave; 17h.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>
          ARTICLE 15. DUREE DE L&rsquo;ABONNEMENT &ndash; RESILIATION
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Les CGS entrent en vigueur &agrave; compter de leur acceptation par le
        Partenaire et pour la dur&eacute;e de l&rsquo;Abonnement.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          15.1. Hypoth&egrave;ses de suspension et de r&eacute;siliation
        </strong>
      </p>
      <p>&nbsp;</p>
      <u>I. Suspension&nbsp;</u>

      <p>&nbsp;</p>
      <p>
        En cas de violation continue des pr&eacute;sentes dispositions ou des
        lois et r&egrave;glements en vigueur, ainsi que d&rsquo;absence de
        coop&eacute;ration et/ou de d&eacute;loyaut&eacute;, et s&rsquo;il y a
        urgence &agrave; faire cesser les agissements constat&eacute;s,
        ANDREWAPP pourra&nbsp;suspendre de plein droit l&rsquo;Abonnement du
        Partenaire, sans indemnit&eacute; au profit du Partenaire, sous
        r&eacute;serve de lui avoir transmis sur un support durable,
        l&rsquo;expos&eacute; des motifs au moment o&ugrave; la suspension prend
        effet.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Cette d&eacute;cision est port&eacute;e &agrave; la connaissance du
        Partenaire par courriel, par l&rsquo;interm&eacute;diaire d&rsquo;un
        support durable indiquant les griefs reproch&eacute;s ainsi que les
        obligations dont le non-respect est all&eacute;gu&eacute;.
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP pourra mettre le Profil du Partenaire hors ligne afin
        qu&rsquo;il r&eacute;gularise les manquements identifi&eacute;s sous dix
        (10) jours, &agrave; compter de la r&eacute;ception du support durable
        pr&eacute;cit&eacute;. A l&rsquo;issue de ce d&eacute;lai, si le
        Partenaire n&rsquo;a pas corrig&eacute; les diff&eacute;rents
        manquements, ANDREWAPP pourra r&eacute;silier l&rsquo;Abonnement et
        supprimer son Profil Partenaire de l&rsquo;Application, dans les
        conditions d&eacute;taill&eacute;es ci-apr&egrave;s.&nbsp;
      </p>
      <p>&nbsp;</p>
      <u>II. R&eacute;siliation suite &agrave; une suspenion&nbsp;</u>
      <p>&nbsp;</p>
      <p>
        Toute suspension pourra mener &agrave; une r&eacute;siliation si le
        Partenaire n&rsquo;apporte pas de r&eacute;ponse satisfaisante aux
        griefs qui lui sont notifi&eacute;s dans les quinze (15) jours suivants
        le premier jour de suspension des Services.
      </p>
      <p>&nbsp;</p>
      <p>
        Dans ce cas, un d&eacute;lai de pr&eacute;avis d&rsquo;un (1) mois,
        augment&eacute; d&rsquo;un (1) mois par ann&eacute;e compl&egrave;te au
        cours de laquelle le Partenaire aura &eacute;t&eacute;
        r&eacute;f&eacute;renc&eacute; sur l&rsquo;Application sera
        activ&eacute;. Ce d&eacute;lai de pr&eacute;avis court &agrave; compter
        de l&rsquo;expiration du d&eacute;lai de suspension. A l&rsquo;issue du
        d&eacute;lai de pr&eacute;avis, l&rsquo;Abonnement sera
        r&eacute;sili&eacute;.&nbsp;
      </p>
      <p>&nbsp;</p>
      <u>III. R&eacute;siliation pour faute&nbsp;</u>
      <p>&nbsp;</p>
      <p>
        En cas de manquement grave ou r&eacute;p&eacute;t&eacute;, par une
        Partie, &agrave; ses obligations au titre des pr&eacute;sentes,
        l&rsquo;Abonnement pourra &ecirc;tre r&eacute;sili&eacute; par
        l&rsquo;autre Partie.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Il est express&eacute;ment convenu que cette r&eacute;siliation aura
        lieu de plein droit, quinze (15) jours apr&egrave;s l&rsquo;envoi
        d&rsquo;une mise en demeure de s&rsquo;ex&eacute;cuter, rest&eacute;e
        sans effet.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        La mise en demeure, qui devra imp&eacute;rativement indiquer les griefs
        reproch&eacute;s et les obligations dont le non-respect est
        all&eacute;gu&eacute;, sera notifi&eacute;e par lettre
        recommand&eacute;e avec accus&eacute; de r&eacute;ception.&nbsp;
      </p>
      <p>&nbsp;</p>
      <u>IV. R&eacute;siliation pour convenance&nbsp;</u>

      <p>&nbsp;</p>
      <p>
        Chaque Partie aura la possibilit&eacute; de r&eacute;silier
        l&rsquo;Abonnement pour convenance.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cadre d&rsquo;un Abonnement mensuel, chaque Partie pourra
        r&eacute;silier l&rsquo;Abonnement &agrave; tout moment depuis la
        plateforme du Prestataire de Services de paiement.
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cadre d&rsquo;un Abonnement annuel, le Partenaire pourra
        r&eacute;silier l&rsquo;Abonnement &agrave; tout moment en se rendant
        sur son Compte Partenaire, sous r&eacute;serve du respect d&rsquo;un
        pr&eacute;avis de 30 Jours Ouvr&eacute;s. ANDREWAPP pourra
        &eacute;galement r&eacute;silier l&rsquo;Abonnement pour convenance
        &agrave; tout moment, en adressant une courrier &eacute;lectronique
        &agrave; l&rsquo;adresse renseign&eacute;e par le Partenaire au moment
        de son inscription&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP proc&egrave;dera dans les meilleurs d&eacute;lais &agrave; la
        d&eacute;sactivation du Compte Partenaire et adressera au Partenaire
        concern&eacute; un courriel confirmant la cl&ocirc;ture de son compte.
        Dans cette hypoth&egrave;se, il est entendu entre les Parties que
        l&rsquo;Abonnement correspondant &agrave; la p&eacute;riode&nbsp;
        entam&eacute;e (mois ou ann&eacute;e, selon l&rsquo;Abonnement souscrit)
        sera d&ucirc; en int&eacute;gralit&eacute;.&nbsp;
      </p>
      <p>&nbsp;</p>
      <u>V. Autres hypoth&egrave;ses de r&eacute;siliation&nbsp;</u>

      <p>&nbsp;</p>
      <p>
        L&rsquo;Abonnement est conclu <em>intuitu personae</em>, en
        consid&eacute;ration des qualit&eacute;s du Partenaire et des relations
        existantes entre ANDREWAPP et le Partenaire.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Par cons&eacute;quent, ANDREWAPP pourra r&eacute;silier de plein droit
        l&rsquo;Abonnement dans le cas o&ugrave; le Partenaire ne disposerait
        plus des qualifications et autorisations requises pour exercer la
        profession d&rsquo;ost&eacute;opathe en conformit&eacute; avec la
        r&egrave;glementation en vigueur.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>15.2. Cons&eacute;quences de la r&eacute;siliation</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Toute r&eacute;siliation, r&eacute;solution ou annulation de
        l&rsquo;Abonnement pour quelque motif que ce soit entra&icirc;ne
        automatiquement le d&eacute;r&eacute;f&eacute;rencement des Partenaires
        de l&rsquo;Application, ainsi que l&rsquo;annulation de
        l&rsquo;acc&egrave;s aux diff&eacute;rents Services propos&eacute;s au
        Partenaire.
      </p>
      <p>&nbsp;</p>
      <p>
        Cependant, le Partenaire conserve l&rsquo;acc&egrave;s &agrave; son
        Backoffice pendant une dur&eacute;e suppl&eacute;mentaire d&rsquo;un (1)
        mois &nbsp;afin de r&eacute;cup&eacute;rer ses donn&eacute;es, factures
        et Contenus. En revanche, il ne peut plus cr&eacute;er de nouveaux
        Profils Patients, de nouveaux parcours d&rsquo;exercices
        personnalis&eacute;s ou de nouveaux conseils personnalis&eacute;s
        d&egrave;s la r&eacute;siliation effective.
      </p>
      <p>&nbsp;</p>
      <p>
        A l&rsquo;issue du d&eacute;lai d&rsquo;un (1) mois&nbsp; laiss&eacute;
        au Partenaire pour r&eacute;cup&eacute;rer ses donn&eacute;es, factures
        et Contenus, les Contenus sp&eacute;cifiques propos&eacute;s par le
        Partenaire &agrave; ses Patients resteront accessibles aux Patients
        durant la dur&eacute;e d&rsquo;accessibilit&eacute; aux Contenus
        personnalis&eacute;s d&eacute;finie par d&eacute;faut ou, le cas
        &eacute;ch&eacute;ant, jusqu&rsquo;&agrave; suppression de son Compte
        Utilisateur par le Patient.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Avant l&rsquo;expiration du d&eacute;lai d&rsquo;un (1) mois
        laiss&eacute; au Partenaire pour r&eacute;cup&eacute;rer ses
        donn&eacute;es, factures et Contenus, le Partenaire peut demander
        &agrave; ANDREWAPP de mettre &agrave; sa disposition, dans un
        d&eacute;lai de soixante (60) Jours Ouvr&eacute;s&nbsp; suivant sa
        demande &eacute;crite, l&rsquo;ensemble des donn&eacute;es relatives
        &agrave; ses Patients d&eacute;tenues par ANDREWAPP dans le cadre de
        l&rsquo;acc&egrave;s aux Services par le Partenaire. Ces donn&eacute;es
        seront mises &agrave; disposition par ANDREWAPP dans un format de
        structur&eacute;, couramment utilis&eacute; et lisible par machine.
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP se r&eacute;serve le droit de renvoyer vers le Partenaire
        toute r&eacute;clamation initi&eacute;e par un Utilisateur concernant un
        suivi r&eacute;alis&eacute; par le Partenaire via l&rsquo;Application et
        conservera la possibilit&eacute; de communiquer en visant sa
        collaboration avec le Partenaire pendant toute cette dur&eacute;e.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Les Articles Propri&eacute;t&eacute; intellectuelle,
        Confidentialit&eacute; et Donn&eacute;es &agrave; caract&egrave;re
        personnel ou toute autre article dont les stipulations, par nature,
        exigent de survivre &agrave; l&rsquo;expiration de l&rsquo;Abonnement,
        resteront en vigueur en cas de r&eacute;siliation des pr&eacute;sentes
        et ce pour une dur&eacute;e compl&eacute;mentaire de cinq (5) ans sauf
        stipulation expresse ou disposition l&eacute;gislative ou
        r&eacute;glementaire contraire.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 16. ASSISTANCE &ndash; SUPPORT PARTENAIRE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Tout Partenaire peut saisir ANDREWAPP aux Jours Ouvr&eacute;s, en
        contactant ANDREWAPP par courriel &agrave; l&rsquo;adresse
        suivante&nbsp;
        <strong>
          <a href="mailto:support@andrewapp.fr">support@andrewapp.fr</a>
        </strong>
        .
      </p>
      <p>&nbsp;</p>
      <p>Tout Partenaire pourra consulter ANDREWAPP concernant :&nbsp;&nbsp;</p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>Un litige en cours avec un Patient&nbsp;;&nbsp;</ListItem>
        <ListItem>Des Anomalies de l&rsquo;Application ;&nbsp;</ListItem>
        <ListItem>
          Des renseignements directement li&eacute;s &agrave; la fourniture des
          Services et notamment concernant le fonctionnement de
          l&rsquo;Application.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 17. CONFIDENTIALITE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Chaque Partie s&apos;engage &agrave; n&rsquo;utiliser les Informations
        Confidentielles, directement ou indirectement, en tout ou partie, que
        pour la stricte ex&eacute;cution des pr&eacute;sentes CGS.
      </p>
      <p>&nbsp;</p>
      <p>
        Toute divulgation fond&eacute;e pourra engager la responsabilit&eacute;
        de son auteur, et ce quelle que soit la cause de la divulgation.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Les obligations de confidentialit&eacute; stipul&eacute;es par la
        pr&eacute;sente clause ne s&rsquo;appliquent pas &agrave;
        l&rsquo;int&eacute;gralit&eacute; ou &agrave; toute partie des
        Informations Confidentielles dans la mesure o&ugrave; :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4} listStyleType="none" spacing={2}>
        <ListItem>
          (a)&nbsp;Elles &eacute;taient l&eacute;galement d&eacute;tenues par la
          Partie destinataire avant leur divulgation ;
        </ListItem>
        <ListItem>
          (b)&nbsp;Elles ont &eacute;t&eacute; l&eacute;galement
          divulgu&eacute;es &agrave; la Partie destinataire par une tierce
          partie sans restriction de divulgation ;
        </ListItem>
        <ListItem>
          (c)&nbsp;Elles sont assujetties &agrave; une obligation l&eacute;gale
          de divulgation par tout tribunal comp&eacute;tent, autorit&eacute; ou
          administration.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        La pr&eacute;sente clause de confidentialit&eacute; sera maintenue
        &agrave; l&rsquo;expiration de l&rsquo;Abonnement pour une dur&eacute;e
        compl&eacute;mentaire de cinq (5) ans.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 18. ANTICORRUPTION ET AUTRES ENGAGEMENTS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>18.1. Anticorruption</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire s&apos;engage, tant pour son propre compte que pour celui
        de ses employ&eacute;s, agents, filiales et sous-traitants
        &eacute;ventuels pour lesquels il se porte garant, &agrave; respecter
        l&apos;ensemble des lois et r&egrave;glements applicables en
        mati&egrave;re de lutte contre la corruption, et notamment la loi n&deg;
        2016-1691 du 9 d&eacute;cembre 2016 relative &agrave; la transparence,
        &agrave; la lutte contre la corruption et &agrave; la modernisation de
        la vie &eacute;conomique ou toute autre loi ou r&eacute;glementation qui
        viendrait &agrave; la remplacer ou la compl&eacute;ter.
      </p>
      <p>&nbsp;</p>
      <p>
        La corruption d&eacute;signe tout comportement par lequel une personne
        sollicite ou accepte un cadeau ou, plus g&eacute;n&eacute;ralement, une
        faveur, en vue d&apos;obtenir ou de conserver un contrat ou tout autre
        avantage indu dans le cadre d&apos;activit&eacute;s nationales ou
        internationales.
      </p>
      <p>&nbsp;</p>
      <p>
        En cons&eacute;quence, le Partenaire agira de mani&egrave;re
        professionnelle et &eacute;thique dans ses relations d&apos;affaires, et
        en particulier, il s&apos;engage &agrave;&nbsp;:
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          S&apos;abstenir de toute activit&eacute; de corruption de quelque
          nature que ce soit (directe ou indirecte, active ou passive,
          financi&egrave;re ou autre), de trafic d&apos;influence,
          d&apos;extorsion, de d&eacute;tournement de fonds ou de toute autre
          conduite sanctionn&eacute;e par la loi ;&nbsp;
        </ListItem>
        <ListItem>
          Mettre en &oelig;uvre et maintenir des politiques et proc&eacute;dures
          ad&eacute;quates en mati&egrave;re d&apos;&eacute;thique et de lutte
          contre la corruption ;
        </ListItem>
        <ListItem>
          Informer rapidement ANDREWAPP de tout &eacute;v&eacute;nement pouvant
          entra&icirc;ner l&apos;obtention d&apos;un avantage indu, financier ou
          autre, de quelque valeur que ce soit, en relation avec les
          pr&eacute;sentes CGS ;
        </ListItem>
        <ListItem>
          Fournir toute l&apos;assistance n&eacute;cessaire &agrave; ANDREWAPP
          pour r&eacute;pondre &agrave; une demande d&apos;une autorit&eacute;
          d&ucirc;ment autoris&eacute;e en mati&egrave;re de lutte contre la
          corruption.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Il est entendu entre les Parties qu&apos;en cas de non-respect des
        dispositions du pr&eacute;sent article, ANDREWAPP pourra suspendre de
        plein droit l&rsquo;Abonnement, sans indemnit&eacute; ni pr&eacute;avis,
        et proc&eacute;der &agrave; la r&eacute;siliation conform&eacute;ment
        &agrave; l&rsquo;Article 15 &laquo; Dur&eacute;e de l&rsquo;Abonnement
        &ndash; R&eacute;siliation &raquo; si le Partenaire n&rsquo;apporte pas
        de r&eacute;ponse satisfaisante &agrave; la non-conformit&eacute; dans
        les d&eacute;lais pr&eacute;vus.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>18.2. Non-sollicitation du personnel</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Chacune des Parties renonce &agrave; engager ou faire travailler,
        directement ou par personnel interpos&eacute;, tout collaborateur de
        l&rsquo;autre Partie. Cet engagement est valable pendant toute la
        dur&eacute;e de l&rsquo;Abonnement et pendant une dur&eacute;e minimum
        d&rsquo;un (1) an apr&egrave;s la fin de ce dernier.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de non-respect de cette disposition, il est convenu entre les
        Parties que la Partie ayant enfreint l&rsquo;obligation de
        non-sollicitation devra payer &agrave; l&rsquo;autre Partie, &agrave;
        premi&egrave;re demande, une somme &eacute;gale &agrave; douze (12) mois
        du dernier salaire brut mensuel du collaborateur embauch&eacute;.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Cette p&eacute;nalit&eacute; trouvera aussi &agrave; s&rsquo;appliquer
        dans l&rsquo;hypoth&egrave;se o&ugrave; le collaborateur
        d&eacute;bauch&eacute; agirait, pour le compte du Partenaire, en tant
        qu&rsquo;auto-entrepreneur ou de dirigeant d&rsquo;une
        soci&eacute;t&eacute; commerciale.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>18.3. Autres exigences</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le Partenaire s&rsquo;engage &agrave; respecter toutes les exigences qui
        lui sont applicables, notamment aux regards des obligations de
        d&eacute;claration en mati&egrave;re sociale et d&rsquo;emploi, et
        garantit qu&rsquo;il n&rsquo;emploie en aucun cas des &eacute;trangers
        non munis de l&rsquo;autorisation d&rsquo;exercer une activit&eacute;
        salari&eacute;e en France, ou dont l&rsquo;autorisation n&rsquo;est pas
        conforme &agrave; l&rsquo;emploi consid&eacute;r&eacute;,
        conform&eacute;ment aux articles L. 8251-1 et suivants du Code du
        travail ou toute autre r&eacute;glementation &eacute;quivalente.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 19. GARANTIES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Chaque Partie s&rsquo;engage envers et garantit l&rsquo;autre Partie que
        :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Elle a le pouvoir et l&rsquo;autorit&eacute; d&rsquo;accepter les
          pr&eacute;sentes CGS, et qu&rsquo;elle assurera et maintiendra, au
          cours de la relation, l&rsquo;ensemble des autorisations
          &eacute;ventuelles n&eacute;cessaires &agrave;
          l&rsquo;ex&eacute;cution de ses obligations ;
        </ListItem>
        <ListItem>
          Elle d&eacute;tient, ou s&rsquo;est vue octroyer les droits lui
          permettant d&rsquo;utiliser aux fins des pr&eacute;sentes CGS,
          l&rsquo;ensemble des droits de propri&eacute;t&eacute; intellectuelle
          n&eacute;cessaires au respect de ses obligations ;
        </ListItem>
        <ListItem>
          Elle ex&eacute;cutera ses obligations en vertu des pr&eacute;sentes
          CGS conform&eacute;ment &agrave; l&rsquo;ensemble des lois en vigueur
          et en d&eacute;montrant une diligence et des comp&eacute;tences
          raisonnables ;
        </ListItem>
        <ListItem>
          Elle ne fera ni n&rsquo;omettra de faire quoi que ce soit pouvant
          entra&icirc;ner pour l&rsquo;autre Partie une violation de toute loi
          ou r&egrave;glement en vigueur ; et
        </ListItem>
        <ListItem>Elle ne d&eacute;nigrera pas l&rsquo;autre Partie.</ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 20. VALIDITE DES CGS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Si l&rsquo;une quelconque des stipulations des pr&eacute;sentes CGS
        venait &agrave; &ecirc;tre d&eacute;clar&eacute;e nulle au regard
        d&rsquo;une disposition l&eacute;gislative ou r&eacute;glementaire en
        vigueur et/ou d&rsquo;une d&eacute;cision de justice ayant
        autorit&eacute; de la chose jug&eacute;e, elle sera
        r&eacute;put&eacute;e non &eacute;crite mais n&rsquo;affectera en rien
        la validit&eacute; des autres clauses qui demeureront pleinement
        applicables.
      </p>
      <p>&nbsp;</p>
      <p>
        Une telle modification ou d&eacute;cision n&rsquo;autorise en aucun cas
        le Partenaire &agrave; m&eacute;connaitre les pr&eacute;sentes CGS.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 21. DISPOSITIONS GENERALES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Chacune des Parties s&apos;engage &agrave; toujours se comporter
        vis-&agrave;-vis de l&rsquo;autre, comme un partenaire loyal et de bonne
        foi, et notamment &agrave; porter sans d&eacute;lai &agrave; la
        connaissance de l&rsquo;autre Partie, tout diff&eacute;rend ou toute
        difficult&eacute; qu&apos;elle pourrait rencontrer dans le cadre de
        l&apos;ex&eacute;cution des pr&eacute;sentes CGS.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Le fait que l&apos;une des Parties n&apos;ait pas exig&eacute;
        l&apos;application d&apos;une clause quelconque des pr&eacute;sentes
        CGS, que ce soit de fa&ccedil;on permanente ou temporaire, ne pourra en
        aucun cas &ecirc;tre consid&eacute;r&eacute; comme une renonciation
        &agrave; ladite clause.
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de difficult&eacute; d&rsquo;interpr&eacute;tation entre
        l&rsquo;un quelconque des titres figurant en t&ecirc;te des clauses, et
        l&rsquo;une quelconque de celles-ci, les titres seront
        d&eacute;clar&eacute;s inexistants.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 22. ASSURANCES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        En acceptant les pr&eacute;sentes CGS le Partenaire confirme disposer
        d&rsquo;une assurance responsabilit&eacute; civile professionnelle
        m&eacute;dicale souscrite aupr&egrave;s d&rsquo;une compagnie
        d&rsquo;assurance notoirement solvable et s&rsquo;engage &agrave; la
        maintenir &agrave; jour pendant toute la dur&eacute;e
        d&rsquo;utilisation de l&rsquo;Application.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 23. COMPETENCE ET DROIT APPLICABLE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        LES PRESENTES CGS AINSI QUE LES RELATIONS ENTRE LES PARTIES SONT REGIES
        PAR LE DROIT FRAN&Ccedil;AIS.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        EN CAS DE DIFFEREND SURVENANT ENTRE LES PARTIES AU SUJET DE
        L&rsquo;INTERPRETATION, DE L&rsquo;EXECUTION OU DE LA RESILIATION DES
        PRESENTES, LES PARTIES S&rsquo;EFFORCERONT DE LE REGLER A
        L&rsquo;AMIABLE.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        DANS L&rsquo;HYPOTHESE OU LA TENTATIVE DE RESOLUTION AMIABLE ECHOUERAIT
        OU NE SERAIT PAS ENVISAGEE, LE LITIGE SERA CONFIE AU TRIBUNAL COMPETENT.
      </p>
      <Divider
        borderColor={"black"}
        width="full"
        orientation="horizontal"
        mt={14}
      />
      <Heading as="h2" color={"tertiary.800"} my={14} fontSize={"xl"}>
        ANNEXE 1 - CONDITIONS TARIFAIRES
      </Heading>
      <Flex w="full" direction="column">
        <Flex width="full" justify="between">
          <Box width="full" border="1px" p={2}>
            Abonnement Annuel
          </Box>
          <Box width="full" border="1px" p={2}>
            358,80 EUROS TTC PAR AN
          </Box>
        </Flex>
        <Flex w="full" justify="between">
          <Box width="full" border="1px" p={2}>
            Abonnement Mensuel
          </Box>
          <Box width="full" border="1px" p={2}>
            39,90 EUROS TTC PAR MOIS
          </Box>
        </Flex>
      </Flex>
      <Heading as="h2" color={"tertiary.800"} my={14} fontSize={"xl"}>
        ANNEXE 2 - ACCORD SUR LA PROTECTION DES DONNEES
      </Heading>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Entre les soussign&eacute;s :</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Soci&eacute;t&eacute; ANDREWAPP, Soci&eacute;t&eacute; par actions
        simplifi&eacute;e, immatricul&eacute;e au Registre du Commerce et des
        Soci&eacute;t&eacute;s de Lyon sous le num&eacute;ro 952 463 636, dont
        le si&egrave;ge social est sis 32 RUE DU B&OElig;UF 69005
        LYON&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <em>Ci-apr&egrave;s, d&eacute;nomm&eacute;e &laquo; </em>
        <strong>
          <em>ANDREWAPP</em>
        </strong>
        <em> &raquo;,</em>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <em>D&rsquo;une part</em>
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ET&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le th&eacute;rapeute partenaire d&rsquo;ANDREWAPP
        r&eacute;f&eacute;renc&eacute; sur l&rsquo;Application Andrew et ayant
        accept&eacute; les Conditions G&eacute;n&eacute;rales de Services
      </p>
      <p>&nbsp;</p>
      <p>
        <em>Ci-apr&egrave;s, d&eacute;nomm&eacute;e le &laquo; </em>
        <strong>
          <em>Partenaire </em>
        </strong>
        <em>&raquo;,</em>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <em>D&rsquo;autre part</em>
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <em>
          Ci-apr&egrave;s, collectivement d&eacute;nomm&eacute;es &laquo;{" "}
        </em>
        <strong>
          <em>Les Parties</em>
        </strong>
        <em> &raquo;</em>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ETANT PREALABLEMENT RAPPELE QUE&nbsp;:</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le pr&eacute;sent Accord, &eacute;tabli en application des articles 26
        et 28 du r&egrave;glement (UE) 2016/679 du Parlement europ&eacute;en et
        du Conseil du 27 avril 2016, a pour objet de d&eacute;finir les
        conditions dans lesquelles les Parties s&rsquo;engagent &agrave;
        effectuer les op&eacute;rations de Traitement de Donn&eacute;es
        personnelles d&eacute;finies en Appendice 1 selon les modalit&eacute;s
        &eacute;dict&eacute;es ci-apr&egrave;s.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cadre de l&rsquo;utilisation de l&rsquo;Application Andrew et
        des services qui y sont propos&eacute;s, les Parties s&rsquo;engagent
        &agrave; respecter la r&eacute;glementation en vigueur applicable au
        Traitement de Donn&eacute;es personnelles et, en particulier, le
        r&egrave;glement (UE) 2016/679 pr&eacute;cit&eacute; applicable depuis
        le 25 mai 2018 (ci-apr&egrave;s, &laquo;{" "}
        <strong>
          le R&egrave;glement G&eacute;n&eacute;ral sur la Protection des
          Donn&eacute;es
        </strong>{" "}
        &raquo;), la loi &laquo; Informatique et libert&eacute;s &raquo;
        n&deg;78-17 du 6 janvier 1978 modifi&eacute;e et les
        r&eacute;glementations en mati&egrave;re de propri&eacute;t&eacute;
        industrielle et intellectuelle.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>ARTICLE 1 &ndash; DEFINITIONS</strong>
      </p>
      <p>
        Pour les besoins des pr&eacute;sentes et nonobstant toutes autres
        d&eacute;finitions pr&eacute;vues dans l&rsquo;Accord, les termes
        suivants auront le sens qui est donn&eacute; ci-dessous :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4} spacing={3}>
        <ListItem>
          &laquo;&nbsp;<strong>Abonnement</strong>&nbsp;&raquo;&nbsp;:
          d&eacute;signe la souscription par le Partenaire aux Services
          propos&eacute;s par ANDREWAPP et tels que d&eacute;taill&eacute;s dans
          les Conditions G&eacute;n&eacute;rales de Services.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Accord</strong>&nbsp;&raquo;&nbsp;:
          D&eacute;signe le pr&eacute;sent Accord sur la Protection des
          Donn&eacute;es compl&eacute;t&eacute; par les appendices suivants
          :&nbsp;
        </ListItem>
        <UnorderedList pl={4} spacing={2}>
          <ListItem>
            <em>
              <strong>
                <em>Appendice 1</em>
              </strong>{" "}
              :{" "}
              <em>
                Description des Traitements de donn&eacute;es
                concern&eacute;s&nbsp;;
              </em>
            </em>
          </ListItem>
          <ListItem>
            <strong>
              <em>Appendice 2&nbsp;</em>
            </strong>
            <em>: Politique de s&eacute;curit&eacute;&nbsp;</em>
          </ListItem>
        </UnorderedList>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        En cas de conflit entre des &eacute;l&eacute;ments essentiels des
        Conditions G&eacute;n&eacute;rales de Services et le pr&eacute;sent
        Accord, les dispositions du second pr&eacute;vaudront sur le premier.
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4} spacing={6}>
        <ListItem>
          <strong>&laquo;&nbsp;Application&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe l&rsquo;application Andrew accessible pour les
          Partenaires par la souscription d&rsquo;un Abonnement aupr&egrave;s
          d&rsquo;ANDREWAPP. L&rsquo;Application est en acc&egrave;s gratuit et
          librement t&eacute;l&eacute;chargeable pour les Utilisateurs autres
          que les Partenaires.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Autorit&eacute; de r&eacute;gulation</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe toute autorit&eacute;
          comp&eacute;tente en mati&egrave;re de protection des Donn&eacute;es
          Personnelles.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Backoffice&nbsp;&raquo;&nbsp;</strong>:
          d&eacute;signe l&rsquo;interface professionnelle
          r&eacute;serv&eacute;e aux Partenaires et accessible sur le Site
          d&rsquo;Andrew &agrave; l&rsquo;adresse suivante{" "}
          <strong>
            <Link
              to="https://pro.andrewapp.fr"
              as={RouterLink}
              target="_blank"
              variant="raw"
              aria-label="Visiter le site Andrew Pro"
            >
              www.pro.andrewapp.fr
            </Link>
          </strong>
          .
        </ListItem>
        <ListItem>
          <strong>
            &laquo;&nbsp;Conditions G&eacute;n&eacute;rales de
            Services&nbsp;&raquo; ou &laquo;&nbsp;CGS&nbsp;&raquo;&nbsp;:
          </strong>{" "}
          d&eacute;signe les conditions contractuelles accept&eacute;es par les
          Partenaires lors de l&rsquo;Abonnement aux Services.
        </ListItem>
        <ListItem>
          &laquo;{" "}
          <strong>
            Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation
          </strong>{" "}
          &raquo; ou &laquo; <strong>CGU</strong> &raquo; : d&eacute;signe les
          conditions contractuelles accept&eacute;es par les Utilisateurs lors
          de leur inscription sur l&rsquo;Application et encadrant leur
          utilisation de l&rsquo;Application.
        </ListItem>
        <ListItem>
          &laquo; <strong>Destinataire autoris&eacute;</strong> &raquo; :
          D&eacute;signe un administrateur, un employ&eacute; ou un
          Sous-traitant ult&eacute;rieur qui a un besoin l&eacute;gitime
          d&rsquo;acc&eacute;der aux Donn&eacute;es personnelles dans le cadre
          de l&rsquo;ex&eacute;cution des Services ou de l&rsquo;Organisation
          interne d&rsquo;ANDREWAPP.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Donn&eacute;es personnelles</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe toute information relative
          &agrave; une personne physique identifi&eacute;e ou qui peut
          &ecirc;tre identifi&eacute;e comme telle, soit directement soit
          indirectement par regroupement d&rsquo;informations, par
          r&eacute;f&eacute;rence &agrave; un num&eacute;ro
          d&rsquo;identification ou &agrave; des &eacute;l&eacute;ments qui lui
          sont propres : nom, adresse, num&eacute;ro de t&eacute;l&eacute;phone,
          adresse IP, adresse email, identifiant/login, mot de passe,
          donn&eacute;es de connexion, etc.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Finalit&eacute; autoris&eacute;e</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe l&rsquo;objet du Traitement de
          Donn&eacute;es personnelles mis en &oelig;uvre par les Parties,
          conform&eacute;ment &agrave; l&rsquo;<em>Appendice 1</em>.
        </ListItem>
        <ListItem>
          &laquo; <strong>Instructions</strong> &raquo; : D&eacute;signe
          l&rsquo;ensemble des instructions &eacute;crites par le Responsable de
          Traitement &agrave; destination du Sous-traitant.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;
          <strong>
            R&egrave;glementation relative &agrave; la protection des
            donn&eacute;es{" "}
          </strong>
          &raquo;&nbsp;: D&eacute;signe la r&eacute;glementation en vigueur
          relative &agrave; la protection des Donn&eacute;es Personnelles et, en
          particulier :
        </ListItem>
        <UnorderedList pl={4}>
          <ListItem>
            le r&egrave;glement (UE) 2016/679 du Parlement europ&eacute;en et du
            Conseil du 27 avril 2016 applicable depuis le 25 mai 2018 dit
            &laquo; R&egrave;glement G&eacute;n&eacute;ral sur la Protection des
            Donn&eacute;es &raquo; ou &laquo;&nbsp;RGPD&nbsp;&raquo; ;
          </ListItem>
          <ListItem>
            la loi &laquo; Informatique et libert&eacute;s &raquo; n&deg;78-17
            du 6 janvier 1978 modifi&eacute;e ;&nbsp;
          </ListItem>
          <ListItem>
            toute l&eacute;gislation entrant en vigueur et susceptible
            d&rsquo;affecter les Traitements vis&eacute;s par le pr&eacute;sent
            Accord ;&nbsp;
          </ListItem>
          <ListItem>
            tout guide de bonnes pratiques et r&eacute;f&eacute;rentiels
            publi&eacute; par les Autorit&eacute;s de r&eacute;gulation (CNIL)
            ou le Comit&eacute; Europ&eacute;en sur la Protection des
            Donn&eacute;es.
          </ListItem>
        </UnorderedList>
        <ListItem>
          &laquo;&nbsp;<strong>Personne concern&eacute;e</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe toute personne physique dont les
          Donn&eacute;es personnelles font l&rsquo;objet d&rsquo;un Traitement.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Responsable de Traitement</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe la personne qui d&eacute;termine
          les moyens et les finalit&eacute;s du Traitement. Lorsqu&rsquo;un
          Traitement conjoint est mis en place par les Parties, celles-ci seront
          regard&eacute;es comme &laquo;&nbsp;
          <strong>Responsables de Traitement conjoints&nbsp;</strong>&raquo;
          d&egrave;s lorsqu&rsquo;elles d&eacute;terminent conjointement les
          moyens et les finalit&eacute;s du Traitement.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Patient&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe le patient auquel le Partenaire propose un suivi
          post-consultation via l&rsquo;Application.
        </ListItem>
        <ListItem>
          &laquo; <strong>Personne concern&eacute;e</strong> &raquo; :
          D&eacute;signe toute personne physique dont les Donn&eacute;es
          personnelles font l&rsquo;objet d&rsquo;un Traitement.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Services&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe l&rsquo;ensemble des fonctionnalit&eacute;s et services
          propos&eacute;s par ANDREWAPP aux Partenaires.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Site&nbsp;&raquo;&nbsp;</strong>: d&eacute;signe
          le site internet d&rsquo;Andrew accessible &agrave; partir de
          l&rsquo;URL{" "}
          <strong>
            <Link
              as={RouterLink}
              variant="raw"
              to="/"
              aria-label="Aller à l'accueil du site Andrew"
            >
              www.andrewapp.fr
            </Link>
          </strong>{" "}
          et &agrave; partir duquel les Partenaires peuvent cr&eacute;er un
          Compte Partenaire et se connecter au Backoffice.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Sous-traitant</strong>&nbsp;&raquo; :
          D&eacute;signe la personne physique ou morale amen&eacute;e &agrave;
          r&eacute;aliser des op&eacute;rations de Traitement pour le compte et
          sur instructions du Responsable de Traitement. L&rsquo;entit&eacute;
          agissant pour le compte et sur intruction du Sous-traitant est
          qualifi&eacute;e de &laquo;{" "}
          <strong>Sous-traitant(s) ult&eacute;rieur(s) </strong>&raquo;.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Traitement</strong>&nbsp;&raquo;&nbsp;:
          D&eacute;signe toute op&eacute;ration ou tout ensemble
          d&apos;op&eacute;rations effectu&eacute;es ou non &agrave; l&apos;aide
          de proc&eacute;d&eacute;s automatis&eacute;s et appliqu&eacute;es
          &agrave; des Donn&eacute;es personnelles ou des ensembles de
          Donn&eacute;es personnelles, telles que la collecte,
          l&apos;enregistrement, l&apos;organisation, la structuration, la
          conservation, l&apos;adaptation ou la modification, l&apos;extraction,
          la consultation, l&apos;utilisation, la communication par
          transmission, la diffusion ou toute autre forme de mise &agrave;
          disposition, le rapprochement ou l&apos;interconnexion, la limitation,
          l&apos;effacement ou la destruction.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;
          <strong>Violation de Donn&eacute;es personnelles</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe une faille de
          s&eacute;curit&eacute; qui entra&icirc;ne accidentellement ou
          illicitement l&rsquo;acc&egrave;s &agrave; ou la destruction, la
          perte, l&rsquo;alt&eacute;ration, la divulgation non autoris&eacute;e
          de Donn&eacute;es Personnelles transmises, stock&eacute;es ou
          trait&eacute;es.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 2 &ndash; DUREE&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le pr&eacute;sent Accord entre en vigueur &agrave; compter de
        l&rsquo;acceptation des Conditions G&eacute;n&eacute;rales de Services
        par le Partenaire et reste applicable pour toute la p&eacute;riode de
        l&rsquo;Abonnement souscrit par le Partenaire.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 3 &ndash; R&Ocirc;LE DES PARTIES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Les Parties conviennent qu&rsquo;ANDREWAPP et le Partenaire auront les
        qualit&eacute;s suivantes&nbsp;:
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Dans le cadre de l&rsquo;ex&eacute;cution des Services, ANDREWAPP est
          amen&eacute;e &agrave; traiter des Donn&eacute;es personnelles pour le
          compte du Partenaire lorsque celui-ci cr&eacute;e une fiche Patient
          pour un Patient ne disposant pas encore de Compte Utilisateur&nbsp;:
          dans ce cas, ANDREWAPP agit en tant que Sous-traitant du Partenaire,
          Responsable du traitement&nbsp;;
        </ListItem>
        <ListItem>
          Dans le cadre de l&rsquo;utilisation de l&rsquo;Application, ANDREWAPP
          est amen&eacute;e &agrave; traiter les Donn&eacute;es personnelles de
          Patients saisies par le Partenaire pour la cr&eacute;ation de
          programmes de contenus personnalis&eacute;s et le suivi de
          l&rsquo;&eacute;volution des sympt&ocirc;mes du Patient : dans ce cas,
          le Partenaire et ANDREWAPP auront la qualit&eacute; de Responsables de
          traitement conjoints.
        </ListItem>
        <ListItem>
          Dans le cadre de la mise &agrave; disposition de l&rsquo;Application
          aupr&egrave;s des Utilisateurs, ANDREWAPP est amen&eacute;e &agrave;
          traiter les Donn&eacute;es personnelles de Patients souhaitant
          acc&eacute;der &agrave; leur Compte utilisateur : dans ce cas,
          ANDREWAPP agit en tant que Responsable du traitement des
          donn&eacute;es en ex&eacute;cution des Conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation accept&eacute;es par
          l&rsquo;Utilisateur lors de son inscription.
        </ListItem>
      </UnorderedList>
      <p>
        <br />
      </p>
      <p>
        <strong>ARTICLE 4 &ndash; CONSIGNES ET CONFORMITE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4.1.</strong> Dans le cadre de la mise en &oelig;uvre des
        op&eacute;rations de traitement, ANDREWAPP et le Partenaire
        s&rsquo;engagent &agrave; :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Respecter la R&egrave;glementation relative &agrave; la protection des
          donn&eacute;es ;
        </ListItem>
        <ListItem>
          Coop&eacute;rer et se conformer aux instructions ou aux
          d&eacute;cisions de toute Autorit&eacute; de r&eacute;gulation ; et
        </ListItem>
        <ListItem>
          Ne pas faire ou omettre de faire ou permettre que quelque chose soit
          fait qui am&egrave;nerait l&rsquo;autre Partie &agrave; enfreindre la
          Loi sur la protection des donn&eacute;es.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>4.2. </strong>En sus des obligations list&eacute;es au 4.1 du
        pr&eacute;sent Accord, lorsqu&rsquo;elle agit en qualit&eacute; de
        Sous-traitant, ANDREWAPP s&rsquo;engage &agrave; :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Traiter uniquement les Donn&eacute;es personnelles n&eacute;cessaires
          aux Finalit&eacute;s autoris&eacute;es conform&eacute;ment aux
          Instructions d&eacute;finies en Appendice 1, y compris en ce qui
          concerne les &eacute;ventuels transferts de Donn&eacute;es
          personnelles vers un pays tiers ou &agrave; une organisation
          internationale, &agrave; moins qu&apos;elle ne soit tenue d&apos;y
          proc&eacute;der en vertu du droit de l&apos;Union ou du droit de
          l&apos;&Eacute;tat membre auquel elle est soumise. Dans ce cas,
          ANDREWAPP informe le Partenaire de cette obligation juridique avant le
          traitement, sauf si le droit concern&eacute; interdit une telle
          information pour des motifs importants d&apos;int&eacute;r&ecirc;t
        </ListItem>
        <ListItem>
          Respecter les Instructions formul&eacute;es par le Responsable de
          Traitement, et s&rsquo;assurer de leur respect par les Destinataires
          autoris&eacute;s ;
        </ListItem>
        <ListItem>
          Ne pas traiter les Donn&eacute;es personnelles &agrave; d&apos;autres
          fins.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 5 &ndash; OBLIGATIONS GENERALES DES PARTIES</strong>
      </p>
      <p>&nbsp;</p>
      <strong>5.1. Sous-traitance</strong>
      <p>&nbsp;</p>
      <p>
        Dans le cadre des op&eacute;rations r&eacute;alis&eacute;es en
        qualit&eacute; de Sous-traitant, ANDREWAPP s&rsquo;engage
        &agrave;&nbsp;:&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Informer le Responsable de Traitement dans les meilleurs d&eacute;lais
          :
        </ListItem>
        <UnorderedList pl={4}>
          <ListItem>
            si des Instructions d&eacute;livr&eacute;es par le Responsable de
            Traitement relatives aux Traitements enfreint la
            R&egrave;glementation sur la protection des donn&eacute;es ;
          </ListItem>
          <ListItem>
            en cas de survenance d&rsquo;une Violation de Donn&eacute;es
            personnelles, ou en cas de survenance d&rsquo;une faille de
            s&eacute;curit&eacute; affectant le syst&egrave;me informatique
            d&rsquo;ANDREWAPP ou d&rsquo;un Sous-traitant ult&eacute;rieur, et
            ce dans les meilleurs d&eacute;lais conform&eacute;ment &agrave; ce
            qui est pr&eacute;vu &agrave; l&rsquo;article 6.4 du pr&eacute;sent
            Accord ;
          </ListItem>
          <ListItem>
            si ANDREWAPP re&ccedil;oit une plainte, un avis ou une communication
            d&rsquo;une Personne concern&eacute;e dans le cadre de
            l&rsquo;exercice de ses droits, ou d&rsquo;une Autorit&eacute; de
            r&eacute;gulation qui concerne directement ou indirectement le
            Traitement ou la conformit&eacute; de l&rsquo;une des Parties
            &agrave; la R&egrave;glementation relative &agrave; la protection
            des donn&eacute;es dans le cadre du Traitement.
          </ListItem>
        </UnorderedList>
      </UnorderedList>
      <UnorderedList pl={4}>
        <ListItem>
          Modifier, transf&eacute;rer et / ou supprimer les Donn&eacute;es
          personnelles d&eacute;tenues par elle ou en son nom par un
          Sous-traitant ult&eacute;rieur, conform&eacute;ment &agrave; toute
          Instruction &eacute;crite du Responsable de Traitement ;
        </ListItem>
        <ListItem>
          Aider le Responsable de Traitement &agrave; respecter les obligations
          &eacute;nonc&eacute;es aux articles 32 &agrave; 36 du RGPD en tenant
          compte de la nature du Traitement et des informations mises &agrave;
          la disposition d&rsquo;ANDREWAPP.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>

      <strong>5.2. Responsabilit&eacute; autonome</strong>
      <p>&nbsp;</p>
      <p>
        <strong>5.2.1.</strong> Dans le cadre des op&eacute;rations
        r&eacute;alis&eacute;es en qualit&eacute; de Responsables du traitement
        autonomes, les Parties s&rsquo;engagent &agrave; respecter les
        obligations impos&eacute;es par la R&egrave;glementation relative
        &agrave; la protection des donn&eacute;es personnelles, et seront
        individuelle responsable de toute non-conformit&eacute; que leur serait
        imputable.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.2.2. </strong>Par ailleurs, les Parties s&rsquo;engagent
        &agrave; partager les Donn&eacute;es personnelles selon les
        modalit&eacute;s de s&eacute;curit&eacute; mentionn&eacute;es en
        Appendice 2.&nbsp;
      </p>
      <p>&nbsp;</p>
      <strong>5.3. Responsabilit&eacute; Conjointe</strong>
      <p>&nbsp;</p>
      <p>
        <strong>5.3.1. </strong>Dans le cadre des op&eacute;rations
        r&eacute;alis&eacute;es en qualit&eacute; de Responsables de traitement
        conjoints, les Parties s&rsquo;engagent conform&eacute;ment &agrave;
        l&rsquo;article 26 du RGPD, &agrave; :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Respecter le principe de lic&eacute;it&eacute; du traitement
          pr&eacute;vu &agrave; l&rsquo;article 6 du RGPD ;
        </ListItem>
        <ListItem>
          Traiter les Donn&eacute;es personnelles uniquement pour les seules
          finalit&eacute;s des traitements renseign&eacute;s en Appendice 1 ;
        </ListItem>
        <ListItem>
          Garantir la confidentialit&eacute; des Donn&eacute;es personnelles
          trait&eacute;es dans le cadre du pr&eacute;sent Accord ;
        </ListItem>
        <ListItem>
          Prendre en compte, s&rsquo;agissant des outils, produits, applications
          ou services utilis&eacute;s, les principes de protection des
          donn&eacute;es d&egrave;s la conception et de protection des
          donn&eacute;es par d&eacute;faut ;
        </ListItem>
        <ListItem>
          Informer les Personnes concern&eacute;es conform&eacute;ment aux
          articles 13 et 14 du RGPD ;
        </ListItem>
        <ListItem>
          Mettre &agrave; disposition des Personnes concern&eacute;es les
          grandes lignes du pr&eacute;sent Accord &agrave; premi&egrave;re
          demande suivant le mod&egrave;le propos&eacute; ci-dessous :
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <Box border="1px" borderColor="black" p={2}>
        Les traitements de donn&eacute;es r&eacute;alis&eacute;s dans le cadre
        de cr&eacute;ation de votre parcours d&rsquo;exercices
        personnalis&eacute;s et du suivi de l&rsquo;&eacute;volution de vos
        sympt&ocirc;mes ont fait l&rsquo;objet d&rsquo;un contrat entre votre
        th&eacute;rapeute et ANDREWAPP, &eacute;diteur de l&rsquo;application
        Andrew. Votre th&eacute;rapeute et ANDREWAPP sont co-responsables du
        traitement au sens de l&rsquo;article 26 du R&egrave;glement
        G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es du 27 avril
        2016 entr&eacute; en vigueur le 25 mai 2018. Ils se sont ainsi
        conjointement engag&eacute;s &agrave; vous informer de la nature de ces
        traitements et des droits dont vous disposez, ainsi qu&rsquo;&agrave;
        mettre en &oelig;uvre les mesures organisationnelles et techniques
        appropri&eacute;es pour assurer la s&eacute;curit&eacute; et la
        confidentialit&eacute; de vos donn&eacute;es. Le d&eacute;tail des
        caract&eacute;ristiques de ces traitements est port&eacute; &agrave;
        votre connaissance au sein de la Politique de confidentialit&eacute;
        disponible depuis votre application Andrew.
      </Box>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          S&rsquo;informer dans les plus brefs d&eacute;lais, dans la mesure
          permise par la loi, en cas de contr&ocirc;les, d&rsquo;inspections ou
          d&rsquo;autres mesures engag&eacute;es par une Autorit&eacute; de
          r&eacute;gulation ou organisation gouvernementale, sauf lorsque ladite
          information est interdite par une disposition l&eacute;gislative ou
          r&eacute;glementaire, ou par une d&eacute;cision d&rsquo;une
          autorit&eacute; comp&eacute;tente ;
        </ListItem>
        <ListItem>
          Identifier et documenter tous les Traitements de Donn&eacute;es
          personnelles mis en &oelig;uvre pour &ecirc;tre capables &agrave; tout
          moment de d&eacute;montrer leur conformit&eacute; et les fondements
          l&eacute;gaux appropri&eacute;s de leurs traitements ;
        </ListItem>
        <ListItem>
          S&rsquo;abstenir de toute action susceptible d&rsquo;affecter la
          capacit&eacute; de l&rsquo;autre Partie &agrave; d&eacute;montrer le
          respect des obligations lui incombant en application de la
          R&egrave;glementation relative &agrave; la protection des
          donn&eacute;es ;
        </ListItem>
        <ListItem>
          Ne faire aucune d&eacute;claration &agrave; un tiers ou annonce
          publique relative au pr&eacute;sent Accord, y compris &agrave; une
          autorit&eacute; comp&eacute;tente, sans avoir, au pr&eacute;alable,
          consult&eacute; l&rsquo;autre Partie concernant le contenu d&rsquo;une
          telle d&eacute;claration ou annonce publique sauf disposition
          express&eacute;ment contraire pr&eacute;vue par le droit d&rsquo;un
          Etat membre ou pays tiers ;
        </ListItem>
        <ListItem>
          D&rsquo;une mani&egrave;re g&eacute;n&eacute;rale, ne traiter,
          collecter, utiliser et transf&eacute;rer des Donn&eacute;es
          personnelles que dans le respect la Loi sur la protection des
          donn&eacute;es.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 6 &ndash; SECURITE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>6.1. </strong>Dans le cadre de l&rsquo;ex&eacute;cution des
        Services ANDREWAPP&nbsp;:&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          S&rsquo;assure que des mesures techniques et organisationnelles
          appropri&eacute;es ont &eacute;t&eacute; mises en place au sein de
          l&rsquo;Application pour garantir un niveau de s&eacute;curit&eacute;
          adapt&eacute; au risque ;
        </ListItem>
        <ListItem>
          Mettre en &oelig;uvre et maintenir &agrave; jour les mesures de
          s&eacute;curit&eacute; conformes &agrave; l&rsquo;&eacute;tat de
          l&rsquo;art eu &eacute;gard aux risques g&eacute;n&eacute;r&eacute;s
          par les Traitements, l&rsquo;&eacute;tat de l&rsquo;art &eacute;tant
          notamment appr&eacute;ci&eacute; au regard des attentes en
          mati&egrave;re de s&eacute;curit&eacute; formul&eacute;es par
          l&rsquo;Autorit&eacute; de r&eacute;gulation, par l&rsquo;Agence
          nationale de s&eacute;curit&eacute; des syst&egrave;mes
          d&rsquo;information (l&rsquo;ANSSI) et par l&rsquo;Agence de
          l&rsquo;Union europ&eacute;enne pour la cybers&eacute;curit&eacute;
          (l&rsquo;Enisa) ; et
        </ListItem>
        <ListItem>
          S&apos;assurer que les Destinataires autoris&eacute;s mettent en
          &oelig;uvre et maintiennent &agrave; jour des mesures de
          s&eacute;curit&eacute; conformes &agrave; la R&egrave;glementation sur
          la protection des donn&eacute;es&nbsp;;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>6.2. </strong>En sus des obligations list&eacute;es &agrave;
        l&rsquo;article 6.1 du pr&eacute;sent Accord, lorsqu&rsquo;elle agit en
        qualit&eacute; de Sous-traitant, ANDREWAPP s&rsquo;engage &agrave; se
        conformer dans des d&eacute;lais raisonnables tenant compte de la
        complexit&eacute; de l&rsquo;op&eacute;ration et de la
        disponibilit&eacute; de ses &eacute;quipes, &agrave; toute demande
        raisonnable du Partenaire en ce qui concerne la s&eacute;curit&eacute;
        et le Traitement des Donn&eacute;es personnelles.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>6.3. </strong>En cas de survenance d&rsquo;une Violation de
        Donn&eacute;es personnelles qualifi&eacute;e affectant les Traitements
        mis en &oelig;uvre par ANDREWAPP en sa qualit&eacute; de Sous-traitant
        ou par un Sous-traitant ult&eacute;rieur, ANDREWAPP s&rsquo;engage
        &agrave; :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Notifier au Partenaire toute faille de s&eacute;curit&eacute; pouvant
          entra&icirc;ner une Violation de Donn&eacute;es personnelles dans les
          meilleurs d&eacute;lais, et au plus tard dans les 72 heures
          apr&egrave;s en avoir pris connaissance, en adressant un courrier
          &eacute;lectronique&nbsp;&agrave; l&rsquo;adresse renseign&eacute;e
          par ses soins lors de son inscription ;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>Cette notification comprendra :&nbsp;</ListItem>
        <UnorderedList pl={4}>
          <ListItem>
            La description de la nature de la Violation de Donn&eacute;es
            personnelles y compris, si possible, les cat&eacute;gories et le
            nombre approximatif de personnes concern&eacute;es par la violation
            et les cat&eacute;gories et le nombre approximatif
            d&apos;enregistrements de Donn&eacute;es personnelles
            concern&eacute;es ;
          </ListItem>
          <ListItem>
            Le cas &eacute;ch&eacute;ant, le nom et les coordonn&eacute;es du
            d&eacute;l&eacute;gu&eacute; &agrave; la protection des
            donn&eacute;es et/ou d&apos;un autre point de contact aupr&egrave;s
            duquel des informations suppl&eacute;mentaires peuvent &ecirc;tre
            obtenues ;
          </ListItem>
          <ListItem>
            La description des cons&eacute;quences probables de la Violation de
            Donn&eacute;es personnelles ; et
          </ListItem>
          <ListItem>
            La description des mesures prises ou envisag&eacute;es pour
            rem&eacute;dier &agrave; la Violation de Donn&eacute;es
            personnelles, y compris, le cas &eacute;ch&eacute;ant, les mesures
            pour en att&eacute;nuer les &eacute;ventuelles cons&eacute;quences
            n&eacute;gatives.&nbsp;
          </ListItem>
        </UnorderedList>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>
          ARTICLE 8 &ndash; DESTINATAIRES DES DONNEES PERSONNELLES
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.1.</strong> Les Parties garantissent mutuellement
        qu&rsquo;elles :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Restreignent l&apos;acc&egrave;s aux Donn&eacute;es personnelles aux
          seuls Destinataires autoris&eacute;s ayant besoin d&apos;avoir
          acc&egrave;s aux Donn&eacute;es personnelles ou d&rsquo;en
          conna&icirc;tre. Dans le cas d&apos;un acc&egrave;s aux Donn&eacute;es
          personnelles par un de ses employ&eacute;s, la Partie concern&eacute;e
          s&rsquo;assure que cet acc&egrave;s est strictement limit&eacute;
          &agrave; l&apos;ex&eacute;cution des t&acirc;ches par cet
          employ&eacute;&nbsp;;
        </ListItem>
        <ListItem>
          Imposent aux Destinataires autoris&eacute;s des obligations de
          confidentialit&eacute; et de s&eacute;curit&eacute;
          &eacute;quivalentes &agrave; celles contenues dans le pr&eacute;sent
          Accord ;&nbsp;
        </ListItem>
        <ListItem>
          Mettent tous les moyens en &oelig;uvre pour s&apos;assurer que les
          Sous-traitants ult&eacute;rieurs respectent la R&egrave;glementation
          relative &agrave; la protection des donn&eacute;es ;
        </ListItem>
        <ListItem>
          Prennent des mesures raisonnables pour assurer la fiabilit&eacute; de
          tous les Destinataires autoris&eacute;s ayant acc&egrave;s aux
          Donn&eacute;es personnelles ; et
        </ListItem>
        <ListItem>
          Ne divulguent aucune Donn&eacute;e personnelle &agrave; une autre
          personne sans en avoir pr&eacute;alablement inform&eacute;
          l&rsquo;autre.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>8.2. </strong>Dans le cadre des Traitements
        r&eacute;alis&eacute;s en sa qualit&eacute; de Sous-traitant, ANDREWAPP
        est express&eacute;ment autoris&eacute;e par le Partenaire &agrave;
        recourir un ou plusieurs Sous-traitants ult&eacute;rieurs pour traiter
        les Donn&eacute;es personnelles :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Apr&egrave;s avoir pr&eacute;alablement inform&eacute; le Responsable
          de Traitement en Appendice 1 des pr&eacute;sentes de
          l&rsquo;identit&eacute; du Sous-traitant ult&eacute;rieur, des
          activit&eacute;s de Traitement qui seront entreprises par lui et le
          cas &eacute;ch&eacute;ant d&rsquo;un transfert de donn&eacute;es en
          dehors de l&rsquo;Espace Economique Europ&eacute;en. En cas
          d&rsquo;ajout ou de remplacement d&rsquo;un Sous-traitant
          ult&eacute;rieur, ANDREWAPP en informera le Responsable de Traitement
          qui dispose d&rsquo;un d&eacute;lai de dix (10) jours &agrave; compter
          de la date de r&eacute;ception de cette information pour
          pr&eacute;senter, le cas &eacute;ch&eacute;ant, ses objections. A
          d&eacute;faut d&rsquo;objection du Responsable de Traitement dans ce
          d&eacute;lai, la sous-traitance sera consid&eacute;r&eacute;e comme
          accept&eacute;e par le Responsable de Traitement ;
        </ListItem>
      </UnorderedList>
      <p>
        <br />
      </p>
      <p>
        <strong>
          ARTICLE 9 &ndash; INFORMATION ET DROITS DES PERSONNES CONCERNEES
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9.1. </strong>Dans le cadre de la mise en &oelig;uvre des
        Services, les Parties conviennent qu&rsquo;ANDREWAPP informe les
        Personnes concern&eacute;es des op&eacute;rations de Traitement au
        travers de la Politique de confidentialit&eacute; accessibles au sein de
        l&rsquo;Application.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9.2.</strong> Lorsque qu&rsquo;ANDREWAPP re&ccedil;oit une
        demande d&rsquo;exercice de droits concernant les Traitements
        effectu&eacute;s pour le compte du Partenaire en qualit&eacute; de
        Sous-traitant, les Parties conviennent que :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>ANDREWAPP agira sur Instruction du Partenaire ;</ListItem>
        <ListItem>
          Coop&eacute;re avec le Partenaire afin de lui permettre de
          r&eacute;pondre dans les d&eacute;lais r&egrave;glementaires&nbsp;;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>9.3.</strong> S&rsquo;agissant des Traitements de donn&eacute;es
        op&eacute;r&eacute;s par les Parties en qualit&eacute; de Responsables
        de traitement conjoint, ANDREWAPP est d&eacute;sign&eacute; comme
        principal point de contact pour les Personnes concern&eacute;es au sein
        de la Politique de confidentialit&eacute; accessible au sein de
        l&rsquo;Application&nbsp;;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9.4.</strong> Lorsqu&rsquo;une demande d&rsquo;exercice de
        droits concerne les Traitements effectu&eacute;s par les Parties en leur
        qualit&eacute; de Responsables du traitement conjoints, les Parties
        conviennent que :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Chaque Partie avise l&rsquo;autre dans les meilleurs d&eacute;lais,
          par courrier &eacute;lectronique dans un d&eacute;lai de cinq (5)
          jours ouvr&eacute;s :
        </ListItem>
        <UnorderedList pl={4}>
          <ListItem>
            Pour le Partenaire&nbsp;: &agrave; l&rsquo;adresse
            l&rsquo;&eacute;lectronique renseign&eacute;e lors de son
            inscription&nbsp;;
          </ListItem>
          <ListItem>
            Pour ANDREWAPP&nbsp;: &agrave; l&rsquo;adresse &eacute;lectronique
            mentionn&eacute;e en Appendice 1&nbsp;;
          </ListItem>
        </UnorderedList>
        <ListItem>
          Chaque Partie informera l&rsquo;autre Partie de l&rsquo;avanc&eacute;e
          du processus de traitement de la demande d&rsquo;exercice de
          droit.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>
        <br />
      </p>
      <p>
        <strong>
          ARTICLE 10 &ndash; TRANSFERT DE DONNEES EN DEHORS DE L&rsquo;UNION
          EUROPEENNE
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Lorsque les Parties agissent en qualit&eacute; de Responsables du
        traitement conjoints ou de Sous-traitant, elles s&rsquo;engagent
        &agrave; ne pas transf&eacute;rer les Donn&eacute;es personnelles en
        dehors de l&rsquo;Union europ&eacute;enne.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 11 &ndash; RESPONSABILITE&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Chaque Partie est responsable des dommages directs subis par
        l&rsquo;autre et d&eacute;coulant de la violation par elle, ses
        employ&eacute;s, ses repr&eacute;sentants, et, le cas
        &eacute;ch&eacute;ant, ses Sous-traitants ult&eacute;rieurs (y compris
        les Destinataires autoris&eacute;s) de ses obligations en vertu du
        pr&eacute;sent Accord.
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre, chaque Partie s&rsquo;engage &agrave; mettre en &oelig;uvre
        les moyens n&eacute;cessaires et raisonnables pour assurer la
        s&eacute;curit&eacute; des traitements, et sera d&egrave;s lors
        responsable des dommages li&eacute;s &agrave; une d&eacute;faillance de
        s&eacute;curit&eacute; des Donn&eacute;es personnelles qui lui serait
        imputable.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>ARTICLE 12 &ndash; AUDIT ET CONTR&Ocirc;LE&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>12.1.</strong> ANDREWAPP met &agrave; la disposition du
        Responsable du traitement les informations n&eacute;cessaires pour
        d&eacute;montrer le respect des obligations pr&eacute;vues au
        pr&eacute;sent Accord et pour permettre, le cas &eacute;ch&eacute;ant,
        la r&eacute;alisation d&rsquo;audits&nbsp;;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>12.2.</strong> Le Partenaire peut commander la
        r&eacute;alisation d&rsquo;audits objectifs de conformit&eacute;
        &agrave; la R&egrave;glement sur la protection des donn&eacute;es sur
        les op&eacute;rations de Traitement r&eacute;alis&eacute;es par
        ANDREWAPP en sa qualit&eacute; de Sous-traitant aux fins de
        l&rsquo;ex&eacute;cution des Services dans les conditions
        d&eacute;finies ci-apr&egrave;s :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          L&rsquo;audit est diligent&eacute; par un auditeur ext&eacute;rieur
          s&eacute;lectionn&eacute; par les Parties pour son expertise, son
          ind&eacute;pendance et son impartialit&eacute; et qui n&rsquo;est, en
          tout &eacute;tat de cause, pas un concurrent d&rsquo;ANDREWAPP ;
        </ListItem>
        <ListItem>
          Les &eacute;l&eacute;ments confidentiels confi&eacute;s &agrave;
          ANDREWAPP par d&rsquo;autres contractants, ou tout autre
          &eacute;l&eacute;ment prot&eacute;g&eacute; par le secret des affaires
          sera exclu du p&eacute;rim&egrave;tre de l&rsquo;audit&nbsp;;&nbsp;
        </ListItem>
        <ListItem>
          L&rsquo;auditeur s&eacute;lectionn&eacute; est li&eacute; aux Parties
          par un accord de confidentialit&eacute; et/ou par le secret
          professionnel ;
        </ListItem>
        <ListItem>
          Le Partenaire avise, par &eacute;crit et moyennant le respect
          d&rsquo;un pr&eacute;avis minimum de trente (30) jours ouvr&eacute;s,
          ANDREWAPP de son intention de faire proc&eacute;der &agrave; un audit
          de conformit&eacute; ;
        </ListItem>
        <ListItem>
          En aucune mani&egrave;re, l&rsquo;audit r&eacute;alis&eacute; ne
          saurait d&eacute;t&eacute;riorer ou ralentir les Services
          propos&eacute;s par OWTEOW ou porter atteinte &agrave; sa gestion
          organisationnelle ;
        </ListItem>
        <ListItem>
          Un exemplaire du rapport d&rsquo;audit identique est remis au
          Partenaire ainsi qu&rsquo;&agrave; ANDREWAPP des suites de la
          r&eacute;alisation de la mission d&rsquo;audit et pour lequel des
          observations pourront &ecirc;tre apport&eacute;es par les Parties ;
        </ListItem>
        <ListItem>
          Les frais de l&rsquo;audit de conformit&eacute; seront &agrave; la
          charge du Partenaire ;
        </ListItem>
        <ListItem>
          Le Partenaire ne saurait commander des audits de conformit&eacute; que
          dans la limite d&rsquo;un (1) audit par an ; et
        </ListItem>
        <ListItem>
          ANDREWAPP disposera d&rsquo;un d&eacute;lai de trois (3) mois &agrave;
          compter de la communication du rapport d&rsquo;audit pour corriger
          &agrave; ses frais les manquements et/ou non-conformit&eacute;s
          constat&eacute;s.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>12.3.</strong> Dans l&rsquo;hypoth&egrave;se d&rsquo;un
        contr&ocirc;le r&eacute;alis&eacute; par une Autorit&eacute; de
        r&eacute;gulation comp&eacute;tente pouvant int&eacute;resser les
        Traitements r&eacute;alis&eacute;s sur instruction du Partenaire,
        ANDREWAPP, en tant que Sous-traitant s&rsquo;engage &agrave;
        coop&eacute;rer pleinement avec l&rsquo;Autorit&eacute; de
        r&eacute;gulation.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 13 &ndash; SORT DES DONNEES&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de r&eacute;siliation de l&rsquo;Abonnement, le sort des
        Donn&eacute;es personnelles des Patients est d&eacute;taill&eacute; au
        sein des Conditions G&eacute;n&eacute;rales de Services.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 14 &ndash; DISPOSITIONS GENERALES&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <u>
        <strong>14.1. Modification de l&rsquo;Accord</strong>
      </u>

      <p>&nbsp;</p>
      <p>
        Cet Accord ne peut &ecirc;tre modifi&eacute; que par &eacute;crit
        sign&eacute; par les repr&eacute;sentants d&ucirc;ment autoris&eacute;s
        de chacune des Parties.
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de modification de la R&egrave;glementation sur la protection des
        donn&eacute;es, il est convenu que les Parties pourront r&eacute;viser
        les dispositions du pr&eacute;sent Accord et n&eacute;gocier de bonne
        foi pour s&rsquo;y conformer.
      </p>
      <p>&nbsp;</p>
      <u>
        <strong>14.2. Droit applicable et juridiction</strong>
      </u>
      <p>&nbsp;</p>
      <p>
        LES PRESENTES CGS AINSI QUE LES RELATIONS ENTRE LES PARTIES SONT REGIES
        PAR LE DROIT FRAN&Ccedil;AIS.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        EN CAS DE DIFFEREND SURVENANT ENTRE LES PARTIES AU SUJET DE
        L&rsquo;INTERPRETATION, DE L&rsquo;EXECUTION OU DE LA RESILIATION DES
        PRESENTES, LES PARTIES S&rsquo;EFFORCERONT DE LE REGLER A
        L&rsquo;AMIABLE.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        DANS L&rsquo;HYPOTHESE OU LA TENTATIVE DE RESOLUTION AMIABLE ECHOUERAIT
        OU NE SERAIT PAS ENVISAGEE, LE LITIGE SERA CONFIE AU TRIBUNAL
        COMPETENT.&nbsp;
      </p>
    </Flex>
  )
}

import { MediaDifficutType } from "../api/media"

export default function getDifficulty(code: MediaDifficutType): string {
  switch (code) {
    case "easy":
      return "Débutant"
    case "normal":
      return "Intermédiaire"
    case "hard":
      return "Confirmé"
    default:
      return "Normal"
  }
}

import { useDisclosure } from "@chakra-ui/react"
import { useEffect, useMemo } from "react"

// CONSTANTS
const COOPTION_POPUP_STORAGE_KEY = "cooptionPopup"
const NEW_FEATURES_POPUP_PREFIX = "newFeatures"
// HELPERS

const toLowerCase = (word: string) => {
  return word
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toLowerCase())
}

const storePopupNewFeaturesDate = (modalName: string) => {
  const camelCaseName = toLowerCase(modalName)

  localStorage.setItem(camelCaseName, new Date().toJSON())
}

const isANewModal = (modalName: string) => {
  const existingModal = localStorage.getItem(modalName)
  if (!existingModal) return true
  return false
}

// HOOKS
const useNewFeaturesModalDisclosure = (
  modalName: string | undefined,
  isDisplayed: boolean,
  isOtherDialogOpen?: boolean
) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const lastCooptionPopupDate = useMemo(
    () => localStorage.getItem(COOPTION_POPUP_STORAGE_KEY),
    []
  )

  const fullName = toLowerCase(`${NEW_FEATURES_POPUP_PREFIX}${modalName}`)

  const hasAlreadyBeenDisplayedCooptionModal = lastCooptionPopupDate !== null

  const canOpenNewFeaturesModal =
    fullName === undefined ? false : isANewModal(fullName) && isDisplayed

  const canOpenModal =
    hasAlreadyBeenDisplayedCooptionModal && canOpenNewFeaturesModal

  useEffect(() => {
    if (!isOtherDialogOpen && canOpenModal) {
      onOpen()
      if (fullName !== undefined) {
        storePopupNewFeaturesDate(fullName)
      }
    }
  }, [isOtherDialogOpen, canOpenModal, onOpen, fullName])

  return { isOpen, onClose }
}

export default useNewFeaturesModalDisclosure

export const registerSuccessful = () => {
  if (process.env.REACT_APP_TRACKING)
    window.fbq("track", "CompleteRegistration")
}

export const subscribeStart = () => {
  if (process.env.REACT_APP_TRACKING) window.fbq("track", "StartSubscribtion")
}

export const subscribeSuccessful = () => {
  if (process.env.REACT_APP_TRACKING)
    window.fbq("track", "CompleteSubscribtion")
}

export const pageView = () => {
  if (process.env.REACT_APP_TRACKING) window.fbq("track", "PageView")
}

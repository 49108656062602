import { Heading, Text } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import getUtcDate from "utils/getUtcDate"
import { PatientType } from "../../../api/patient"
import ABox from "../../../components/ABox"

interface IProps {
  patient: PatientType | undefined
}

const LastAppointment: React.FC<IProps> = ({ patient }) => {
  const lastAppointment =
    patient && patient.appointments && patient.appointments.length > 0
      ? patient.appointments[patient.appointments.length - 1]
      : undefined
  return (
    <ABox flex={1}>
      <Heading fontSize={18} mb={4}>
        Dernière consultation
      </Heading>
      <Text mb={4}>
        {lastAppointment
          ? format(getUtcDate(lastAppointment.meetingDate), "dd/MM/yyyy")
          : "Pas de consultation disponible"}
      </Text>
    </ABox>
  )
}

export default LastAppointment

import { useContext } from "react"
import PropTypes from "prop-types"
import RgpdContext from "./RgpdContext"

const Rgpd = ({ children, serviceId, noPending, disable }) => {
  const { getServiceById } = useContext(RgpdContext)
  const service = getServiceById(serviceId)

  if (disable) return null

  if (service.status === "allowed") return children

  if (noPending) return null
}

Rgpd.propTypes = {
  serviceId: PropTypes.string.isRequired,
  noPending: PropTypes.bool,
  disable: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

Rgpd.defaultProps = {
  noPending: false,
  disable: false,
}

export default Rgpd

import React from "react"
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
} from "@chakra-ui/react"
import useToast from "../../hooks/useToast"
import * as Yup from "yup"
import AButton from "../../components/AButton"
import { forgotPassword } from "../../api/user"
import { useMutation } from "@tanstack/react-query"
import { Field, Form, Formik } from "formik"
import Cover from "../../assets/andrew-cover.png"
import { useNavigate } from "react-router-dom"
import ALogo from "../../components/ALogo"

interface MyFormValues {
  email: string
}

const ForgotPassword = () => {
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const forgotPasswordMutation = useMutation(forgotPassword)
  const toast = useToast()
  const navigate = useNavigate()
  const initialValues: MyFormValues = { email: "" }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Ceci n'est pas un email")
          .required("Ce champ est obligatoire"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { email } = values

        forgotPasswordMutation.mutate(
          { email },
          {
            onSuccess: () => {
              setSubmitting(false)
              setFormSubmitted(true)
            },
            onError: () => {
              setSubmitting(false)
              toast({
                status: "error",
                title: "Une erreur s'est produite",
              })
            },
          }
        )
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Flex alignItems="center">
            <Flex
              flex={1}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Flex w="67%" flexDirection="column" alignItems="center">
                <ALogo />
                {formSubmitted ? (
                  <Heading
                    as="h1"
                    mb={238}
                    maxW={440}
                    textAlign="center"
                    color="tertiary.500"
                    fontSize={22}
                  >
                    Un mail de réinitialisation vous a bien été envoyé
                  </Heading>
                ) : (
                  <div>
                    <Heading
                      as="h1"
                      mb={12}
                      maxW={440}
                      textAlign="center"
                      color="tertiary.500"
                      fontSize={22}
                    >
                      Renseignez votre adresse mail pour réinitialiser votre mot
                      de passe
                    </Heading>
                    <Box w="100%" mb={5}>
                      <Field name="email">
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            isInvalid={form.errors.email && form.touched.email}
                          >
                            <FormLabel mb="8px" fontSize={14}>
                              Adresse mail
                            </FormLabel>
                            <Input
                              {...field}
                              placeholder="Email"
                              bg="white"
                              type="text"
                            />
                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </div>
                )}
              </Flex>

              {formSubmitted ? (
                <AButton
                  text="Retour à la page de connexion"
                  px={109}
                  py={3}
                  isLoading={isSubmitting}
                  onClick={() => navigate("/login")}
                />
              ) : (
                <AButton
                  type="submit"
                  text="Réinitialiser le mot de passe"
                  px={109}
                  py={3}
                  mt={152}
                  isLoading={isSubmitting}
                />
              )}
            </Flex>
            <Box flex={1}>
              <Image
                src={Cover}
                alt="Andrew"
                w="100%"
                h="100vh"
                objectFit="cover"
              />
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default ForgotPassword

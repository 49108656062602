import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { getStripeConnectLoginLink } from "api/teleconsultation"
import React from "react"

interface IProps {
  accountId: string
}

const StripeConnectLoginLink: React.FC<IProps> = ({ accountId }) => {
  const { data } = useQuery({
    queryKey: ["stripeConnectLoginLink", accountId],
    queryFn: () => getStripeConnectLoginLink(accountId),
  })
  if (!data?.url) return null
  return (
    <Flex
      direction="column"
      flex={1}
      bg="white"
      borderRadius={9}
      p={8}
      mr={6}
      gap="16px"
    >
      <Flex flexDirection="column" gap={4}>
        <Heading fontSize="22px">Votre profil stripe connect</Heading>
        <Text>
          Vous pouvez vous connecter à votre compte Stripe en cliquant sur le
          bouton ci-dessous.
        </Text>
        <Text>
          Cela vous permettra de visualiser vos transactions et de gérer vos
          paiements liés aux téléconsultations.
        </Text>
        <a
          href={data!.url}
          target="_blank"
          rel="noreferrer"
          style={{ display: "block" }}
        >
          <Box
            bg="primary.800"
            color="white"
            borderColor="primary.500"
            border="1px solid"
            borderRadius="52px"
            w="fit-content"
            px={4}
            py={2}
            fontWeight="bold"
            _hover={{
              bg: "primary.700",
              color: "white",
              borderColor: "primary.400",
            }}
            _focus={{
              bg: "primary.700",
              color: "white",
              borderColor: "primary.400",
            }}
          >
            Se connecter
          </Box>
        </a>
      </Flex>
    </Flex>
  )
}

export default StripeConnectLoginLink

import {
  Container,
  Flex,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react"
import { useMutation } from "@tanstack/react-query"
import { createCooption } from "api/cooption"
import AndrewHero from "assets/illustrations/andrew-superheros.png"
import ASubmitButton from "components/ASubmitButton"
import AInputField from "components/Field/AInputField"
import { Form, Formik, FormikHelpers } from "formik"
import useToast from "hooks/useToast"
import * as Yup from "yup"

// CONSTANTS
const INITIAL_VALUES = {
  email: "",
}

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email("Ceci n'est pas un email").required("Email requis"),
})

type CooptValues = {
  email: string
}

// COMPONENTS
const Cooption = () => {
  const createCooptionMutation = useMutation(createCooption)
  const toast = useToast()

  const onSubmit = (
    { email }: CooptValues,
    { setSubmitting }: FormikHelpers<CooptValues>
  ) => {
    createCooptionMutation.mutate(email, {
      onSuccess: () => {
        toast({
          status: "success",
          title: "Parrainage envoyé",
        })
      },
      onError: (error: any) => {
        const isAlreadyExistsError = error?.response?.status === 400
        toast({
          status: "error",
          title: isAlreadyExistsError
            ? `Ce mail est déjà associé à un compte existant`
            : "Une erreur s'est produite",
        })
      },
      onSettled: () => {
        setSubmitting(false)
      },
    })
  }

  return (
    <Container minH="100vh" p={0}>
      <Heading
        as="h1"
        color={"tertiary.500"}
        fontSize={28}
        fontWeight={700}
        mt={10}
        mb={12}
        textAlign="center"
      >
        Parrainer un confrère
      </Heading>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        <Form>
          <Flex direction="column" gap={12} alignItems="center">
            <AInputField
              name="email"
              label={<FormLabel fontSize={14}>Mail *</FormLabel>}
              helperText={
                <FormHelperText>
                  Un mail sera envoyé à cette adresse avec un lien de
                  parrainage.
                </FormHelperText>
              }
              placeholder="Mail"
              bg="common.100"
            />
            <Flex
              direction="column"
              width="100%"
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              <ASubmitButton
                text="Parrainer"
                py={3}
                px={28}
                isLoading={createCooptionMutation.isLoading}
              />
              <Text
                maxW={416}
                fontSize={16}
                textAlign="center"
                fontWeight={700}
              >
                Une fois l'abonnement de votre ami facturé, vous recevrez 1 mois
                d'abonnement gratuit à l'application Andrew® Pro
              </Text>
              <Image
                src={AndrewHero}
                position="absolute"
                right={-6}
                w={87}
                h={107}
                alt="Andrew Super héros"
              />
            </Flex>
          </Flex>
        </Form>
      </Formik>
    </Container>
  )
}

export default Cooption

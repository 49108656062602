import React from "react"
import { SVGProps } from "../type"

const EntorseCheville: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M22.5 4.5C21.3 6.1 9.667 7.5 4 8c1.6 6.4 0 15.333-1 19l23-2c-.5-1.5-1.6-5.1-2-7.5-.4-2.4-1.167-9.667-1.5-13z"
      ></path>
    </svg>
  )
}

export default EntorseCheville

import React from "react"
import { Box, Flex, Link, Text } from "@chakra-ui/react"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { PatientAppointmentType } from "api/patient"
import getUtcDate from "utils/getUtcDate"
import { format } from "date-fns"

interface ScrollableSelectTabProps {
  tabs: PatientAppointmentType[]
  otherProps?: {
    patientId: number
    treatmentId: number
  }
}

const ScrollableSelectTab = ({
  tabs,
  otherProps,
}: ScrollableSelectTabProps) => {
  const location = useLocation()

  return (
    <Box
      maxWidth={"800px"}
      overflowX="auto"
      whiteSpace="nowrap"
      my={4}
      sx={{
        "&::-webkit-scrollbar": {
          height: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "primary.300",
          borderRadius: "24px",
        },
      }}
      pb={2}
    >
      <Flex gap={"24px"}>
        {tabs.map((tab) => {
          const isSelected = location.pathname.includes(
            `/appointment/${tab.id}`
          )
          return (
            <Link
              key={tab.id}
              as={RouterLink}
              to={`/patients/${otherProps?.patientId}/treatment/${otherProps?.treatmentId}/appointment/${tab.id}`}
              color="gray.800"
              borderBottom={isSelected ? "2px solid" : "2px solid transparent"}
              borderBottomColor={isSelected ? "primary.600" : "transparent"}
              _hover={{
                textDecoration: "none",
                borderBottomColor: "primary.400",
              }}
            >
              <Text fontSize="16px" fontWeight={isSelected ? "bold" : "medium"}>
                {format(getUtcDate(tab.meetingDate || ""), "dd/MM/yyyy")}
              </Text>
            </Link>
          )
        })}
      </Flex>
    </Box>
  )
}

export default ScrollableSelectTab

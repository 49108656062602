import { Heading, Text } from "@chakra-ui/react"
import AButton from "components/AButton"
import {
  StripeProductType,
  updateCenterGroupSubscription,
} from "api/subscriptions"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AdminCenterCurrentScope, useAppContext } from "AppContext"
import { useEffect, useRef, useState } from "react"

import useToast from "hooks/useToast"
import { MIN_SEATS } from "constants/subscription"
import {
  AdminCenterGroup,
  getCenterGroup,
  getCenterGroupOsteos,
} from "api/centerGroup"
import { centerGroupQueryKeys } from "pages/Center/constants/queryKeys"
import collaboratorsQueryKeys from "constants/queryKeys/collaborators"
import useCenterIsTester from "hooks/useCenterIsTester"

import getSubPricePerPeriodPerSeat from "utils/price/getSubPricePerPeriodPerSeat"
import { DEFAULT_CURRENCY_CODE } from "constants/currencies"
import formatCurrency from "utils/price/formatCurrency"
import KineTeleconsultationSubscriptionCard from "components/Subscription/KineTeleconsultationSubscriptionCard"
import CenterGroupSubscriptionButton from "components/Subscription/CenterGroupTeleconsultation/CenterGroupTeleconsultationSubscriptionButton"
import AdjustCenterGroupSubscriptionDialog from "components/Subscription/CenterGroupTeleconsultation/AdjustCenterGroupTeleconsultationSubscriptionDialog"

interface CenterGroupTeleconsultationSubscriptionProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  stripeProducts?: StripeProductType[]
  isLoadingStripeProducts: boolean
}
const CenterGroupTeleconsultationSubscription = ({
  isOpen,
  onOpen,
  onClose,
  stripeProducts,
  isLoadingStripeProducts,
}: CenterGroupTeleconsultationSubscriptionProps) => {
  const { currentScope } = useAppContext()
  const queryClient = useQueryClient()
  const toast = useToast()
  const [seats, setSeats] = useState(MIN_SEATS)
  const cancelRef = useRef<HTMLButtonElement>(null)
  const centerIsTester = useCenterIsTester()

  const { data: centerGroup, isLoading: isLoadingCenterGroup } = useQuery(
    centerGroupQueryKeys.current(
      (currentScope as AdminCenterCurrentScope)?.centerGroupId
    ),
    () =>
      getCenterGroup((currentScope as AdminCenterCurrentScope).centerGroupId)
  )

  const { data: collaborators, isLoading: isLoadingCollaborators } = useQuery(
    collaboratorsQueryKeys.list(
      (currentScope as AdminCenterCurrentScope)?.centerGroupId,
      false,
      "",
      1
    ),
    () =>
      getCenterGroupOsteos({
        centerGroupId: (currentScope as AdminCenterCurrentScope).centerGroupId,
        showDisabled: false,
        search: "",
        page: 1,
      })
  )

  const activeCollaboratorsCount = collaborators?.activeItems
  const minSeats = Math.max(MIN_SEATS, activeCollaboratorsCount ?? 0)

  const subscription = centerGroup?.subscription
  const subscriptionPrice = subscription
    ? getSubPricePerPeriodPerSeat(subscription)
    : undefined
  const subscriptionPriceReadable =
    subscription?.plan.interval === "month"
      ? `${subscriptionPrice?.monthly} ${formatCurrency(
          subscription.currency
        )} / mois / thérapeute`
      : `${subscriptionPrice?.yearly} ${formatCurrency(
          subscription?.currency ?? DEFAULT_CURRENCY_CODE
        )} / an / thérapeute`

  const currentQuantity = subscription?.quantity ?? "?"

  const updateSubscriptionMutation = useMutation(
    updateCenterGroupSubscription,
    {
      onSuccess: () => {
        toast({
          status: "success",
          title: "Abonnement modifié avec succès",
        })
        onClose()
        queryClient.setQueryData(
          centerGroupQueryKeys.current(
            (currentScope as AdminCenterCurrentScope)?.centerGroupId
          ),
          (centerGroup?: AdminCenterGroup) => {
            if (centerGroup?.subscription) {
              return {
                ...centerGroup,
                subscription: {
                  ...centerGroup.subscription,
                  quantity: seats,
                },
              }
            }
          }
        )
      },
    }
  )

  const currentStripeProduct = stripeProducts?.find(
    ({ id }) => subscription?.plan.product === id
  )

  const onAdjustSubscribe = (priceId: string) => {
    if (!centerGroup) return
    updateSubscriptionMutation.mutate({
      quantity: seats,
      priceId,
      stripeCustomerId: centerGroup.stripeCustomerId,
      centerGroupId: centerGroup.id,
    })
  }

  const onCloseResetSeats = () => {
    if (subscription) {
      setSeats(subscription.quantity)
    }
    onClose()
  }

  useEffect(() => {
    if (subscription) {
      setSeats(subscription.quantity)
    }
  }, [subscription])

  if (centerIsTester) return null

  return (
    <>
      <KineTeleconsultationSubscriptionCard
        variant="center"
        price={subscriptionPriceReadable}
        action={
          <CenterGroupSubscriptionButton
            centerGroup={centerGroup}
            isLoading={isLoadingCenterGroup}
          />
        }
      >
        <Heading fontSize={22} fontWeight={700}>
          Licences
        </Heading>
        <Text fontSize={18} maxW={372}>
          Votre abonnement actuel est valable pour
          <Text as="span" fontWeight={700}>
            {" "}
            {currentQuantity} thérapeutes.{" "}
          </Text>
          Vous pouvez ajuster ce nombre à tout moment.
        </Text>
        <AButton
          width={347}
          variant="secondary"
          text="Modifier le nombre de licences"
          isLoading={
            isLoadingStripeProducts ||
            isLoadingCenterGroup ||
            isLoadingCollaborators
          }
          onClick={onOpen}
        />
      </KineTeleconsultationSubscriptionCard>
      {currentStripeProduct && (
        <AdjustCenterGroupSubscriptionDialog
          cancelRef={cancelRef}
          isOpen={isOpen}
          onClose={onCloseResetSeats}
          onSubscribe={onAdjustSubscribe}
          seats={seats}
          minSeats={minSeats}
          setSeats={setSeats}
          isLoading={updateSubscriptionMutation.isLoading}
          currentStripeProduct={currentStripeProduct}
        />
      )}
    </>
  )
}

export default CenterGroupTeleconsultationSubscription

import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Heading,
  Flex,
  Image,
} from "@chakra-ui/react"
import AButton from "components/AButton"
import { useNavigate } from "react-router-dom"
import OsteoMan from "../assets/illustrations/osteo-man.png"
import OsteoWoman from "../assets/illustrations/osteo-woman.png"

interface ACooptionModalProps {
  isOpen: boolean
  onClose: () => void
}
const ACooptionModal = ({ isOpen, onClose }: ACooptionModalProps) => {
  const navigate = useNavigate()
  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />

      <ModalContent maxW={724} borderRadius={8} bg="primary.100">
        <ModalCloseButton aria-label="Fermer" />
        <ModalHeader
          mb={8}
          p={0}
          fontSize={28}
          color="secondary.900"
          textAlign="center"
        >
          Parrainez,{" "}
          <Heading as="span" fontSize={28} fontWeight={700}>
            Andrew
          </Heading>{" "}
          vous offre un mois !
        </ModalHeader>
        <Heading fontWeight={400} fontSize={21} textAlign="center">
          Invitez un confrère à découvrir Andrew et recevez un mois d'abonnement
          gratuit.
        </Heading>
        <Heading fontWeight={700} fontSize={21} textAlign="center">
          Partagez l&apos;innovation, gagnez des avantages !
        </Heading>
        <Flex justify="center" width="100%" mt={8}>
          <Image w={184} h={200} src={OsteoMan} alt="Ostéo homme" />
          <Image w={168} h={205} src={OsteoWoman} alt="Ostéo femme" />
        </Flex>
        <ModalFooter justifyContent="center" p={0} mt={14}>
          <AButton
            text="Parrainer un confrère"
            onClick={() => navigate("/cooption")}
            variant="primary"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ACooptionModal

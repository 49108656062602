import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react"
import useToast from "hooks/useToast"
import { Field, Form, Formik } from "formik"
import { useMutation } from "@tanstack/react-query"
import { updateForumOsteo } from "../../api/osteo"
import AButton from "../../components/AButton"
import * as Yup from "yup"
import { useAppContext } from "../../AppContext"
import ALogoutButton from "components/ALogoutButton"
import { useState } from "react"
import AAdressInput from "../../components/AAdressInput"
import { MeType, UserOsteoType } from "api/user"

const FillMissingForumData = () => {
  const { user, setUser } = useAppContext()
  const updateForumOsteoMutation = useMutation(updateForumOsteo)
  const toast = useToast()

  const validationSchema = Yup.object().shape({
    urlDoctolib: Yup.string(),
  })

  const [mainAddress, setMainAddress] = useState<AddressType | undefined>(
    undefined
  )
  const [mainAddressError, setMainAddressError] = useState<string | undefined>()
  const [secondaryAddress, setSecondaryAddress] = useState<
    AddressType | undefined
  >(undefined)

  return (
    <Flex justify="center" w="full" align="center">
      <Flex direction="column" w="67%" align="stretch" mt={28}>
        <Heading as="h1" fontSize={28} color="tertiary.500" mb={6}>
          Compléter mon profil
        </Heading>
        <Text mb={6} fontWeight="semibold">
          Nous avons besoin d'informations supplémentaires pour finaliser la
          création de votre compte pro.
        </Text>
        <Box flex={1} bg="white" borderRadius={9} p={6} mr={6}>
          <Formik
            initialValues={{
              urlDoctolib: "",
            }}
            validationSchema={validationSchema}
            onSubmit={({ ...values }, { setSubmitting }) => {
              if (!user?.osteo?.id) return
              if (!mainAddress) {
                setMainAddressError("Ce champ est obligatoire")
                setSubmitting(false)
                return
              }
              updateForumOsteoMutation.mutate(
                {
                  id: user?.osteo?.id?.toString(),
                  data: {
                    ...values,
                    mainAddress,
                    secondaryAddress,
                  },
                },
                {
                  onSuccess: (data: MeType) => {
                    toast({
                      status: "success",
                      title: "Modifications enregistrées",
                    })
                    setSubmitting(false)
                    setUser(
                      (prev) =>
                        prev && {
                          ...prev,
                          osteo: data.osteo,
                          role: data.role,
                        }
                    )
                  },
                  onError: () => {
                    setSubmitting(false)
                    toast({
                      status: "error",
                      title: "Une erreur s'est produite",
                    })
                  },
                }
              )
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <>
                  <Flex mb={6}>
                    <Field name="urlDoctolib">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.urlDoctolib && form.touched.urlDoctolib
                          }
                        >
                          <FormLabel fontSize={14}>
                            Url du site de réservation (exemple: doctolib)
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="URL Doctolib"
                            bg="common.100"
                          />
                          <FormErrorMessage>
                            {form.errors.urlDoctolib}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Flex gap={4} mt={6}>
                    <FormControl isInvalid={mainAddressError !== undefined}>
                      <FormLabel mb="8px">
                        Adresse du lieu d'exercice *
                      </FormLabel>
                      <AAdressInput
                        setAddress={setMainAddress}
                        defaultValue={mainAddress?.name}
                        setAddressError={setMainAddressError}
                      />
                      <FormErrorMessage>{mainAddressError}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex my={6}>
                    <FormControl>
                      <FormLabel mb="8px">Adresse secondaire</FormLabel>
                      <AAdressInput
                        setAddress={setSecondaryAddress}
                        defaultValue={secondaryAddress?.name}
                        setAddressError={setMainAddressError}
                      />
                    </FormControl>
                  </Flex>
                </>

                <Flex justifyContent="center" mt={12}>
                  <AButton
                    text="Enregistrer les modifications"
                    py={3}
                    px={4}
                    type="submit"
                    isLoading={isSubmitting}
                  />
                </Flex>
              </Form>
            )}
          </Formik>
          <Flex justify="end" mt={4}>
            <ALogoutButton
              bg="none"
              _hover={{ bg: "none", textDecor: "underline" }}
              _focus={{ bg: "none", textDecor: "underline" }}
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

export default FillMissingForumData

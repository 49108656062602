import React, { useState } from "react"
import { Box, Flex, Select, Text } from "@chakra-ui/react"
import { Heading } from "@chakra-ui/react"
import getVideoGroupCategory from "utils/getVideoGroupCategory"
import AInputSearch from "components/AInputSearch"
import AButton from "components/AButton"
import { VideoGroupCategoryType } from "api/appointmentReason"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"
import { ReasonType } from "api/patient"

interface IProps {
  setSearch: React.Dispatch<React.SetStateAction<string>>
}
const FilterPrograms = ({ setSearch }: IProps) => {
  return (
    <Flex direction="column" gap={8} my={4}>
      <Flex direction={"column"} gap={4}>
        <AInputSearch
          placeholder="Rechercher programme"
          canClear
          setSearch={setSearch}
        />
      </Flex>
    </Flex>
  )
}

export default FilterPrograms

const getChartOptions = (categories: string[], colors: string[]) => ({
  chart: {
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "date",
    categories: categories,
    show: false,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  colors: colors,
  legend: {
    show: false,
  },
  grid: {
    show: false,
  },
})
export default getChartOptions

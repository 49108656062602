import axiosInstance from "./axiosInstance"

export interface JobType {
  id: number
  name: string
  key: string
}

export const getAllJobs = async () => {
  const { data } = await axiosInstance.get<JobType[]>(`/job/all`)
  return data
}

import { TrainingVideoGroupsType } from "api/appointmentReason"

export const niceDisplayVideoGroup = (groups: TrainingVideoGroupsType[]) => {
  const sortedGroups: TrainingVideoGroupsType[] = []
  let soloCount = 0

  for (let i = 0; i < groups.length; i++) {
    const group = groups[i]
    const videosCount = group.videos.filter(
      ({ limitedMode }) => limitedMode === false
    ).length

    if (videosCount === 1) {
      // Groupe solo
      sortedGroups.push(group)
      soloCount++

      // Si nous avons atteint 3 groupes solo, réinitialisons le compteur
      if (soloCount === 3) {
        soloCount = 0
      }
    } else {
      // Groupe avec plusieurs vidéos
      // Si nous avons des groupes solo incomplets, complétons-les
      while (soloCount > 0 && soloCount < 3) {
        // Cherchons le prochain groupe solo disponible
        let nextSoloIndex = groups
          .slice(i + 1)
          .findIndex(
            (g) =>
              g.videos.filter(({ limitedMode }) => limitedMode === false)
                .length === 1
          )

        if (nextSoloIndex !== -1) {
          // Si on trouve un groupe solo, on le déplace ici
          nextSoloIndex += i + 1 // Ajuster l'index par rapport au tableau original
          sortedGroups.push(groups[nextSoloIndex])
          groups[nextSoloIndex] = { ...group, videos: [] } // Remplacer par un groupe vide
        } else {
          // Sinon, on ajoute un groupe vide
          sortedGroups.push({ ...group, videos: [] })
        }
        soloCount++
      }

      // Réinitialiser le compteur de solo après avoir complété un groupe
      if (soloCount === 3) soloCount = 0

      // Ajouter le groupe multi-vidéos actuel
      sortedGroups.push(group)
    }
  }

  // S'il reste des groupes solo incomplets à la fin, complétons-les
  while (soloCount > 0 && soloCount < 3) {
    sortedGroups.push({ ...groups[0], videos: [] })
    soloCount++
  }

  return sortedGroups
}

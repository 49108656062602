import {
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import AAcademyAvatar from "components/AAvatar/AAcademyAvatar"
import { RiPencilLine } from "react-icons/ri"

interface PlacesProps {
  onEditPlace: (place: AcademieLocationType) => void
  list?: AcademieLocationType[]
}
const Places = ({ onEditPlace, list }: PlacesProps) => {
  return (
    <TableContainer>
      <Table size="lg" background="white" borderRadius={10}>
        <Thead>
          <Tr>
            <Th
              color="common.700"
              textTransform="none"
              fontFamily="Mulish"
              textAlign="left"
            >
              Avatar
            </Th>
            <Th
              color="common.700"
              textTransform="none"
              fontFamily="Mulish"
              textAlign="left"
            >
              Nom
            </Th>

            <Th
              color="common.700"
              textTransform="none"
              fontFamily="Mulish"
              textAlign="left"
            >
              Adresse
            </Th>
            <Th
              color="common.700"
              textTransform="none"
              fontFamily="Mulish"
              textAlign="left"
              aria-label="Modifier"
            />
          </Tr>
        </Thead>
        <Tbody>
          {(list || []).map((place) => (
            <Tr key={place.id} border="none">
              <Td
                fontSize={14}
                border="none"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AAcademyAvatar avatar={place.avatar} />
              </Td>
              <Td fontSize={14} border="none" textAlign="left">
                {place.name}
              </Td>
              <Td fontSize={14} border="none" textAlign="left">
                {place.address.name}
              </Td>
              <Td fontSize={14} border="none" textAlign="left">
                <IconButton
                  onClick={() => onEditPlace(place)}
                  variant="ghost"
                  aria-label="Modifier"
                  color="primary.500"
                  icon={<RiPencilLine />}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default Places

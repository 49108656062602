import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useSearchParams } from "react-router-dom"
import { updateOsteo } from "api/osteo"
import { useAppContext } from "AppContext"
import { subscribeSuccessful } from "utils/facebookPixelEvents"
import { sendinblueTrack } from "utils/sendinBlueEvents"
import useToast from "hooks/useToast"
import getMeQueryKeys from "constants/queryKeys/getMe"
import { SESSION_ID_KEY } from "constants/subscription"
import { getCustomer } from "api/subscriptions"

// HOOKS
const useStoreSuccessPaymentSessionIndividual = () => {
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams()
  const { user, setUser, jwt } = useAppContext()

  const sessionId = searchParams.get(SESSION_ID_KEY)

  const clearSessionId = () =>
    setSearchParams((prevParams) => {
      prevParams.delete(SESSION_ID_KEY)
      return prevParams
    })

  const queryClient = useQueryClient()
  const { data } = useQuery(["getCustomer"], () => getCustomer(sessionId!), {
    retry: false,
    staleTime: Infinity,
    enabled: Boolean(sessionId),
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
  })
  const customerId = data?.customer_id

  useQuery(
    ["updateOsteoSession"],
    () =>
      updateOsteo({
        id: user!.osteo.id.toString()!,
        data: {
          sessionId: sessionId!,
          stripeCustomerId: customerId,
          hasUsedTrial: true,
        },
      }),
    {
      retry: false,
      staleTime: Infinity,
      enabled: Boolean(user) && Boolean(customerId) && Boolean(sessionId),
      onError: () => {
        toast({
          status: "error",
          title: "Une erreur s'est produite",
        })
      },
      onSuccess: async () => {
        // do nothing if already set
        if (
          user?.stripeCustomer?.id === customerId &&
          user?.osteo.sessionId === sessionId
        ) {
          return
        }
        if (user?.email) {
          sendinblueTrack("start_trial", {
            email: user?.email,
            HAS_USED_TRIAL: true,
          })
        }
        subscribeSuccessful()

        setUser(
          (prev) =>
            prev && {
              ...prev,
              stripeCustomer: { id: customerId! },
              osteo: {
                ...prev.osteo,
                hasUsedTrial: true,
                sessionId: sessionId!,
              },
            }
        )
        await queryClient.invalidateQueries({
          queryKey: getMeQueryKeys.me(jwt!),
        })
      },
    }
  )

  return { sessionId, clearSessionId }
}

export default useStoreSuccessPaymentSessionIndividual

import { useQuery } from "@tanstack/react-query"
import { useSearchParams } from "react-router-dom"
import { useAppContext } from "AppContext"
import useToast from "hooks/useToast"
import { saveStripeConnectAccount } from "api/teleconsultation"
import { useState } from "react"

// HOOKS
const useStoreStripeConnectAccount = () => {
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams()
  const { user, setUser } = useAppContext()
  const [isDone, setIsDone] = useState(false)

  const stripeConnectAccount = searchParams.get("stripe_connect_account")

  useQuery({
    queryKey: ["stripeConnectAccount"],
    queryFn: () => saveStripeConnectAccount(stripeConnectAccount!),
    enabled: Boolean(user) && Boolean(stripeConnectAccount),
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
    onSuccess: async (data) => {
      setIsDone(data.success)
      // do nothing if already set
      if (
        user?.osteo.stripeConnectAccount === stripeConnectAccount ||
        !data.success
      ) {
        return
      }

      setUser(
        (prev) =>
          prev && {
            ...prev,
            osteo: {
              ...prev.osteo,
              stripeConnectAccount: stripeConnectAccount!,
            },
          }
      )
    },
  })

  const clearStripeConnectAccount = () =>
    setSearchParams((prevParams) => {
      prevParams.delete("stripe_connect_account")
      return prevParams
    })

  return { clearStripeConnectAccount, isDone }
}

export default useStoreStripeConnectAccount

import {
  FormLabel,
  Grid,
  GridItem,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { Form, Formik, FormikHelpers } from "formik"
import {
  AcademieDetailsType,
  addLocation,
  editLocation,
} from "../../../api/academie"
import AAdressField from "../../../components/Field/AAdressField"
import AInputField from "../../../components/Field/AInputField"
import * as Yup from "yup"
import validateAddress from "../../../utils/validators/validateAddress"
import ASubmitButton from "../../../components/ASubmitButton"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAppContext } from "../../../AppContext"
import useToast from "../../../hooks/useToast"
import AAcademyAvatarField from "components/Field/AAcademyAvatarField"
import validateAvatarSize from "utils/validators/validateAvatarSize"
import { uploadAvatar } from "api/upload"
import { AVATAR_LABEL } from "constants/avatar"
import { academieQueryKeys } from "pages/Academy/constants/queryKeys"

// CONSTANTS
type EditPlaceType = {
  avatar?: string
  name: string
  address: AddressType
}

const INITIAL_VALUES_NO_PLACE = {
  avatar: "",
  name: "",
  address: {
    name: "",
  },
} as EditPlaceType

const VALIDATION_SCHEMA = Yup.object().shape({
  avatar: Yup.mixed().test(validateAvatarSize),
  name: Yup.string().required("Nom requis"),
  address: Yup.object().test(validateAddress),
})

// HELPERS
const getInitialValues = (place?: AcademieLocationType) => {
  if (place === undefined) {
    return INITIAL_VALUES_NO_PLACE
  }
  return { ...place, avatar: place.avatar?.url ?? "" }
}

// COMPONENTS
interface EditPlaceProps {
  isOpen: boolean
  onClose: () => void
  place?: AcademieLocationType
}
const EditPlace = ({ place, isOpen, onClose }: EditPlaceProps) => {
  const initialValues = getInitialValues(place)
  const initialAvatar = initialValues.avatar
  const { currentScope } = useAppContext()
  const uploadAvatarMutation = useMutation(uploadAvatar)
  const toast = useToast()
  const queryClient = useQueryClient()

  const addOrEditMutation = useMutation({
    mutationFn: (values: EditPlaceType) =>
      place
        ? editLocation({
            academieId: currentScope?.id!,
            academieLocationId: place.id!,
            ...values,
          })
        : addLocation({ academieId: currentScope?.id!, ...values }),
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
  })

  const mutatePlace = (
    values: EditPlaceType,
    { setSubmitting }: FormikHelpers<EditPlaceType>
  ) =>
    addOrEditMutation.mutate(values, {
      onSuccess: (submittedLocation) => {
        toast({
          status: "success",
          title: place ? "Modification enregistrée" : "Lieu ajouté",
        })
        onClose()
        if (place) {
          queryClient.setQueriesData<AcademieDetailsType>(
            academieQueryKeys.current(currentScope!.id),
            (academie) => {
              if (academie) {
                return {
                  ...academie,
                  locations: academie.locations.map((location) =>
                    location.id !== submittedLocation.id
                      ? location
                      : submittedLocation
                  ),
                }
              }
            }
          )
        } else {
          queryClient.setQueriesData<AcademieDetailsType>(
            [academieQueryKeys.all, currentScope?.id],
            (academie) => {
              if (academie) {
                return {
                  ...academie,
                  locations: [...academie.locations, submittedLocation],
                }
              }
            }
          )
        }
      },
      onSettled: () => {
        setSubmitting(false)
      },
    })

  const onSubmit = (
    { avatar, ...values }: EditPlaceType,
    formikHelpers: FormikHelpers<EditPlaceType>
  ) => {
    if (avatar && avatar !== initialAvatar) {
      const file = new FormData()
      file.append("files", avatar)
      return uploadAvatarMutation.mutate(file, {
        onError: () => {
          formikHelpers.setSubmitting(false)
          toast({
            status: "error",
            title: "Une erreur s'est produite",
          })
        },
        onSuccess: (fileId: string) => {
          mutatePlace({ ...values, avatar: fileId }, formikHelpers)
        },
      })
    }
    return mutatePlace(values, formikHelpers)
  }

  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />

      <ModalContent maxW={724} borderRadius={8} p={8}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={VALIDATION_SCHEMA}
        >
          <Form>
            <ModalCloseButton aria-label="Fermer" />
            <ModalHeader mb={8} p={0} fontSize={18}>
              {place ? "Modifier le" : "Ajouter un"} lieu
            </ModalHeader>
            <AAcademyAvatarField
              name="avatar"
              label={AVATAR_LABEL}
              avatar={place?.avatar}
            />
            <Grid templateColumns="repeat(2, 1fr)" rowGap={10}>
              <GridItem>
                <AInputField
                  name="name"
                  label={<FormLabel fontSize={14}>Nom *</FormLabel>}
                  placeholder="Nom"
                  bg="common.100"
                />
              </GridItem>
              <GridItem colSpan={2}>
                <AAdressField name="address" />
              </GridItem>
            </Grid>
            <ModalFooter justifyContent="center" p={0} mt={14}>
              <ASubmitButton
                text={place ? "Enregistrer les modifications" : "Créer le lieu"}
                py={3}
                px={28}
              />
            </ModalFooter>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default EditPlace

import { useDisclosure } from "@chakra-ui/react"
import { differenceInMonths } from "date-fns"
import { useEffect, useMemo } from "react"

// CONSTANTS
const COOPTION_POPUP_STORAGE_KEY = "cooptionPopup"

// HELPERS
const getIsLastPopupDateOverThreeMonths = (lastPopupDate: Date) =>
  differenceInMonths(new Date(), lastPopupDate) > 3

const storeNewPopupDate = () => {
  localStorage.setItem(COOPTION_POPUP_STORAGE_KEY, new Date().toJSON())
}

// HOOKS
const useCooptionModalDisclosure = (isOtherDialogOpen?: boolean) => {
  // avoid recomputing last popup date too regularly
  const lastPopupDate = useMemo(
    () => localStorage.getItem(COOPTION_POPUP_STORAGE_KEY),
    []
  )
  const { isOpen, onClose, onOpen } = useDisclosure()

  const shouldOpenCooptionModal =
    lastPopupDate !== null
      ? getIsLastPopupDateOverThreeMonths(new Date(lastPopupDate))
      : true

  useEffect(() => {
    if (!isOtherDialogOpen && shouldOpenCooptionModal) {
      onOpen()
      storeNewPopupDate()
    }
  }, [isOtherDialogOpen, shouldOpenCooptionModal, onOpen])

  return { isOpen, onClose }
}

export default useCooptionModalDisclosure

import axiosInstance from "./axiosInstance"

export interface AdminCenterGroup extends CenterGroupType {
  stripeCustomerId: string
  subscription?: StripeSubscription
  centers: CenterType[]
}

export interface CreateCenterGroupParams {
  sessionId: string
  stripeCustomerId: string
}
export interface CreateCenterGroupResponse extends AdminCenterGroup {}

export const createCenterGroup = async ({
  sessionId,
  stripeCustomerId,
}: CreateCenterGroupParams) => {
  const response = await axiosInstance.post<CreateCenterGroupResponse>(
    "/center-groups/new",
    { session_id: sessionId, stripe_customer_id: stripeCustomerId }
  )
  return response.data
}

export const getCenterGroup = async (centerGroupId: CenterGroupType["id"]) => {
  const response = await axiosInstance.post<AdminCenterGroup>(
    "/center-groups/details",
    { center_group_id: centerGroupId }
  )
  return response.data
}

export interface AddCenterParams
  extends Pick<CenterType, "name" | "address" | "url"> {
  avatar?: string
  centerGroupId: number
}

export interface AddEditCenterResponse extends CenterType {}

export const addCenter = async ({
  centerGroupId,
  ...params
}: AddCenterParams) => {
  const response = await axiosInstance.post<AddEditCenterResponse>(
    "/center-groups/new-center",
    { ...params, center_group_id: centerGroupId }
  )
  return response.data
}

export interface EditCenterParams extends AddCenterParams {
  centerId: number
}

export const editCenter = async ({
  centerGroupId,
  centerId,
  ...params
}: EditCenterParams) => {
  const response = await axiosInstance.post<AddEditCenterResponse>(
    "/center-groups/update-center",
    {
      ...params,
      center_group_id: centerGroupId,
      center_id: centerId,
    }
  )
  return response.data
}

export interface CenterGroupOsteo extends OsteoType {
  account: {
    id: string
    email: string
  }
  active: boolean
  centers?: CenterType[]
  is_admin?: boolean
}

export type GetCenterGroupOsteosParams = PaginationParamsType & {
  centerGroupId: CenterGroupType["id"]
  showDisabled: boolean
  search: string
}

export type GetCenterGroupOsteosResponse = {
  result: CenterGroupOsteo[]
  count: PaginationType
  activeItems: number
}

export const getCenterGroupOsteos = async ({
  centerGroupId,
  showDisabled,
  perPage,
  ...params
}: GetCenterGroupOsteosParams) => {
  const response = await axiosInstance.post<GetCenterGroupOsteosResponse>(
    "/center-groups/osteos",
    {
      center_group_id: centerGroupId,
      show_disabled: showDisabled,
      per_page: perPage,
      ...params,
    }
  )
  return response.data
}

export type UpdateCenterGroupOsteoStatusParams = {
  newStatus: "enable" | "disable"
  centerGroupId: number
  osteoId: number
}
export const updateCenterGroupOsteoStatus = async ({
  centerGroupId,
  newStatus,
  osteoId,
}: UpdateCenterGroupOsteoStatusParams) => {
  const response = await axiosInstance.put<CenterGroupOsteo>(
    "/center-groups/osteo/status",
    {
      center_group_id: centerGroupId,
      new_status: newStatus,
      osteo_id: osteoId,
    }
  )
  return response.data
}

export type AddCenterGroupOsteoParams = {
  email: string
  firstname: string
  lastname: string
  centerGroupId: number
  centerIds: number[]
}

export const addCenterGroupOsteo = async ({
  centerGroupId,
  centerIds,
  ...params
}: AddCenterGroupOsteoParams) => {
  const response = await axiosInstance.post<CenterGroupOsteo>(
    "/center-groups/osteo/new",
    {
      center_group_id: centerGroupId,
      center_ids: JSON.stringify(centerIds),
      ...params,
    }
  )
  return response.data
}

export type LinkUnlinkCenterGroupOsteoCenterParams = {
  centerId: number
  centerGroupId: number
  osteoId: number
}

export const linkCenterGroupOsteoCenter = async ({
  centerId,
  centerGroupId,
  osteoId,
}: LinkUnlinkCenterGroupOsteoCenterParams) => {
  const response = await axiosInstance.put<CenterType[]>(
    "/center-groups/osteo/centers",
    null,
    {
      params: {
        center_group_id: centerGroupId,
        center_id: centerId,
        osteo_id: osteoId,
      },
    }
  )
  return response.data
}

export const unlinkCenterGroupOsteoCenter = async ({
  centerId,
  centerGroupId,
  osteoId,
}: LinkUnlinkCenterGroupOsteoCenterParams) => {
  const response = await axiosInstance.delete<CenterType[]>(
    "/center-groups/osteo/centers",
    {
      params: {
        center_group_id: centerGroupId,
        center_id: centerId,
        osteo_id: osteoId,
      },
    }
  )
  return response.data
}

const collaboratorsQueryKeys = {
  all: ["getCollaborators"] as const,
  lists: (centerGroupId: number) =>
    [...collaboratorsQueryKeys.all, centerGroupId] as const,
  list: (
    centerGroupId: number,
    showDisabled: boolean,
    search: string,
    page: number
  ) =>
    [
      ...collaboratorsQueryKeys.lists(centerGroupId),
      showDisabled,
      search,
      page,
    ] as const,
}

export default collaboratorsQueryKeys

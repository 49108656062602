import {
  GEOLOCATION_OPTIONS,
  useGeolocationContext,
} from "components/Geolocation/GeolocationContext"
import { useEffect } from "react"

const useStudentGeolocationEffect = () => {
  const {
    isLoading,
    setLoading,
    can,
    error,
    isImprovingAccuracy,
    shouldWait,
    isStudent,
    hasGeolocationCheck,
    onGeolocationNotSupported,
    onSuccess,
    onError,
  } = useGeolocationContext()

  useEffect(() => {
    setLoading(true)
    if (shouldWait) {
      return
    }
    if (!isStudent || !hasGeolocationCheck) {
      return
    }

    if (!("geolocation" in navigator)) {
      onGeolocationNotSupported()
      return
    }

    navigator.geolocation.getCurrentPosition(
      onSuccess,
      onError,
      GEOLOCATION_OPTIONS
    )
  }, [
    setLoading,
    onGeolocationNotSupported,
    shouldWait,
    isStudent,
    onSuccess,
    onError,
    hasGeolocationCheck,
  ])

  return {
    can,
    error,
    isLoading,
    isImprovingAccuracy,
    isStudent,
    hasGeolocationCheck,
  }
}

export default useStudentGeolocationEffect

import { PatientType } from "api/patient"
import DiscloseTempPassword from "components/DiscloseTempPassword"

interface InvitePatientProps {
  isOpen: boolean
  onClose: () => void
  password: string
  patient?: PatientType
  isReinvitation?: Boolean
}
const DisclosePatientTempPassword = ({
  isOpen,
  onClose,
  password,
  patient,
  isReinvitation,
}: InvitePatientProps) => {
  const { lastname, firstname } = patient ?? {}
  const displayName = `${firstname} ${lastname}`
  const title = isReinvitation ? "Invitation envoyée" : "Patient modifié"
  const text = isReinvitation
    ? "Une invitation a été renvoyée au patient"
    : `Le patient ${displayName} a été modifié`

  return (
    <DiscloseTempPassword
      isOpen={isOpen}
      onClose={onClose}
      password={password}
      patient={patient}
      title={title}
      text={text}
      closeText="Fermer"
    />
  )
}

export default DisclosePatientTempPassword

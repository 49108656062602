import { CurrencyCode, StripePrice, StripeProductType } from "api/subscriptions"

export const getCenterPerSeatPrice = (product: StripeProductType) => {
  const priceFloat =
    product.price.recurring.interval === "year"
      ? product.price.unit_amount / 100 / 12
      : product.price.unit_amount / 100

  return priceFloat
}

export const getLocalizedCenterPerSeatPrice = (
  product: StripeProductType,
  currencyCode: CurrencyCode
) => {
  const currencyPrice = product.pricesPerCurrency[currencyCode]
  const priceFloat =
    currencyPrice.recurring.interval === "year"
      ? currencyPrice.unit_amount / 100 / 12
      : currencyPrice.unit_amount / 100

  return priceFloat
}

export const getCenterPrice = (product: StripeProductType, quantity = 2) => {
  const priceFloat = getCenterPerSeatPrice(product)

  return priceFloat * quantity
}

export const getLocalizedCenterPrice = (price: StripePrice, quantity = 2) => {
  const priceFloat =
    price.recurring.interval === "year"
      ? price.unit_amount / 100 / 12
      : price.unit_amount / 100

  return priceFloat * quantity
}

import { Box, Flex, Heading, useDisclosure } from "@chakra-ui/react"
import AButton from "components/AButton"
import Billing from "components/Billing/Billing"
import ReminderSettings from "components/ReminderSettings"
import Subscription from "components/Subscription/Subscription"
import { FormikProps } from "formik"
import StripeConnectLoginLink from "pages/Profile/StripeConnectLoginLink"
import { useRef, useState } from "react"
import { useAppContext } from "../../AppContext"
import EditProfil from "../../components/EditProfil"

export type ProfileForm = {
  avatar: string
  firstname: string
  lastname: string
  urlDoctolib: string
  telephone: string
  email: string
  mainAddress: AddressType | undefined
  secondaryAddress: AddressType | undefined
  urlGoogleMyBusiness: string
}

export type ReminderDataType = {
  reminderText: string | undefined
  reminderDelay: number | undefined
  isReminderActivated: boolean | undefined
}

const Profile = () => {
  const { user, setUser } = useAppContext()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const formRef = useRef<FormikProps<ProfileForm>>(null)
  const [isFormLoading, setIsFormLoading] = useState(false)

  const [reminderData, setReminderData] = useState<ReminderDataType>({
    reminderText: user?.osteo.reminderText,
    reminderDelay: user?.osteo.reminderDelay,
    isReminderActivated: user?.osteo.isReminderActivated,
  })

  const handleSubmitForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  return (
    <Flex direction="column" gap="24px">
      <Heading as="h1" fontSize={28} color="tertiary.500">
        Mon profil thérapeute
      </Heading>
      <EditProfil
        user={user}
        setUser={setUser}
        formRef={formRef}
        reminderData={reminderData}
        setIsFormLoading={setIsFormLoading}
      />
      <ReminderSettings
        reminderData={reminderData}
        setReminderData={setReminderData}
      />
      <Flex justifyContent="center">
        <AButton
          text="Enregistrer les modifications"
          py={3}
          px={4}
          onClick={handleSubmitForm}
          isLoading={isFormLoading}
          //isLoading={formRef.current?.isSubmitting}
        />
      </Flex>
      <Flex mt={12} gap={12} alignItems="flex-start">
        <Subscription isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        <Billing />
      </Flex>
      {user?.osteo?.stripeConnectAccount && (
        <StripeConnectLoginLink accountId={user.osteo.stripeConnectAccount} />
      )}
    </Flex>
  )
}

export default Profile

import { MeType } from "api/user"
import { CurrentScope } from "AppContext"

interface GetPatientParamsProps {
  osteoId: string | null
  centerId: string | null
  academyId: string | null
  hasPersonalAccount: boolean
  user: MeType | null
  currentScope: CurrentScope | null
  setCurrentScope: (scope: CurrentScope | null) => void
  setCenterAdminCurrentScope: (scope: CurrentScope | null) => void
  patientId: string | undefined
}

const useHasPatientOnDifferentAccount = ({
  osteoId,
  centerId,
  academyId,
  hasPersonalAccount,
  user,
  currentScope,
  setCurrentScope,
  setCenterAdminCurrentScope,
  patientId,
}: GetPatientParamsProps) => {
  if (hasPersonalAccount) {
    const centers = user?.osteo?.centers || []
    const academies = user?.osteo?.academies || []
    if (centerId) {
      const center = centers.find((c) => c.id.toString() === centerId)
      if (center) {
        const centerGroupId = center?.centerGroup?.id
        const userIsCenterGroupAdmin = user?.osteo.adminCenterGroups?.some(
          ({ id }) => id === centerGroupId
        )

        if (centerGroupId && userIsCenterGroupAdmin) {
          if (currentScope?.type !== "center")
            setCenterAdminCurrentScope({
              type: "center",
              id: center.id,
              centerGroupId,
            })
          return {
            patientId,
            centerId: center.id,
          }
        } else {
          if (currentScope?.type !== "center")
            setCurrentScope({ type: "center", id: center.id })
          return {
            patientId,
            centerId: center.id,
          }
        }
      }
    } else if (academyId) {
      const academy = academies.find((a) => a.id.toString() === academyId)
      if (academy) {
        if (currentScope?.type !== "academy")
          setCurrentScope({ id: academy.id, type: "academy" })
        return {
          patientId,
          academieId: academy.id,
        }
      }
    } else if (osteoId) {
      if (user?.osteo.id.toString() === osteoId) {
        if (currentScope !== null) setCurrentScope(null)
      }
    }
  }

  if (currentScope?.type === "center") {
    return {
      patientId,
      centerId: currentScope.id,
    }
  } else if (currentScope?.type === "academy") {
    return {
      patientId,
      academieId: currentScope.id,
    }
  }
  return {
    patientId,
  }
}

export default useHasPatientOnDifferentAccount

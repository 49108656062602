import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react"
import { useAppContext } from "AppContext"
import React from "react"
import AButton from "./AButton"

interface IProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
}

const ALogoutModal = ({ cancelRef, onClose, isOpen }: IProps) => {
  const { setJwt } = useAppContext()
  const handleDisconnect = () => {
    setJwt(null)
    onClose()
  }
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent maxW={724} borderRadius={8} py={8}>
        <AlertDialogHeader fontSize={18}>
          Souhaitez-vous vraiment vous déconnecter ?
        </AlertDialogHeader>
        <AlertDialogBody>
          Vous serez déconnecté de votre compte et vous devrez vous reconnecter
          pour accéder à vos données.
        </AlertDialogBody>
        <AlertDialogFooter>
          <AButton
            ref={cancelRef}
            onClick={onClose}
            text="Non"
            variant={"tertiary"}
            p="12px 25px"
          />
          <AButton
            ml={3}
            text="Oui, me déconnecter"
            p="12px 45px"
            onClick={handleDisconnect}
          />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default ALogoutModal

const makeIncludesInsensitive = (searchText: string) =>
  new RegExp(`${searchText}`, "i")

export const curryIncludesInsensitive = (searchText: string) => {
  const regexp = makeIncludesInsensitive(searchText)
  return (string: string) => regexp.test(string)
}

const includesInsensitive = (searchText: string, string: string) => {
  const regexp = makeIncludesInsensitive(searchText)
  return regexp.test(string)
}

export default includesInsensitive

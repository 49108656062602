import { StripeProductType } from "api/subscriptions"

const groupStripeProductsById = (subscriptions?: StripeProductType[]) => {
  const subscriptionsByMetadataId = (subscriptions || []).reduce<{
    [key: string]: StripeProductType
  }>((acc, product) => {
    const productId = product?.metadata?.id

    return productId === undefined
      ? acc
      : {
          ...acc,
          [productId]: product,
        }
  }, {})

  return subscriptionsByMetadataId
}

export default groupStripeProductsById

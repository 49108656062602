import { Flex } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Stats from "pages/Patient/components/Stats"

import { useMutation, useQuery } from "@tanstack/react-query"
import { getPatient, PatientType } from "../../api/patient"

import { useAppContext } from "AppContext"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import userHasNoPersonalAccount from "../../utils/user/userHasNoPersonalAccount"
import userHasPatientOnDifferentAccount from "utils/multi/userHasPatientOnDifferentAccount"
import RightTabKine from "components/Treatment/RightTabKine"
import TreatmentHeader from "components/Treatment/TreatmentHeader"
import { TreatmentType } from "api/treatment"
import { getTreatmentByPatientId } from "api/treatment"

const PatientKine = () => {
  const { patientId } = useParams()
  const { currentScope, user, setCurrentScope, setCenterAdminCurrentScope } =
    useAppContext()
  const [patient, setPatient] = React.useState<PatientType | undefined>(
    undefined
  )
  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const [searchParams] = useSearchParams()
  const osteoId = searchParams.get("osteoId")
  const centerId = searchParams.get("centerId")
  const academyId = searchParams.get("academyId")
  const hasPersonalAccount = !userHasNoPersonalAccount(user)
  const treatmentsMutation = useMutation(getTreatmentByPatientId)
  const [treatments, setTreatments] = React.useState<TreatmentType[]>([])
  const navigate = useNavigate()
  const patientParams = userHasPatientOnDifferentAccount({
    osteoId,
    centerId,
    academyId,
    hasPersonalAccount,
    user,
    currentScope,
    setCurrentScope,
    setCenterAdminCurrentScope,
    patientId,
  })

  useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(patientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error) =>
        retryPatientNoAccessRight(failureCount, error),
      onSuccess: (data) => {
        setPatient(data)
      },
      onError: (error: any) => onPatientNoAccessRight(error),
    }
  )

  useEffect(() => {
    if (patient?.appointments) {
      const lastAppointmentIndex = patient.appointments.length - 1
      const lastAppointment = patient.appointments[lastAppointmentIndex]
      if (lastAppointment && lastAppointment.treatment) {
        navigate(
          `/patients/${patient.id}/treatment/${lastAppointment.treatment.id}/appointment/${lastAppointment.id}`
        )
      }
    }
    if (patient?.id) {
      treatmentsMutation.mutate(patient.id, {
        onSuccess: (data: TreatmentType[]) => {
          setTreatments(data)
        },
      })
    }
  }, [patient])

  if (!patient) return null

  return (
    <Flex direction={"column"} gap={6}>
      <TreatmentHeader
        patient={patient}
        isInPatientPage={true}
        setPatient={setPatient}
      />
      {patient.checkups && patient.checkups.length > 0 ? (
        <Flex justify={"space-between"} align={"start"} gap={"20px"}>
          <Flex direction={"column"} w={"full"}>
            <Stats patient={patient} isInPatientKinePage={true} />
          </Flex>
          {patient.id !== undefined && (
            <RightTabKine patient={patient} treatments={treatments} />
          )}
        </Flex>
      ) : (
        <Flex justify={"end"} w={"full"}>
          <RightTabKine patient={patient} treatments={treatments} />
        </Flex>
      )}
    </Flex>
  )
}

export default PatientKine

import { COOPTION_KEY } from "constants/cooption"
import { useSearchParams } from "react-router-dom"

const useCooptionToken = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const cooptionToken = searchParams.get(COOPTION_KEY)

  const clearCooptionToken = () =>
    setSearchParams((prevParams) => {
      prevParams.delete(COOPTION_KEY)
      return prevParams
    })

  return [cooptionToken, clearCooptionToken] as const
}

export default useCooptionToken

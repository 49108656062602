import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Grid,
  GridItem,
  FormLabel,
} from "@chakra-ui/react"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import AInputField from "components/Field/AInputField"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCenterAdminCurrentScope } from "AppContext"
import { addCenterGroupOsteo } from "api/centerGroup"
import useToast from "hooks/useToast"
import ASubmitButton from "components/ASubmitButton"
import collaboratorsQueryKeys from "constants/queryKeys/collaborators"
import AAutocompleteMultipleField from "components/Field/AAutocompleteMultipleField"
import { useState } from "react"
import { useDebounce } from "use-debounce"
import { DEBOUNCE_DELAY } from "constants/transitions"
import { curryIncludesInsensitive } from "utils/includesInsensitive"

// CONSTANTS
const INITIAL_VALUES = {
  email: "",
  firstname: "",
  lastname: "",
  centers: [] as CenterType[],
}

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email("Ceci n'est pas un email").required("Email requis"),
  lastname: Yup.string().required("Nom requis"),
  firstname: Yup.string().required("Prénom requis"),
  centers: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
      })
    )
    .min(1, "Au moins un centre requis"),
})

// HELPERS
const renderItem = ({ name }: CenterType) => name

const getKey = ({ id }: CenterType) => id.toString()

type InviteCollaboratorType = {
  firstname: string
  lastname: string
  email: string
  centers: CenterType[]
}

// COMPONENTS
interface InviteCollaboratorProps {
  isOpen: boolean
  onClose: () => void
  centers: CenterType[]
}
const InviteCollaborator = ({
  isOpen,
  onClose,
  centers,
}: InviteCollaboratorProps) => {
  const initialValues = INITIAL_VALUES
  const currentScope = useCenterAdminCurrentScope()
  const toast = useToast()
  const queryClient = useQueryClient()

  const [search, setSearch] = useState("")

  const [debouncedSearch] = useDebounce(search, DEBOUNCE_DELAY)
  const includesSearch = curryIncludesInsensitive(debouncedSearch)

  const filteredCenters = centers.filter(({ name }) => includesSearch(name))

  const addCenterGroupOsteoMutation = useMutation({
    mutationFn: ({ centers, ...values }: InviteCollaboratorType) =>
      addCenterGroupOsteo({
        centerGroupId: currentScope.centerGroupId,
        centerIds: centers.map(({ id }) => id),
        ...values,
      }),
  })

  const onSubmit = (
    values: InviteCollaboratorType,
    { setSubmitting }: FormikHelpers<InviteCollaboratorType>
  ) => {
    const formattedValues = {
      ...values,
      email: values.email.toLowerCase(),
    }
    addCenterGroupOsteoMutation.mutate(formattedValues, {
      onSuccess: () => {
        toast({
          status: "success",
          title: "Invitation envoyée",
        })
        onClose()
        queryClient.invalidateQueries({
          queryKey: collaboratorsQueryKeys.lists(currentScope.centerGroupId),
        })
      },
      onError: (error: any) => {
        const isAlreadyExistsError = error?.response?.status === 400
        toast({
          status: "error",
          title: isAlreadyExistsError
            ? `Ce mail est déjà associé à un compte existant`
            : "Une erreur s'est produite",
        })
      },
      onSettled: () => {
        setSubmitting(false)
      },
    })
  }

  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />

      <ModalContent maxW={724} borderRadius={8} p={8}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={VALIDATION_SCHEMA}
        >
          <Form>
            <ModalCloseButton aria-label="Fermer" />
            <ModalHeader mb={8} p={0} fontSize={18}>
              Ajouter un collaborateur
            </ModalHeader>
            <Grid templateColumns="repeat(2, 1fr)" rowGap={10} columnGap={4}>
              <GridItem colSpan={2}>
                <AInputField
                  name="email"
                  label={
                    <FormLabel mb={3} fontSize={16}>
                      Mail *
                    </FormLabel>
                  }
                  placeholder="Mail"
                  bg="common.100"
                />
              </GridItem>
              <GridItem>
                <AInputField
                  name="lastname"
                  label={
                    <FormLabel mb={3} fontSize={16}>
                      Nom *
                    </FormLabel>
                  }
                  placeholder="Nom"
                  bg="common.100"
                />
              </GridItem>
              <GridItem>
                <AInputField
                  name="firstname"
                  label={
                    <FormLabel mb={3} fontSize={16}>
                      Prénom *
                    </FormLabel>
                  }
                  placeholder="Prénom"
                  bg="common.100"
                />
              </GridItem>
              <GridItem colSpan={2}>
                <AAutocompleteMultipleField
                  bg="common.100"
                  name="centers"
                  renderItem={renderItem}
                  getKey={getKey}
                  inputValue={search}
                  setInputValue={setSearch}
                  items={filteredCenters}
                  labelText="Centres d'exercice du collaborateur *"
                  LabelComponent={FormLabel}
                  labelProps={{ mb: 3, fontSize: 16 }}
                  placeholder="Saisir des caractères pour filtrer"
                />
              </GridItem>
            </Grid>
            <ModalFooter justifyContent="center" p={0} mt={14}>
              <ASubmitButton
                text="Inviter le collaborateur"
                py={3}
                px={28}
                isLoading={addCenterGroupOsteoMutation.isLoading}
              />
            </ModalFooter>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default InviteCollaborator

import { Container, Flex, Heading, Text, Image } from "@chakra-ui/react"
import ZenIllustration from "../../assets/illustrations/zen.png"
import ALogo from "../../components/ALogo"

const UnderMaintenance = () => {
  return (
    <Flex
      backgroundColor={"primary.100"}
      direction={"column"}
      alignItems="center"
      minH="100vh"
    >
      <ALogo />
      <Container maxW={"container"} mt={20} mb={10}>
        <Flex
          direction="column"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Image src={ZenIllustration} alt={"zen"} width={269} height={206} />
          <Heading
            as={"h1"}
            fontSize={{ base: "24px", lg: "3rem" }}
            lineHeight={{ base: "35px", lg: "65px" }}
            color={"tertiary.800"}
            mb={6}
          >
            En maintenance
          </Heading>
          <Text>
            Le site Andrew® Pro est actuellement indisponible, pour cause de
            maintenance, merci de votre compréhension.
          </Text>
        </Flex>
      </Container>
    </Flex>
  )
}

export default UnderMaintenance

import { ChevronRightIcon } from "@chakra-ui/icons"
import { Box, Flex, Heading, Image, Link } from "@chakra-ui/react"
import React from "react"
import { TopicListType } from "../../../api/topics"
import { Link as RouterLink } from "react-router-dom"

interface IProps {
  topic: TopicListType
}

const TopicItem: React.FC<IProps> = ({ topic }) => {
  return (
    <Link
      as={RouterLink}
      to={
        topic.attributes.key === "podcast"
          ? "/podcasts"
          : `/topics?id=${topic.id}`
      }
      _hover={{ textDecoration: "none" }}
    >
      <Box
        pos="relative"
        w={708}
        h={236}
        borderRadius={9}
        overflow="hidden"
        cursor="pointer"
      >
        <Image
          w="100%"
          h="100%"
          objectFit="cover"
          src={topic.attributes.illustration.data.attributes.formats.medium.url}
        />
        <Flex pos="absolute" bottom={4} left={4} alignItems="center">
          <Heading as="h3" color="white" fontSize={20} fontWeight={700} mr={1}>
            {topic.attributes.title}
          </Heading>
          <ChevronRightIcon color="white" fontSize={26} fontWeight={700} />
        </Flex>
      </Box>
    </Link>
  )
}

export default TopicItem

import { useAppContext } from "AppContext"
import getCurrentCenter from "utils/getCurrentCenter"

const useCenterIsTester = () => {
  const { user, currentScope } = useAppContext()
  const currentCenter = getCurrentCenter({ currentScope, user })

  return currentCenter?.centerGroup?.isTester
}

export default useCenterIsTester

import React from "react"
import { SVGProps } from "../type"

const Genoux: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="44"
      fill="none"
      viewBox="0 0 38 44"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M35 4C24.6.4 8.667 3.834 2 6c2.4 7.6 1 26.834 0 35.5 3.2-1.2 23.333-.5 33 0-2.8-6.8-1.167-15.833 0-19.5 2-6.4.833-14.666 0-18z"
      ></path>
    </svg>
  )
}

export default Genoux

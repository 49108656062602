import { useAppContext } from "AppContext"
import SubscribeKine from "pages/Subscribe/components/SubscribeKine"
import SubscribeOsteo from "pages/Subscribe/components/SubscribeOsteo"

const Subscribe = () => {
  const { user } = useAppContext()
  const isKine = user?.osteo?.jobs?.some((job) => job.key === "kine")

  return isKine ? <SubscribeKine /> : <SubscribeOsteo />
}

export default Subscribe

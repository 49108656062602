import { MeType } from "api/user"
import { CurrentScope } from "AppContext"

const userHasAcademyInCurrentScope = (
  user: MeType | null,
  currentScope: CurrentScope | null
) =>
  currentScope?.type === "academy" &&
  user?.osteo.academies?.some((academy) => academy.id === currentScope.id)

export default userHasAcademyInCurrentScope

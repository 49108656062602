import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react"
import { useField } from "formik"
import { ReactNode } from "react"
import AAdressInput from "../AAdressInput"

interface AAdressFieldProps {
  name: string
  label?: ReactNode
  formControlProps?: FormControlProps
  formLabelProps?: FormLabelProps
}
const AAdressField = ({
  name,
  label = "Adresse *",
  formControlProps = {},
  formLabelProps = {},
  ...props
}: AAdressFieldProps) => {
  const [{ value }, { error }, { setValue }] = useField<AddressType>({
    name,
    type: "text",
  })

  return (
    <FormControl isInvalid={error !== undefined} {...formControlProps}>
      <FormLabel mb="8px">{label}</FormLabel>
      <AAdressInput
        setAddress={setValue}
        defaultValue={value?.name}
        {...props}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default AAdressField

import React from "react"
import { SVGProps } from "../type"

const Main: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      width="23"
      height="47"
      viewBox="0 0 23 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.1518 1H17.9391C18.4418 1 18.8664 1.37326 18.9308 1.87187L20.7273 15.7761L21.9709 26.9328C21.99 27.104 21.9645 27.2773 21.8969 27.4358L16.3551 40.4336C16.3008 40.561 16.2205 40.6758 16.1195 40.7706L11.2712 45.3192C10.8698 45.6957 10.2396 45.6772 9.86105 45.2777L1.27409 36.2147C1.09809 36.0289 1 35.7828 1 35.5269V13.2679C1 13.1499 1.02088 13.0329 1.06167 12.9222L5.21348 1.65426C5.35832 1.26115 5.73286 1 6.1518 1Z"
        fill={fill || "#E4FBEA"}
        fillOpacity={"0.8"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="2"
      />
    </svg>
  )
}

export default Main

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react"
import AAutocompleteMultiple from "components/AAutocompleteMultiple/AAutocompleteMultiple"
import { useField } from "formik"
import { ComponentProps } from "react"

interface AAutocompleteMultipleFieldProps<T>
  extends Omit<
    ComponentProps<typeof AAutocompleteMultiple<T>>,
    "selectedItems" | "setSelectedItems"
  > {
  name: string
  formControlProps?: FormControlProps
}
const AAutocompleteMultipleField = <T extends unknown>({
  name,
  formControlProps = {},
  ...props
}: AAutocompleteMultipleFieldProps<T>) => {
  const [{ value }, { error }, { setValue }] = useField<
    AAutocompleteMultipleFieldProps<T>["items"]
  >({
    name,
    multiple: true,
  })

  return (
    <FormControl isInvalid={error !== undefined} {...formControlProps}>
      <AAutocompleteMultiple
        isInvalid={error !== undefined}
        LabelComponent={FormLabel}
        selectedItems={value}
        setSelectedItems={setValue}
        {...props}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default AAutocompleteMultipleField

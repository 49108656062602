import React from "react"
import { SVGProps } from "../type"

const Coude: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="40"
      fill="none"
      viewBox="0 0 34 40"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M32 4.5l-26-2c.667 3.667 1.6 12 0 16s-3 11-3.5 14l25.5 5c1.6-6 3.333-24.5 4-33z"
      ></path>
    </svg>
  )
}

export default Coude

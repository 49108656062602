import { ChevronDownIcon } from "@chakra-ui/icons"
import * as Yup from "yup"
import {
  Box,
  Text,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Textarea,
  FormErrorMessage,
  FormControl,
  Switch,
} from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  getAllTrainingVideoExerciceType,
  TrainingVideoExerciceType,
} from "api/trainingVideoExerciceType"
import AAutocompleteMultiple from "components/AAutocompleteMultiple/AAutocompleteMultiple"
import AButton from "components/AButton"
import ANumberInput from "components/Field/ANumberInput"
import { Field, Form, Formik } from "formik"
import {
  ZonePathologieType,
  getZones,
} from "pages/NewAppointment/newAppointment.mock"
import { useEffect, useRef, useState } from "react"
import { createTrainingVideoUploaded } from "api/trainingVideoExercice"
import useToast from "hooks/useToast"
import useJobIsKine from "hooks/useIsJobKine"

interface NewExerciceModalProps {
  isOpen: boolean
  onClose: () => void
  appointmentReason?: number
}

const renderItem = ({ name }: ZonePathologieType) => name
const getKey = ({ id }: ZonePathologieType) => id.toString()

const NewExerciceModal = ({
  isOpen,
  onClose,
  appointmentReason,
}: NewExerciceModalProps) => {
  const { data: trainingVideoExerciceType } = useQuery(
    ["trainingVideoExerciceType"],
    getAllTrainingVideoExerciceType
  )
  const isJobKine = useJobIsKine()
  const queryClient = useQueryClient()

  const toast = useToast()
  const [level, setLevel] = useState<number>(1)
  const [zones, setZones] = useState<ZonePathologieType[]>([])
  const [zonesError, setZonesError] = useState<string>("")
  const [series, setSeries] = useState<number>(1)
  const [typeValue, setTypeValue] = useState<number>(30)
  const [rest, setRest] = useState<number>(30)
  const inputRef = useRef<HTMLInputElement>(null)
  const [video, setVideo] = useState<File | null>(null)
  const [videoError, setVideoError] = useState<string>("")
  const [canUseWeight, setCanUseWeight] = useState<boolean>(false)
  const [weight, setWeight] = useState<number>(5)
  const [
    selectedTrainingVideoExerciceType,
    setSelectedTrainingVideoExerciceType,
  ] = useState<TrainingVideoExerciceType>()

  const createTrainingVideoUploadedMutation = useMutation(
    createTrainingVideoUploaded
  )

  useEffect(() => {
    if (trainingVideoExerciceType) {
      setSelectedTrainingVideoExerciceType(trainingVideoExerciceType[0])
    }
  }, [trainingVideoExerciceType])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.400" />
      <ModalContent
        maxW="356"
        maxH="90vh"
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "primary.300",
            borderRadius: "24px",
          },
        }}
      >
        <Formik
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Titre requis"),
            description: Yup.string().optional(),
          })}
          initialValues={{
            title: "",
            description: "",
          }}
          onSubmit={({ title, description }, { setSubmitting }) => {
            if (!video) {
              setSubmitting(false)
              setVideoError("Veuillez ajouter une vidéo")
              return
            } else {
              setVideoError("")
            }

            if (!zones.length) {
              setSubmitting(false)
              setZonesError("Veuillez sélectionner au moins une zone")
              return
            } else {
              setZonesError("")
            }

            if (!selectedTrainingVideoExerciceType) {
              return
            }

            const formData = new FormData()
            formData.append("title", title)
            formData.append("description", description)
            formData.append("video", video)
            formData.append("level", level.toString())
            formData.append("zones", JSON.stringify(zones))
            formData.append("series", series.toString())
            formData.append("rest", rest.toString())
            formData.append("weight", weight.toString())
            formData.append("canUseWeight", canUseWeight.toString())
            formData.append(
              "serieType",
              selectedTrainingVideoExerciceType.id.toString()
            )
            if (selectedTrainingVideoExerciceType?.key !== "untilFailure")
              formData.append("serieValue", typeValue.toString())

            createTrainingVideoUploadedMutation.mutate(formData, {
              onError: (data: any) => {
                setSubmitting(false)
                toast({
                  status: "error",
                  title: data.response.data.error.message,
                })
              },
              onSuccess: async () => {
                setSubmitting(false)
                await queryClient.refetchQueries([
                  "uploadedVideos",
                  appointmentReason ?? -1,
                ])
                onClose()
                toast({
                  status: "success",
                  title: "Exercice créé avec succès",
                })
              },
            })
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box
                pos="relative"
                borderRadius={9}
                bg="white"
                w={352}
                py="16px"
                px="16px"
              >
                <ModalCloseButton aria-label="Fermer" />
                <Heading fontSize={18} fontWeight="bold" mb={4}>
                  Télécharger un exercice
                </Heading>

                <Flex
                  bg="common.200"
                  flexDir="column"
                  justifyContent="center"
                  alignItems="center"
                  py="60px"
                  borderRadius="8px"
                  mb={2}
                  cursor="pointer"
                  onClick={() => inputRef.current?.click()}
                >
                  <Input
                    type="file"
                    display="none"
                    ref={inputRef}
                    accept="video/*"
                    onChange={(e) => {
                      if (!e.currentTarget?.files || !e.currentTarget?.files[0])
                        return

                      // accept only file with max size 250Mo
                      if (e.currentTarget.files[0].size > 250 * 1000 * 1000) {
                        setVideoError(
                          "La taille de la vidéo ne doit pas dépasser 250Mo"
                        )
                        return
                      }

                      setVideoError("")
                      setVideo(e.currentTarget.files[0])
                    }}
                  />
                  <Text color="common.500" fontSize="16px" fontWeight={700}>
                    {video
                      ? "Vidéo sélectionnée"
                      : "Cliquez pour ajouter votre vidéo"}
                  </Text>
                  <Text color="common.400" fontSize="14px">
                    {video ? video.name : "orientation paysage, max 250Mo"}
                  </Text>
                </Flex>
                {videoError && (
                  <Text color="red.500" fontSize="14px">
                    {videoError}
                  </Text>
                )}
                <Field name="title">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.title && form.touched.title}
                      mb={1}
                    >
                      <Input
                        placeholder="Titre exercice"
                        border="none"
                        p={0}
                        fontSize="18px"
                        fontWeight={700}
                        _focusVisible={{ border: "none" }}
                        {...field}
                      />
                      <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Menu>
                  <MenuButton
                    w="fit-content"
                    px="8px"
                    py="4px"
                    border="1px solid #CBCCCF"
                    rounded="4px"
                    mb={2}
                    type="button"
                  >
                    <Flex alignItems="center" gap="6px">
                      <Text fontSize="14px">Niveau {level}</Text>
                      <ChevronDownIcon boxSize="20px" />
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    {[1, 2, 3].map((currLevel, index) => (
                      <MenuItem
                        fontSize="16px"
                        key={index + 1}
                        onClick={() => setLevel(currLevel)}
                      >
                        Niveau {currLevel}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
                <AAutocompleteMultiple
                  bg="white"
                  border="1px solid #CBCCCF"
                  width="100%"
                  placeholder="Sélectionner..."
                  selectedItems={zones}
                  setSelectedItems={setZones}
                  items={[
                    ...getZones(isJobKine),
                    {
                      code: "other",
                      name: "Autre",
                      id: -1,
                      left: "0",
                      top: "0",
                      schema: 1,
                      type: "zone",
                    },
                  ]}
                  renderItem={renderItem}
                  inputValue=""
                  getKey={getKey}
                  isLoading={false}
                />
                {zonesError && (
                  <Text color="red.500" fontSize="14px">
                    {zonesError}
                  </Text>
                )}

                <Field name="description">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={
                        form.errors.description && form.touched.description
                      }
                      mb={1}
                    >
                      <Textarea
                        borderColor="#CBCCCF"
                        my={2}
                        placeholder="Description de l'exercice"
                        rows={4}
                        {...field}
                      />
                      <FormErrorMessage>
                        {form.errors.description}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Text fontWeight={700} fontSize="14px" mb={2}>
                  Par défaut
                </Text>
                <Flex justifyContent="space-between" mb={2}>
                  <Text>Séries</Text>
                  <ANumberInput
                    name="series"
                    min={1}
                    max={10}
                    step={1}
                    defaultValue={1}
                    value={series}
                    setValue={setSeries}
                  />
                </Flex>
                <Flex justifyContent="space-between" mb={2} gap={4}>
                  <Menu>
                    <MenuButton
                      w="fit-content"
                      px="8px"
                      py="4px"
                      border="1px solid #CBCCCF"
                      rounded="4px"
                      mb={2}
                      type="button"
                    >
                      <Flex alignItems="center" gap="6px">
                        <Text fontSize="14px" textAlign="start">
                          {selectedTrainingVideoExerciceType?.title}
                        </Text>
                        <ChevronDownIcon boxSize="20px" />
                      </Flex>
                    </MenuButton>
                    <MenuList>
                      {trainingVideoExerciceType?.map((currType, index) => (
                        <MenuItem
                          fontSize="16px"
                          key={index + 1}
                          onClick={() =>
                            setSelectedTrainingVideoExerciceType(currType)
                          }
                          textAlign="start"
                        >
                          {currType.title}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                  {selectedTrainingVideoExerciceType?.key !==
                    "untilFailure" && (
                    <ANumberInput
                      name="typeValue"
                      value={typeValue}
                      setValue={setTypeValue}
                    />
                  )}
                </Flex>
                <Flex justifyContent="space-between" mb={4}>
                  <Text>Repos (en secondes)</Text>

                  <ANumberInput name="rest" value={rest} setValue={setRest} />
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Text>Compatible avec une charge</Text>
                  <Switch
                    checked={canUseWeight}
                    onChange={() => setCanUseWeight((prev) => !prev)}
                    colorScheme="orange"
                  />
                </Flex>

                {canUseWeight && (
                  <Flex justifyContent="space-between" mb={4}>
                    <Text>Poids (en kg)</Text>

                    <ANumberInput
                      name="weight"
                      value={weight}
                      setValue={setWeight}
                      defaultValue={5}
                      step={1}
                    />
                  </Flex>
                )}
                <AButton
                  type="submit"
                  isLoading={isSubmitting}
                  text="Ajouter"
                  variant="custom"
                  w="100%"
                  bg="primary.200"
                  _hover={{ bg: "primary.300" }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default NewExerciceModal

import { useAppContext } from "AppContext"

const useJobIsKine = () => {
  const { user } = useAppContext()
  const kineJobKey = "kine"

  return user?.osteo?.jobs?.some((job) => job.key === kineJobKey)
}

export default useJobIsKine

import {
  Box,
  Divider,
  Flex,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"

const ThreeParty = () => {
  return (
    <Flex direction={"column"}>
      <Heading as="h1" color={"tertiary.800"} mb={14}>
        Conditions Générales de Services
      </Heading>
      <p>
        <strong>Bienvenue sur Andrew</strong>
      </p>
      <Text mb={6} mt={6} fontSize={18} fontWeight={"bold"}>
        IMPORTANT
        <br />
        TOUTE INSCRIPTION SUR L’APPLICATION PAR LE PARTENAIRE IMPLIQUE
        L’ACCEPTATION SANS RESERVE PAR CELUI-CI DES PRESENTES CONDITIONS
        GENERALES DE SERVICES.
      </Text>
      <p>&nbsp;</p>
      <p>
        <strong>PREAMBULE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        La soci&eacute;t&eacute; ANDREWAPP, soci&eacute;t&eacute; par actions
        simplifi&eacute;e, immatricul&eacute;e au RCS de LYON sous le n&deg; 952
        463 636 et dont le si&egrave;ge social est situ&eacute; au 32 RUE DU
        B&OElig;UF 69005 (ci-apr&egrave;s &laquo; ANDREWAPP&raquo;) &eacute;dite
        et exploite l&rsquo;application Andrew accessible &agrave;
        l&rsquo;adresse suivante :{" "}
        <strong>
          <Link
            as={RouterLink}
            to="/"
            aria-label="Aller à l'accueil du site Andrew"
          >
            https://www.andrewapp.fr
          </Link>
        </strong>{" "}
        (ci-apr&egrave;s &laquo; l&rsquo;Application &raquo;).&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        L&rsquo;Application permet &agrave; des organismes (Organismes ou
        centres d&rsquo;ost&eacute;opathie) partenaires d&rsquo;ANDREWAPP
        (ci-apr&egrave;s les &laquo; <strong>Organismes Partenaires</strong>{" "}
        &raquo;) de mettre &agrave; disposition de certains de leurs
        &eacute;l&egrave;ves ou membres ost&eacute;opathes (ci-apr&egrave;s les
        &laquo; <strong>Th&eacute;rapeutes Partenaires</strong> &raquo;) un
        acc&egrave;s &agrave; l&rsquo;Application afin de leur permettre de
        cr&eacute;er un parcours d&rsquo;exercices &agrave; destination de leurs
        patients, utilisateurs de l&rsquo;Application et
        pr&eacute;c&eacute;demment re&ccedil;us en consultation (ci-apr&egrave;s
        les &laquo; <strong>Patients</strong> &raquo;) et, plus
        g&eacute;n&eacute;ralement, de leur donner acc&egrave;s &agrave;
        l&rsquo;ensemble des Services. L&rsquo;utilisation des Services et de
        l&rsquo;Application, par les Th&eacute;rapeutes Partenaires, est
        effectu&eacute;e sous l&rsquo;enti&egrave;re responsabilit&eacute; de
        l&rsquo;Organisme Partenaire, ce qu&rsquo;elle reconna&icirc;t et
        accepte.
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire qui n’accepte pas de se porter fort, vis-à-vis de
        ANDREWAPP, du respect, par les Thérapeutes Partenaires, de l’ensemble
        des dispositions des présentes ne doit pas souscrire à l’Application et
        aux Services, ce qu’il reconnait et accepte expressément.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 1. DEFINITIONS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Les termes, mentionn&eacute;s ci-dessous, ont dans les pr&eacute;sentes
        Conditions G&eacute;n&eacute;rales de Services, la signification
        suivante&nbsp;:&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4} spacing={4}>
        <ListItem>
          &laquo;&nbsp;<strong>Abonnement</strong>&nbsp;&raquo;&nbsp;:
          d&eacute;signe la souscription par l&apos;Organisme Partenaire aux
          Services propos&eacute;s par ANDREWAPP et tels que
          d&eacute;taill&eacute;s dans les pr&eacute;sentes Conditions
          G&eacute;n&eacute;rales de Service.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Anomalie&nbsp;&raquo;&nbsp;: </strong>
          d&eacute;signe toute difficult&eacute; de fonctionnement de
          l&rsquo;Application r&eacute;p&eacute;titive et reproductible.&nbsp;
        </ListItem>
        <ListItem>
          &laquo; <strong>Anomalie Bloquante</strong> &raquo; d&eacute;signe un
          dysfonctionnement qui emp&ecirc;che l&apos;utilisation de tout ou
          partie des fonctionnalit&eacute;s essentielles de
          l&rsquo;Application.&nbsp;&nbsp;&nbsp;
        </ListItem>
        <ListItem>
          &laquo; <strong>Anomalie Non Bloquante</strong> &raquo; d&eacute;signe
          tous dysfonctionnements autres que ceux d&eacute;finis dans les
          Anomalies Bloquantes, notamment ceux qui emp&ecirc;chent
          l&apos;utilisation normale de tout ou partie des
          fonctionnalit&eacute;s non essentielles de l&rsquo;Application ou qui
          peuvent &ecirc;tre contourn&eacute;s.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Application&nbsp;&raquo;</strong>&nbsp;: désigne
          l’application Andrew accessible pour les Organismes Partenaires et les
          Thérapeutes Partenaires par la souscription d’un Abonnement, par
          l’Organisme Partenaire, auprès d’ANDREWAPP. L’Application est en accès
          gratuit et librement téléchargeable pour les Patients Utilisateurs;
        </ListItem>
        <ListItem>
          <strong>
            &laquo;&nbsp;Backoffice&nbsp;&raquo; ou &laquo;&nbsp;Compte
            Administrateur&nbsp;&raquo;&nbsp;
          </strong>
          : d&eacute;signe l&rsquo;interface professionnelle réservée à
          l’administrateur désigné au sein de l’Organisme Partenaire et
          accessible sur le Site d&rsquo;Andrew &agrave; l&rsquo;adresse
          suivante&nbsp;:{" "}
          <strong>
            <Link
              as={RouterLink}
              to="https://pro.andrewapp.fr"
              target="_blank"
              aria-label="Visiter le site Andrew Pro"
            >
              www.pro.andrewapp.fr
            </Link>
          </strong>
          &nbsp;;&nbsp;
        </ListItem>
        <ListItem>
          <strong>
            &laquo;&nbsp;Compte&nbsp;Utilisateur ou Patient
            Utilisateur&nbsp;&raquo;
          </strong>
          &nbsp;: d&eacute;signe l&rsquo;espace &agrave; partir duquel les
          Patients Utilisateurs acc&egrave;dent &agrave; l&rsquo;Application.
          L&rsquo;acc&egrave;s se fait via les Identifiants.
        </ListItem>
        <ListItem>
          <strong>
            &laquo;&nbsp;Compte Thérapeute Partenaire&nbsp;&raquo;&nbsp;
          </strong>
          : désigne l’espace à partir duquel les Thérapeutes Partenaires
          accèdent aux Services mis à leur disposition sur l’Application.
          L’accès se fait via les Identifiants.
        </ListItem>
        <ListItem>
          <strong>
            &laquo;&nbsp;Conditions G&eacute;n&eacute;rales de
            Services&nbsp;&raquo; ou &laquo;&nbsp;CGS&nbsp;&raquo;&nbsp;:
          </strong>{" "}
          désigne les présentes conditions contractuelles acceptées par (i) les
          Organismes Partenaires lors de leur inscription sur l’Application et
          par (ii) les Thérapeutes Partenaires préalablement à leur utilisation
          des Services et encadrant l’utilisation de l’Application et des
          Services ;
        </ListItem>
        <ListItem>
          <strong>
            &laquo;&nbsp;Conditions G&eacute;n&eacute;rales
            d&rsquo;Utilisation&nbsp;&raquo; ou
            &laquo;&nbsp;CGU&nbsp;&raquo;&nbsp;:
          </strong>{" "}
          désigne les conditions contractuelles acceptées par les Patients
          Utilisateurs lors de leur inscription sur l’Application et encadrant
          leur utilisation de l’Application ;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Contenu&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe l&rsquo;ensemble des photographies, textes,
          vid&eacute;o, fichiers, audio, podcast ou tout autre contenu
          accessibles &agrave; partir de l&rsquo;Application&nbsp;;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Jours Ouvr&eacute;s&nbsp;&raquo;&nbsp;: </strong>
          d&eacute;signe les jours habituellement travaill&eacute;s en France,
          c&rsquo;est-&agrave;-dire du lundi au vendredi, &agrave;
          l&rsquo;exclusion des jours f&eacute;ri&eacute;s, entre 9 h et 18
          heures .&nbsp;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Patient&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe le patient auquel le Thérapeute Partenaire propose un
          suivi post-consultation via l&rsquo;Application ;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Organismes Partenaires&nbsp;&raquo;</strong>
          &nbsp;: désigne les Organismes d’ostéopathie ayant souscrit un
          Abonnement à l’Application Andrew afin de mettre à disposition des
          Thérapeutes Partenaires les Services. Les Organismes Partenaires
          acceptent les présentes Conditions Générales de Services ;
        </ListItem>
        <ListItem>
          <strong> « Thérapeutes Partenaires »</strong> : désigne les élèves ou
          membres ostéopathes dont l’école ou le centre d’ostéopathie auquel ils
          sont rattachés est Organisme Partenaire et qui leur a donné un accès à
          l’Application via la mise à disposition d’un Compte Thérapeute
          Partenaire ; les Thérapeutes Partenaires sont tenus d’accepter les CGS
          préalablement à toute utilisation des Services.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Prestataire de Services de paiement</strong>
          &nbsp;&raquo; ou &laquo;&nbsp;<strong>PSP</strong>
          &nbsp;&raquo;&nbsp;: désigne la société, détentrice d’un agrément
          bancaire, fournissant des services de paiement à ANDREWAPP afin de
          leur permettre d’encaisser les paiements des Organismes Partenaires.
          Le Prestataire de Services de Paiement de l’Opérateur est Stripe
          Payments Europe Ltd., immatriculée sous le numéro 985420235 (DUNS
          number) dont le siège social est C/O A & L Goodbody, Ifsc, North Wall
          Quay, Dublin, D01 H104, Ireland. Ireland.&nbsp;&nbsp;
        </ListItem>
        <ListItem>
          <strong>
            &laquo;&nbsp;Profil du Thérapeute Partenaire&nbsp;&raquo;&nbsp;
          </strong>
          : désigne le profil du Thérapeute Partenaire tel qu’accessible par les
          Patients Utilisateurs dans l’Application et paramétrable par le
          Thérapeute Partenaire depuis son Compte Thérapeute Partenaire ;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Profil Patient&nbsp;&raquo;&nbsp;</strong>:
          désigne le profil Patient créé par un Thérapeute Partenaire depuis son
          Compte Thérapeute Partenaire;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Identifiants&nbsp;&raquo;</strong>&nbsp;: désigne
          l’adresse email et le mot de passe nécessaires à la connexion aux
          Comptes Administrateur, Thérapeutes Partenaires et Patients
          Utilisateurs ;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Parties&nbsp;&raquo;</strong>&nbsp;: désigne
          ensemble, d’une part la société ANDREWAPP et d’autre part les
          Organismes Partenaires et les Thérapeutes Partenaires ayant accepté
          les présentes CGS. Au singulier, désigne une seule des Parties ;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Services&nbsp;&raquo;</strong>&nbsp;: désigne
          l’ensemble des fonctionnalités et services proposés par ANDREWAPP via
          l’Application. Ils comprennent le Service de suivi du Patient et le
          Service de prise de rendez-vous en ligne ;
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Site&nbsp;&raquo;&nbsp;</strong>: d&eacute;signe
          le site internet d&rsquo;Andrew accessible &agrave; partir de
          l&rsquo;URL{" "}
          <strong>
            <Link
              as={RouterLink}
              to="/"
              aria-label="Aller à l'accueil du site Andrew"
            >
              www.andrewapp.fr
            </Link>
          </strong>{" "}
          et à partir duquel les Organismes Partenaires et les Thérapeutes
          Partenaires ont accès à leur Compte Administrateur et Thérapeutes
          Partenaires ;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 2. OBJET ET ACCEPTATION DES CGS</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        Les présentes Conditions Générales de Services ont pour objet de fixer
        les dispositions contractuelles relatives aux droits et obligations
        respectifs d’ANDREWAPP, des Organismes Partenaires et des Thérapeutes
        Partenaires dans le cadre de l’utilisation de l’Application Andrew et
        des Services qui y sont proposés.
      </p>
      <p>&nbsp;</p>
      <p>
        Ainsi, les Organismes Partenaires et les Thérapeutes Partenaires
        s’engagent à lire attentivement les présentes CGS et sont invités à les
        télécharger, les imprimer et à en conserver une copie.
      </p>
      <p>&nbsp;</p>
      <p>
        Il est précisé que les présentes CGS, disponibles dans le footer du
        Backoffice au moyen d’un lien hypertexte, peuvent ainsi être consultées
        à tout moment.
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire reconnaît avoir reçu d’ANDREWAPP toutes les
        informations et conseils lui permettant de bien connaître la teneur des
        Services, d’apprécier leur adéquation à ses besoins et ainsi d’accepter
        les Conditions Générales de Services en connaissance de cause.
      </p>
      <p>&nbsp;</p>
      <p>
        Les présentes CGS, qui excluent tout lien de subordination, ne confèrent
        en aucun cas à l’Organisme Partenaire, ni au Thérapeute Partenaire la
        qualité de salarié, mandataire, agent ou représentant d’ANDREWAPP. Les
        Parties déclarent en outre que les présentes CGS ne peuvent en aucun cas
        être considérées comme un acte constitutif de personne morale ou d’une
        entité juridique quelconque, et que toute forme « d’affectio societatis
        » est formellement exclue de leurs relations.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 3. MODIFICATION DES CGS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP se r&eacute;serve la possibilit&eacute; de modifier &agrave;
        tout moment les pr&eacute;sentes CGS.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Ces modifications seront notifiées à l’Organisme Partenaire et au
        Thérapeute Partenaire sur un support durable au moins trente (30) jours
        avant l’entrée en vigueur des changements. En cas de modifications
        substantielles des présentes, il convient de distinguer les hypothèses
        suivantes :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Soit l’Organisme Partenaire consent auxdites modifications
          substantielles, auquel cas celles-ci entreront automatiquement en
          vigueur à la date prévue dans la notification,
        </ListItem>
        <ListItem>
          Soit l’Organisme Partenaire refuse les modifications substantielles,
          auquel cas il pourra résilier son Abonnement avant l’expiration du
          délai de préavis. Cette résiliation prend effet dans les quinze (15)
          jours suivant la réception de la notification par l’Organisme
          Partenaire de sa décision de résilier son Abonnement.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Le Thérapeute Partenaire est informé et accepte que toute résiliation de
        l’Abonnement de l’Organisme Partenaire entraine la fin des Services
        auxquels il n’aura plus accès.
      </p>
      <p>&nbsp;</p>
      <p>
        Le Thérapeute Partenaire qui n’accepterait pas les CGS modifiées, malgré
        l’acceptation de l’Organisme Partenaire à laquelle il est rattaché, doit
        cesser d’utiliser les Services et en informer l’Organisme Partenaire.
      </p>
      <p>&nbsp;</p>
      <p>
        Le Thérapeute Partenaire qui continue à utiliser les Services est réputé
        accepter les CGS modifiées.
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire accepte expressément que son silence consécutif à
        l’information donnée au sujet de la modification des présentes CGS
        pendant un délai de quinze (15) jours soit considéré comme une
        acceptation des modifications apportées.
      </p>
      <p>&nbsp;</p>
      <p>
        Ce d&eacute;lai de pr&eacute;avis est susceptible de ne pas
        s&rsquo;appliquer si&nbsp;:&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          ANDREWAPP est assujettie &agrave; une obligation l&eacute;gale ou
          r&eacute;glementaire de changer ses conditions g&eacute;n&eacute;rales
          d&rsquo;une mani&egrave;re qui ne lui permet pas de respecter ce
          d&eacute;lai de pr&eacute;avis&nbsp;;&nbsp;
        </ListItem>
        <ListItem>
          ANDREWAPP doit exceptionnellement changer ses conditions générales
          pour faire face à un danger imminent et imprévu afin de protéger
          l’Application et/ou les Patients Utilisateurs contre la fraude, des
          logiciels malveillants, des spams ou tout risque en matière de
          cybersécurité.
        </ListItem>
      </UnorderedList>
      <p>
        <br />
        <br />
      </p>
      <p>
        <strong>ARTICLE 4. MODALITES D&rsquo;ACCES AUX SERVICES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          4.1. Modalit&eacute;s d&rsquo;inscription des Partenaires
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Pour avoir accès au Backoffice et être référencé sur l’Application, mais
        également faire bénéficier des Services tout Thérapeute Partenaire de
        son choix, l’Organisme Partenaire devra notamment :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Disposer de l’ensemble des qualifications et autorisations requises
          pour exercer sa mission ou son activité en conformité avec la
          règlementation en vigueur au jour de son inscription, puis tout long
          de l’utilisation de l’Application ;
        </ListItem>
        <ListItem>
          Prendre contact avec ANDREWAPP et lui communiquer les informations
          suivantes (ci-après la « Demande ») :
        </ListItem>
        <p>&nbsp;</p>
        <p>Sur son Administrateur :</p>
        <UnorderedList pl={4}>
          <ListItem>Nom et pr&eacute;nom</ListItem>
          <ListItem>Email</ListItem>
          <ListItem>Num&eacute;ro de t&eacute;l&eacute;phone</ListItem>
          <ListItem>
            Informations bancaires&nbsp; de l&apos;Organisme Partenaire
          </ListItem>
        </UnorderedList>
        <p>&nbsp;</p>
        <p>Sur ses Thérapeutes Partenaires :</p>
        <UnorderedList pl={4}>
          <ListItem>Leur nombre</ListItem>
          <ListItem>Email</ListItem>
          <ListItem>Num&eacute;ro de t&eacute;l&eacute;phone</ListItem>
          <ListItem>Nom</ListItem>
          <ListItem>Prénom</ListItem>
        </UnorderedList>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP se réserve la faculté d’accepter ou de refuser l’inscription
        de l’Organisme Partenaire.
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de refus de l’inscription de l’Organisme Partenaire par
        ANDREWAPP, cette dernière informera l’Organisme Partenaire par e-mail.
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP se réserve le droit de refuser l’inscription d’une Organisme
        Partenaire ne satisfaisant pas aux exigences des présentes, notamment au
        regard des conditions de qualifications et compétences professionnelles
        précitées.
      </p>
      <p>&nbsp;</p>
      <p>
        A réception de la Demande et sous réserve de sa validation par
        ANDREWAPP, cette dernière ouvrira :
        <UnorderedList>
          <ListItem>
            un Compte Administrateur au nom de l’Organisme Partenaire et
          </ListItem>
          <ListItem>
            autant de Comptes Thérapeutes que d’élèves ou de membres ostéopathes
            auxquels l’Organisme Partenaire souhaite faire bénéficier des
            Services et tel que mentionné dans la Demande.
          </ListItem>
        </UnorderedList>
      </p>
      <p>&nbsp;</p>
      <p>
        Une fois le Compte Administrateur et les Comptes Thérapeutes ouverts,
        ANDREWAPP adressera, par email à l’Administrateur, des liens de
        confirmation des Comptes. Ces liens internet permettent la validation du
        Compte Administrateur et des Comptes Thérapeutes.
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire et les Thérapeutes Partenaires reconnaissent et
        acceptent que pour pouvoir activer leur Compte et bénéficier des
        Services, chacun d’eux devra accepter les présentes CGS en cochant la
        case : « J’ai lu et j’accepte les conditions générales de Services de
        ANDREWAPP ».
      </p>
      <p>&nbsp;</p>
      <p>
        Chacun des Thérapeutes Partenaires pourra bénéficier des Services en se
        connectant à son Compte.
      </p>
      <p>&nbsp;</p>
      <p>
        En tout état de cause, pour pouvoir bénéficier des Services, le
        Thérapeute Partenaire doit disposer d’une adresse de courrier
        électronique valide et fonctionnelle.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4.2. Modalités de sélection des Thérapeutes Partenaires</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        L’Application étant dédiée au suivi de consultations ostéopathiques, les
        Thérapeutes Partenaires devront détenir les compétences, qualifications
        et qualités professionnelles requises par la profession d’ostéopathe et,
        s’agissant des élèves, avoir validé au moins leur 4e année d’étude . Il
        s’agit d’une condition essentielle à l’accès à l’Application par les
        Thérapeutes Partenaires, ce que l’Organisme Partenaire reconnait et
        accepte expressément.
      </p>
      <p>&nbsp;</p>
      <p>
        Ainsi, les Thérapeutes Partenaires déclarent remplir l’ensemble des
        conditions et exigences suivantes :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Se conformer aux exigences des pr&eacute;sentes CGS&nbsp;;&nbsp;
        </ListItem>
        <ListItem>
          Fournir, et maintenir à jour, l’ensemble des informations demandées
          par ANDREWAPP et permettant de vérifier leur statut et leur conformité
          aux obligations légales. L’Organisme Partenaire garantit la véracité
          des informations transmises sur les Thérapeutes Partenaires et
          informera ANDREWAPP de toute modification ou difficulté quelconque
          rencontrée quant à la fourniture de leurss informations ou de leur
          statut ;
        </ListItem>
        <ListItem>
          Dans le cadre de l’usage de l’Application et des Services, respecter à
          tout moment les obligations imposées aux Thérapeutes Partenaires au
          titre des obligations déontologiques et légales qui leur sont imposées
          par la réglementation en vigueur et les règles reconnues par la
          profession ;
        </ListItem>
        <ListItem>
          Respecter les règles d’usage en matière de communication en ligne,
          tout particulièrement à l’égard du Patient et de tout représentant
          d’ANDREWAPP.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Par ailleurs, l’Organisme Partenaire se porte garant, vis-à-vis
        d’ANDREWAPP, du respect de l’ensemble de ces conditions et, plus
        généralement, des obligations imparties aux Thérapeutes Partenaires au
        titre des présentes ou induites par l’utilisation des Services.
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP se réserve la possibilité de vérifier la véracité des
        informations professionnelles fournies sur ou par les Thérapeutes
        Partenaires, notamment par la mise en œuvre de tout procédé
        d’authentification nécessaire pour accéder à la solution.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 5. SERVICES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.1. Service de suivi du Patient</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le Service de suivi du Patient permet aux Thérapeutes Partenaires de
        réaliser un suivi post-consultation de leurs Patients.
      </p>
      <p>&nbsp;</p>
      <p>
        Aussi, dès la première consultation, les Thérapeutes Partenaires
        peuvent, depuis leur Compte :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Créer des Profils Patients pour permettre à leurs Patients
          préalablement reçus en consultation et souhaitant bénéficier des
          Services offerts sur l’Application, de recevoir un lien d’activation
          et de créer leur Compte Utilisateur. Le Thérapeute Partenaire est
          invité à compléter les informations suivantes relatives à son Patient
          :
        </ListItem>
        <UnorderedList pl={4}>
          <ListItem>Nom et pr&eacute;nom&nbsp;;&nbsp;</ListItem>
          <ListItem>Email&nbsp;;</ListItem>
          <ListItem>Code postal&nbsp;(facultatif);&nbsp;</ListItem>
          <ListItem>
            Num&eacute;ro de t&eacute;l&eacute;phone (facultatif);
          </ListItem>
        </UnorderedList>
      </UnorderedList>

      <p>
        Le Thérapeute Partenaire est entièrement responsable de l’exactitude des
        données de ses Patients renseignées lors de la création d’un Profil
        Patient, puis lors du suivi de ses Patients depuis son Compte Thérapeute
        ;
      </p>
      <UnorderedList pl={4}>
        <ListItem>
          Saisir des informations relatives &agrave; la pathologie et au
          parcours de soin du Patient d&egrave;s la phase de consultation.
        </ListItem>
        <ListItem>
          Saisir des notes d’ordres générales sur le patient à l’exception de
          toutes données de santé et de tous propos injurieux.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        L&rsquo;acc&egrave;s aux autres services compris dans le Service de
        suivi du Patient, pour un Patient donn&eacute;, est conditionn&eacute;
        &agrave; l&rsquo;acceptation des Conditions G&eacute;n&eacute;rales
        d&rsquo;Utilisation par ledit Patient.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Aussi, dès acceptation des CGU par le Patient et, ainsi, création de son
        Compte Utilisateur, le Thérapeute Partenaire peut, depuis son Compte
        Thérapeute :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Sugg&eacute;rer des Contenus choisis aupr&egrave;s du Patient
          Utilisateur sur la base d&rsquo;un catalogue de Contenus
          propos&eacute;s dans l&rsquo;Application&nbsp;;
        </ListItem>
        <ListItem>
          Proposer des Contenus sp&eacute;cifiques et un planning
          d&rsquo;exercices aupr&egrave;s du Patient re&ccedil;u en
          consultation&nbsp;;
        </ListItem>
        <ListItem>
          D&eacute;livrer des conseils de suivi ou des rappels aupr&egrave;s des
          Patients re&ccedil;us en consultation&nbsp;;
        </ListItem>
        <ListItem>
          Adapter les Contenus proposés à chaque Patient au fil des
          consultations et des réponses formulées par le Patient lors des
          questionnaires de suivi ;
        </ListItem>
        <ListItem>
          Réaliser un suivi de l’évolution des symptômes du Patient suite à la
          consultation et à la réalisation des exercices conseillés ;
        </ListItem>
        <ListItem>
          Réaliser un suivi de l’évolution du stress du Patient Utilisateur.
        </ListItem>
      </UnorderedList>
      <p>
        <br />
      </p>
      <p>
        L’Application permet aux Thérapeutes Partenaires de se voir proposer des
        Contenus qu’ils peuvent proposer auprès de leurs Patients Utilisateurs
        de l’Application.
      </p>
      <p>&nbsp;</p>
      <p>
        Les Contenus sont proposés sur la base du catalogue contenu au sein de
        l’Application et selon les champs renseignés par le Thérapeute
        Partenaire au sein du Profil Patient concerné.
      </p>
      <p>&nbsp;</p>
      <p>
        Pour se voir proposer des Contenus spécifiques du catalogue, le
        Thérapeute Partenaire peut renseigner les informations suivantes au sein
        du Profil Patient sur la base des informations recueillies en
        consultation :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>Symptomatologie&nbsp;;</ListItem>
        <ListItem>Zone de plainte&nbsp;;</ListItem>
        <ListItem>Motif de la consultation.</ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Le Contenu sp&eacute;cifique sera accessible par le Patient depuis
        l&rsquo;Application pendant une dur&eacute;e de trois (3) mois.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Le Thérapeute Partenaire est invité à prendre connaissance des Contenus
        suggérés en intégralité et de manière attentive afin de vérifier que ces
        Contenus sont adaptés au Patient Utilisateur auquel il souhaite les
        proposer, à la lumière du diagnostic réalisé en consultation et des
        particularités de chaque Patient.
      </p>
      <p>&nbsp;</p>
      <p>
        Le Thérapeute Partenaire est seul responsable de la réalisation des
        consultations d’ostéopathie et de la cohérence des contenus proposés par
        l’intermédiaire de l’Application avec le diagnostic effectué en
        consultation, ce que les Thérapeutes Partenaires et l’Organisme
        Partenaire reconnaissent et acceptent expressément. L’Organisme
        Partenaire s’engage à garantir ANDREWAPP de toute contestation des
        Thérapeutes Partenaires à cet effet dirigée à son encontre.
      </p>
      <p>&nbsp;</p>
      <p>
        L’utilisation de l’Application ne saurait diluer, modifier ou atténuer
        la responsabilité et les obligations du Thérapeute Partenaire et des
        Organismes Partenaires vis-à-vis des Patients Utilisateurs de
        l’Application.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.2. Service de prise de rendez-vous en ligne</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le Service de prise de rendez-vous en ligne permet aux Patients de
        prendre rendez-vous avec les diff&eacute;rents Thérapeutes Partenaires
        accessibles via l&rsquo;Application au sein de l&rsquo;encart
        Doctolib.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Pour permettre aux Patients de prendre rendez- vous en ligne et gérer
        ces rendez-vous, le Thérapeute Partenaire et l’Organisme Partenaire sont
        informés et acceptent que :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          le Thérapeute Partenaire doit disposer d’un compte Doctolib permettant
          aux Patients de réserver des rendez-vous en ligne ;
        </ListItem>
        <ListItem>
          l’URL de son profil Doctolib, doit être communiqué à ANDREWAPP, lors
          de l’inscription de l’Organisme Partenaire, dans le champ dédié à cet
          effet
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>
          ARTICLE 6. CONNEXION AU BACKOFFICE ET AUX COMPTES THERAPEUTES
          PARTENAIRES
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire et les Thérapeutes Partenaires peuvent se
        connecter à leur Compte en renseignant leurs Identifiants, à savoir leur
        adresse email et mot de passe.
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire sera responsable vis- à-vis de ANDREWAPP de
        l&apos;utilisation faite des Comptes Administrateur et/ou Thérapeutes
        Partenaire, ce qu’il reconnait et accepte expressément.
      </p>
      <p>&nbsp;</p>
      <p>
        En aucun cas, ANDREWAPP ne saurait être tenue responsable en cas
        d’usurpation de l’identité d’un Administrateur ou d’un Thérapeute
        Partenaire. Tout accès et actions effectués à partir des Comptes
        Administrateur et Thérapeutes Partenaires seront présumés être effectués
        par l’Organisme Partenaire ou par un Thérapeute Partenaire autorisé et
        sous sa responsabilité.
      </p>
      <p>&nbsp;</p>
      <p>
        Toute perte, détournement, ou utilisation non autorisée des Identifiants
        d’un Thérapeute Partenaire et leurs conséquences relèvent de la seule
        responsabilité du Thérapeute Partenaire concerné et de l’Organisme
        Partenaire.
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cas où un Administrateur ou Thérapeute Partenaire divulguerait
        ou utiliserait ses Identifiants de façon contraire à leur destination,
        ANDREWAPP pourra alors suspendre son accès à l’Application, ce dernier
        étant tenu d’en avertir ANDREWAPP sans délai, par message électronique
        adressé à l’adresse suivante : contact@andrewapp.fr
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 7. MODALITES FINANCIERES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Lors de l&rsquo;inscription, l&apos;Organisme Partenaire a le choix de
        souscrire un Abonnement mensuel ou annuel, selon les conditions
        tarifaires d&eacute;taill&eacute;es en{" "}
        <strong>
          <em>Annexe 1</em>
        </strong>{" "}
        &laquo;&nbsp;Conditions tarifaires&nbsp;&raquo;.
      </p>
      <p>&nbsp;</p>
      <p>
        Le paiement s’effectue au moment de l’inscription, par virement suite à
        envoi d’une facture, par carte bancaire (CB, Visa, MasterCard, American
        Express) via le pestataire de service de paiement Stripe, auprès duquel
        l’Organisme Partenaire renseigne directement ses coordonnées bancaires.
      </p>
      <p>&nbsp;</p>
      <p>
        En acceptant les présentes CGS, le Partenaire accepte le prélèvement
        correspondant au prix d’Abonnement tel que détaillé en <em>Annexe 1</em>
        « Conditions tarifaires » et conformément au mandat de prélèvement
        accepté auprès du PSP.
      </p>
      <p>&nbsp;</p>
      <p>
        La durée de l&apos;Abonnement se renouvelle automatiquement, aux mêmes
        termes et conditions, par tacite reconduction pour des périodes de même
        durée (mensuelle ou annuelle suivant l’Abonnement choisi) sauf
        résiliation par l&apos;Organisme Partenaire dans les conditions
        précisées à l&apos;Article 15 ci-après.
      </p>
      <p>&nbsp;</p>
      <p>
        Le Paiement s’effectue par l’intermédiaire du Prestataire de Services de
        paiement (PSP). Pour pouvoir payer et bénéficier des Services
        l’Organisme Partenaire devra accepter les Conditions Générales du PSP,
        ce qu’il reconnait et accepte expressément.
      </p>
      <p>&nbsp;</p>
      <p>
        Il est entendu entre les Parties que les présentes CGS et les conditions
        générales du PSP sont des contrats interdépendants. Ainsi ANDREWAPP
        pourra librement, de plein droit et sans délai, mettre fin aux présentes
        dans l’hypothèse où le PSP mettrait fin à ses relations contractuelles
        avec l’Organisme Partenaire.
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de défaut ou de retard de paiement après mise en demeure restée
        sans réponse dans un délai de trente (30) jours, ANDREWAPP se réserve la
        possibilité d’activer sa procédure de recouvrement.
      </p>
      <p>
        Dans ce cas, l’Organisme Partenaire sera de plein droit débitrice, à
        l&apos;égard d’ANDREWAPP, d&apos;une indemnité forfaitaire pour frais de
        recouvrement d’un montant de 40 euros. Lorsque des frais de recouvrement
        exposés sont supérieurs au montant de cette indemnité forfaitaire,
        ANDREWAPP pourra demander une indemnisation compl&eacute;mentaire, sur
        justification.
      </p>
      <p>&nbsp;</p>
      <p>
        Le défaut de paiement de l’Organisme Partenaire entraîne automatiquement
        une suspension des Services jusqu’au règlement complet des sommes dues
        et ce, par carte bancaire, virement bancaire ou prélèvement automatique.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 8. OBLIGATIONS D&rsquo;ANDREWAPP</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP s&rsquo;engage &agrave; r&eacute;aliser les Services dans le
        respect des r&egrave;gles applicables, telles que r&eacute;sultant des
        r&egrave;gles de l&rsquo;art, normes europ&eacute;ennes, lois,
        d&eacute;crets, arr&ecirc;t&eacute;s et textes l&eacute;gislatifs,
        r&eacute;glementaires ou administratifs nationaux, locaux ou
        professionnels.
      </p>
      <p>&nbsp;</p>
      <p>
        Il est express&eacute;ment convenu entre les Parties qu&rsquo;ANDREWAPP
        est soumise &agrave; une obligation g&eacute;n&eacute;rale de moyens et
        qu&rsquo;elle n&rsquo;est tenue par aucune obligation de r&eacute;sultat
        ou de moyens renforc&eacute;s d&rsquo;aucune sorte.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.1. H&eacute;bergement - disponibilit&eacute;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP s&rsquo;engage &agrave; mettre tous les moyens en &oelig;uvre
        pour assurer une continuit&eacute; d&rsquo;acc&egrave;s et
        d&rsquo;utilisation de l&rsquo;Application.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre,&nbsp;ANDREWAPP h&eacute;berge les donn&eacute;es de
        l&rsquo;Application aupr&egrave;s d&rsquo;un h&eacute;bergeur de
        donn&eacute;es de sant&eacute; certifi&eacute; HDS dans les conditions
        pr&eacute;cis&eacute;es en{" "}
        <strong>
          <em>Annexe 3</em>
        </strong>{" "}
        des pr&eacute;sentes.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP attire toutefois l’attention des Thérapeutes Partenaires et
        des Organismes Partenaires sur le fait que les protocoles actuels de
        communication via Internet ne permettent pas d’assurer de manière
        certaine et continue la transmission des échanges électroniques et
        Contenus.
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP ne saurait &ecirc;tre tenue responsable des perturbations,
        coupures et anomalies qui ne sont pas de son fait et qui affecteraient,
        par exemple, les transmissions par le r&eacute;seau Internet et plus
        g&eacute;n&eacute;ralement par le r&eacute;seau de communication,
        quelles qu&rsquo;en soient l&rsquo;importance et la dur&eacute;e.
      </p>
      <p>&nbsp;</p>
      <p>
        Par ailleurs, conform&eacute;ment aux dispositions des{" "}
        <strong>
          <Link
            as={RouterLink}
            to="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000033219601/"
            target="_blank"
            aria-label="Lire l'article L.111-7 sur le site legifrance"
          >
            articles L.111-7 et suivants du Code de la consommation
          </Link>
        </strong>
        , ANDREWAPP s&rsquo;engage &agrave; apporter une information claire
        transparente et loyale sur les modalit&eacute;s de son intervention.
        <strong>&nbsp;&nbsp;&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.2. Maintenance&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP s&rsquo;engage &agrave; mettre tout en &oelig;uvre pour faire
        effectuer les corrections techniques &agrave; apporter &agrave;
        l&rsquo;Application concernant les &eacute;ventuelles Anomalies,
        notamment celles impactant la s&eacute;curit&eacute; informatique de
        l&rsquo;Application.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre, il est pr&eacute;cis&eacute; que les Anomalies sont
        r&eacute;pertori&eacute;es en fonction de la nature des
        dysfonctionnements constat&eacute;s&nbsp;:
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          ANDREWAPP s&apos;engage &agrave; corriger toute Anomalie Bloquante ou
          mettre en place une solution de contournement, dans un d&eacute;lai de
          trois (3) Jours Ouvr&eacute;s &agrave; compter de sa notification par
          l&apos;Organisme Partenaire.&nbsp;
        </ListItem>
        <ListItem>
          ANDREWAPP s&rsquo;engage &agrave; corriger toute Anomalie Non
          Bloquante dans sept (7) Jours Ouvr&eacute;s &agrave; compter de sa
          notification par l&apos;Organisme Partenaire.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Les Organismes Partenaires peuvent remonter &agrave; ANDREWAPP, tel que
        pr&eacute;vu &agrave; l&rsquo;Article 16 &laquo;&nbsp;Assistance &ndash;
        Support Partenaire&nbsp;&raquo;.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Toute intervention résultant d’une mauvaise utilisation par l’Organisme
        Partenaire ou les Thérapeutes Partenaires de l’Application ou des
        Services pourra donner lieu à une facturation spécifique.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.3. Evolution de l&rsquo;Application</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Il est express&eacute;ment convenu entre les Parties que
        l&rsquo;Application et les Services objets des pr&eacute;sentes pourront
        faire l&rsquo;objet d&rsquo;&eacute;volutions d&eacute;cid&eacute;es par
        ANDREWAPP en vue de s&rsquo;adapter aux &eacute;volutions des
        technologies ou pour optimiser ses Services.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Dans ce cas, l’Organisme Partenaire accepte sans réserve que lui soient
        appliquées lesdites évolutions après en avoir été préalablement informé
        par ANDREWAPP. Dans l’hypothèse où l’Organisme Partenaire n’accepterait
        pas lesdites évolutions, il disposera alors de la possibilité de
        résilier son Abonnement selon les modalités prévues à l’Article 15 des
        présentes.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.4. S&eacute;curit&eacute;&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP s&rsquo;engage &agrave; tout mettre en &oelig;uvre pour&nbsp;:
      </p>
      <UnorderedList pl={4}>
        <ListItem>
          Assurer une s&eacute;curit&eacute; logique et physique de ses
          syst&egrave;mes d&rsquo;information&nbsp;;
        </ListItem>
        <ListItem>
          R&eacute;duire au minimum le risque d&rsquo;une infraction de
          s&eacute;curit&eacute;.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP s’engage ainsi à protéger les Contenus ou Données fournis par
        l’Organisme Partenaire ou les Thérapeutes Partenaires.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.5. Conservation des donn&eacute;es</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP respecte les durées légales de conservation des données de
        l’Organisme Partenaire ou des Thérapeutes Partenaires.
      </p>
      <p>&nbsp;</p>
      <p>
        En application de l’article L. 123-22 du Code de commerce, les données
        de l’Organisme Partenaire ou des Thérapeutes Partenaires seront ainsi
        conservées pendant dix (10) ans à des fins probatoires et cinq (5) ans
        pour prouver l’existence de la relation contractuelle en application de
        l’article 2224 du Code civil.
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de r&eacute;siliation de l&rsquo;Abonnement, les modalit&eacute;s
        de suppression des Contenus sont d&eacute;taill&eacute;es &agrave;
        l&rsquo;Article 15.&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          ARTICLE 9. OBLIGATIONS DE L’ORGANISME PARTENAIRE ET DES THERAPEUTES
          PARTENAIRES
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9.1. Obligations g&eacute;n&eacute;rales</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Lors de l&rsquo;utilisation de l&rsquo;Application, chaque Thérapeute
        Partenaire s&rsquo;engage &agrave; ne pas porter atteinte &agrave;
        l&rsquo;ordre public et &agrave; se conformer aux lois et
        r&egrave;glements en vigueur, &agrave; respecter les droits des tiers et
        les dispositions des pr&eacute;sentes CGS.
      </p>
      <p>Chaque Thérapeute Partenaire a pour obligation de :</p>
      <UnorderedList pl={4}>
        <ListItem>
          Se comporter de fa&ccedil;on loyale et raisonnable &agrave;
          l&rsquo;&eacute;gard d&rsquo;ANDREWAPP, des Patients Utilisateurs et
          des tiers ;&nbsp;
        </ListItem>
        <ListItem>
          &Ecirc;tre honn&ecirc;te et sinc&egrave;re dans les informations
          fournies&nbsp;;
        </ListItem>
        <ListItem>
          Utiliser l&rsquo;Application conform&eacute;ment &agrave; son objet
          tel que d&eacute;crit dans les pr&eacute;sentes CGS&nbsp;;
        </ListItem>
        <ListItem>
          Ne pas d&eacute;tourner la finalit&eacute; de l&rsquo;Application pour
          commettre des crimes, d&eacute;lits ou contraventions
          r&eacute;prim&eacute;s par le code p&eacute;nal ou par toute autre
          disposition l&eacute;gale ou r&eacute;glementaire ;
        </ListItem>
        <ListItem>
          Respecter les droits de propri&eacute;t&eacute; intellectuelle
          d&rsquo;ANDREWAPP et des tiers portant sur les &eacute;l&eacute;ments
          de l&rsquo;Application et les Contenus ;&nbsp;
        </ListItem>
        <ListItem>
          Ne pas chercher &agrave; porter atteinte au sens des{" "}
          <strong>
            <Link
              as={RouterLink}
              to="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000030939438/"
              target="_blank"
              aria-label="Lire l'article 323-1 sur le site lefifrance"
            >
              articles 323-1 et suivants du code p&eacute;nal
            </Link>
          </strong>{" "}
          aux syst&egrave;mes de traitements automatis&eacute;s de
          donn&eacute;es mis en &oelig;uvre sur l&rsquo;Application, notamment
          par l&rsquo;interm&eacute;diaire de pratiques telles que le
          webscraping ;
        </ListItem>
        <ListItem>
          Ne pas diffuser des donn&eacute;es ayant pour effet de diminuer, de
          d&eacute;sorganiser, de ralentir ou d&rsquo;interrompre le
          fonctionnement normal de l&rsquo;Application.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Chaque Organisme Partenaire garantit ANDREWAPP du parfait respect, par
        ses Administrateur ou Thérapeutes Partenaires, des conditions et
        obligations susvisées.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9.2. Obligations sp&eacute;cifiques&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        L’utilisation de l’Application est strictement réservée aux
        Administrateur et Thérapeutes Partenaires disposant de la qualité
        d’élève ostéopathe en 4e ou 5e année d’étude.
      </p>
      <p>&nbsp;</p>
      <p>
        Le Thérapeute Partenaire s’engage notamment à disposer de l’ensemble des
        qualifications et autorisations requises pour exercer ses missions en
        conformité avec la règlementation en vigueur au jour de l’inscription de
        l’Organisme Partenaire, puis tout long de l’utilisation de
        l’Application.
      </p>
      <p>&nbsp;</p>
      <p>
        Si ces conditions venaient à ne plus être remplies pour quelque cause
        que ce soit, pour l’un ou plusieurs de ses Thérapeutes Partenaires,
        l’Organisme Partenaire s’engage à en informer ANDREWAPP sans délai par
        email à l’adresse suivante : contact@andrewapp.fr. Dans cette hypothèse,
        ANDREWAPP se réserve le droit de suspendre le Compte du ou des
        Thérapeute(s) Partenaire(s) à tout moment.
      </p>
      <p>&nbsp;</p>
      <p>
        De manière générale, le Thérapeute Partenaire s’engage à utiliser
        l’Application conformément aux obligations déontologiques et légales qui
        lui sont imposées par la réglementation en vigueur et les règles
        reconnues par la profession. Aussi, le Thérapeute Partenaire s’engage à
        ne pas utiliser l’Application dans le cadre d’une activité – qu’elle
        soit médicale, thérapeutique ou de bien-être – que la réglementation en
        vigueur exclut du champ de la pratique ostéopathique.
      </p>
      <p>&nbsp;</p>
      <p>
        Le Thérapeute Partenaire s’engage dès lors à utiliser l’Application
        uniquement dans le cadre de la relation avec les Patients Utilisateurs
        effectivement reçus en consultation et dans le strict respect du secret
        médical applicable.
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre, le Thérapeute Partenaire s’engage à ne proposer
        l’Application et à générer un Profil Patient et un lien d’inscription,
        uniquement pour les Patients souhaitant devenir Utilisateur de
        l’Application.
      </p>
      <p>&nbsp;</p>
      <p>
        En acceptant les présentes CGS, le Thérapeute Partenaire confirme
        disposer d’une assurance responsabilité civile professionnelle médicale
        souscrite auprès d’une compagnie d’assurance notoirement solvable et
        s’engage à la maintenir à jour pendant toute la durée d’utilisation de
        l’Application.
      </p>
      <p>&nbsp;&nbsp;&nbsp;</p>
      <p>
        Chaque Organisme Partenaire garantit ANDREWAPP du parfait respect, par
        ses Administrateur ou Thérapeutes Partenaires, des conditions et
        obligations susvisées.
      </p>
      <p>&nbsp;&nbsp;&nbsp;</p>
      <p>
        <strong>ARTICLE 10. RESPONSABILITE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>10.1. Principes g&eacute;n&eacute;raux&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>ANDREWAPP d&eacute;cline toute responsabilit&eacute; notamment :</p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          En cas d&rsquo;impossibilit&eacute; d&rsquo;acc&eacute;der
          temporairement &agrave; l&rsquo;Application pour des op&eacute;rations
          de maintenance technique ou d&rsquo;actualisation des informations
          publi&eacute;es.&nbsp;
        </ListItem>
        <ListItem>
          En cas de dysfonctionnements ou d’interruptions des réseaux de
          transmission ou du matériel informatique de l’Organisme ou des
          Thérapeutes Partenaires ou de l’Utilisateur de l’Application ;
        </ListItem>
        <ListItem>
          En cas d&rsquo;attaque virale, intrusion illicite dans un
          syst&egrave;me de traitement automatis&eacute; de donn&eacute;es ;
        </ListItem>
        <ListItem>
          En cas d’utilisation anormale ou d’une exploitation illicite de
          l’Application par un Utilisateur, un Administrateur ou un Thérapeute
          Partenaire ou un tiers ;
        </ListItem>
        <ListItem>
          Relativement au contenu des sites internet tiers vers lesquels
          renvoient des liens hypertextes pr&eacute;sents sur
          l&rsquo;Application ;
        </ListItem>
        <ListItem>
          En cas de non-respect des présentes CGS imputable à un Organisme
          Partenaire, son Administrateur ou ses Thérapeutes Partenaires ;
        </ListItem>
        <ListItem>
          En cas de retard ou d’inexécution de ses obligations, lorsque la cause
          du retard ou de l’inexécution est liée à un cas de force majeure telle
          qu’elle est définie à l’Article 11 des présentes CGS ;
        </ListItem>
        <ListItem>
          En cas de cause étrangère non imputable à ANDREWAPP ;
        </ListItem>
        <ListItem>
          En cas d’agissement illicite de l’Organisme Partenaire, son
          Administrateur ou ses Thérapeutes Partenaires, ou d’inexécution
          contractuelle dont l’une de ces personnes se serait rendu coupable.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        En cas d’utilisation anormale ou d’une exploitation illicite de
        l’Application par les personnes susvisées lesquelles sont sous la
        responsabilité de l’Organisme Partenaire, ce dernier est alors seul
        responsable des dommages causés aux tiers et des conséquences des
        réclamations ou actions qui pourraient en découler.
      </p>
      <p>&nbsp;</p>
      <p>
        En aucun cas la responsabilité d’ANDREWAPP ne pourra être recherchée,
        quel que soit le type d’action intentée, pour un dommage indirect
        d’aucune sorte par exemple, et sans que la liste soit exhaustive, tout
        préjudice financier ou commercial, perte de bénéfice, trouble
        commercial, manque à gagner, préjudice d’un tiers, ou action intentée
        par un tiers contre l’Organisme Partenaire ou un Thérapeute Partenaire,
        ainsi que leurs conséquences, lié aux présentes ou à leur exécution.
        L’Organisme Partenaire est seul responsable de tout préjudice, direct ou
        indirect, matériel ou immatériel causé par lui-même ou un de ses
        préposés à ANDREWAPP ou à des tiers du fait de l’utilisation des
        Services par son Administrateur ou les Thérapeutes Partenaires.
      </p>
      <p>&nbsp;</p>
      <p>
        En tout état de cause, il est expressément convenu entre les Parties que
        si la responsabilité d’ANDREWAPP était retenue dans le cadre de
        l’exécution des présentes CGS, celle-ci serait limitée à tous préjudices
        directs et toutes demandes confondues, aux sommes versées par
        l’Organisme Partenaire à ANDREWAPP (au titre de paiement des Services)
        au cours des trois (3) derniers mois.
      </p>
      <p>&nbsp;</p>
      <p>
        Les Parties conviennent expressément qu’ANDREWAPP peut faire appel à des
        prestataires extérieurs pour exécuter tout ou partie des Services. Dans
        ce cas, l’Organisme Partenaire sera informé sur simple demande des
        garanties et niveau de service proposés par le prestataire extérieur qui
        lui seront opposables. En tout état de cause, ANDREWAPP demeurera
        l’interlocuteur unique de l’Organisme Partenaire en cas d’appel à des
        sous-traitants et n’engagera sa responsabilité que s’il est démontré
        qu’il a commis une faute grave ou que le prestataire extérieur n’a pas
        respecté les garanties et niveau de service proposés. Le plafond de
        responsabilité prévu à l’alinéa précédent trouvera également à
        s’appliquer dans ce cas.
      </p>
      <p>&nbsp;</p>
      <p>
        Il est expressément convenu entre les Parties que les stipulations de la
        présente clause continueront à s’appliquer même en cas de résolution des
        présentes constatée par une décision de justice devenue définitive.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>IMPORTANT</strong>
      </p>
      <p>
        ANDREWAPP décline toute responsabilité quant au caractère adapté des
        Contenus proposés par les Thérapeutes Partenaires via l’Application et
        des conseils délivrés pour un Patient donné.
      </p>
      <p>&nbsp;</p>
      <p>
        Il est clairement entendu que le caractère adapté d’un Contenu doit être
        évalué pour chaque Patient par son Thérapeute Partenaire en lien avec la
        consultation réalisée précédemment en cabinet et les consultations de
        suivi.
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre, le Thérapeute lui-même et l’Organisme Partenaire restent
        seuls responsables des contenus que le Thérapeute Partenaire souhaite
        recommander à ses Patients Utilisateurs de l’Application, laquelle ne
        saurait constituer une aide au diagnostic, ni à la prescription.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          10.2. Statut d&rsquo;h&eacute;bergeur au sens de la LCEN
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire et les Thérapeutes Partenaires reconnaissent
        qu’ANDREWAPP peut avoir la qualité d’hébergeur au sens de l’article 6 I
        2° de la loi du 21 juin 2004 pour la confiance dans l’économie numérique
        dite LCEN.
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre, ANDREWAPP se réserve la possibilité de retirer tout Contenu
        qu’elle considèrera comme manifestement illicite au sens de l’article 6
        I 2° de la LCEN.
      </p>
      <p>&nbsp;</p>
      <p>
        La notification des Contenus manifestement illicites par un Utilisateur,
        un Thérapeute Partenaire, un Organisme Partenaire ou tout autre tiers
        doit se faire par :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Courrier &eacute;lectronique &agrave; l&rsquo;adresse
          :contact@andrewapp.fr ; ou au 07 89 34 42 76
        </ListItem>
        <ListItem>
          Courrier en recommand&eacute; avec avis de r&eacute;ception &agrave; :
          ANDREWAPP 32 rue du B&oelig;uf 69005 LYON]
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Conform&eacute;ment &agrave; l&rsquo;article 6 I 5&deg; de la LCEN, la
        notification, pour &ecirc;tre valide, doit reprendre les
        &eacute;l&eacute;ments suivants :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>La date de la notification ;</ListItem>
        <ListItem>
          Si le notifiant est une personne physique : ses nom, pr&eacute;noms,
          profession, domicile, nationalit&eacute;, date et lieu de naissance
          ;&nbsp;
        </ListItem>
        <ListItem>
          Si le requ&eacute;rant est une personne morale : sa forme, sa
          d&eacute;nomination, son si&egrave;ge social et l&apos;organe qui la
          repr&eacute;sente l&eacute;galement ; &eacute;tant
          pr&eacute;cis&eacute; que la condition relative &agrave; la fourniture
          de l&rsquo;identit&eacute; du notifiant est r&eacute;put&eacute;e
          acquise si celui-ci est un utilisateur inscrit du service de
          communication au public et qu&rsquo;il est connect&eacute; au moment
          de proc&eacute;der &agrave; la notification et que
          l&rsquo;h&eacute;bergeur a recueilli les &eacute;l&eacute;ments
          n&eacute;cessaires &agrave; son identification ;
        </ListItem>
        <ListItem>
          Le nom et le domicile du destinataire ou, s&apos;il s&apos;agit
          d&apos;une personne morale, sa d&eacute;nomination et son si&egrave;ge
          social ;
        </ListItem>
        <ListItem>
          La description des faits litigieux et leur localisation pr&eacute;cise
          ; &eacute;tant pr&eacute;cis&eacute; que ces conditions sont
          r&eacute;put&eacute;es satisfaites d&egrave;s lors que le service
          permet de proc&eacute;der pr&eacute;cis&eacute;ment &agrave; cette
          notification par un dispositif technique directement accessible depuis
          ledit contenu litigieux ;&nbsp;
        </ListItem>
        <ListItem>
          Les motifs pour lesquels le contenu doit &ecirc;tre retir&eacute;,
          comprenant la mention des dispositions l&eacute;gales et des
          justifications de faits ; &eacute;tant pr&eacute;cis&eacute; que cette
          condition est r&eacute;put&eacute;e satisfaite d&egrave;s lors que le
          service de communication au public en ligne permet de proc&eacute;der
          &agrave; la notification par un dispositif technique proposant
          d&rsquo;int&eacute;grer la cat&eacute;gorie d&rsquo;infraction
          &agrave; laquelle peut &ecirc;tre rattach&eacute; ce contenu litigieux
          ;
        </ListItem>
        <ListItem>
          La copie de la correspondance adress&eacute;e &agrave; l&apos;auteur
          ou &agrave; l&apos;&eacute;diteur des informations ou activit&eacute;s
          litigieuses demandant leur interruption, leur retrait ou leur
          modification, ou la justification de ce que l&apos;auteur ou
          l&apos;&eacute;diteur n&apos;a pu &ecirc;tre contact&eacute;, sauf
          exception pr&eacute;vue pour certaines infractions par l&rsquo;article
          6 I 5&deg; de la LCEN.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>
          10.3. Litige entre un Patient Utilisateur et un Thérapeute Partenaire
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Il est précisé que tout litige survenant entre un Patient Utilisateur et
        un Thérapeute Partenaire devra être traité entre eux ou avec l’Organisme
        Partenaire, ce que ce dernier reconnait et accepte.
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire reconnait que l’utilisation des Services depuis
        l’Application par l’Administrateur ou les Thérapeutes Partenaires,
        engage l’image de marque d’ANDREWAPP. L’Organisme Partenaire reconnaît
        donc que les agissements de ses Administrateur ou Thérapeutes
        Partenaires, qui ne respecteraient pas l’ensemble des obligations
        stipulées aux présentes CGS, peuvent avoir un effet préjudiciable pour
        ANDREWAPP. L’Organisme Partenaire déclare assumer toute responsabilité à
        cet égard.
      </p>
      <p>&nbsp;</p>
      <p>
        Lorsque l’Utilisateur contacte ANDREWAPP par email à l’adresse
        contact@andrewapp.fr ou par téléphone au 07 89 34 42 76 afin de formuler
        une réclamation à propos d’un Thérapeute Partenaire, ANDREWAPP
        répercutera la réclamation sous 24 heures ouvrées par email auprès du
        Thérapeute Partenaire concerné. Lui ou l’Organisme dont il dépend sera
        seul responsable du traitement du litige.
      </p>
      <p>&nbsp;</p>
      <p>
        En tout état de cause, la Partie concernée par la réclamation de
        l’Utilisateur s’engage à répondre à cette réclamation dans les meilleurs
        délais.
      </p>
      <p>&nbsp;</p>
      <p>
        Soucieuse de l’image de son Application, ANDREWAPP invite les Parties à
        faire leurs meilleurs efforts pour résoudre amiablement tout litige dans
        lesquels ils seraient impliqués.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 11. FORCE MAJEURE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        La responsabilit&eacute; d&rsquo;ANDREWAPP ne pourra pas &ecirc;tre mise
        en &oelig;uvre si la non-ex&eacute;cution ou le retard dans
        l&rsquo;ex&eacute;cution de l&rsquo;une de ses obligations
        d&eacute;crites dans les pr&eacute;sentes CGS d&eacute;coule d&rsquo;un
        cas de force majeure.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Il y a force majeure en mati&egrave;re contractuelle lorsqu&rsquo;un
        &eacute;v&egrave;nement &eacute;chappant au contr&ocirc;le du
        d&eacute;biteur, qui ne pouvait &ecirc;tre raisonnablement pr&eacute;vu
        lors de la conclusion des CGS et dont les effets ne peuvent &ecirc;tre
        &eacute;vit&eacute;s par des mesures appropri&eacute;es, emp&ecirc;che
        l&rsquo;ex&eacute;cution de son obligation par le d&eacute;biteur.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Si l&rsquo;emp&ecirc;chement est temporaire, l&rsquo;ex&eacute;cution de
        l&rsquo;obligation est suspendue &agrave; moins que le retard qui en
        r&eacute;sulterait ne justifie la r&eacute;solution des CGS. Si
        l&rsquo;emp&ecirc;chement est d&eacute;finitif, les CGS sont
        r&eacute;solues de plein droit et les Parties sont
        lib&eacute;r&eacute;es de leurs obligations dans les conditions
        pr&eacute;vues aux articles 1351 et 1351-1 du Code civil.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de survenance d’un évènement revêtant les caractéristiques de la
        force majeure, ANDREWAPP s’efforcera d’informer l’Organisme Partenaire
        dès que possible.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 12. PROPRIETE INTELLECTUELLE&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          12.1. Titularit&eacute; des droits de propri&eacute;t&eacute;
          intellectuelle des Thérapeutes Partenaires&nbsp;
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cadre de l&rsquo;utilisation de l&rsquo;Application, les
        Thérapeutes Partenaires sont susceptibles de pr&eacute;senter des
        photographies, marques, logos, dessins et autres mod&egrave;les leur
        appartenant ou appartenant &agrave; des tiers.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire garantit ANDREWAPP que tout Thérapeute Partenaire
        qui dépose un Contenu par l’intermédiaire de l’Application a le droit de
        faire une représentation ou une reproduction de ces Contenus et en
        tiendra ANDREWAPP indemne.
      </p>
      <p>&nbsp;</p>
      <p>
        En tout &eacute;tat de cause, ANDREWAPP ne saurait en aucun cas
        &ecirc;tre tenue responsable d&rsquo;un acte de contrefa&ccedil;on,
        compte tenu de sa simple qualit&eacute; d&rsquo;h&eacute;bergeur des
        Contenus publi&eacute;s par les Thérapeutes Partenaires.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        En vue de l’exécution des présentes CGS, tout Thérapeute Partenaire
        accorde à ANDREWAPP une licence gratuite et non exclusive, pour
        utiliser, reproduire, représenter, adapter et mettre à disposition du
        public les Contenus qu’il diffuse sous l’Application, incluant notamment
        les créations protégées par le droit d&apos;auteur ainsi que tout signe
        distinctif visible en vue de l’exécution des présentes CGS. Cette
        licence sera valable pendant toute la durée de l’utilisation de
        l’Application en vertu des présentes et dans le monde entier.
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire garantit ANDREWAPP de la pleine effectivité de la
        licence susvisée.
      </p>
      <p>&nbsp;</p>
      <p>
        Cette licence n’emporte aucun droit de sous- licence à une tierce
        partie. ANDREWAPP ne pourra néanmoins pas être tenue responsable de la
        copie du Contenu par tout Utilisateur naviguant sur l’Application.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          12.2. Titularit&eacute; des droits de propri&eacute;t&eacute;
          intellectuelle d&rsquo;ANDREWAPP
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire reconnait les droits de propriété intellectuelle
        d’ANDREWAPP sur l’Application, ses composantes et les Contenus y
        afférent et renonce à contester ces droits sous quelle que forme que ce
        soit.
      </p>
      <p>&nbsp;</p>
      <p>
        Les marques, logos, slogans, graphismes, photographies, animations,
        vidéos, solutions logicielles et textes et tout autre Contenu sur
        l’Application, à l’exception des Contenus de tiers, identifiés comme
        tel, et des Contenus de Thérapeutes Partenaires, sont la propriété
        intellectuelle exclusive d’ANDREWAPP et ne peuvent être reproduits,
        utilisés ou représentés sans autorisation expresse sous peine de
        poursuites judiciaires.
      </p>
      <p>&nbsp;</p>
      <p>
        Toute représentation ou reproduction, totale ou partielle, de
        l’Application et de son contenu, par quel que procédé que ce soit, sans
        l’autorisation préalable expresse d’ANDREWAPP, est interdite et
        constituera une contrefaçon sanctionnée par les articles L.335- 2 et
        suivants et les articles L.713-1 et suivants du code de la propriété
        intellectuelle.
      </p>
      <p>&nbsp;</p>
      <p>
        En particulier, ANDREWAPP interdit express&eacute;ment en tant que
        producteur de base de donn&eacute;es :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          L&rsquo;extraction, par transfert permanent ou temporaire de la
          totalit&eacute; ou d&rsquo;une partie qualitativement ou
          quantitativement substantielle du contenu de sa base de donn&eacute;es
          sur un autre support, par tout moyen et sous quelque forme que ce soit
          ;
        </ListItem>
        <ListItem>
          La r&eacute;utilisation, par la mise &agrave; la disposition au public
          de la totalit&eacute; ou d&rsquo;une partie qualitativement ou
          quantitativement substantielle du contenu de la base, quelle
          qu&rsquo;en soit la forme ;&nbsp;
        </ListItem>
        <ListItem>
          La reproduction, l&rsquo;extraction ou la r&eacute;utilisation, par
          tout moyen, y compris les m&eacute;thodes assimilables au scraping des
          contenus (photographies, description etc&hellip;) publi&eacute;s par
          ANDREWAPP.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        L’acceptation des présentes CGS vaut reconnaissance par l’Organisme
        Partenaire et les Thérapeutes Partenaires des droits de propriété
        intellectuelle d’ANDREWAPP et engagement à les respecter.
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire se porte garant du respect, par les Thérapeutes
        Partenaires, de ces obligations.
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP accorde à l’Administrateur et aux Thérapeutes Partenaires une
        licence personnelle, non-exclusive et non cessible les autorisant à
        utiliser l’Application et les informations qu’elle contient conformément
        aux présentes CGS.
      </p>
      <p>&nbsp;</p>
      <p>
        Toute autre exploitation de l&rsquo;Application et de son contenu est
        exclue du domaine de la pr&eacute;sente licence et ne pourra &ecirc;tre
        effectu&eacute;e sans l&rsquo;autorisation pr&eacute;alable expresse de
        d&rsquo;ANDREWAPP.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 13. PROTECTION DES DONNEES PERSONNELLES&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cadre du traitement de donn&eacute;es &agrave; caract&egrave;re
        personnel, ANDREWAPP s&rsquo;engage &agrave; se conformer aux lois et
        r&egrave;glements en vigueur encadrant les traitements de donn&eacute;es
        &agrave; caract&egrave;re personnel, en ce compris la Loi &laquo;
        Informatique, fichiers et libert&eacute;s &raquo; du 6 janvier 1978
        modifi&eacute;e par la loi du 20 juin 2018 et le R&egrave;glement (UE)
        2016/679 du 27 avril 2016 relatif &agrave; la protection des personnes
        physiques &agrave; l&apos;&eacute;gard du Traitement des Donn&eacute;es
        &agrave; Caract&egrave;re Personnel et &agrave; la libre circulation de
        ces donn&eacute;es (le &laquo;&nbsp;RGPD&nbsp;&raquo;).
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 14. RECLAMATIONS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Toute question ou r&eacute;clamation concernant l&rsquo;utilisation ou
        le fonctionnement de l&rsquo;Application peut &ecirc;tre formul&eacute;e
        selon les modalit&eacute;s suivantes :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Par courrier &eacute;lectronique &agrave; l&rsquo;adresse suivante :{" "}
          <strong>
            <a href="mailto:support@andrewapp.fr">contact@andrewapp.fr</a>
          </strong>
          &nbsp;&nbsp;
        </ListItem>
        <ListItem>
          Par courrier AndrewApp 32 rue du B&oelig;uf 69005 Lyon
        </ListItem>
        <ListItem>
          Par t&eacute;l&eacute;phone au :&nbsp; 07 89 34 42 76 du Lundi au
          Vendredi de 9h &agrave; 12h et de 14h &agrave; 17h.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>
          ARTICLE 15. DUREE DE L&rsquo;ABONNEMENT &ndash; RESILIATION
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Les CGS entrent en vigueur à compter de leur acceptation par l’Organisme
        Partenaire et pour la durée de l’Abonnement.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          15.1. Hypoth&egrave;ses de suspension et de r&eacute;siliation
        </strong>
      </p>
      <p>&nbsp;</p>
      <u>I. Suspension&nbsp;</u>

      <p>&nbsp;</p>
      <p>
        En cas de violation continue des présentes dispositions ou des lois et
        règlements en vigueur, ainsi que d’absence de coopération et/ou de
        déloyauté, et s’il y a urgence à faire cesser les agissements constatés,
        ANDREWAPP pourra suspendre de plein droit l’Abonnement de l’Organisme
        Partenaire, sans indemnité à son profit, sous réserve de lui avoir
        transmis sur un support durable, l’exposé des motifs au moment où la
        suspension prend effet.
      </p>
      <p>&nbsp;</p>
      <p>
        Cette décision est portée à la connaissance de l’Organisme Partenaire
        par courriel, par l’intermédiaire d’un support durable indiquant les
        griefs reprochés, ainsi que les obligations dont le non-respect est
        allégué.
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP pourra suspendre l’accès aux Compte Administrateur et aux
        Comptes Thérapeutes Partenaires, le temps que les manquements identifiés
        et ce, nécessairement sous un délai de dix (10) jours, à compter de la
        réception du support durable précité. A l’issue de ce délai, si
        l’Organisme Partenaire n’a pas corrigé les différents manquements,
        ANDREWAPP pourra résilier l’Abonnement et supprimer les Compte
        Administrateur et Comptes Thérapeutes Partenaires de l’Application, dans
        les conditions détaillées ci- après.
      </p>
      <p>&nbsp;</p>
      <u>II. R&eacute;siliation suite &agrave; une suspenion&nbsp;</u>
      <p>&nbsp;</p>
      <p>
        Toute suspension pourra mener à une résiliation si l’Organisme
        Partenaire n’apporte pas de réponse satisfaisante aux griefs qui lui
        sont notifiés dans les quinze (15) jours suivants le premier jour de
        suspension des Services.
      </p>
      <p>&nbsp;</p>
      <p>
        Dans ce cas, un délai de préavis d’un (1) mois, augmenté d’un (1) mois
        par année complète au cours de laquelle l’Organisme Partenaire aura été
        référencé sur l’Application, sera activé. Ce délai de préavis court à
        compter de l’expiration du délai de suspension. A l’issue du délai de
        préavis, l’Abonnement sera résilié.
      </p>
      <p>&nbsp;</p>
      <u>III. R&eacute;siliation pour faute&nbsp;</u>
      <p>&nbsp;</p>
      <p>
        En cas de manquement grave ou répété, par une Partie, à ses obligations
        au titre des présentes, l’Abonnement pourra être résilié par l’autre
        Partie.
      </p>
      <p>&nbsp;</p>
      <p>
        Il est express&eacute;ment convenu que cette r&eacute;siliation aura
        lieu de plein droit, quinze (15) jours apr&egrave;s l&rsquo;envoi
        d&rsquo;une mise en demeure de s&rsquo;ex&eacute;cuter, rest&eacute;e
        sans effet.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        La mise en demeure, qui devra imp&eacute;rativement indiquer les griefs
        reproch&eacute;s et les obligations dont le non-respect est
        all&eacute;gu&eacute;, sera notifi&eacute;e par lettre
        recommand&eacute;e avec accus&eacute; de r&eacute;ception.&nbsp;
      </p>
      <p>&nbsp;</p>
      <u>IV. R&eacute;siliation pour convenance&nbsp;</u>

      <p>&nbsp;</p>
      <p>
        Chaque Partie aura la possibilit&eacute; de r&eacute;silier
        l&rsquo;Abonnement pour convenance.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cadre d&rsquo;un Abonnement mensuel, chaque Partie pourra
        r&eacute;silier l&rsquo;Abonnement &agrave; tout moment depuis la
        plateforme du Prestataire de Services de paiement.
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cadre d&rsquo;un Abonnement annuel, le Partenaire pourra
        r&eacute;silier l&rsquo;Abonnement &agrave; tout moment en se rendant
        sur son Compte Partenaire, sous r&eacute;serve du respect d&rsquo;un
        pr&eacute;avis de 30 Jours Ouvr&eacute;s. ANDREWAPP pourra
        &eacute;galement r&eacute;silier l&rsquo;Abonnement pour convenance
        &agrave; tout moment, en adressant une courrier &eacute;lectronique
        &agrave; l&rsquo;adresse renseign&eacute;e par le Partenaire au moment
        de son inscription&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP procèdera dans les meilleurs délais à la désactivation du
        Compte Partenaire et adressera au Partenaire concerné un courriel
        confirmant la clôture de son compte. Dans cette hypothèse, il est
        entendu entre les Parties que l’Abonnement correspondant à la période
        entamée (mois ou année, selon l’Abonnement souscrit) sera dû en
        intégralité.
      </p>
      <p>&nbsp;</p>
      <u>V. Autres hypoth&egrave;ses de r&eacute;siliation&nbsp;</u>

      <p>&nbsp;</p>
      <p>
        L’Abonnement est conclu intuitu personae, en considération des qualités
        de l’Organisme Partenaire et des relations existantes entre ANDREWAPP et
        l’Organisme Partenaire.
      </p>
      <p>&nbsp;</p>
      <p>
        Par conséquent, ANDREWAPP pourra résilier de plein droit l’Abonnement
        dans le cas où l’Organisme Partenaire ou les Thérapeutes Partenaires ne
        disposeraient plus des qualifications et autorisations requises pour
        exercer la profession d’ostéopathe en conformité avec la règlementation
        en vigueur.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>15.2. Cons&eacute;quences de la r&eacute;siliation</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Toute résiliation, résolution ou annulation de l’Abonnement pour quelque
        motif que ce soit entraîne automatiquement le déréférencement des
        Organismes Partenaires et des Thérapeutes Partenaires de l’Application,
        ainsi que l’annulation de l’accès aux différents Services qui leur sont
        proposés.
      </p>
      <p>&nbsp;</p>
      <p>
        Cependant, l’Organisme Partenaire conserve l’accès à son Backoffice
        pendant une durée supplémentaire d’un (1) mois afin de récupérer ses
        données, factures et Contenus, ainsi que de ceux de ses Thérapeutes
        Partenaires. En revanche, ils ne peuvent plus créer de nouveaux Profils
        Patients, de nouveaux parcours d’exercices personnalisés ou de nouveaux
        conseils personnalisés dès la résiliation effective.
      </p>
      <p>&nbsp;</p>
      <p>
        A l’issue du délai d’un (1) mois laissé à l’Organisme Partenaire pour
        récupérer toutes données, factures et Contenus, les Contenus spécifiques
        proposés par les Thérapeutes Partenaires à ses Patients resteront
        accessibles aux Patients durant la durée d’accessibilité aux Contenus
        personnalisés définie par défaut ou, le cas échéant, jusqu’à suppression
        de son Compte Utilisateur par le Patient.
      </p>
      <p>&nbsp;</p>
      <p>
        Avant l’expiration du délai d’un (1) mois laissé à l’Organisme
        Partenaire pour récupérer toutes données, factures et Contenus,
        l’Organisme Partenaire peut demander à ANDREWAPP de mettre à sa
        disposition, dans un délai de soixante (60) Jours Ouvrés suivant sa
        demande écrite, l’ensemble des données relatives aux Patients des
        Thérapeutes Partenaires détenues par ANDREWAPP dans le cadre de l’accès
        aux Services. Ces données seront mises à disposition par ANDREWAPP dans
        un format de structuré, couramment utilisé et lisible par machine.
      </p>
      <p>&nbsp;</p>
      <p>
        ANDREWAPP se réserve le droit de renvoyer vers l’Organisme Partenaire
        toute réclamation initiée par un Patient Utilisateur concernant un suivi
        réalisé par un Thérapeute Partenaire via l’Application, dont l’Organisme
        Partenaire a la responsabilité et conservera la possibilité de
        communiquer en visant sa collaboration avec l’Organisme Partenaire
        pendant toute cette durée.
      </p>
      <p>&nbsp;</p>
      <p>
        Les Articles « Propriété intellectuelle », « Confidentialité » et «
        Données à caractère personne »l ou toute autre article dont les
        stipulations, par nature, exigent de survivre à l’expiration de
        l’Abonnement, resteront en vigueur en cas de résiliation des présentes
        et ce pour une durée complémentaire de cinq (5) ans sauf stipulation
        expresse ou disposition législative ou réglementaire contraire.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 16. ASSISTANCE &ndash; SUPPORT PARTENAIRE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Tout Organisme Partenaire ou Thérapeute Partenaire peut saisir ANDREWAPP
        aux Jours Ouvr&eacute;s, en contactant ANDREWAPP par courriel &agrave;
        l&rsquo;adresse suivante&nbsp;
        <strong>
          <a href="mailto:damien@andrewapp.fr">damien@andrewapp.fr</a>
        </strong>
        .
      </p>
      <p>&nbsp;</p>
      <p>
        Tout Organisme Partenaire ou Thérapeute Partenaire pourra consulter
        ANDREWAPP concernant :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>Un litige en cours avec un Patient&nbsp;;&nbsp;</ListItem>
        <ListItem>Des Anomalies de l&rsquo;Application ;&nbsp;</ListItem>
        <ListItem>
          Des renseignements directement li&eacute;s &agrave; la fourniture des
          Services et notamment concernant le fonctionnement de
          l&rsquo;Application.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 17. CONFIDENTIALITE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Chaque Partie s&apos;engage &agrave; n&rsquo;utiliser les Informations
        Confidentielles, directement ou indirectement, en tout ou partie, que
        pour la stricte ex&eacute;cution des pr&eacute;sentes CGS.
      </p>
      <p>&nbsp;</p>
      <p>
        Toute divulgation non fondée pourra engager la responsabilité de son
        auteur et ce, quelle que soit la cause de la divulgation.
      </p>
      <p>&nbsp;</p>
      <p>
        Les obligations de confidentialit&eacute; stipul&eacute;es par la
        pr&eacute;sente clause ne s&rsquo;appliquent pas &agrave;
        l&rsquo;int&eacute;gralit&eacute; ou &agrave; toute partie des
        Informations Confidentielles dans la mesure o&ugrave; :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4} listStyleType="none" spacing={2}>
        <ListItem>
          (a)&nbsp;Elles &eacute;taient l&eacute;galement d&eacute;tenues par la
          Partie destinataire avant leur divulgation ;
        </ListItem>
        <ListItem>
          (b)&nbsp;Elles ont &eacute;t&eacute; l&eacute;galement
          divulgu&eacute;es &agrave; la Partie destinataire par une tierce
          partie sans restriction de divulgation ;
        </ListItem>
        <ListItem>
          (c)&nbsp;Elles sont assujetties &agrave; une obligation l&eacute;gale
          de divulgation par tout tribunal comp&eacute;tent, autorit&eacute; ou
          administration.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        La pr&eacute;sente clause de confidentialit&eacute; sera maintenue
        &agrave; l&rsquo;expiration de l&rsquo;Abonnement pour une dur&eacute;e
        compl&eacute;mentaire de cinq (5) ans.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 18. ANTICORRUPTION ET AUTRES ENGAGEMENTS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>18.1. Anticorruption</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire s&apos;engage, tant pour son propre compte que
        pour celui de ses employés, agents, filiales et sous-traitants éventuels
        pour lesquels elle se porte garante, à respecter l&apos;ensemble des
        lois et règlements applicables en matière de lutte contre la corruption,
        et notamment la loi n° 2016-1691 du 9 décembre 2016 relative à la
        transparence, à la lutte contre la corruption et à la modernisation de
        la vie économique ou toute autre loi ou réglementation qui viendrait à
        la remplacer ou la compléter.
      </p>
      <p>&nbsp;</p>
      <p>
        La corruption d&eacute;signe tout comportement par lequel une personne
        sollicite ou accepte un cadeau ou, plus g&eacute;n&eacute;ralement, une
        faveur, en vue d&apos;obtenir ou de conserver un contrat ou tout autre
        avantage indu dans le cadre d&apos;activit&eacute;s nationales ou
        internationales.
      </p>
      <p>&nbsp;</p>
      <p>
        En conséquence, l’Organisme Partenaire agira de manière professionnelle
        et éthique dans ses relations d&apos;affaires, et en particulier, il
        s&apos;engage à :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          S&apos;abstenir de toute activit&eacute; de corruption de quelque
          nature que ce soit (directe ou indirecte, active ou passive,
          financi&egrave;re ou autre), de trafic d&apos;influence,
          d&apos;extorsion, de d&eacute;tournement de fonds ou de toute autre
          conduite sanctionn&eacute;e par la loi ;&nbsp;
        </ListItem>
        <ListItem>
          Mettre en &oelig;uvre et maintenir des politiques et proc&eacute;dures
          ad&eacute;quates en mati&egrave;re d&apos;&eacute;thique et de lutte
          contre la corruption ;
        </ListItem>
        <ListItem>
          Informer rapidement ANDREWAPP de tout &eacute;v&eacute;nement pouvant
          entra&icirc;ner l&apos;obtention d&apos;un avantage indu, financier ou
          autre, de quelque valeur que ce soit, en relation avec les
          pr&eacute;sentes CGS ;
        </ListItem>
        <ListItem>
          Fournir toute l&apos;assistance n&eacute;cessaire &agrave; ANDREWAPP
          pour r&eacute;pondre &agrave; une demande d&apos;une autorit&eacute;
          d&ucirc;ment autoris&eacute;e en mati&egrave;re de lutte contre la
          corruption.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        Il est entendu entre les Parties qu&apos;en cas de non-respect des
        dispositions du présent article, ANDREWAPP pourra suspendre de plein
        droit l’Abonnement, sans indemnité ni préavis, et procéder à la
        résiliation conformément à l’Article 15 « Durée de l’Abonnement –
        Résiliation » si l’Organisme Partenaire n’apporte pas de réponse
        satisfaisante à la non- conformité dans les délais prévus.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>18.2. Non-sollicitation du personnel</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Chacune des Parties renonce à engager ou faire travailler, directement
        ou par personnel interposé, tout collaborateur, élève ou membre de
        l’autre Partie. Cet engagement est valable pendant toute la durée de
        l’Abonnement et pendant une durée minimum d’un (1) an après la fin de ce
        dernier.
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de non-respect de cette disposition, il est convenu entre les
        Parties que la Partie ayant enfreint l’obligation de non-sollicitation
        devra payer à l’autre Partie, à première demande, une somme égale à
        douze (12) mois du dernier salaire brut mensuel du collaborateur
        embauché.
      </p>
      <p>&nbsp;</p>
      <p>
        Cette pénalité trouvera aussi à s’appliquer dans l’hypothèse où le
        collaborateur ou élève débauché agirait, pour le compte de l’Organisme
        Partenaire, en tant qu’auto- entrepreneur ou de dirigeant d’une société
        commerciale.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>18.3. Autres exigences</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        L’Organisme Partenaire s’engage à respecter toutes les exigences qui lui
        sont applicables, notamment aux regards des obligations de déclaration
        en matière sociale et d’emploi, et garantit qu’il n’emploie en aucun cas
        des étrangers non munis de l’autorisation d’exercer une activité
        salariée en France, ou dont l’autorisation n’est pas conforme à l’emploi
        considéré, conformément aux articles L. 8251- 1 et suivants du Code du
        travail ou toute autre réglementation équivalente.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 19. GARANTIES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Chaque Partie s&rsquo;engage envers et garantit l&rsquo;autre Partie que
        :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Elle a le pouvoir et l&rsquo;autorit&eacute; d&rsquo;accepter les
          pr&eacute;sentes CGS, et qu&rsquo;elle assurera et maintiendra, au
          cours de la relation, l&rsquo;ensemble des autorisations
          &eacute;ventuelles n&eacute;cessaires &agrave;
          l&rsquo;ex&eacute;cution de ses obligations ;
        </ListItem>
        <ListItem>
          Elle d&eacute;tient, ou s&rsquo;est vue octroyer les droits lui
          permettant d&rsquo;utiliser aux fins des pr&eacute;sentes CGS,
          l&rsquo;ensemble des droits de propri&eacute;t&eacute; intellectuelle
          n&eacute;cessaires au respect de ses obligations ;
        </ListItem>
        <ListItem>
          Elle ex&eacute;cutera ses obligations en vertu des pr&eacute;sentes
          CGS conform&eacute;ment &agrave; l&rsquo;ensemble des lois en vigueur
          et en d&eacute;montrant une diligence et des comp&eacute;tences
          raisonnables ;
        </ListItem>
        <ListItem>
          Elle ne fera ni n&rsquo;omettra de faire quoi que ce soit pouvant
          entra&icirc;ner pour l&rsquo;autre Partie une violation de toute loi
          ou r&egrave;glement en vigueur ; et
        </ListItem>
        <ListItem>Elle ne d&eacute;nigrera pas l&rsquo;autre Partie.</ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 20. VALIDITE DES CGS</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Si l&rsquo;une quelconque des stipulations des pr&eacute;sentes CGS
        venait &agrave; &ecirc;tre d&eacute;clar&eacute;e nulle au regard
        d&rsquo;une disposition l&eacute;gislative ou r&eacute;glementaire en
        vigueur et/ou d&rsquo;une d&eacute;cision de justice ayant
        autorit&eacute; de la chose jug&eacute;e, elle sera
        r&eacute;put&eacute;e non &eacute;crite mais n&rsquo;affectera en rien
        la validit&eacute; des autres clauses qui demeureront pleinement
        applicables.
      </p>
      <p>&nbsp;</p>
      <p>
        Une telle modification ou décision n’autorise en aucun cas l’Organisme
        Partenaire ou le Thérapeute Partenaire à méconnaitre les présentes CGS.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 21. DISPOSITIONS GENERALES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Chacune des Parties s&apos;engage &agrave; toujours se comporter
        vis-&agrave;-vis de l&rsquo;autre, comme un partenaire loyal et de bonne
        foi, et notamment &agrave; porter sans d&eacute;lai &agrave; la
        connaissance de l&rsquo;autre Partie, tout diff&eacute;rend ou toute
        difficult&eacute; qu&apos;elle pourrait rencontrer dans le cadre de
        l&apos;ex&eacute;cution des pr&eacute;sentes CGS.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Le fait que l&apos;une des Parties n&apos;ait pas exig&eacute;
        l&apos;application d&apos;une clause quelconque des pr&eacute;sentes
        CGS, que ce soit de fa&ccedil;on permanente ou temporaire, ne pourra en
        aucun cas &ecirc;tre consid&eacute;r&eacute; comme une renonciation
        &agrave; ladite clause.
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de difficult&eacute; d&rsquo;interpr&eacute;tation entre
        l&rsquo;un quelconque des titres figurant en t&ecirc;te des clauses, et
        l&rsquo;une quelconque de celles-ci, les titres seront
        d&eacute;clar&eacute;s inexistants.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 22. ASSURANCES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        En acceptant les présentes CGS, l’Organisme Partenaire confirme qu’il ou
        les Thérapeutes Partenaires dispose(nt) d’une assurance responsabilité
        civile professionnelle médicale souscrite auprès d’une compagnie
        d’assurance notoirement solvable et s’engage(nt) à la maintenir à jour
        pendant toute la durée d’utilisation de l’Application.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 23. COMPETENCE ET DROIT APPLICABLE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        LES PRESENTES CGS AINSI QUE LES RELATIONS ENTRE LES PARTIES SONT REGIES
        PAR LE DROIT FRAN&Ccedil;AIS.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        EN CAS DE DIFFEREND SURVENANT ENTRE LES PARTIES AU SUJET DE
        L&rsquo;INTERPRETATION, DE L&rsquo;EXECUTION OU DE LA RESILIATION DES
        PRESENTES, LES PARTIES S&rsquo;EFFORCERONT DE LE REGLER A
        L&rsquo;AMIABLE.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        DANS L&rsquo;HYPOTHESE OU LA TENTATIVE DE RESOLUTION AMIABLE ECHOUERAIT
        OU NE SERAIT PAS ENVISAGEE, LE LITIGE SERA CONFIE AU TRIBUNAL COMPETENT.
      </p>
      <Divider
        borderColor={"black"}
        width="full"
        orientation="horizontal"
        mt={14}
      />
      <Heading as="h2" color={"tertiary.800"} my={14} fontSize={"xl"}>
        ANNEXE 1 - CONDITIONS TARIFAIRES (Applicable uniquement à l’Organisme
        Partenaire)
      </Heading>
      <Flex w="full" direction="column">
        <Flex width="full" justify="between">
          <Box width="full" border="1px" p={2}>
            Abonnement Annuel
          </Box>
          <Box width="full" border="1px" p={2}>
            358,80 EUROS TTC PAR AN
          </Box>
        </Flex>
        <Flex w="full" justify="between">
          <Box width="full" border="1px" p={2}>
            Abonnement Mensuel
          </Box>
          <Box width="full" border="1px" p={2}>
            39,90 EUROS TTC PAR MOIS
          </Box>
        </Flex>
      </Flex>
      <Heading as="h2" color={"tertiary.800"} my={14} fontSize={"xl"}>
        ANNEXE 3 - ACCORD SUR LA PROTECTION DES DONNEES (Applicable uniquement
        aux Thérapeutes Partenaires)
      </Heading>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Entre les soussign&eacute;s :</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Soci&eacute;t&eacute; ANDREWAPP, Soci&eacute;t&eacute; par actions
        simplifi&eacute;e, immatricul&eacute;e au Registre du Commerce et des
        Soci&eacute;t&eacute;s de Lyon sous le num&eacute;ro 952 463 636, dont
        le si&egrave;ge social est sis 32 RUE DU B&OElig;UF 69005
        LYON&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <em>Ci-apr&egrave;s, d&eacute;nomm&eacute;e &laquo; </em>
        <strong>
          <em>ANDREWAPP</em>
        </strong>
        <em> &raquo;,</em>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <em>D&rsquo;une part</em>
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ET&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le th&eacute;rapeute partenaire d&rsquo;ANDREWAPP
        r&eacute;f&eacute;renc&eacute; sur l&rsquo;Application Andrew et ayant
        accept&eacute; les Conditions G&eacute;n&eacute;rales de Services
      </p>
      <p>&nbsp;</p>
      <p>
        <em>Ci-apr&egrave;s, d&eacute;nomm&eacute;e le &laquo; </em>
        <strong>
          <em>Partenaire </em>
        </strong>
        <em>&raquo;,</em>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <em>D&rsquo;autre part</em>
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <em>
          Ci-apr&egrave;s, collectivement d&eacute;nomm&eacute;es &laquo;{" "}
        </em>
        <strong>
          <em>Les Parties</em>
        </strong>
        <em> &raquo;</em>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ETANT PREALABLEMENT RAPPELE QUE&nbsp;:</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le pr&eacute;sent Accord, &eacute;tabli en application des articles 26
        et 28 du r&egrave;glement (UE) 2016/679 du Parlement europ&eacute;en et
        du Conseil du 27 avril 2016, a pour objet de d&eacute;finir les
        conditions dans lesquelles les Parties s&rsquo;engagent &agrave;
        effectuer les op&eacute;rations de Traitement de Donn&eacute;es
        personnelles d&eacute;finies en Appendice 1 selon les modalit&eacute;s
        &eacute;dict&eacute;es ci-apr&egrave;s.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Dans le cadre de l&rsquo;utilisation de l&rsquo;Application Andrew et
        des services qui y sont propos&eacute;s, les Parties s&rsquo;engagent
        &agrave; respecter la r&eacute;glementation en vigueur applicable au
        Traitement de Donn&eacute;es personnelles et, en particulier, le
        r&egrave;glement (UE) 2016/679 pr&eacute;cit&eacute; applicable depuis
        le 25 mai 2018 (ci-apr&egrave;s, &laquo;{" "}
        <strong>
          le R&egrave;glement G&eacute;n&eacute;ral sur la Protection des
          Donn&eacute;es
        </strong>{" "}
        &raquo;), la loi &laquo; Informatique et libert&eacute;s &raquo;
        n&deg;78-17 du 6 janvier 1978 modifi&eacute;e et les
        r&eacute;glementations en mati&egrave;re de propri&eacute;t&eacute;
        industrielle et intellectuelle.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>ARTICLE 1 &ndash; DEFINITIONS</strong>
      </p>
      <p>
        Pour les besoins des pr&eacute;sentes et nonobstant toutes autres
        d&eacute;finitions pr&eacute;vues dans l&rsquo;Accord, les termes
        suivants auront le sens qui est donn&eacute; ci-dessous :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4} spacing={3}>
        <ListItem>
          &laquo;&nbsp;<strong>Abonnement</strong>&nbsp;&raquo;&nbsp;: désigne
          la souscription par l’Organisme Partenaire aux Services proposés par
          ANDREWAPP et tels que détaillés dans les Conditions Générales de
          Services.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Accord</strong>&nbsp;&raquo;&nbsp;:
          D&eacute;signe le pr&eacute;sent Accord sur la Protection des
          Donn&eacute;es compl&eacute;t&eacute; par les appendices suivants
          :&nbsp;
        </ListItem>
        <UnorderedList pl={4} spacing={2}>
          <ListItem>
            <em>
              <strong>
                <em>Appendice 1</em>
              </strong>{" "}
              :{" "}
              <em>
                Description des Traitements de donn&eacute;es
                concern&eacute;s&nbsp;;
              </em>
            </em>
          </ListItem>
          <ListItem>
            <strong>
              <em>Appendice 2&nbsp;</em>
            </strong>
            <em>: Politique de s&eacute;curit&eacute;&nbsp;</em>
          </ListItem>
        </UnorderedList>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        En cas de conflit entre des &eacute;l&eacute;ments essentiels des
        Conditions G&eacute;n&eacute;rales de Services et le pr&eacute;sent
        Accord, les dispositions du second pr&eacute;vaudront sur le premier.
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4} spacing={6}>
        <ListItem>
          <strong>&laquo;&nbsp;Application&nbsp;&raquo;</strong>&nbsp;: désigne
          l’application Andrew accessible pour les Partenaires. L’Application
          est en accès gratuit et librement téléchargeable pour les Patients
          Utilisateurs.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Autorit&eacute; de r&eacute;gulation</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe toute autorit&eacute;
          comp&eacute;tente en mati&egrave;re de protection des Donn&eacute;es
          Personnelles.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Backoffice&nbsp;&raquo;&nbsp;</strong>:
          d&eacute;signe l&rsquo;interface professionnelle
          r&eacute;serv&eacute;e aux Organismes Partenaires et accessible sur le
          Site d&rsquo;Andrew &agrave; l&rsquo;adresse suivante{" "}
          <strong>
            <Link
              as={RouterLink}
              to="https://pro.andrewapp.fr"
              target="_blank"
              aria-label="Visiter le site Andrew Pro"
            >
              www.pro.andrewapp.fr
            </Link>
          </strong>
          .
        </ListItem>
        <ListItem>
          <strong>
            &laquo;&nbsp;Conditions G&eacute;n&eacute;rales de
            Services&nbsp;&raquo; ou &laquo;&nbsp;CGS&nbsp;&raquo;&nbsp;:
          </strong>{" "}
          désigne les conditions contractuelles acceptées par les Organismes
          Partenaires lors de l’Abonnement aux Services et par les Partenaires,
          préalablement à leur utilisation des Services.
        </ListItem>
        <ListItem>
          &laquo;{" "}
          <strong>
            Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation
          </strong>{" "}
          &raquo; ou &laquo; <strong>CGU</strong> &raquo; : désigne les
          conditions contractuelles acceptées par les Patients Utilisateurs lors
          de leur inscription sur l’Application et encadrant leur utilisation de
          l’Application.
        </ListItem>
        <ListItem>
          &laquo; <strong>Destinataire autoris&eacute;</strong> &raquo; :
          Désigne un administrateur, un thérapeute partenaire autorisé par
          l’Organisme Partenaire pour utiliser les Services, autre que le
          Partenaire, un employé ou un Sous- traitant ultérieur qui a un besoin
          légitime d’accéder aux Données personnelles dans le cadre de
          l’exécution des Services ou de l’Organisation interne d’ANDREWAPP.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Donn&eacute;es personnelles</strong>
          &nbsp;&raquo;&nbsp;: Désigne toute information relative à une personne
          physique identifiée ou qui peut être identifiée comme telle, soit
          directement soit indirectement par regroupement d’informations, par
          référence à un numéro d’identification ou à des éléments qui lui sont
          propres : nom, adresse, numéro de téléphone, adresse IP, adresse
          email, identifiant/login, mot de passe, données de connexion, etc.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Finalit&eacute; autoris&eacute;e</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe l&rsquo;objet du Traitement de
          Donn&eacute;es personnelles mis en &oelig;uvre par les Parties,
          conform&eacute;ment &agrave; l&rsquo;<em>Appendice 1</em>.
        </ListItem>
        <ListItem>
          &laquo; <strong>Instructions</strong> &raquo; : D&eacute;signe
          l&rsquo;ensemble des instructions &eacute;crites par le Responsable de
          Traitement &agrave; destination du Sous-traitant.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;
          <strong>
            R&egrave;glementation relative &agrave; la protection des
            donn&eacute;es{" "}
          </strong>
          &raquo;&nbsp;: D&eacute;signe la r&eacute;glementation en vigueur
          relative &agrave; la protection des Donn&eacute;es Personnelles et, en
          particulier :
        </ListItem>
        <UnorderedList pl={4}>
          <ListItem>
            le r&egrave;glement (UE) 2016/679 du Parlement europ&eacute;en et du
            Conseil du 27 avril 2016 applicable depuis le 25 mai 2018 dit
            &laquo; R&egrave;glement G&eacute;n&eacute;ral sur la Protection des
            Donn&eacute;es &raquo; ou &laquo;&nbsp;RGPD&nbsp;&raquo; ;
          </ListItem>
          <ListItem>
            la loi &laquo; Informatique et libert&eacute;s &raquo; n&deg;78-17
            du 6 janvier 1978 modifi&eacute;e ;&nbsp;
          </ListItem>
          <ListItem>
            toute l&eacute;gislation entrant en vigueur et susceptible
            d&rsquo;affecter les Traitements vis&eacute;s par le pr&eacute;sent
            Accord ;&nbsp;
          </ListItem>
          <ListItem>
            tout guide de bonnes pratiques et r&eacute;f&eacute;rentiels
            publi&eacute; par les Autorit&eacute;s de r&eacute;gulation (CNIL)
            ou le Comit&eacute; Europ&eacute;en sur la Protection des
            Donn&eacute;es.
          </ListItem>
        </UnorderedList>
        <ListItem>
          &laquo;&nbsp;<strong>Personne concern&eacute;e</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe toute personne physique dont les
          Donn&eacute;es personnelles font l&rsquo;objet d&rsquo;un Traitement.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Responsable de Traitement</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe la personne qui d&eacute;termine
          les moyens et les finalit&eacute;s du Traitement. Lorsqu&rsquo;un
          Traitement conjoint est mis en place par les Parties, celles-ci seront
          regard&eacute;es comme &laquo;&nbsp;
          <strong>Responsables de Traitement conjoints&nbsp;</strong>&raquo;
          d&egrave;s lorsqu&rsquo;elles d&eacute;terminent conjointement les
          moyens et les finalit&eacute;s du Traitement.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Patient&nbsp;&raquo;</strong>&nbsp;:
          d&eacute;signe le patient auquel le Partenaire propose un suivi
          post-consultation via l&rsquo;Application.
        </ListItem>
        <ListItem>
          &laquo; <strong>Personne concern&eacute;e</strong> &raquo; :
          D&eacute;signe toute personne physique dont les Donn&eacute;es
          personnelles font l&rsquo;objet d&rsquo;un Traitement.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Services&nbsp;&raquo;</strong>&nbsp;: désigne
          l’ensemble des fonctionnalités et services proposés par ANDREWAPP aux
          Partenaires.
        </ListItem>
        <ListItem>
          <strong>&laquo;&nbsp;Site&nbsp;&raquo;&nbsp;</strong>: d&eacute;signe
          le site internet d&rsquo;Andrew accessible &agrave; partir de
          l&rsquo;URL{" "}
          <strong>
            <Link
              as={RouterLink}
              to="/"
              aria-label="Aller à l'accueil du site Andrew"
            >
              www.andrewapp.fr
            </Link>
          </strong>{" "}
          et &agrave; partir duquel les Partenaires accéder à leur Compte
          Partenaire et se connecter au Backoffice.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Sous-traitant</strong>&nbsp;&raquo; : Désigne la
          personne physique ou morale amenée à réaliser des opérations de
          Traitement pour le compte et sur instructions du Responsable de
          Traitement. L’entité agissant pour le compte et sur instruction du
          Sous-traitant est qualifiée de «{" "}
          <em>Sous-traitant(s) ultérieur(s)</em>»
        </ListItem>
        <ListItem>
          &laquo;&nbsp;<strong>Traitement</strong>&nbsp;&raquo;&nbsp;:
          D&eacute;signe toute op&eacute;ration ou tout ensemble
          d&apos;op&eacute;rations effectu&eacute;es ou non &agrave; l&apos;aide
          de proc&eacute;d&eacute;s automatis&eacute;s et appliqu&eacute;es
          &agrave; des Donn&eacute;es personnelles ou des ensembles de
          Donn&eacute;es personnelles, telles que la collecte,
          l&apos;enregistrement, l&apos;organisation, la structuration, la
          conservation, l&apos;adaptation ou la modification, l&apos;extraction,
          la consultation, l&apos;utilisation, la communication par
          transmission, la diffusion ou toute autre forme de mise &agrave;
          disposition, le rapprochement ou l&apos;interconnexion, la limitation,
          l&apos;effacement ou la destruction.
        </ListItem>
        <ListItem>
          &laquo;&nbsp;
          <strong>Violation de Donn&eacute;es personnelles</strong>
          &nbsp;&raquo;&nbsp;: D&eacute;signe une faille de
          s&eacute;curit&eacute; qui entra&icirc;ne accidentellement ou
          illicitement l&rsquo;acc&egrave;s &agrave; ou la destruction, la
          perte, l&rsquo;alt&eacute;ration, la divulgation non autoris&eacute;e
          de Donn&eacute;es Personnelles transmises, stock&eacute;es ou
          trait&eacute;es.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 2 &ndash; DUREE&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Le présent Accord entre en vigueur à compter de l’acceptation des
        Conditions Générales de Services par le Partenaire et reste applicable
        pour toute la période d’utilisation des Services par le Partenaire.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 3 &ndash; R&Ocirc;LE DES PARTIES</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Les Parties conviennent qu&rsquo;ANDREWAPP et le Partenaire auront les
        qualit&eacute;s suivantes&nbsp;:
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Dans le cadre de l&rsquo;ex&eacute;cution des Services, ANDREWAPP est
          amen&eacute;e &agrave; traiter des Donn&eacute;es personnelles pour le
          compte du Partenaire lorsque celui-ci cr&eacute;e une fiche Patient
          pour un Patient ne disposant pas encore de Compte Utilisateur&nbsp;:
          dans ce cas, ANDREWAPP agit en tant que Sous-traitant du Partenaire,
          Responsable du traitement&nbsp;;
        </ListItem>
        <ListItem>
          Dans le cadre de l&rsquo;utilisation de l&rsquo;Application, ANDREWAPP
          est amen&eacute;e &agrave; traiter les Donn&eacute;es personnelles de
          Patients saisies par le Partenaire pour la cr&eacute;ation de
          programmes de contenus personnalis&eacute;s et le suivi de
          l&rsquo;&eacute;volution des sympt&ocirc;mes du Patient : dans ce cas,
          le Partenaire et ANDREWAPP auront la qualit&eacute; de Responsables de
          traitement conjoints.
        </ListItem>
        <ListItem>
          Dans le cadre de la mise &agrave; disposition de l&rsquo;Application
          aupr&egrave;s des Utilisateurs, ANDREWAPP est amen&eacute;e &agrave;
          traiter les Donn&eacute;es personnelles de Patients souhaitant
          acc&eacute;der &agrave; leur Compte utilisateur : dans ce cas,
          ANDREWAPP agit en tant que Responsable du traitement des
          donn&eacute;es en ex&eacute;cution des Conditions
          g&eacute;n&eacute;rales d&rsquo;utilisation accept&eacute;es par
          l&rsquo;Utilisateur lors de son inscription.
        </ListItem>
      </UnorderedList>
      <p>
        <br />
      </p>
      <p>
        <strong>ARTICLE 4 &ndash; CONSIGNES ET CONFORMITE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4.1.</strong> Dans le cadre de la mise en &oelig;uvre des
        op&eacute;rations de traitement, ANDREWAPP et le Partenaire
        s&rsquo;engagent &agrave; :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Respecter la R&egrave;glementation relative &agrave; la protection des
          donn&eacute;es ;
        </ListItem>
        <ListItem>
          Coop&eacute;rer et se conformer aux instructions ou aux
          d&eacute;cisions de toute Autorit&eacute; de r&eacute;gulation ; et
        </ListItem>
        <ListItem>
          Ne pas faire ou omettre de faire ou permettre que quelque chose soit
          fait qui am&egrave;nerait l&rsquo;autre Partie &agrave; enfreindre la
          Loi sur la protection des donn&eacute;es.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>4.2. </strong>En sus des obligations list&eacute;es au 4.1 du
        pr&eacute;sent Accord, lorsqu&rsquo;elle agit en qualit&eacute; de
        Sous-traitant, ANDREWAPP s&rsquo;engage &agrave; :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Traiter uniquement les Donn&eacute;es personnelles n&eacute;cessaires
          aux Finalit&eacute;s autoris&eacute;es conform&eacute;ment aux
          Instructions d&eacute;finies en Appendice 1, y compris en ce qui
          concerne les &eacute;ventuels transferts de Donn&eacute;es
          personnelles vers un pays tiers ou &agrave; une organisation
          internationale, &agrave; moins qu&apos;elle ne soit tenue d&apos;y
          proc&eacute;der en vertu du droit de l&apos;Union ou du droit de
          l&apos;&Eacute;tat membre auquel elle est soumise. Dans ce cas,
          ANDREWAPP informe le Partenaire de cette obligation juridique avant le
          traitement, sauf si le droit concern&eacute; interdit une telle
          information pour des motifs importants d&apos;int&eacute;r&ecirc;t
        </ListItem>
        <ListItem>
          Respecter les Instructions formul&eacute;es par le Responsable de
          Traitement, et s&rsquo;assurer de leur respect par les Destinataires
          autoris&eacute;s ;
        </ListItem>
        <ListItem>
          Ne pas traiter les Donn&eacute;es personnelles &agrave; d&apos;autres
          fins.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 5 &ndash; OBLIGATIONS GENERALES DES PARTIES</strong>
      </p>
      <p>&nbsp;</p>
      <strong>5.1. Sous-traitance</strong>
      <p>&nbsp;</p>
      <p>
        Dans le cadre des op&eacute;rations r&eacute;alis&eacute;es en
        qualit&eacute; de Sous-traitant, ANDREWAPP s&rsquo;engage
        &agrave;&nbsp;:&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Informer le Responsable de Traitement dans les meilleurs d&eacute;lais
          :
        </ListItem>
        <UnorderedList pl={4}>
          <ListItem>
            si des Instructions d&eacute;livr&eacute;es par le Responsable de
            Traitement relatives aux Traitements enfreint la
            R&egrave;glementation sur la protection des donn&eacute;es ;
          </ListItem>
          <ListItem>
            en cas de survenance d&rsquo;une Violation de Donn&eacute;es
            personnelles, ou en cas de survenance d&rsquo;une faille de
            s&eacute;curit&eacute; affectant le syst&egrave;me informatique
            d&rsquo;ANDREWAPP ou d&rsquo;un Sous-traitant ult&eacute;rieur, et
            ce dans les meilleurs d&eacute;lais conform&eacute;ment &agrave; ce
            qui est pr&eacute;vu &agrave; l&rsquo;article 6.4 du pr&eacute;sent
            Accord ;
          </ListItem>
          <ListItem>
            si ANDREWAPP re&ccedil;oit une plainte, un avis ou une communication
            d&rsquo;une Personne concern&eacute;e dans le cadre de
            l&rsquo;exercice de ses droits, ou d&rsquo;une Autorit&eacute; de
            r&eacute;gulation qui concerne directement ou indirectement le
            Traitement ou la conformit&eacute; de l&rsquo;une des Parties
            &agrave; la R&egrave;glementation relative &agrave; la protection
            des donn&eacute;es dans le cadre du Traitement.
          </ListItem>
        </UnorderedList>
      </UnorderedList>
      <UnorderedList pl={4}>
        <ListItem>
          Modifier, transf&eacute;rer et / ou supprimer les Donn&eacute;es
          personnelles d&eacute;tenues par elle ou en son nom par un
          Sous-traitant ult&eacute;rieur, conform&eacute;ment &agrave; toute
          Instruction &eacute;crite du Responsable de Traitement ;
        </ListItem>
        <ListItem>
          Aider le Responsable de Traitement &agrave; respecter les obligations
          &eacute;nonc&eacute;es aux articles 32 &agrave; 36 du RGPD en tenant
          compte de la nature du Traitement et des informations mises &agrave;
          la disposition d&rsquo;ANDREWAPP.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>

      <strong>5.2. Responsabilit&eacute; autonome</strong>
      <p>&nbsp;</p>
      <p>
        <strong>5.2.1.</strong> Dans le cadre des opérations réalisées en
        qualité de Responsables du traitement autonomes, les Parties s’engagent
        à respecter les obligations imposées par la Règlementation relative à la
        protection des données personnelles, et seront individuellement
        responsable de toute non-conformité que leur serait imputable.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.2.2. </strong>Par ailleurs, les Parties s&rsquo;engagent
        &agrave; partager les Donn&eacute;es personnelles selon les
        modalit&eacute;s de s&eacute;curit&eacute; mentionn&eacute;es en
        Appendice 2.&nbsp;
      </p>
      <p>&nbsp;</p>
      <strong>5.3. Responsabilit&eacute; Conjointe</strong>
      <p>&nbsp;</p>
      <p>
        <strong>5.3.1. </strong>Dans le cadre des op&eacute;rations
        r&eacute;alis&eacute;es en qualit&eacute; de Responsables de traitement
        conjoints, les Parties s&rsquo;engagent conform&eacute;ment &agrave;
        l&rsquo;article 26 du RGPD, &agrave; :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Respecter le principe de lic&eacute;it&eacute; du traitement
          pr&eacute;vu &agrave; l&rsquo;article 6 du RGPD ;
        </ListItem>
        <ListItem>
          Traiter les Donn&eacute;es personnelles uniquement pour les seules
          finalit&eacute;s des traitements renseign&eacute;s en Appendice 1 ;
        </ListItem>
        <ListItem>
          Garantir la confidentialit&eacute; des Donn&eacute;es personnelles
          trait&eacute;es dans le cadre du pr&eacute;sent Accord ;
        </ListItem>
        <ListItem>
          Prendre en compte, s&rsquo;agissant des outils, produits, applications
          ou services utilis&eacute;s, les principes de protection des
          donn&eacute;es d&egrave;s la conception et de protection des
          donn&eacute;es par d&eacute;faut ;
        </ListItem>
        <ListItem>
          Informer les Personnes concern&eacute;es conform&eacute;ment aux
          articles 13 et 14 du RGPD ;
        </ListItem>
        <ListItem>
          Mettre &agrave; disposition des Personnes concern&eacute;es les
          grandes lignes du pr&eacute;sent Accord &agrave; premi&egrave;re
          demande suivant le mod&egrave;le propos&eacute; ci-dessous :
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <Box border="1px" borderColor="black" p={2}>
        Les traitements de donn&eacute;es r&eacute;alis&eacute;s dans le cadre
        de cr&eacute;ation de votre parcours d&rsquo;exercices
        personnalis&eacute;s et du suivi de l&rsquo;&eacute;volution de vos
        sympt&ocirc;mes ont fait l&rsquo;objet d&rsquo;un contrat entre votre
        th&eacute;rapeute et ANDREWAPP, &eacute;diteur de l&rsquo;application
        Andrew. Votre th&eacute;rapeute et ANDREWAPP sont co-responsables du
        traitement au sens de l&rsquo;article 26 du R&egrave;glement
        G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es du 27 avril
        2016 entr&eacute; en vigueur le 25 mai 2018. Ils se sont ainsi
        conjointement engag&eacute;s &agrave; vous informer de la nature de ces
        traitements et des droits dont vous disposez, ainsi qu&rsquo;&agrave;
        mettre en &oelig;uvre les mesures organisationnelles et techniques
        appropri&eacute;es pour assurer la s&eacute;curit&eacute; et la
        confidentialit&eacute; de vos donn&eacute;es. Le d&eacute;tail des
        caract&eacute;ristiques de ces traitements est port&eacute; &agrave;
        votre connaissance au sein de la Politique de confidentialit&eacute;
        disponible depuis votre application Andrew.
      </Box>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          S&rsquo;informer dans les plus brefs d&eacute;lais, dans la mesure
          permise par la loi, en cas de contr&ocirc;les, d&rsquo;inspections ou
          d&rsquo;autres mesures engag&eacute;es par une Autorit&eacute; de
          r&eacute;gulation ou organisation gouvernementale, sauf lorsque ladite
          information est interdite par une disposition l&eacute;gislative ou
          r&eacute;glementaire, ou par une d&eacute;cision d&rsquo;une
          autorit&eacute; comp&eacute;tente ;
        </ListItem>
        <ListItem>
          Identifier et documenter tous les Traitements de Donn&eacute;es
          personnelles mis en &oelig;uvre pour &ecirc;tre capables &agrave; tout
          moment de d&eacute;montrer leur conformit&eacute; et les fondements
          l&eacute;gaux appropri&eacute;s de leurs traitements ;
        </ListItem>
        <ListItem>
          S&rsquo;abstenir de toute action susceptible d&rsquo;affecter la
          capacit&eacute; de l&rsquo;autre Partie &agrave; d&eacute;montrer le
          respect des obligations lui incombant en application de la
          R&egrave;glementation relative &agrave; la protection des
          donn&eacute;es ;
        </ListItem>
        <ListItem>
          Ne faire aucune d&eacute;claration &agrave; un tiers ou annonce
          publique relative au pr&eacute;sent Accord, y compris &agrave; une
          autorit&eacute; comp&eacute;tente, sans avoir, au pr&eacute;alable,
          consult&eacute; l&rsquo;autre Partie concernant le contenu d&rsquo;une
          telle d&eacute;claration ou annonce publique sauf disposition
          express&eacute;ment contraire pr&eacute;vue par le droit d&rsquo;un
          Etat membre ou pays tiers ;
        </ListItem>
        <ListItem>
          D&rsquo;une mani&egrave;re g&eacute;n&eacute;rale, ne traiter,
          collecter, utiliser et transf&eacute;rer des Donn&eacute;es
          personnelles que dans le respect la Loi sur la protection des
          donn&eacute;es.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 6 &ndash; SECURITE</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>6.1. </strong>Dans le cadre de l&rsquo;ex&eacute;cution des
        Services ANDREWAPP s&apos;engage à&nbsp;:&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          S’assurer que des mesures techniques et organisationnelles appropriées
          ont été mises en place au sein de l’Application pour garantir un
          niveau de sécurité adapté au risque ;
        </ListItem>
        <ListItem>
          Mettre en &oelig;uvre et maintenir &agrave; jour les mesures de
          s&eacute;curit&eacute; conformes &agrave; l&rsquo;&eacute;tat de
          l&rsquo;art eu &eacute;gard aux risques g&eacute;n&eacute;r&eacute;s
          par les Traitements, l&rsquo;&eacute;tat de l&rsquo;art &eacute;tant
          notamment appr&eacute;ci&eacute; au regard des attentes en
          mati&egrave;re de s&eacute;curit&eacute; formul&eacute;es par
          l&rsquo;Autorit&eacute; de r&eacute;gulation, par l&rsquo;Agence
          nationale de s&eacute;curit&eacute; des syst&egrave;mes
          d&rsquo;information (l&rsquo;ANSSI) et par l&rsquo;Agence de
          l&rsquo;Union europ&eacute;enne pour la cybers&eacute;curit&eacute;
          (l&rsquo;Enisa) ; et
        </ListItem>
        <ListItem>
          S&apos;assurer que les Destinataires autoris&eacute;s mettent en
          &oelig;uvre et maintiennent &agrave; jour des mesures de
          s&eacute;curit&eacute; conformes &agrave; la R&egrave;glementation sur
          la protection des donn&eacute;es&nbsp;;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>6.2. </strong>En sus des obligations listées à l’article 6.1 du
        présent Accord, lorsqu’elle agit en qualité de Sous- traitant, ANDREWAPP
        s’engage à se conformer dans des délais raisonnables tenant compte de la
        complexité de l’opération et de la disponibilité de ses équipes, à toute
        demande raisonnable du Partenaire en ce qui concerne la sécurité et le
        Traitement des Données personnelles.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>6.3. </strong>En cas de survenance d’une Violation de Données
        personnelles qualifiée affectant les Traitements mis en œuvre par
        ANDREWAPP en sa qualité de Sous-traitant ou par un Sous-traitant
        ultérieur, ANDREWAPP s’engage à :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Notifier au Partenaire toute faille de s&eacute;curit&eacute; pouvant
          entra&icirc;ner une Violation de Donn&eacute;es personnelles dans les
          meilleurs d&eacute;lais, et au plus tard dans les 72 heures
          apr&egrave;s en avoir pris connaissance, en adressant un courrier
          &eacute;lectronique&nbsp;&agrave; l&rsquo;adresse renseign&eacute;e
          par ses soins lors de son inscription ;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>Cette notification comprendra :&nbsp;</ListItem>
        <UnorderedList pl={4}>
          <ListItem>
            La description de la nature de la Violation de Donn&eacute;es
            personnelles y compris, si possible, les cat&eacute;gories et le
            nombre approximatif de personnes concern&eacute;es par la violation
            et les cat&eacute;gories et le nombre approximatif
            d&apos;enregistrements de Donn&eacute;es personnelles
            concern&eacute;es ;
          </ListItem>
          <ListItem>
            Le cas &eacute;ch&eacute;ant, le nom et les coordonn&eacute;es du
            d&eacute;l&eacute;gu&eacute; &agrave; la protection des
            donn&eacute;es et/ou d&apos;un autre point de contact aupr&egrave;s
            duquel des informations suppl&eacute;mentaires peuvent &ecirc;tre
            obtenues ;
          </ListItem>
          <ListItem>
            La description des cons&eacute;quences probables de la Violation de
            Donn&eacute;es personnelles ; et
          </ListItem>
          <ListItem>
            La description des mesures prises ou envisag&eacute;es pour
            rem&eacute;dier &agrave; la Violation de Donn&eacute;es
            personnelles, y compris, le cas &eacute;ch&eacute;ant, les mesures
            pour en att&eacute;nuer les &eacute;ventuelles cons&eacute;quences
            n&eacute;gatives.&nbsp;
          </ListItem>
        </UnorderedList>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>
          ARTICLE 8 &ndash; DESTINATAIRES DES DONNEES PERSONNELLES
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8.1.</strong> Les Parties garantissent mutuellement
        qu&rsquo;elles :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Restreignent l&apos;acc&egrave;s aux Donn&eacute;es personnelles aux
          seuls Destinataires autoris&eacute;s ayant besoin d&apos;avoir
          acc&egrave;s aux Donn&eacute;es personnelles ou d&rsquo;en
          conna&icirc;tre. Dans le cas d&apos;un acc&egrave;s aux Donn&eacute;es
          personnelles par un de ses employ&eacute;s, la Partie concern&eacute;e
          s&rsquo;assure que cet acc&egrave;s est strictement limit&eacute;
          &agrave; l&apos;ex&eacute;cution des t&acirc;ches par cet
          employ&eacute;&nbsp;;
        </ListItem>
        <ListItem>
          Imposent aux Destinataires autoris&eacute;s des obligations de
          confidentialit&eacute; et de s&eacute;curit&eacute;
          &eacute;quivalentes &agrave; celles contenues dans le pr&eacute;sent
          Accord ;&nbsp;
        </ListItem>
        <ListItem>
          Mettent tous les moyens en &oelig;uvre pour s&apos;assurer que les
          Sous-traitants ult&eacute;rieurs respectent la R&egrave;glementation
          relative &agrave; la protection des donn&eacute;es ;
        </ListItem>
        <ListItem>
          Prennent des mesures raisonnables pour assurer la fiabilit&eacute; de
          tous les Destinataires autoris&eacute;s ayant acc&egrave;s aux
          Donn&eacute;es personnelles ; et
        </ListItem>
        <ListItem>
          Ne divulguent aucune Donn&eacute;e personnelle &agrave; une autre
          personne sans en avoir pr&eacute;alablement inform&eacute;
          l&rsquo;autre.
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>8.2. </strong>Dans le cadre des Traitements
        r&eacute;alis&eacute;s en sa qualit&eacute; de Sous-traitant, ANDREWAPP
        est express&eacute;ment autoris&eacute;e par le Partenaire &agrave;
        recourir un ou plusieurs Sous-traitants ult&eacute;rieurs pour traiter
        les Donn&eacute;es personnelles :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Apr&egrave;s avoir pr&eacute;alablement inform&eacute; le Responsable
          de Traitement en Appendice 1 des pr&eacute;sentes de
          l&rsquo;identit&eacute; du Sous-traitant ult&eacute;rieur, des
          activit&eacute;s de Traitement qui seront entreprises par lui et le
          cas &eacute;ch&eacute;ant d&rsquo;un transfert de donn&eacute;es en
          dehors de l&rsquo;Espace Economique Europ&eacute;en. En cas
          d&rsquo;ajout ou de remplacement d&rsquo;un Sous-traitant
          ult&eacute;rieur, ANDREWAPP en informera le Responsable de Traitement
          qui dispose d&rsquo;un d&eacute;lai de dix (10) jours &agrave; compter
          de la date de r&eacute;ception de cette information pour
          pr&eacute;senter, le cas &eacute;ch&eacute;ant, ses objections. A
          d&eacute;faut d&rsquo;objection du Responsable de Traitement dans ce
          d&eacute;lai, la sous-traitance sera consid&eacute;r&eacute;e comme
          accept&eacute;e par le Responsable de Traitement ;
        </ListItem>
      </UnorderedList>
      <p>
        <br />
      </p>
      <p>
        <strong>
          ARTICLE 9 &ndash; INFORMATION ET DROITS DES PERSONNES CONCERNEES
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9.1. </strong>Dans le cadre de la mise en &oelig;uvre des
        Services, les Parties conviennent qu&rsquo;ANDREWAPP informe les
        Personnes concern&eacute;es des op&eacute;rations de Traitement au
        travers de la Politique de confidentialit&eacute; accessibles au sein de
        l&rsquo;Application.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9.2.</strong> Lorsque qu&rsquo;ANDREWAPP re&ccedil;oit une
        demande d&rsquo;exercice de droits concernant les Traitements
        effectu&eacute;s pour le compte du Partenaire en qualit&eacute; de
        Sous-traitant, les Parties conviennent que :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>ANDREWAPP agira sur Instruction du Partenaire ;</ListItem>
        <ListItem>
          Coop&eacute;re avec le Partenaire afin de lui permettre de
          r&eacute;pondre dans les d&eacute;lais r&egrave;glementaires&nbsp;;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>9.3.</strong> S&rsquo;agissant des Traitements de donn&eacute;es
        op&eacute;r&eacute;s par les Parties en qualit&eacute; de Responsables
        de traitement conjoint, ANDREWAPP est d&eacute;sign&eacute; comme
        principal point de contact pour les Personnes concern&eacute;es au sein
        de la Politique de confidentialit&eacute; accessible au sein de
        l&rsquo;Application&nbsp;;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>9.4.</strong> Lorsqu&rsquo;une demande d&rsquo;exercice de
        droits concerne les Traitements effectu&eacute;s par les Parties en leur
        qualit&eacute; de Responsables du traitement conjoints, les Parties
        conviennent que :&nbsp;
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          Chaque Partie avise l&rsquo;autre dans les meilleurs d&eacute;lais,
          par courrier &eacute;lectronique dans un d&eacute;lai de cinq (5)
          jours ouvr&eacute;s :
        </ListItem>
        <UnorderedList pl={4}>
          <ListItem>
            Pour le Partenaire&nbsp;: &agrave; l&rsquo;adresse
            l&rsquo;&eacute;lectronique renseign&eacute;e lors de son
            inscription&nbsp;;
          </ListItem>
          <ListItem>
            Pour ANDREWAPP&nbsp;: &agrave; l&rsquo;adresse &eacute;lectronique
            mentionn&eacute;e en Appendice 1&nbsp;;
          </ListItem>
        </UnorderedList>
        <ListItem>
          Chaque Partie informera l&rsquo;autre Partie de l&rsquo;avanc&eacute;e
          du processus de traitement de la demande d&rsquo;exercice de
          droit.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>
        <br />
      </p>
      <p>
        <strong>
          ARTICLE 10 &ndash; TRANSFERT DE DONNEES EN DEHORS DE L&rsquo;UNION
          EUROPEENNE
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Lorsque les Parties agissent en qualit&eacute; de Responsables du
        traitement conjoints ou de Sous-traitant, elles s&rsquo;engagent
        &agrave; ne pas transf&eacute;rer les Donn&eacute;es personnelles en
        dehors de l&rsquo;Union europ&eacute;enne.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 11 &ndash; RESPONSABILITE&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Chaque Partie est responsable des dommages directs subis par
        l&rsquo;autre et d&eacute;coulant de la violation par elle, ses
        employ&eacute;s, ses repr&eacute;sentants, et, le cas
        &eacute;ch&eacute;ant, ses Sous-traitants ult&eacute;rieurs (y compris
        les Destinataires autoris&eacute;s) de ses obligations en vertu du
        pr&eacute;sent Accord.
      </p>
      <p>&nbsp;</p>
      <p>
        A ce titre, chaque Partie s&rsquo;engage &agrave; mettre en &oelig;uvre
        les moyens n&eacute;cessaires et raisonnables pour assurer la
        s&eacute;curit&eacute; des traitements, et sera d&egrave;s lors
        responsable des dommages li&eacute;s &agrave; une d&eacute;faillance de
        s&eacute;curit&eacute; des Donn&eacute;es personnelles qui lui serait
        imputable.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>ARTICLE 12 &ndash; AUDIT ET CONTR&Ocirc;LE&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>12.1.</strong> ANDREWAPP met &agrave; la disposition du
        Responsable du traitement les informations n&eacute;cessaires pour
        d&eacute;montrer le respect des obligations pr&eacute;vues au
        pr&eacute;sent Accord et pour permettre, le cas &eacute;ch&eacute;ant,
        la r&eacute;alisation d&rsquo;audits&nbsp;;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>12.2.</strong> Le Partenaire peut commander la réalisation
        d’audits objectifs de conformité à la Règlement sur la protection des
        données sur les opérations de Traitement réalisées par ANDREWAPP en sa
        qualité de Sous- traitant aux fins de l’exécution des Services dans les
        conditions définies ci-après :
      </p>
      <p>&nbsp;</p>
      <UnorderedList pl={4}>
        <ListItem>
          L&rsquo;audit est diligent&eacute; par un auditeur ext&eacute;rieur
          s&eacute;lectionn&eacute; par les Parties pour son expertise, son
          ind&eacute;pendance et son impartialit&eacute; et qui n&rsquo;est, en
          tout &eacute;tat de cause, pas un concurrent d&rsquo;ANDREWAPP ;
        </ListItem>
        <ListItem>
          Les &eacute;l&eacute;ments confidentiels confi&eacute;s &agrave;
          ANDREWAPP par d&rsquo;autres contractants, ou tout autre
          &eacute;l&eacute;ment prot&eacute;g&eacute; par le secret des affaires
          sera exclu du p&eacute;rim&egrave;tre de l&rsquo;audit&nbsp;;&nbsp;
        </ListItem>
        <ListItem>
          L&rsquo;auditeur s&eacute;lectionn&eacute; est li&eacute; aux Parties
          par un accord de confidentialit&eacute; et/ou par le secret
          professionnel ;
        </ListItem>
        <ListItem>
          Le Partenaire avise, par &eacute;crit et moyennant le respect
          d&rsquo;un pr&eacute;avis minimum de trente (30) jours ouvr&eacute;s,
          ANDREWAPP de son intention de faire proc&eacute;der &agrave; un audit
          de conformit&eacute; ;
        </ListItem>
        <ListItem>
          En aucune manière, l’audit réalisé ne saurait détériorer ou ralentir
          les Services proposés par ANDREWAPP ou porter atteinte à sa gestion
          organisationnelle ;
        </ListItem>
        <ListItem>
          Un exemplaire du rapport d&rsquo;audit identique est remis au
          Partenaire ainsi qu&rsquo;&agrave; ANDREWAPP des suites de la
          r&eacute;alisation de la mission d&rsquo;audit et pour lequel des
          observations pourront &ecirc;tre apport&eacute;es par les Parties ;
        </ListItem>
        <ListItem>
          Les frais de l&rsquo;audit de conformit&eacute; seront &agrave; la
          charge du Partenaire ;
        </ListItem>
        <ListItem>
          Le Partenaire ne saurait commander des audits de conformit&eacute; que
          dans la limite d&rsquo;un (1) audit par an ; et
        </ListItem>
        <ListItem>
          ANDREWAPP disposera d&rsquo;un d&eacute;lai de trois (3) mois &agrave;
          compter de la communication du rapport d&rsquo;audit pour corriger
          &agrave; ses frais les manquements et/ou non-conformit&eacute;s
          constat&eacute;s.&nbsp;
        </ListItem>
      </UnorderedList>
      <p>&nbsp;</p>
      <p>
        <strong>12.3.</strong> Dans l&rsquo;hypoth&egrave;se d&rsquo;un
        contr&ocirc;le r&eacute;alis&eacute; par une Autorit&eacute; de
        r&eacute;gulation comp&eacute;tente pouvant int&eacute;resser les
        Traitements r&eacute;alis&eacute;s sur instruction du Partenaire,
        ANDREWAPP, en tant que Sous-traitant s&rsquo;engage &agrave;
        coop&eacute;rer pleinement avec l&rsquo;Autorit&eacute; de
        r&eacute;gulation.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 13 &ndash; SORT DES DONNEES&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de résiliation des présentes, le sort des Données personnelles
        des Patients est détaillé au sein des Conditions Générales de Services.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ARTICLE 14 &ndash; DISPOSITIONS GENERALES&nbsp;</strong>
      </p>
      <p>&nbsp;</p>
      <u>
        <strong>14.1. Modification de l&rsquo;Accord</strong>
      </u>

      <p>&nbsp;</p>
      <p>
        Cet Accord ne peut &ecirc;tre modifi&eacute; que par &eacute;crit
        sign&eacute; par les repr&eacute;sentants d&ucirc;ment autoris&eacute;s
        de chacune des Parties.
      </p>
      <p>&nbsp;</p>
      <p>
        En cas de modification de la R&egrave;glementation sur la protection des
        donn&eacute;es, il est convenu que les Parties pourront r&eacute;viser
        les dispositions du pr&eacute;sent Accord et n&eacute;gocier de bonne
        foi pour s&rsquo;y conformer.
      </p>
      <p>&nbsp;</p>
      <u>
        <strong>14.2. Droit applicable et juridiction</strong>
      </u>
      <p>&nbsp;</p>
      <p>
        LES PRESENTES CGS AINSI QUE LES RELATIONS ENTRE LES PARTIES SONT REGIES
        PAR LE DROIT FRAN&Ccedil;AIS.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        EN CAS DE DIFFEREND SURVENANT ENTRE LES PARTIES AU SUJET DE
        L&rsquo;INTERPRETATION, DE L&rsquo;EXECUTION OU DE LA RESILIATION DES
        PRESENTES, LES PARTIES S&rsquo;EFFORCERONT DE LE REGLER A
        L&rsquo;AMIABLE.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        DANS L&rsquo;HYPOTHESE OU LA TENTATIVE DE RESOLUTION AMIABLE ECHOUERAIT
        OU NE SERAIT PAS ENVISAGEE, LE LITIGE SERA CONFIE AU TRIBUNAL
        COMPETENT.&nbsp;
      </p>
    </Flex>
  )
}
export default ThreeParty

import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { PatientType } from "../../../../../api/patient"

interface IProps {
  patient: PatientType
}

const Coordinates: React.FC<IProps> = ({ patient }) => {
  return (
    <div>
      <Box
        bg="secondary.100"
        padding="16px"
        borderRadius={9}
        mb={6}
        // maxW="208px"
        minW="208px"
      >
        <Heading fontSize={14} fontWeight="400" mb={4}>
          Informations du patient
        </Heading>
        <Flex gap="8px" direction="column" fontWeight="700" fontSize="16px">
          <Text>
            {patient.lastname} {patient.firstname}
          </Text>
          <Text>
            {patient?.birthday
              ? format(new Date(patient?.birthday), "dd/MM/yyyy")
              : ""}
          </Text>
          <Text>{patient.telephone}</Text>
          <Text>{patient.account?.email}</Text>
          <Text>{patient.postalCode}</Text>
        </Flex>
      </Box>
    </div>
  )
}

export default Coordinates

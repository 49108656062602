import { TriangleUpIcon } from "@chakra-ui/icons"
import {
  Box,
  Center,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { MediaType } from "../../../api/media"
import { PodcastItemType } from "../../../api/podcast"
import formatDuration from "../../../utils/formatDuration"

interface IProps {
  media: MediaType | PodcastItemType
}

const PodcastItem: React.FC<IProps> = ({ media }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <div>
      <Box
        w="100%"
        minW="100%"
        borderRadius={9}
        overflow="hidden"
        pos="relative"
        cursor="pointer"
        bg="primary.200"
        onClick={onOpen}
        p={4}
      >
        <Flex alignItems="center">
          <Center bg="tertiary.500" borderRadius="50%" w={8} h={8}>
            <TriangleUpIcon
              transform="rotate(90deg)"
              color="primary.200"
              fontSize={14}
              ml={0.5}
            />
          </Center>
          <Box ml={4}>
            <Text fontSize={16} fontWeight={700} lineHeight={1.5} mb={1}>
              {media.title}
            </Text>
            <Text fontSize={16}>
              {"type" in media
                ? formatDuration(media.duration)
                : media.duration}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent maxW="724">
          <Box
            pos="relative"
            borderRadius={9}
            bg="white"
            w={724}
            h={180}
            py="32px"
            px="54px"
          >
            <ModalCloseButton aria-label="Fermer" />
            <Heading
              as="p"
              fontSize={22}
              fontWeight="bold"
              textAlign="center"
              mb={7}
            >
              {media.title}
            </Heading>
            <Flex justifyContent="center">
              <audio
                src={"type" in media ? media.media.url : media.url}
                controls
              ></audio>
            </Flex>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default PodcastItem

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Text,
  Heading,
  Image,
  Box,
  Checkbox,
} from "@chakra-ui/react"
import ZonesPathologies from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/ZonesPathologies"
import {
  ZonePathologieType,
  getZones,
} from "pages/NewAppointment/newAppointment.mock"
import arrowDropDown from "assets/arrow_drop_down.svg"
import { useEffect, useState } from "react"
import {
  EducationalContentType,
  EducationalGroupContentType,
  getAllProfessionalEducationalContent,
} from "api/educationalContent"
import { useQuery } from "@tanstack/react-query"
import useJobIsKine from "hooks/useIsJobKine"
import EducationalContentAccordionItem from "pages/NewAppointment/components/EducationalContent/components/EducationalContentAccordionItem"

const ELearningKine = () => {
  const [selectedZone, setSelectedZone] = useState<
    ZonePathologieType | undefined
  >(undefined)
  const { data } = useQuery({
    queryKey: ["allProfessionalEducationalContent"],
    queryFn: getAllProfessionalEducationalContent,
  })
  const isJobKine = useJobIsKine()
  const [generalContent, setGeneralContent] = useState<
    EducationalContentType[]
  >([])
  const [allGeneralContent, setAllGeneralContent] = useState<
    EducationalContentType[]
  >([])
  const [filteredEducationalContent, setFilteredEducationalContent] = useState<
    EducationalGroupContentType[]
  >([])

  const [allFilteredEducationalContent, setAllFilteredEducationalContent] =
    useState<EducationalContentType[]>([])

  const [onlyProfessionalContent, setOnlyProfessionalContent] = useState(false)

  useEffect(() => {
    const newFilteredContent = selectedZone
      ? allFilteredEducationalContent.filter(
          (item) =>
            item.appointmentReasons.length === 0 ||
            item.appointmentReasons.find(
              (reason) => reason.id === selectedZone.id
            )
        )
      : allFilteredEducationalContent

    const newGeneralContent = selectedZone
      ? allGeneralContent.filter(
          (item) =>
            item.appointmentReasons.length === 0 ||
            item.appointmentReasons.find(
              (reason) => reason.id === selectedZone.id
            )
        )
      : allGeneralContent

    if (onlyProfessionalContent) {
      setGeneralContent(newGeneralContent.filter((item) => item.onlyForPro))
      setFilteredEducationalContent(
        formatContent(newFilteredContent.filter((item) => item.onlyForPro))
      )
    } else {
      setGeneralContent(newGeneralContent)
      setFilteredEducationalContent(formatContent(newFilteredContent))
    }
  }, [onlyProfessionalContent, selectedZone])

  const formatContent = (content: EducationalContentType[]) =>
    content.reduce((acc: EducationalGroupContentType[], item) => {
      const currentGroups = item.groups
      const newGroups = currentGroups.map((group) => ({
        ...group,
        content: item,
      }))

      // Traiter chaque groupe individuellement
      const updatedAcc = [...acc]
      newGroups.forEach((newGroup) => {
        const existingGroupIndex = updatedAcc.findIndex(
          (accItem) => accItem.id === newGroup.id
        )

        if (existingGroupIndex !== -1) {
          // Le groupe existe déjà, ajouter le contenu à ce groupe
          updatedAcc[existingGroupIndex] = {
            ...updatedAcc[existingGroupIndex],
            content: [
              ...updatedAcc[existingGroupIndex].content,
              newGroup.content,
            ],
          }
        } else {
          // Le groupe n'existe pas, l'ajouter à l'acc
          updatedAcc.push({
            id: newGroup.id,
            name: newGroup.name,
            content: [newGroup.content],
          })
        }
      })

      return updatedAcc
    }, [])

  useEffect(() => {
    if (data) {
      const generalContent = data.filter(
        (item) => !item.groups || item.groups.length === 0
      )
      const filteredContent = data.filter((item) => item.groups.length > 0)
      setAllGeneralContent(generalContent)
      setGeneralContent(generalContent)
      setFilteredEducationalContent(formatContent(filteredContent))
      setAllFilteredEducationalContent(filteredContent)
    }
  }, [data])

  return (
    <Box p="16px" width="full" pb={"120px"}>
      <Heading fontSize={26} color="tertiary.500" mb={16}>
        E-learning
      </Heading>
      <Flex>
        <Flex direction="column" pos="sticky" top={4} h="fit-content">
          <Flex alignItems={"center"}>
            <Flex justifyContent="center" w="full">
              <ZonesPathologies
                datas={getZones(isJobKine)}
                selectedData={selectedZone}
                setSelectedData={setSelectedZone}
              />
            </Flex>
          </Flex>
        </Flex>
        <Box flex={1} px="60px" position="relative">
          <Flex gap={"10px"} mb={"16px"}>
            <Checkbox
              colorScheme="orange"
              borderColor="black"
              onChange={() =>
                setOnlyProfessionalContent(!onlyProfessionalContent)
              }
              isChecked={onlyProfessionalContent}
            />
            <Text>Contenu professionnel uniquement</Text>
          </Flex>
          {generalContent.map((content, index) => (
            <Box key={index} height={"46px"}>
              <EducationalContentAccordionItem
                content={content}
                isEvenIndex={index % 2 === 0}
                onCheck={() => null}
                isChecked={false}
                hasCheckbox={false}
              />
            </Box>
          ))}

          {filteredEducationalContent.length !== 0 && (
            <Flex direction={"column"} mt={"52px"}>
              <Accordion allowMultiple>
                {filteredEducationalContent
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((group, index) => (
                    <AccordionItem
                      key={index}
                      border={"none"}
                      mt={index !== 0 ? "30px" : "0"}
                    >
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton gap={"10px"}>
                            <Image
                              src={arrowDropDown}
                              width={"10px"}
                              height={"10px"}
                              transform={
                                isExpanded ? "rotate(0deg)" : "rotate(180deg)"
                              }
                              transition="transform 0.2s ease"
                            />
                            <Text textAlign={"left"} fontWeight={"bold"}>
                              {group.name}
                            </Text>
                          </AccordionButton>
                          <AccordionPanel padding={0}>
                            {group.content.map((ct, index) => (
                              <EducationalContentAccordionItem
                                key={index}
                                content={ct}
                                isEvenIndex={index % 2 === 0}
                                onCheck={() => null}
                                isChecked={false}
                                hasCheckbox={false}
                              />
                            ))}
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  ))}
              </Accordion>
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default ELearningKine

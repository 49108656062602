import { SVGProps } from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/components/svg/type"
import React from "react"

const BabyBelly: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      width="85"
      height="81"
      viewBox="0 0 85 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.5003 2C28.1003 2 13.5003 12 8.50026 17C4.50026 27 -1.89974 50.6 4.50026 65C10.9003 79.4 46.5003 78.5 46.5003 78.5C46.5003 78.5 79.7003 78 82.5003 60C85.3003 42 81.3336 20.8333 79.0003 12.5C75.8336 9 64.9003 2 46.5003 2Z"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        fillOpacity="0.6"
        strokeWidth={3}
      />
    </svg>
  )
}

export default BabyBelly

import { Box, Text } from "@chakra-ui/react"
import { TrainingPlaylistType } from "api/patient"
import { format } from "date-fns"
import ReactApexChart from "react-apexcharts"
import getUtcDate from "utils/getUtcDate"

interface TrainingKineGraphProps {
  data: TrainingPlaylistType
  selectedSeries: string | null
}

const TrainingKineGraph = ({
  data,
  selectedSeries,
}: TrainingKineGraphProps) => {
  const generateDataForExercice = (
    exerciceId: number,
    side: "left" | "right" | null
  ) =>
    // Il faut que ce soit un tableau de nombre qui va pour chaque log, récupérer les results dedans, puis si l'exerciceId en string est celui passé en paramètre, retourner le tableau des résultats. Pour les résultats, si on a un durationComplete (boolean), on met 0 ou 1. Pour les repetitions, on met directement le nombre de répétitions.
    // Ca ne marche pas regardes bien mes types pour mieux gérer ta fonction
    data.logs.reduce((acc: number[], log) => {
      const results = log.results.reduce((newAcc: number[], result) => {
        if (result.exerciceId === exerciceId && result.side === side) {
          if (result.results[0].durationCompleted !== undefined) {
            const durationsCompletes: number[] = result.results.map((res) =>
              res.durationCompleted ? -1 : 0
            )
            const durationsCompletesSum: number = durationsCompletes.reduce(
              (durationAcc, val) => durationAcc + val,
              0
            )
            return [...newAcc, durationsCompletesSum]
          }
          if (result.results[0].repetitions !== undefined) {
            const repetitions: number[] = result.results.map(
              (res) => res.repetitions ?? 0
            )
            const repetitionsSum: number = repetitions.reduce(
              (repetitionAcc, val) => repetitionAcc + val,
              0
            )

            return [...newAcc, repetitionsSum]
          }
        }
        return newAcc
      }, [])
      return [...acc, ...results]
    }, [])

  const series = data.exercices
    .map((exercice) => {
      const side = exercice.side

      const name =
        exercice.osteoSelection?.group[0].title ??
        exercice.trainingVideoUploaded?.title
      // Gardes que les 20 premiers caracteres de name. Si plus long ajoute ...
      const nameShort =
        name && name?.length > 20 ? name?.substring(0, 20) + "..." : name

      if (side === "both") {
        return [
          !selectedSeries ||
          selectedSeries === `${exercice.id.toString()}__left`
            ? {
                name: `${nameShort} à gauche`,
                data: generateDataForExercice(exercice.id, "left"),
              }
            : null,
          !selectedSeries ||
          selectedSeries === `${exercice.id.toString()}__right`
            ? {
                name: `${nameShort} à droite`,
                data: generateDataForExercice(exercice.id, "right"),
              }
            : null,
        ]
      } else {
        if (
          !selectedSeries ||
          selectedSeries === `${exercice.id.toString()}__${side}`
        ) {
          return {
            name: nameShort,
            data: generateDataForExercice(exercice.id, side),
          }
        }
        return null
      }
    })
    .flat()
    .filter((serie) => serie !== null) as { name: string; data: number[] }[]

  const maxOnSeries = series.reduce((max, serie) => {
    return Math.max(max, Math.max(...serie.data))
  }, 0)

  const minOnSeries = series.reduce((min, serie) => {
    return Math.min(min, Math.min(...serie.data))
  }, 0)

  const step =
    maxOnSeries > 0 ? Math.floor(Math.abs(maxOnSeries / minOnSeries)) : 1

  const serieFormatted = series.map((serie) => ({
    ...serie,
    data: serie.data.map((data) => (data < 0 ? -data * step : data)),
  }))

  const tooltipFormatter = data.exercices.reduce((acc: any[], exercice) => {
    const exerciceType = exercice.type?.key
    let exerciceTypeName = ""
    if (exerciceType === "untilFailure") exerciceTypeName = "seconde(s)"
    if (exerciceType === "repetition") exerciceTypeName = "répétition(s)"
    if (exerciceType === "time") exerciceTypeName = "séries terminée(s)"

    if (exercice.side === "both") {
      return [
        ...acc,
        {
          formatter: (value: number) =>
            `: ${
              exerciceType === "time" ? value / step : value
            } ${exerciceTypeName}`,
        },
        {
          formatter: (value: number) =>
            `: ${
              exerciceType === "time" ? value / step : value
            } ${exerciceTypeName}`,
        },
      ]
    }
    return [
      ...acc,
      {
        formatter: (value: number) =>
          `: ${
            exerciceType === "time" ? value / step : value
          } ${exerciceTypeName}`,
      },
    ]
  }, [])
  const options: any = {
    chart: {
      height: 350,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "date",
      categories: data.logs.map((log) =>
        format(getUtcDate(log.sessionDate), "dd/MM/yyyy")
      ),
    },
    colors: ["#A8D8C3", "#F6C36C", "#974837", "#EF8276"],
    legend: {
      show: false,
    },
    tooltip: {
      y: tooltipFormatter,
    },
  }

  return (
    <Box flex={1} maxW="full">
      {data.logs.length > 0 ? (
        <Box
          height={500}
          maxW="full"
          bg="white"
          pb={10}
          borderRadius={9}
          pos="relative"
        >
          <ReactApexChart
            options={options}
            series={serieFormatted}
            type="line"
            height={450}
          />
        </Box>
      ) : (
        <Box mt={6} p={6} bg="white" borderRadius={9}>
          <Text>Pas de données</Text>
        </Box>
      )}
    </Box>
  )
}

export default TrainingKineGraph

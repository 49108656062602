import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Circle,
  Flex,
  FormLabel,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react"
import { useSelect } from "downshift"

// CONSTANTS
const COLORS = [
  "#7a9f96",
  "#b7dfcb",
  "#cec5c4",
  "#7faf1b",
  "#b7d5df",
  "#eeb4b4",
  "#ddb7df",
  "#dfb7b7",
  "#e1b788",
]

// HELPERS
const itemToString = (item: string | null) => item || ""

// COMPONENTS
interface ASelectColorProps {
  value: string
  setValue: (value: string) => void
  label: string
}

const ASelectColor = ({ value, setValue, label }: ASelectColorProps) => {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useSelect({
    items: COLORS,
    itemToString,
    selectedItem: value,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) =>
      // Workaround to bug: https://github.com/downshift-js/downshift/issues/1511
      // @see https://github.com/downshift-js/downshift/issues/1511#issuecomment-1611303885
      setTimeout(() => {
        setValue(newSelectedItem || "")
      }, 0),
  })

  return (
    <Box>
      <FormLabel {...getLabelProps()}>{label}</FormLabel>
      <Flex
        {...getToggleButtonProps()}
        bg="common.100"
        borderRadius="md"
        border="1px solid"
        borderColor="inherit"
        align="center"
        p={4}
      >
        <Flex grow={1} align="center" gap={4}>
          {value && (
            <>
              <Circle
                size={6}
                bg={value}
                border="1px solid"
                borderColor="gray.600"
              />
              <Text>{value}</Text>
            </>
          )}
        </Flex>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Flex>
      <List
        as="div"
        position="absolute"
        zIndex="dropdown"
        top={2}
        right={0}
        left={0}
        maxH={96}
        overflow="auto"
        bg="common.100"
        display={!isOpen ? "none" : undefined}
        borderRadius="md"
        boxShadow="base"
        {...getMenuProps()}
      >
        {isOpen &&
          COLORS.map((item, index) => (
            <ListItem
              key={item}
              aria-label={item}
              as={Button}
              type="button"
              isActive={value === item}
              width="100%"
              justifyContent="flex-start"
              py={3}
              bg={highlightedIndex === index ? "primary" : "common.100"}
              gap={4}
              h="auto"
              onPointerUp={() => {
                selectItem(item)
              }}
              {...getItemProps({ item, index })}
            >
              <Circle
                size={10}
                bg={item}
                border="1px solid"
                borderColor="gray.600"
              />
              {item}
            </ListItem>
          ))}
      </List>
    </Box>
  )
}

export default ASelectColor

import axios from "axios"

const axiosInstance = axios.create({
  // baseURL: `https://api.staging.andrewapp.fr/api`,
  baseURL: `${process.env.REACT_APP_STRAPI_URL}/api`,
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error)

    if (error.response.status === 401) {
      localStorage.removeItem("jwt")
    }
    return Promise.reject(error)
  }
)

export default axiosInstance

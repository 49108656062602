import { StripePrice } from "api/subscriptions"
import formatPrice from "utils/price/formatPrice"

const getPricePerPeriodPerSeat = (price: StripePrice) => {
  const priceFloat = price.unit_amount / 100

  return {
    monthly:
      price.recurring.interval === "year"
        ? formatPrice(priceFloat / 12)
        : formatPrice(priceFloat),
    yearly:
      price.recurring.interval === "year"
        ? formatPrice(priceFloat)
        : formatPrice(priceFloat * 12),
  }
}
export default getPricePerPeriodPerSeat

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
} from "@chakra-ui/react"
import useToast from "../../../hooks/useToast"
import { useMutation } from "@tanstack/react-query"
import React from "react"
import { createInvitation } from "../../../api/invitation"
import { useAppContext } from "../../../AppContext"
import AButton from "../../../components/AButton"
import getCurrentScopeParams from "utils/getCurrentScopeParams"

interface IProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
  email: string
}
const InvitePatient: React.FC<IProps> = ({
  isOpen,
  onClose,
  cancelRef,
  email,
}) => {
  const createInvitationMutation = useMutation(createInvitation)
  const toast = useToast()
  const { user, currentScope } = useAppContext()

  const createInvitationExtraParams = getCurrentScopeParams(currentScope)

  const handleInvitePatient = () => {
    if (!user?.osteo.id) return
    createInvitationMutation.mutate(
      {
        patientEmail: email,
        osteoId: user?.osteo.id,
        ...createInvitationExtraParams,
      },
      {
        onError: (data: any) => {
          toast({
            status: "error",
            title: data.response.data.error.message,
          })
        },
        onSuccess: (data: any) => {
          toast({
            status: "success",
            title: "Invitation envoyée avec succès",
          })
          onClose()
        },
      }
    )
  }
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent maxW={724} borderRadius={8} py={14}>
        <AlertDialogBody textAlign="center">
          Un patient avec ce mail existe déjà. Veuillez envoyer une demande
          d'autorisation d'accès pour accéder à sa fiche.
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="center">
          <AButton
            ref={cancelRef}
            onClick={onClose}
            text="Annuler"
            variant="tertiary"
            p="12px 145px"
          />
          <AButton
            ml={3}
            text="Envoyer l'invitation"
            p="12px 145px"
            onClick={handleInvitePatient}
          />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default InvitePatient

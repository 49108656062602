import React from "react"
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  ViewIcon,
} from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { SelectedDiscoverType } from "api/appointmentReason"
import { EducationalContentType } from "api/educationalContent"

type CombinedContentType = SelectedDiscoverType | EducationalContentType

interface IProps<T extends CombinedContentType> {
  selectedDiscover: T
  setSelectedDiscovers: React.Dispatch<React.SetStateAction<T[]>>
  position: number
  size: number
  isDisabled: boolean
  isSelectedDiscoverType: boolean
}

const SelectedDiscoverCard = <T extends CombinedContentType>({
  selectedDiscover,
  setSelectedDiscovers,
  position,
  size,
  isDisabled,
  isSelectedDiscoverType,
}: IProps<T>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleRemoveDiscover = () => {
    if (!isDisabled) {
      setSelectedDiscovers((prev) =>
        prev.filter((discover) => discover.id !== selectedDiscover.id)
      )
    }
  }

  const changePosition = (direction: "up" | "down") => {
    if (!isDisabled) {
      const pos = position - 1
      setSelectedDiscovers((prev) => {
        const newItems = [...prev]
        const swapIndex = direction === "up" ? pos - 1 : pos + 1
        ;[newItems[pos], newItems[swapIndex]] = [
          newItems[swapIndex],
          newItems[pos],
        ]
        return newItems
      })
    }
  }

  const title = isSelectedDiscoverType
    ? (selectedDiscover as SelectedDiscoverType).title
    : (selectedDiscover as EducationalContentType).name

  const type = isSelectedDiscoverType
    ? (selectedDiscover as SelectedDiscoverType).type
    : "video"

  const mediaUrl = isSelectedDiscoverType
    ? (selectedDiscover as SelectedDiscoverType).media?.url
    : (selectedDiscover as EducationalContentType).video_BunnyID

  if (!selectedDiscover) return null

  return (
    <Flex alignItems="center" as="li">
      <Flex
        flexDir="column"
        alignItems="center"
        bg="white"
        borderRadius={9}
        py={2.5}
        px={2}
        mr={2}
      >
        {position !== 1 && (
          <ChevronUpIcon
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={() => changePosition("up")}
          />
        )}
        <Text>{position}</Text>
        {position !== size && (
          <ChevronDownIcon
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={() => changePosition("down")}
          />
        )}
      </Flex>

      <Flex
        bg="white"
        py={2}
        px={3}
        borderRadius={9}
        pos="relative"
        w="full"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text color="tertiary.500" fontSize={14} fontWeight={700}>
          {title}
        </Text>
        <Flex alignItems="center">
          {type === "video" && (
            <Flex
              cursor={isDisabled ? "not-allowed" : "pointer"}
              borderRadius="50%"
              border="1px solid"
              borderColor="primary.500"
              w={34}
              h={34}
              justifyContent="center"
              alignItems="center"
              mr={1}
              onClick={isDisabled ? undefined : onOpen}
              color="primary.500"
              _hover={{
                bg: "primary.500",
                color: "white",
              }}
            >
              <ViewIcon />
            </Flex>
          )}
          <IconButton
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={handleRemoveDiscover}
            _hover={{ color: "danger.500" }}
            aria-label="Supprimer la vidéo"
            background="none"
            icon={<DeleteIcon />}
          />
        </Flex>
      </Flex>

      {type === "video" && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay bg="blackAlpha.400" />
          <ModalContent maxW="724">
            <Box
              pos="relative"
              borderRadius={9}
              bg="white"
              w={724}
              h={469}
              py="32px"
              px="54px"
            >
              <ModalCloseButton aria-label="Fermer" />
              <Heading
                fontSize={22}
                fontWeight="bold"
                textAlign="center"
                mb={7}
              >
                {title}
              </Heading>
              {!isSelectedDiscoverType ? (
                <Flex
                  justify={"center"}
                  align={"center"}
                  w={"full"}
                  h={"full"}
                  overflow={"hidden"}
                  pb="46px"
                >
                  <iframe
                    src={`${mediaUrl}?autoplay=true&loop=false&muted=true&preload=true&responsive=true`}
                    loading="lazy"
                    style={{
                      border: "none",
                      height: "100%",
                      width: "100%",
                    }}
                    allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                    allowFullScreen
                    title={title}
                  />
                </Flex>
              ) : (
                <video
                  src={mediaUrl}
                  controls
                  onContextMenu={(e) => e.preventDefault()}
                  controlsList="nodownload"
                ></video>
              )}
            </Box>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  )
}

export default SelectedDiscoverCard

import {
  Box,
  Button,
  Divider,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Icon,
  Center,
} from "@chakra-ui/react"
import EditIcon from "../../assets/edit-black.svg"
import { useNavigate } from "react-router-dom"
import { ChevronDownIcon } from "@chakra-ui/icons"
import ACurrentProfile from "./components/ACurrentProfile"
import { useAppContext } from "../../AppContext"
import { MdSchool, MdHome } from "react-icons/md"
import ALogoutMenuItem from "./components/ALogoutMenuItem"
import AOtherProfilesMenuItem from "./components/AOtherProfilesMenuItem"
import ATransferPatientMenuItem from "./components/ATransferPatientMenuItem"
import userAdminsCenterGroupInCurrentScope from "utils/currentScope/userAdminsCenterGroupInCurrentScope"
import ACooptionMenuItem from "components/AProfileButton/components/ACooptionMenuItem"

const AProfileButton = () => {
  const navigate = useNavigate()
  const { user, currentScope } = useAppContext()

  return (
    <>
      <Box maxWidth="full">
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                background="primary.200"
                _hover={{ background: "primary.300" }}
                _active={{ background: "primary.400" }}
                as={Button}
                rightIcon={
                  <ChevronDownIcon
                    transform={isOpen ? "rotate(-180deg)" : "rotate(0deg)"}
                    transition="transform 0.2s"
                  />
                }
                height="auto"
                py={2}
                px={2}
                aria-label="Ouvrir le menu"
                maxW="full"
              >
                <ACurrentProfile />
              </MenuButton>
              <MenuList background="primary.300">
                <ATransferPatientMenuItem />
                {!currentScope && <ACooptionMenuItem />}
                <MenuItem
                  minH="48px"
                  background="primary.300"
                  _hover={{ background: "primary.400" }}
                  _focus={{ background: "primary.400" }}
                  onClick={() => navigate("/profile")}
                >
                  <Center w={6}>
                    <Image src={EditIcon} ml={1} />
                  </Center>
                  <Text ml={2} fontSize={16}>
                    Modifier mon profil
                  </Text>
                </MenuItem>
                {userAdminsCenterGroupInCurrentScope(user, currentScope) && (
                  <MenuItem
                    minH="48px"
                    background="primary.300"
                    _hover={{ background: "primary.400" }}
                    _focus={{ background: "primary.400" }}
                    onClick={() => navigate(`/center`)}
                  >
                    <Center w={6}>
                      <Icon fontSize={16} as={MdHome} ml={1} />
                    </Center>
                    <Text ml={2} fontSize={16}>
                      Centre & collaborateurs
                    </Text>
                  </MenuItem>
                )}
                {currentScope?.type === "academy" &&
                  user?.osteo.adminAcademies?.find(
                    (academy) => academy.id === currentScope.id
                  ) && (
                    <MenuItem
                      minH="48px"
                      background="primary.300"
                      _hover={{ background: "primary.400" }}
                      _focus={{ background: "primary.400" }}
                      onClick={() => navigate(`/academy`)}
                    >
                      <Center w={6}>
                        <Icon fontSize={16} as={MdSchool} ml={1} />
                      </Center>
                      <Text ml={2} fontSize={16}>
                        Mon académie
                      </Text>
                    </MenuItem>
                  )}
                <Box px={4}>
                  <Divider borderColor="common.700" />
                </Box>
                <AOtherProfilesMenuItem />

                <ALogoutMenuItem />
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
    </>
  )
}

export default AProfileButton

import { AddIcon } from "@chakra-ui/icons"
import {
  Flex,
  Heading,
  Center as ChakraCenter,
  useDisclosure,
  Spinner,
  Box,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { getCenterGroup, getCenterGroupOsteos } from "api/centerGroup"
import { AdminCenterCurrentScope, useAppContext } from "AppContext"
import AButton from "components/AButton"
import AWelcomeModal from "components/AWelcomeModal"
import collaboratorsQueryKeys from "constants/queryKeys/collaborators"
import useStoreSuccessPaymentSessionCenter from "hooks/useStoreSuccessPaymentSession/center"
import Centers from "pages/Center/components/Centers"
import Collaborators from "pages/Center/components/Collaborators"
import EditCenter from "pages/Center/components/EditCenter"
import InviteCollaborator from "pages/Center/components/InviteCollaborator"

import { centerGroupQueryKeys } from "pages/Center/constants/queryKeys"
import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import userAdminsCenterGroupInCurrentScope from "utils/currentScope/userAdminsCenterGroupInCurrentScope"
import UpgradeSubscriptionAlertDialog from "pages/Center/components/UpgradeSubscriptionAlertDialog"
import Billing from "components/Billing/Billing"
import Subscription from "components/Subscription/Subscription"

const Center = () => {
  const { user } = useAppContext()
  const { currentScope } = useAppContext()
  const [centerEditing, setCenterEditing] = useState<CenterType>()
  const [isEditCenterOpen, setIsEditCenterOpen] = useState<boolean>(false)
  const [isInviteCollaboratorOpen, setIsInviteCollaboratorOpen] =
    useState<boolean>(false)
  const isCenterAdmin = userAdminsCenterGroupInCurrentScope(user, currentScope)
  const [showDisabled, setShowDisabled] = useState<boolean>(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")

  const { sessionId, isLoading, isSuccess, clearSessionId } =
    useStoreSuccessPaymentSessionCenter()

  const queriesEnabled = Boolean(isCenterAdmin) && !sessionId

  const { data: centerGroup, isLoading: isLoadingCenterGroup } = useQuery(
    centerGroupQueryKeys.current(
      (currentScope as AdminCenterCurrentScope)?.centerGroupId
    ),
    () =>
      getCenterGroup((currentScope as AdminCenterCurrentScope).centerGroupId),
    { enabled: queriesEnabled }
  )

  const { data: osteos, isLoading: isLoadingOsteos } = useQuery(
    collaboratorsQueryKeys.list(
      (currentScope as AdminCenterCurrentScope)?.centerGroupId,
      showDisabled,
      search,
      page
    ),
    () =>
      getCenterGroupOsteos({
        centerGroupId: (currentScope as AdminCenterCurrentScope).centerGroupId,
        showDisabled,
        search,
        page,
      }),
    { enabled: queriesEnabled }
  )

  const {
    isOpen: isWelcomeOpen,
    onClose: onWelcomeClose,
    onOpen: onWelcomeOpen,
  } = useDisclosure()
  useEffect(() => {
    if (isSuccess && isCenterAdmin) {
      onWelcomeOpen()
    }
  }, [onWelcomeOpen, isSuccess, isCenterAdmin])

  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isUpgradeOpen,
    onClose: onUpgradeClose,
    onOpen: onUpgradeOpen,
  } = useDisclosure()
  const onUpgradeConfirm = () => {
    onUpgradeClose()
    onOpen()
  }

  const onWelcomeCloseRemoveSessionId = () => {
    clearSessionId()
    onWelcomeClose()
  }

  const onEditCenter = (place: CenterType) => {
    setCenterEditing(place)
    setIsEditCenterOpen(true)
  }
  const onAddCenter = () => {
    setIsEditCenterOpen(true)
  }
  const onCloseEditCenter = () => {
    setIsEditCenterOpen(false)
    setCenterEditing(undefined)
  }
  const onInviteCollaborator = () => {
    setIsInviteCollaboratorOpen(true)
  }
  const onCloseInviteCollaborator = () => {
    setIsInviteCollaboratorOpen(false)
  }

  if (!sessionId && !isCenterAdmin) {
    return <Navigate to="/" replace />
  }

  const list = osteos?.result || []
  const pageCount = osteos?.count.totalPage || 0
  const activeCollaborators = osteos?.activeItems ?? "?"
  const maxSeats = centerGroup?.subscription?.quantity ?? "?"
  const maxSeatsReached = activeCollaborators === maxSeats

  return (
    <Flex direction="column" h="100%">
      <Heading as="h1" fontSize={28} color="tertiary.500" mb={10}>
        Centre & collaborateurs
      </Heading>
      {sessionId ? (
        <>
          {isLoading && (
            <ChakraCenter mt={20}>
              <Flex direction="column" align="center" gap={10}>
                <Spinner color="primary.500" />
                <Heading fontSize={32} fontWeight={700}>
                  Un instant, nous créons votre centre...
                </Heading>
              </Flex>
            </ChakraCenter>
          )}
          <AWelcomeModal
            isOpen={isWelcomeOpen}
            onClose={onWelcomeCloseRemoveSessionId}
          />
        </>
      ) : (
        <Flex direction="column" alignItems="flex-start" gap={14}>
          <Flex direction="column" gap={6} alignItems="flex-start">
            <Heading fontSize={22}>Mes centres</Heading>
            <AButton
              text="Ajouter un centre"
              px={35}
              py={5}
              leftIcon={<AddIcon w={3} h={3} />}
              fontFamily="Montserrat"
              onClick={onAddCenter}
            />
            <EditCenter
              center={centerEditing}
              isOpen={isEditCenterOpen}
              onClose={onCloseEditCenter}
            />
            <Centers onEditCenter={onEditCenter} list={centerGroup?.centers} />
          </Flex>
          <Box>
            <Heading fontSize={22} mb={6}>
              Mes collaborateurs ({activeCollaborators}/{maxSeats})
            </Heading>
            <Flex direction="column" gap={6} alignItems="flex-start">
              <AButton
                text="Ajouter un collaborateur"
                px={35}
                py={5}
                leftIcon={<AddIcon w={3} h={3} />}
                fontFamily="Montserrat"
                isLoading={isLoadingOsteos || isLoadingCenterGroup}
                onClick={maxSeatsReached ? onUpgradeOpen : onInviteCollaborator}
              />
              {!isLoadingCenterGroup && (
                <InviteCollaborator
                  isOpen={isInviteCollaboratorOpen}
                  onClose={onCloseInviteCollaborator}
                  centers={centerGroup!.centers}
                />
              )}
              <Collaborators
                showDisabled={showDisabled}
                setShowDisabled={setShowDisabled}
                list={list}
                isLoading={isLoadingOsteos}
                page={page}
                setPage={setPage}
                pageCount={pageCount}
                search={search}
                setSearch={setSearch}
                centers={centerGroup?.centers}
                onUpgradeOpen={maxSeatsReached ? onUpgradeOpen : undefined}
              />
            </Flex>
          </Box>
          <Flex gap={12} alignItems="flex-start">
            <Subscription isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
            <Billing />
            <UpgradeSubscriptionAlertDialog
              isOpen={isUpgradeOpen}
              onClose={onUpgradeClose}
              onConfirm={onUpgradeConfirm}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default Center

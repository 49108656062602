import { IconButton, ButtonProps, Image } from "@chakra-ui/react"
import EditIcon from "../assets/edit.svg"
import EditBlackIcon from "../assets/edit-black.svg"
import React from "react"

interface IProps extends ButtonProps {
  ariaLabel?: string
  hasNoBg?: boolean
}

const AEditButton: React.FC<IProps> = ({
  ariaLabel = "Modifier",
  hasNoBg = false,
  ...props
}) => {
  return (
    <IconButton
      w={8}
      h={8}
      bg={hasNoBg ? "transparent" : "primary.500"}
      icon={
        <Image src={hasNoBg ? EditBlackIcon : EditIcon} w={hasNoBg ? 6 : 4} />
      }
      aria-label={ariaLabel}
      borderRadius="50%"
      minWidth={8}
      _hover={hasNoBg ? { bg: "transparent" } : { bg: "primary.600" }}
      {...props}
    />
  )
}

export default AEditButton

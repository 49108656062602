import { useAppContext } from "AppContext"

const useJobIsOsteo = () => {
  const { user } = useAppContext()
  const osteoJobKey = "osteo"

  return user?.osteo?.jobs?.some((job) => job.key === osteoJobKey)
}

export default useJobIsOsteo

import { MeType } from "api/user"
import userHasSubscription from "utils/subscription/userHasSubscription"

const userHasNoPersonalAccount = (user: MeType | null) => {
  if (!user) return true
  return (
    !user.osteo.isOsteow && !userHasSubscription(user) && !user.osteo.isTester
  )
}

export default userHasNoPersonalAccount

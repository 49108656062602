import { Box } from "@chakra-ui/react"
import React from "react"
import { SelectedDiscoverType } from "api/appointmentReason"
import { MediaType } from "api/media"
import ABabyDiscoverCard from "components/ABabyDiscoverCard/ABabyDiscoverCard"

interface IProps {
  media: MediaType
  selectedDiscovers: SelectedDiscoverType[]
  setSelectedDiscovers: React.Dispatch<
    React.SetStateAction<SelectedDiscoverType[]>
  >
}

const BabyDiscoverMedia: React.FC<IProps> = ({
  media,
  selectedDiscovers,
  setSelectedDiscovers,
}) => {
  return (
    <Box w="calc(33% - 6px)" minW={"220px"}>
      <ABabyDiscoverCard
        key={`media_${media.id}`}
        media={media}
        selectedDiscovers={selectedDiscovers}
        setSelectedDiscovers={setSelectedDiscovers}
      />
    </Box>
  )
}

export default BabyDiscoverMedia

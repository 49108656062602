import { useContext, useState } from "react"
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
} from "@chakra-ui/react"
import useToast from "../../hooks/useToast"
import * as Yup from "yup"
import AButton from "../../components/AButton"
import { login } from "../../api/user"
import { useMutation } from "@tanstack/react-query"
import { Field, Form, Formik } from "formik"
import { Link as RouterLink } from "react-router-dom"
import Cover from "../../assets/andrew-cover.png"
import { useAppContext } from "../../AppContext"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import RgpdContext from "../../components/Rgpd/RgpdContext"
import ALogo from "../../components/ALogo"

interface MyFormValues {
  email: string
  password: string
}
const Login = () => {
  const loginMutation = useMutation(login)
  const { setJwt } = useAppContext()
  const toast = useToast()
  const initialValues: MyFormValues = { email: "", password: "" }
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const { marginWithBanner } = useContext(RgpdContext)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Ceci n'est pas un email")
          .required("Ce champ est obligatoire"),
        password: Yup.string()
          .min(8, "Le mot de passe doit faire 8 caractères minimum")
          .required("Ce champ est obligatoire"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { email, password } = values
        loginMutation.mutate(
          { identifier: email, password },
          {
            onSuccess: ({ data }) => {
              setSubmitting(false)
              setJwt(data.jwt)
            },
            onError: () => {
              setSubmitting(false)
              toast({
                status: "error",
                title: "L'identifiant ou mot de passe n'est pas correct",
              })
            },
          }
        )
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <Flex alignItems="stretch">
            <Flex
              flex={1}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pb={marginWithBanner}
            >
              <Flex w="67%" flexDirection="column" alignItems="center">
                <ALogo />
                <Heading
                  as="h1"
                  fontSize={28}
                  color="tertiary.500"
                  whiteSpace="nowrap"
                  mb={12}
                >
                  Bienvenue sur Andrew® Pro
                </Heading>
                <Flex
                  mt={8}
                  mb={20}
                  flexDir="column"
                  alignItems="flex-start"
                  w="100%"
                >
                  <Heading fontSize={22} color="tertiary.500">
                    Nouveau sur Andrew® Pro ?
                  </Heading>
                  <Flex justifyContent="center" w="100%">
                    <Link
                      as={RouterLink}
                      to="/register"
                      _hover={{ textDecoration: "none" }}
                    >
                      <AButton
                        text="Créer un compte thérapeute"
                        px={46}
                        py={3}
                        mt={6}
                        isLoading={isSubmitting}
                        variant="secondary"
                      />
                    </Link>
                  </Flex>
                </Flex>
                <Flex w="100%" mb={5} alignItems="flex-start" flexDir="column">
                  <Heading fontSize={22} mb={2} color="tertiary.500">
                    Vous avez déjà un compte Andrew® Pro ?
                  </Heading>

                  <Text fontStyle="italic" fontSize={14}>
                    Si vous avez un compte patient et que vous voulez accédez à
                    Andrew® Pro, créez un nouveau compte ci-dessus.
                  </Text>
                </Flex>
                <Box w="100%" mb={5}>
                  <Field name="email">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel mb="8px" fontSize={14}>
                          Email pro
                        </FormLabel>
                        <Input
                          {...field}
                          autoComplete="username"
                          placeholder="Email pro"
                          bg="white"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box w="100%">
                  <Field name="password">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.password && form.touched.password
                        }
                      >
                        <FormLabel mb="8px" fontSize={14}>
                          Mot de passe (min 8 caractères)
                        </FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            autoComplete="current-password"
                            placeholder="Mot de passe"
                            bg="white"
                            type={showPassword ? "text" : "password"}
                          />
                          <InputRightElement
                            children={
                              showPassword ? (
                                <Icon
                                  fontSize={20}
                                  as={AiFillEye}
                                  onClick={() => setShowPassword(false)}
                                />
                              ) : (
                                <Icon
                                  fontSize={20}
                                  as={AiFillEyeInvisible}
                                  onClick={() => setShowPassword(true)}
                                />
                              )
                            }
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Flex justifyContent="flex-end" w="100%" mt={4}>
                  <Link
                    as={RouterLink}
                    to={`/forgot-password`}
                    color="tertiary.500"
                    textDecor="underline"
                    fontWeight={700}
                    fontSize={14}
                  >
                    Mot de passe oublié ?
                  </Link>
                </Flex>
              </Flex>

              <AButton
                type="submit"
                text="Se connecter"
                px={109}
                py={3}
                mt={10}
                isLoading={isSubmitting}
              />
            </Flex>
            <Box flex={1} display={{ base: "none", lg: "block" }}>
              <Image
                src={Cover}
                alt="Andrew"
                w="100%"
                h="100vh"
                maxH="100vh"
                objectFit="cover"
              />
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default Login

import { AxiosError } from "axios"
import useToast from "hooks/useToast"
import { useNavigate } from "react-router-dom"
import { isPatientNoAccessRight } from "utils/patientNoAccessRight"

const useOnPatientNoAccessRight = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const onPatientNoAccessRight = (error: AxiosError) => {
    if (isPatientNoAccessRight(error)) {
      toast({
        status: "error",
        title: "Vous n'avez pas les droits pour accéder à ce patient",
      })
      navigate("/dashboard")
    }
  }

  return onPatientNoAccessRight
}
export default useOnPatientNoAccessRight

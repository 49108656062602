import axiosInstance from "api/axiosInstance"

export type CreateScheduledGroupMessageType = {
  title: string
  sendDateTime: string
  text: string
  center?: number
}

export type ScheduledGroupMessageType = {
  id: number
  title: string
  sendDateTime: string
  text: string
  center?: number
  osteo?: number
  isSent?: boolean
}

export const createScheduledGroupMessage = async ({
  ...data
}: CreateScheduledGroupMessageType) => {
  const response = await axiosInstance.post<ScheduledGroupMessageType>(
    `/scheduled-group-message`,
    {
      ...data,
    }
  )
  return response.data
}

export const updateScheduledGroupMessage = async ({
  ...data
}: ScheduledGroupMessageType) => {
  const response = await axiosInstance.put<ScheduledGroupMessageType>(
    `/scheduled-group-message`,
    {
      ...data,
    }
  )
  return response.data
}

export const getScheduledGroupMessages = async (centerId?: number) => {
  const response = await axiosInstance.get<ScheduledGroupMessageType[]>(
    centerId
      ? `/scheduled-group-message/all/${centerId}`
      : `/scheduled-group-message/all`
  )
  return response.data
}

export const getScheduledGroupMessage = async (id: number) => {
  const response = await axiosInstance.get<ScheduledGroupMessageType>(
    `/scheduled-group-message/${id}`
  )
  return response.data
}

export const deleteScheduledGroupMessage = async (id: number) => {
  const response = await axiosInstance.delete<ScheduledGroupMessageType>(
    `/scheduled-group-message/${id}`
  )
  return response.data
}

import { useQuery, useQueryClient } from "@tanstack/react-query"
import useMountEffect from "hooks/useMountEffect"
import useToast from "hooks/useToast"
import packageJson from "../../package.json"

export type Meta = {
  buildDate: string
}

// HELPERS
const packageMetaBuildDatesMatch = (meta?: Meta) => {
  const metaBuildDate = Number(meta?.buildDate || 0) // avoids NaN result from Number()
  const packageBuildDate = Number(packageJson?.buildDate || 0)

  // Both dates are written at the same time, so they either match or not.
  return metaBuildDate === packageBuildDate
}

// we do not need axios, as it is a relative fetch
const getMeta = async () => {
  const req = await fetch("/meta.json")
  return req.json()
}

// HOOKS
const useNeedsHardReload = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const queryMeta = useQuery<Meta>(["getMeta"], getMeta, {
    // no cache
    cacheTime: 0,
    onSuccess: (result) => {
      const isUpToDate = packageMetaBuildDatesMatch(result)
      if (isUpToDate) {
        return
      }
      localStorage.setItem("refreshing", "true")
      // clears all react-query caches, @see https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientclear
      queryClient.clear()
      // reload page
      window.location.reload()
    },
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
  })

  useMountEffect(() => {
    if (Boolean(localStorage.getItem("refreshing"))) {
      localStorage.removeItem("refreshing")
      toast({
        status: "success",
        title: "L'application a été mise à jour",
        // longer toast to make sure user can see it
        duration: 9000,
      })
    }
  })

  return queryMeta
}

export default useNeedsHardReload

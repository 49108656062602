import React from "react"
import { SVGProps } from "../type"

const Cruralgie: React.FC<SVGProps> = ({ fill, stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="119"
    fill="none"
    viewBox="0 0 30 119"
  >
    <path
      fill={fill || "#F1FDF3"}
      stroke={stroke || "#A8D8C3"}
      fillOpacity="0.6"
      strokeWidth="3"
      d="M14.5 5.5c-2-6.4-9.167-1.334-12.5 2 10.4 19.2 12.667 40.666 12.5 49-1.167 11.833-3.6 38.1-4 48.5-.4 10.4 2.5 12.333 4 12 2.333-1.833 7.5-7.4 9.5-15 2.5-9.5 3-21 3.5-47.5s-10.5-41-13-49z"
    ></path>
  </svg>
)

export default Cruralgie

import { Box, Button, Flex, Heading, Link, Text } from "@chakra-ui/react"
import React, { useState, useContext, useEffect } from "react"
import RgpdContext from "./RgpdContext"
import RgpdCustomService from "./RgpdCustomService"
import { Link as RouterLink } from "react-router-dom"
import AButton from "../AButton"

export default function RgpdCustom() {
  const {
    services,
    allowServiceById,
    denyServiceById,
    customStatus,
    hideCustomServices,
  } = useContext(RgpdContext)
  const [ready, setReady] = useState(false)

  const handleSaveCustom = () => {
    services.map((service) => {
      if (service.status === "denied") {
        denyServiceById(service.id)
      } else if (service.status === "allowed") {
        allowServiceById(service.id)
      } else {
        denyServiceById(service.id)
      }
    })
    hideCustomServices()
  }

  useEffect(() => {
    setReady(true)
  }, [])

  if (!ready) return null

  return (
    <Flex
      className={`RgpdCustom ${customStatus === "visible" ? "-visible" : ""}`}
      opacity={customStatus === "visible" ? 1 : 0}
      visibility={customStatus === "visible" ? "visible" : "hidden"}
      zIndex={99999}
      position={"fixed"}
      top={0}
      left={0}
      w={"100%"}
      h={"100%"}
      p={"20px"}
      transition="all 300ms ease"
    >
      <Box
        role="button"
        tabIndex="0"
        onClick={hideCustomServices}
        position={"absolute"}
        top={0}
        left={0}
        w={"100%"}
        h={"100%"}
        bg={"rgba(0,0,0,0.5)"}
      />
      <Box
        className="RgpdCustom__content"
        position={"relative"}
        margin={"auto"}
        p={"20px"}
        transform={
          customStatus === "visible" ? "translateY(20%)" : "translateY(0%)"
        }
        transition="transform 300ms ease"
        borderRadius={"5px"}
        boxShadow={"0 0 44px 0 rgba(34, 34, 34, 0.37)"}
        px={12}
        py={8}
        bg={"primary.100"}
      >
        <Heading as={"h3"} size={"sm"} mb={2}>
          À propos des cookies
        </Heading>

        <Text
          className="RgpdCustom__text"
          fontSize={"12px"}
          color={"black"}
          maxW={"600px"}
          mb={"4px"}
        >
          Les cookies sont des petits fichiers textes susceptibles d’être
          enregistrés dans un espace dédié du disque dur de votre terminal
          (ordinateur, smartophone, tablette etc..), qui peuvent être utilisés
          par les sites internets pour une meilleure expérience utili...
        </Text>
        <Link
          as={RouterLink}
          href={"#"}
          isExternal
          fontSize={"sm"}
          fontWeight={"bold"}
          textDecoration={"underline"}
          _hover={{ textDecoration: "underline", opacity: 0.7 }}
        >
          Voir plus
        </Link>

        <Box mt={4}>
          {services.map((service, i) => (
            <RgpdCustomService key={i} service={service} />
          ))}
        </Box>
        <Flex justifyContent={"end"}>
          <AButton
            variant="primary"
            onClick={handleSaveCustom}
            px={"16px"}
            text="Enregistrer et appliquer"
          />
        </Flex>
      </Box>
    </Flex>
  )
}

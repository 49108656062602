import { Flex, FlexProps, Image, Text } from "@chakra-ui/react"
import AndrewLogo from "../assets/ANDREW_logo_color.png"

interface ALogoProps extends FlexProps {
  hidePro?: boolean
}
const ALogo = ({ hidePro, ...props }: ALogoProps) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-end"
      mb={63}
      mt={12}
      {...props}
    >
      <Image src={AndrewLogo} alt="Andrew Logo" w={214} />
      {!hidePro && (
        <Text color="tertiary.500" fontSize={32} fontWeight={700} mt={-4}>
          Pro
        </Text>
      )}
    </Flex>
  )
}

export default ALogo

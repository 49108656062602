import { Center, Flex, Heading, Spinner } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import React from "react"
import { useSearchParams } from "react-router-dom"
import { acceptInvitation } from "../../api/invitation"

const Invitation = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")

  const {
    data,
    isLoading,
    error,
  }: { data: any; isLoading: boolean; error: any } = useQuery(
    ["acceptInvitation", token],
    () => acceptInvitation(token!),
    {
      enabled: !!token,
      retry: false,
    }
  )

  return (
    <Center h="100vh">
      {isLoading ? (
        <Spinner color="primary.500" />
      ) : (
        <Flex flexDir="column" gap={4} alignItems="center">
          <Heading
            as="h1"
            color="tertiary.500"
            fontSize={32}
            fontWeight="bold"
            textAlign="center"
            mb={7}
          >
            Andrew
          </Heading>
          {error && (
            <Heading fontSize={22}>{error.response.data.error.message}</Heading>
          )}
          {data && <Heading fontSize={22}>{data}</Heading>}
        </Flex>
      )}
    </Center>
  )
}

export default Invitation

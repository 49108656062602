import React, { useState, useEffect, useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Flex } from "@chakra-ui/react"
import { useMutation, useQuery } from "@tanstack/react-query"
import TreatmentHeader from "components/Treatment/TreatmentHeader"
import { getPatient, PatientAppointmentType, PatientType } from "api/patient"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import { useAppContext } from "AppContext"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import { getTreatmentByPatientId, TreatmentType } from "api/treatment"
import TreatmentsContainer from "components/Treatment/TreatmentsContainer"

const Treatments: React.FC = () => {
  const navigate = useNavigate()
  const { patientId, treatmentId, appointmentId } = useParams<{
    patientId: string
    treatmentId: string
    appointmentId?: string
  }>()

  const treatmentIdNumber = treatmentId ? parseInt(treatmentId, 10) : undefined
  const appointmentIdNumber = appointmentId
    ? parseInt(appointmentId, 10)
    : undefined

  const [patient, setPatient] = useState<PatientType | undefined>(undefined)
  const [currentAppointment, setCurrentAppointment] =
    useState<PatientAppointmentType | null>(null)
  const [currentTreatment, setCurrentTreatment] = useState<
    TreatmentType | undefined
  >(undefined)
  const [treatments, setTreatments] = useState<TreatmentType[]>([])

  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()
  const { currentScope } = useAppContext()

  const getPatientParams = {
    patientId,
    ...getCurrentScopeParams(currentScope),
  }

  const { isLoading: isLoadingPatient } = useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(getPatientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error: any) =>
        retryPatientNoAccessRight(failureCount, error) &&
        retryCollaboratorUnauthorized(failureCount, error),
      onSuccess: (data) => {
        setPatient(data)
      },
      onError: (error: any) => {
        onPatientNoAccessRight(error)
        onCollaboratorUnauthorized(error)
      },
    }
  )

  const treatmentsMutation = useMutation(getTreatmentByPatientId, {
    onSuccess: (data: TreatmentType[]) => {
      const appointmentsWithoutTreatment =
        patient?.appointments?.filter(
          (appointment) => !appointment.treatment
        ) || []

      if (appointmentsWithoutTreatment.length > 0 && patient) {
        const fakeTreatment: TreatmentType = {
          id: -1,
          title: "Consultations Ostéo",
          appointments: appointmentsWithoutTreatment,
          patient: patient,
          osteo: appointmentsWithoutTreatment[0].osteo,
        }
        data.push(fakeTreatment)
      }

      setTreatments(data)
      if (treatmentId) {
        const treatment = data.find((t) => t.id === parseInt(treatmentId))

        if (!treatment) return

        setCurrentTreatment(treatment)
        if (!appointmentId) {
          navigate(
            `/patients/${patientId}/treatment/${treatmentId}/appointment/${treatment.appointments[0].id}`
          )
          return
        }
        const selectedAppoitnent = treatment.appointments.find(
          (a) => a.id === parseInt(appointmentId)
        )
        setCurrentAppointment(selectedAppoitnent ?? null)
      }
      setCurrentTreatment(data[0])
    },
  })

  useEffect(() => {
    if (patient?.id) {
      treatmentsMutation.mutate(patient.id)
    }
  }, [patient])

  const updateCurrentTreatment = useCallback(() => {
    if (treatmentIdNumber && treatments.length > 0) {
      const treatment = treatments.find((t) => t.id === treatmentIdNumber)
      setCurrentTreatment(treatment)
      setCurrentAppointment(treatment?.appointments[0] ?? null)
    }
  }, [treatmentIdNumber, treatments])

  const updateCurrentAppointment = useCallback(() => {
    if (currentTreatment === undefined) return
    if (appointmentIdNumber && currentTreatment?.appointments.length > 0) {
      const appointment = currentTreatment.appointments.find(
        (a) => a.id === appointmentIdNumber
      )
      setCurrentAppointment(appointment ?? null)
    }
  }, [appointmentIdNumber, currentTreatment])

  useEffect(() => {
    updateCurrentTreatment()
    updateCurrentAppointment()
  }, [updateCurrentTreatment, updateCurrentAppointment])

  const isLoading = isLoadingPatient || treatmentsMutation.isLoading

  if (isLoading || !patient) return null

  return (
    <Flex direction="column" gap={6}>
      <TreatmentHeader patient={patient} setPatient={setPatient} />
      {currentTreatment && currentAppointment && (
        <TreatmentsContainer
          treatments={treatments}
          currentTreatment={currentTreatment}
          currentAppointment={currentAppointment}
          setCurrentAppointment={setCurrentAppointment}
          patient={patient}
        />
      )}
    </Flex>
  )
}

export default Treatments

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { PatientAppointmentType } from "api/patient"
import { format } from "date-fns"
import getUtcDate from "utils/getUtcDate"

interface AllAppointmentModalProps {
  isOpen: boolean
  onClose: () => void
  appointments: PatientAppointmentType[]
  treatmentTitle: string
}

const AllAppointmentModal = ({
  isOpen,
  onClose,
  appointments,
  treatmentTitle,
}: AllAppointmentModalProps) => {
  const getAppointmentLabelIndex = (index: number) => {
    const appointmentLabelIndex = appointments.length - index
    return appointmentLabelIndex === 1
      ? "1 ère consultation"
      : `${appointmentLabelIndex} ème consultation`
  }
  return (
    <Modal onClose={onClose} isCentered isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent maxW={400} p={4} maxH={440} w={"100%"}>
        <ModalCloseButton aria-label="Fermer" top={8} />
        <ModalHeader>{treatmentTitle}</ModalHeader>
        <ModalBody overflow={"hidden"}>
          <Flex
            direction={"column"}
            gap={"8px"}
            maxH={340}
            overflowY={"auto"}
            pb={16}
          >
            {appointments.map((appointment, index) => (
              <Flex key={appointment.id} justify={"space-between"}>
                <Text fontWeight={"bold"}>
                  {format(
                    getUtcDate(appointment.meetingDate || ""),
                    "dd/MM/yyyy"
                  )}
                </Text>

                <Text>{getAppointmentLabelIndex(index)}</Text>
              </Flex>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AllAppointmentModal

import axiosInstance from "./axiosInstance"
import { TraininScheduleType } from "./patient"
import { EducationalContentType } from "api/educationalContent"
import { TrainingVideoExerciceType } from "api/trainingVideoExerciceType"
import { JobType } from "./job"
import { SelectedVideoOrderType } from "api/appointmentReason"

export type AppointmentType = {
  id: number
  dateFormatted: string
  patient: {
    firstname: string
    lastname: string
    id: number
  }
  createdAt: string
  osteo: OsteoType
  treatment?: {
    id: number
  }
  job: JobType
}

export type AppointmentSimplifiedType = Omit<AppointmentType, "job"> & {
  job: string
}

export type GetAppointmentsParams = PaginationParamsType &
  (
    | { centerId: number; osteos?: number[] }
    | {
        academieId: number
        osteos?: number[]
      }
    | {}
  )
export type GetAppointmentsResponse = {
  results: AppointmentType[]
  count: PaginationType
}
export const getAppointments = async (params: GetAppointmentsParams) => {
  const endpoint = "/osteo/me/getAllAppointments"
  let fullParams
  if ("centerId" in params) {
    fullParams = {
      center_id: params.centerId,
      osteos: params.osteos,
      per_page: params.perPage,
      page: params.page,
    }
  } else if ("academieId" in params) {
    fullParams = {
      academie_id: params.academieId,
      osteos: params.osteos,
      per_page: params.perPage,
      page: params.page,
    }
  } else {
    fullParams = {
      per_page: params.perPage,
      page: params.page,
    }
  }
  const response = await axiosInstance.get<GetAppointmentsResponse>(endpoint, {
    params: fullParams,
  })
  return response.data
}

// export type VideoExerciceType = {
//   duration?: number
//   videoGroup: number
//   osteoSelection: number
//   series?: number
//   repetitions?: number
//   restDuration?: number
//   type?: TrainingVideoExerciceType
//   side?: "both" | "left" | "right"
//   weight?: number
// }
export type VideoExerciceType = {
  duration: number | null
  videoGroup: number
  osteoSelection: number
  series: number | null
  repetitions: number | null
  restDuration: number | null
  type: TrainingVideoExerciceType | null
  side: "both" | "left" | "right" | null
  weight: number | null
}

export type UploadVideoExerciceType = {
  duration: number | null
  series: number | null
  repetitions: number | null
  restDuration: number | null
  type: TrainingVideoExerciceType | null
  weight: number | null
  trainingVideoUploaded: number | null
}

export type CreateAppointmentType = {
  appointment: {
    osteo: number
    academieId?: number
    centerId?: number
    patient: number
    osteoAdvice: string | null
    meetingDate: string
    internalNote: string | null
    secondaryInternalNote: string | null
    explorerMedias?: number[] | null
    reminderScheduledDate?: string | null
    reminderMessage?: string | null
    educationalContents?: EducationalContentType[]
    job: JobType
  }
  playlist: {
    author: number
    expiry: string
    mainReason?: number
    reasons?: (number | undefined)[]
  }
  videoExercices: VideoExerciceType[]
  videoUploadedExercices: UploadVideoExerciceType[]
  videoOrder: SelectedVideoOrderType[]
  checkup?: {
    pain?: number
    stress?: number
    frequency?: number
    mobility?: number
    patient: number
    mainReason?: number
    reasons?: (number | undefined)[]
  }
  trainingSchedule: TraininScheduleType[]
  patientId: number
  treatment?: {
    id?: number
    title: string
  }
}

export const newAppointment = async ({
  appointment,
  ...data
}: CreateAppointmentType) => {
  const { academieId, centerId, ...restAppointment } = appointment
  const response = await axiosInstance.post("/osteo/me/appointment/new", {
    ...data,
    appointment: {
      ...restAppointment,
      academie_id: academieId,
      center_id: centerId,
    },
  })
  return response.data
}

export type UpdateAppointmentParams = {
  id: string
  data: {
    osteoAdvice?: string
    videoExercices?: VideoExerciceType[]
    videoUploadedExercices?: UploadVideoExerciceType[]
    videoOrder?: SelectedVideoOrderType[]
    expiry?: number
    explorerMedias?: number[] | null
    educationalContents?: EducationalContentType[]
  }
}

export const updateAppointment = async (params: UpdateAppointmentParams) => {
  const response = await axiosInstance.put(
    `/osteo/me/appointment/update`,
    params
  )
  return response.data
}

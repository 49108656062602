import AAlertDialogConfirm from "components/AAlertDialogConfirm"
import { ComponentProps } from "react"

interface UpgradeSubscriptionAlertDialogProps
  extends Pick<
    ComponentProps<typeof AAlertDialogConfirm>,
    "isOpen" | "onClose" | "isLoading" | "onConfirm"
  > {}
const UpgradeSubscriptionAlertDialog = (
  props: UpgradeSubscriptionAlertDialogProps
) => {
  return (
    <AAlertDialogConfirm
      headerContent="Vous souhaitez ajouter un thérapeute ?"
      bodyContent="Mettez à jour votre abonnement pour ajouter un collaborateur Andrew® Pro dans votre centre."
      destructiveActionText="Mettre à jour mon abonnement"
      destructiveActionVariant="primary"
      {...props}
    />
  )
}

export default UpgradeSubscriptionAlertDialog

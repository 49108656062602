import { Box, Flex } from "@chakra-ui/react"
import { PatientAppointmentType } from "api/patient"
import TrainingExerciceKineCard from "pages/Appointment/components/TrainingExerciceKineCard"
import React from "react"

interface TrainingExerciceKineCardsProps {
  appointment: PatientAppointmentType
  selectedSeries: string | null
  setSelectedSeries: (series: string | null) => void
}

const TrainingExerciceKineCards = ({
  appointment,
  selectedSeries,
  setSelectedSeries,
}: TrainingExerciceKineCardsProps) => {
  return (
    <Flex
      flexWrap={"nowrap"}
      gap={4}
      overflow={"auto"}
      w="full"
      maxW="full"
      sx={{
        "&::-webkit-scrollbar": {
          height: "4px",
        },
        "&::-webkit-scrollbar-track": {
          height: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "primary.300",
          borderRadius: "24px",
        },
      }}
    >
      {appointment.trainingPlaylist?.exercices.map((exercice) => {
        if (exercice.side === "both") {
          return (
            <React.Fragment key={exercice.id}>
              <TrainingExerciceKineCard
                exercice={exercice}
                side={"left"}
                selectedSeries={selectedSeries}
                setSelectedSeries={setSelectedSeries}
              />
              <TrainingExerciceKineCard
                exercice={exercice}
                side={"right"}
                selectedSeries={selectedSeries}
                setSelectedSeries={setSelectedSeries}
              />
            </React.Fragment>
          )
        }
        return (
          <TrainingExerciceKineCard
            exercice={exercice}
            side={exercice.side}
            selectedSeries={selectedSeries}
            setSelectedSeries={setSelectedSeries}
          />
        )
      })}
    </Flex>
  )
}

export default TrainingExerciceKineCards

import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { EducationalContentType } from "api/educationalContent"
import PlayIcon from "assets/play_circle_filled.svg"

interface IProps {
  content: EducationalContentType
  onCheck: (ct: EducationalContentType) => void
  isEvenIndex: boolean
  isChecked: boolean
  hasCheckbox?: boolean
}

const EducationalContentAccordionItem: React.FC<IProps> = ({
  content,
  onCheck,
  isEvenIndex,
  isChecked,
  hasCheckbox = true,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Flex
        overflow="hidden"
        w={"full"}
        align={"center"}
        h={"full"}
        paddingX={"8px"}
        paddingY={"11px"}
        bg={isEvenIndex ? "primary.200" : "none"}
        justify={"space-between"}
        gap={"8px"}
      >
        <Flex gap={"8px"}>
          {hasCheckbox && (
            <Checkbox
              colorScheme="orange"
              borderColor="black"
              onChange={() => onCheck(content)}
              isChecked={isChecked}
            />
          )}

          <Text alignSelf={"self-start"} fontSize={14} fontWeight={700}>
            {content.name}
          </Text>
        </Flex>

        <Image
          src={PlayIcon}
          w={"24px"}
          h={"24px"}
          cursor={"pointer"}
          onClick={onOpen}
        />
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent maxW="724">
          <Box
            pos="relative"
            borderRadius={9}
            bg="white"
            w={724}
            h={469}
            py="32px"
            px="54px"
          >
            <ModalCloseButton aria-label="Fermer" />
            <Heading fontSize={22} fontWeight="bold" textAlign="center" mb={7}>
              {content.name}
            </Heading>
            <Flex
              justify={"center"}
              align={"center"}
              w={"616"}
              h={"359"}
              overflow={"hidden"}
              pb="46px"
            >
              <iframe
                src={`${content.video_BunnyID}?autoplay=true&loop=false&muted=true&preload=true&responsive=true`}
                loading="lazy"
                style={{
                  border: "none",
                  width: "100%",
                  height: "100%",
                }}
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
                title={content.name}
              />
            </Flex>
          </Box>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EducationalContentAccordionItem

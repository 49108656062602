import { useFormikContext } from "formik"
import { ComponentProps } from "react"
import AButton from "./AButton"

interface ASubmitButtonProps
  extends Omit<ComponentProps<typeof AButton>, "type"> {}
const ASubmitButton = (props: ASubmitButtonProps) => {
  const { isSubmitting } = useFormikContext()

  return <AButton {...props} type="submit" isLoading={isSubmitting} />
}

export default ASubmitButton

import formatPrice from "utils/price/formatPrice"

const getSubPricePerPeriodPerSeat = (subscription: StripeSubscription) => {
  const perSeatFloat = subscription.plan.amount / 100

  return {
    monthly:
      subscription.plan.interval === "year"
        ? formatPrice(perSeatFloat / 12)
        : formatPrice(perSeatFloat),
    yearly:
      subscription.plan.interval === "year"
        ? formatPrice(perSeatFloat)
        : formatPrice(perSeatFloat * 12),
  }
}

export default getSubPricePerPeriodPerSeat

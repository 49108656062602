import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Grid,
  GridItem,
  FormLabel,
} from "@chakra-ui/react"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import AInputField from "../../../components/Field/AInputField"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAppContext } from "../../../AppContext"
import { addStudent } from "../../../api/academie"
import useToast from "../../../hooks/useToast"
import ASubmitButton from "components/ASubmitButton"
import studentsQueryKeys from "constants/queryKeys/students"

// CONSTANTS
const INITIAL_VALUES = {
  email: "",
  firstname: "",
  lastname: "",
}

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email("Ceci n'est pas un email").required("Email requis"),
  lastname: Yup.string().required("Nom requis"),
  firstname: Yup.string().required("Prénom requis"),
})

type InviteStudentType = {
  firstname: string
  lastname: string
  email: string
}

// COMPONENTS
interface InviteStudentProps {
  isOpen: boolean
  onClose: () => void
}
const InviteStudent = ({ isOpen, onClose }: InviteStudentProps) => {
  const initialValues = INITIAL_VALUES
  const { currentScope } = useAppContext()
  const toast = useToast()
  const queryClient = useQueryClient()

  const addStudentMutation = useMutation({
    mutationFn: (values: InviteStudentType) =>
      addStudent({ academieId: currentScope?.id!, ...values }),
  })

  const onSubmit = (
    values: InviteStudentType,
    { setSubmitting }: FormikHelpers<InviteStudentType>
  ) => {
    const { email, ...otherValues } = values
    addStudentMutation.mutate(
      { email: email.trim(), ...otherValues },
      {
        onSuccess: () => {
          toast({
            status: "success",
            title: "Invitation envoyée",
          })
          onClose()
          queryClient.invalidateQueries({
            queryKey: studentsQueryKeys.lists(currentScope?.id!),
          })
        },
        onError: (error: any) => {
          const isAlreadyExistsError = error?.response?.status === 400
          toast({
            status: "error",
            title: isAlreadyExistsError
              ? `Ce mail est déjà associé à un compte existant`
              : "Une erreur s'est produite",
          })
        },
        onSettled: () => {
          setSubmitting(false)
        },
      }
    )
  }

  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />

      <ModalContent maxW={724} borderRadius={8} p={8}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={VALIDATION_SCHEMA}
        >
          <Form>
            <ModalCloseButton aria-label="Fermer" />
            <ModalHeader mb={8} p={0} fontSize={18}>
              Ajouter un étudiant
            </ModalHeader>
            <Grid templateColumns="repeat(2, 1fr)" rowGap={10} columnGap={4}>
              <GridItem colSpan={2}>
                <AInputField
                  name="email"
                  label={<FormLabel fontSize={14}>Mail *</FormLabel>}
                  placeholder="Mail"
                  bg="common.100"
                />
              </GridItem>
              <GridItem>
                <AInputField
                  name="lastname"
                  label={<FormLabel fontSize={14}>Nom *</FormLabel>}
                  placeholder="Nom"
                  bg="common.100"
                />
              </GridItem>
              <GridItem>
                <AInputField
                  name="firstname"
                  label={<FormLabel fontSize={14}>Prénom *</FormLabel>}
                  placeholder="Prénom"
                  bg="common.100"
                />
              </GridItem>
            </Grid>
            <ModalFooter justifyContent="center" p={0} mt={14}>
              <ASubmitButton
                text="Inviter l'étudiant"
                py={3}
                px={28}
                isLoading={addStudentMutation.isLoading}
              />
            </ModalFooter>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default InviteStudent

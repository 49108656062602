import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
} from "@chakra-ui/react"
import useToast from "../../../hooks/useToast"
import { useMutation } from "@tanstack/react-query"
import { format } from "date-fns"
import { Field, Form, Formik } from "formik"
import React, { useState } from "react"
import { PatientType, updatePatient } from "../../../api/patient"
import AButton from "../../../components/AButton"
import * as Yup from "yup"
import parseBirthday from "utils/parseBirthday"
import DisclosePatientTempPassword from "pages/Patient/components/DisclosePatientTempPassword"

interface IProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
  patient?: PatientType
  setPatient?: React.Dispatch<React.SetStateAction<PatientType | undefined>>
}

const getNextAccount = (data: PatientType, prev?: PatientType) => {
  if (prev?.account && data.account) {
    return { account: { ...prev.account, email: data.account.email } }
  }
  return {}
}

const EditInfos: React.FC<IProps> = ({
  isOpen,
  onClose,
  cancelRef,
  patient,
  setPatient,
}) => {
  const updatePatientMutation = useMutation(updatePatient)
  const toast = useToast()
  const [tempPassword, setTempPassword] = useState<string>("")
  const {
    isOpen: passwordIsOpen,
    onOpen: passwordOnOpen,
    onClose: passwordOnClose,
  } = useDisclosure()

  const onPatientSuccess = (password?: string) => {
    if (!password) {
      return toast({
        status: "success",
        title: "Modifications enregistrées",
      })
    }
    setTempPassword(password)
    passwordOnOpen()
  }

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent maxW={724} borderRadius={8} p={8}>
          <AlertDialogHeader mb={8} p={0} fontSize={18}>
            Modifier les coordonnées du patient
          </AlertDialogHeader>
          <Formik
            validationSchema={Yup.object().shape({
              lastname: Yup.string(),
              firstname: Yup.string(),
              postalCode: Yup.string(),
              email: Yup.string().email("Ceci n'est pas un email"),
              birthday: Yup.date()
                .transform(function (value, originalValue) {
                  return parseBirthday(originalValue)
                })
                .typeError("Merci de rentrer une date valide"),
            })}
            initialValues={{
              lastname: patient?.lastname || "",
              firstname: patient?.firstname || "",
              postalCode: patient?.postalCode || "",
              birthday: patient?.birthday
                ? format(new Date(patient?.birthday), "dd/MM/yyyy")
                : "",
              email: patient?.account?.email || "",
            }}
            onSubmit={({ birthday, ...values }, { setSubmitting }) => {
              if (!patient || !patient.id) return

              const newBirthday = parseBirthday(birthday)

              updatePatientMutation.mutate(
                {
                  id: patient?.id?.toString(),
                  data: {
                    ...values,
                    birthday: format(newBirthday, "yyyy-MM-dd"),
                  },
                },
                {
                  onSuccess: (data) => {
                    if (!setPatient) return
                    setSubmitting(false)
                    onClose()
                    setPatient((prev) => ({
                      ...prev,
                      lastname: data?.lastname,
                      firstname: data?.firstname,
                      postalCode: data?.postalCode,
                      birthday: data?.birthday,
                      ...getNextAccount(data, prev),
                    }))
                    onPatientSuccess(data.temp_password)
                  },
                  onError: () => {
                    setSubmitting(false)
                    toast({
                      status: "error",
                      title: "Une erreur s'est produite",
                    })
                  },
                }
              )
            }}
          >
            {(props) => (
              <Form>
                <Flex gap={4}>
                  <Field name="lastname">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl>
                        <FormLabel>Nom</FormLabel>
                        <Input
                          {...field}
                          placeholder="Nom de famille"
                          bg="common.100"
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="firstname">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl>
                        <FormLabel>Prénom</FormLabel>
                        <Input
                          {...field}
                          placeholder="Prénom"
                          bg="common.100"
                        />
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Field name="birthday">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl>
                      <FormLabel mt={6}>Date de naissance</FormLabel>
                      <Input
                        {...field}
                        bg="common.100"
                        placeholder="01/01/2001"
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl>
                      <FormLabel mt={6}>Mail</FormLabel>
                      <Input {...field} placeholder="Email" bg="common.100" />
                    </FormControl>
                  )}
                </Field>
                <Field name="postalCode">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl>
                      <FormLabel mt={6}>Code postal</FormLabel>
                      <Input
                        {...field}
                        placeholder="Code postal"
                        bg="common.100"
                      />
                    </FormControl>
                  )}
                </Field>
                <AlertDialogFooter justifyContent="flex-end" p={0} mt={14}>
                  <AButton
                    ref={cancelRef}
                    onClick={onClose}
                    text="Annuler"
                    variant="tertiary"
                    py={3}
                    px={27}
                  />
                  <AButton
                    ml={3}
                    text="Enregistrer les modifications"
                    py={3}
                    px={4}
                    type="submit"
                    isLoading={props.isSubmitting}
                  />
                </AlertDialogFooter>
              </Form>
            )}
          </Formik>
        </AlertDialogContent>
      </AlertDialog>
      <DisclosePatientTempPassword
        patient={patient}
        onClose={passwordOnClose}
        isOpen={passwordIsOpen}
        password={tempPassword}
      />
    </>
  )
}

export default EditInfos

import { useNavigate, useParams } from "react-router-dom"
import { SelectedDiscoverType } from "api/appointmentReason"
import { updateAppointment } from "api/appointments"
import { useMutation, useQuery } from "@tanstack/react-query"
import useToast from "hooks/useToast"
import React, { useEffect, useState } from "react"
import { getPatient, PatientAppointmentType, PatientType } from "api/patient"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { useAppContext } from "AppContext"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import { BabyMediaTag } from "api/media"
import SelectDiscovers from "pages/NewAppointment/components/SelectDiscovers/SelectDiscovers"

const EditExplorerAppointment = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { patientId, appointmentId } = useParams()
  const { currentScope } = useAppContext()
  const updateAppointmentMutation = useMutation(updateAppointment)

  const [selectedDiscovers, setSelectedDiscovers] = useState<
    SelectedDiscoverType[]
  >([])
  const toast = useToast()
  const navigate = useNavigate()

  const [currentAppointment, setCurrentAppointment] =
    React.useState<PatientAppointmentType | null>(null)
  const [selectedBabyMediaTag, setSelectedBabyMediaTag] =
    useState<BabyMediaTag | null>(null)
  const getPatientParams = {
    patientId,
    ...getCurrentScopeParams(currentScope),
  }
  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()

  const babyMediaTags = [
    { key: "bebe_tete", name: "Crâne" },
    { key: "bebe_ventre", name: "Système digestif" },
    { key: "bebe_portage", name: "Portage" },
    { key: "bebe_moteur", name: "Éveil et développement moteur" },
  ]

  const { isLoading } = useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(getPatientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error: any) =>
        retryPatientNoAccessRight(failureCount, error) &&
        retryCollaboratorUnauthorized(failureCount, error),
      onSuccess: (data) => {
        setCurrentAppointment(
          !appointmentId
            ? null
            : data?.appointments?.find(
                (appointment) => appointment.id === parseInt(appointmentId)
              ) ?? null
        )
      },
      onError: (error: any) => {
        onPatientNoAccessRight(error)
        onCollaboratorUnauthorized(error)
      },
    }
  )

  useEffect(() => {
    if (currentAppointment) {
      if (currentAppointment.explorerMedias) {
        const discovers = currentAppointment.explorerMedias.map((media) => ({
          ...media,
          reason: media.tag?.key,
        }))
        setSelectedDiscovers(discovers)
      }
    }
  }, [currentAppointment])

  const isAppointmentKineType =
    currentAppointment?.treatment &&
    currentAppointment?.job !== null &&
    currentAppointment?.job.key === "kine"

  const onBack = () => {
    if (isAppointmentKineType) {
      navigate(
        `/patients/${patientId}/treatment/${currentAppointment.treatment?.id}/appointment/${currentAppointment.id}`
      )
      return
    }

    navigate(`/patients/${patientId}/appointments/${appointmentId}`)
  }

  const onNext = async () => {
    if (currentAppointment && currentAppointment.id) {
      await updateAppointmentMutation.mutateAsync(
        {
          id: currentAppointment.id.toString(),
          data: {
            explorerMedias: selectedDiscovers.map((discover) => discover.id),
          },
        },
        {
          onSuccess: () => {
            toast({
              status: "success",
              title: "Modifications enregistrées",
            })
          },
          onError: () => {
            toast({
              status: "error",
              title: "Une erreur s'est produite",
            })
          },
        }
      )
      if (isAppointmentKineType) {
        navigate(
          `/patients/${patientId}/treatment/${currentAppointment.treatment?.id}/appointment/${currentAppointment.id}`
        )
      } else {
        navigate(`/patients/${patientId}/appointments/${appointmentId}`)
      }
    }
  }

  if (!currentAppointment) return null

  const isStandardAppointment =
    currentAppointment.explorerMedias?.length === 0 ||
    currentAppointment.explorerMedias?.some(
      (media) => !media.tag || !media.tag?.key?.startsWith("bebe_")
    )

  return (
    <SelectDiscovers
      selectedDiscovers={selectedDiscovers}
      setSelectedDiscovers={setSelectedDiscovers}
      selectedBabyMediaTag={selectedBabyMediaTag}
      setSelectedBabyMediaTag={setSelectedBabyMediaTag}
      babyMediaTags={babyMediaTags}
      isBabySwitchOn={!isStandardAppointment}
      onNext={onNext}
      onBack={onBack}
      reason={undefined}
      isSubmitting={isSubmitting}
    />
  )
}
export default EditExplorerAppointment

import { Flex, Image, Text } from "@chakra-ui/react"
import React from "react"
import { MediaAuthorType } from "../../../api/media"

interface IProps {
  author: MediaAuthorType
  setAuthorFilter: React.Dispatch<React.SetStateAction<number | null>>
  isSelected: boolean
}

const AuthorFilter: React.FC<IProps> = ({
  author,
  setAuthorFilter,
  isSelected,
}) => {
  return (
    <Flex
      cursor="pointer"
      alignItems="center"
      bg={isSelected ? "primary.500" : "primary.300"}
      _hover={{ bg: isSelected ? "primary.500" : "primary.400" }}
      py={2}
      px={3}
      borderRadius={24}
      border="1px solid"
      borderColor="primary.500"
      onClick={() =>
        setAuthorFilter((prev) => (prev === author.id ? null : author.id))
      }
    >
      <Image
        src={author.avatar.formats.thumbnail.url}
        w={8}
        h={8}
        borderRadius="50%"
        mr={2}
      />
      <Text>{author.displayName}</Text>{" "}
    </Flex>
  )
}

export default AuthorFilter

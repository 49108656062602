import * as Yup from "yup"
import {
  Flex,
  FormLabel,
  Text,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Link,
  Skeleton,
} from "@chakra-ui/react"
import { AcademieDetailsType, updateAcademie } from "api/academie"
import { RiPencilLine } from "react-icons/ri"
import { Form, Formik, FormikHelpers } from "formik"
import AInputField from "components/Field/AInputField"
import ASubmitButton from "components/ASubmitButton"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import useToast from "hooks/useToast"
import { academieQueryKeys } from "pages/Academy/constants/queryKeys"
import { useAppContext } from "AppContext"

// CONSTANTS
type EditGlobalForm = { name: string; urlDoctolib: string }

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Nom requis"),
  urlDoctolib: Yup.string(),
})

// COMPONENTS
interface EditGlobalProps {
  academie?: AcademieDetailsType
  isLoading: boolean
}

const EditGlobal = ({ academie, isLoading }: EditGlobalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { currentScope } = useAppContext()
  const toast = useToast()

  const editGlobalMutation = useMutation({
    mutationFn: ({ urlDoctolib, name }: EditGlobalForm) =>
      updateAcademie({ academieId: currentScope!.id, url: urlDoctolib, name }),
  })
  const queryClient = useQueryClient()

  const onSubmit = (
    values: EditGlobalForm,
    { setSubmitting }: FormikHelpers<EditGlobalForm>
  ) =>
    editGlobalMutation.mutate(values, {
      onSuccess: (data) => {
        setSubmitting(false)
        queryClient.invalidateQueries({
          queryKey: academieQueryKeys.current(currentScope!.id),
        })
        toast({
          status: "success",
          title: "Modifications enregistrées",
        })
      },
      onError: () => {
        setSubmitting(false)
        toast({
          status: "error",
          title: "Une erreur s'est produite",
        })
      },
    })

  if (isLoading || !academie) {
    return <Skeleton h={58} w={527} borderRadius={9} isLoaded={false} />
  }

  const initialValues = {
    name: academie.name,
    urlDoctolib: academie.url,
  }

  return (
    <>
      <Flex p={4} align="center" gap={4} borderRadius={9} bg="white">
        <Text fontFamily="Montserrat" as="p">
          <Text as="span" fontFamily="inherit" fontWeight={700}>
            {academie.name}
          </Text>
          , réservations sur :{" "}
          <Link href={academie.url} color="tertiary.500" fontWeight={700}>
            {academie.url}
          </Link>
        </Text>
        <IconButton
          onClick={onOpen}
          variant="ghost"
          aria-label="Modifier"
          color="primary.500"
          icon={<RiPencilLine />}
        />
      </Flex>
      <Modal
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />

        <ModalContent maxW={724} borderRadius={8} p={8}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={VALIDATION_SCHEMA}
          >
            <Form>
              <ModalCloseButton aria-label="Fermer" />
              <ModalHeader mb={8} p={0} fontSize={18}>
                Modifier les informations globales
              </ModalHeader>
              <Flex direction="column" gap={8}>
                <AInputField
                  name="name"
                  label={<FormLabel fontSize={14}>Nom *</FormLabel>}
                  placeholder="Nom"
                  bg="common.100"
                />
                <AInputField
                  name="urlDoctolib"
                  label={
                    <FormLabel fontSize={14}>Url de réservation</FormLabel>
                  }
                  placeholder="Exemple : https://doctolib.fr"
                  bg="common.100"
                />
              </Flex>
              <ModalFooter justifyContent="center" p={0} mt={14}>
                <ASubmitButton
                  text={"Enregistrer les modifications"}
                  py={3}
                  px={28}
                />
              </ModalFooter>
            </Form>
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditGlobal

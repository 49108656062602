import axiosInstance from "./axiosInstance"

export type SettingsType = {
  data: {
    attributes: {
      under_maintenance: boolean
    }
  }
}

export const getSettings = async () => {
  const response = await axiosInstance.get<SettingsType>("/pro-setting")
  return response.data.data
}

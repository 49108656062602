import React from "react"
import { SelectedDiscoverType } from "api/appointmentReason"
import ADiscoverCardBase from "components/ADiscoverCard/Base"
import { MediaType } from "api/media"

interface IProps {
  media: MediaType
  selectedDiscovers: SelectedDiscoverType[]
  setSelectedDiscovers: React.Dispatch<
    React.SetStateAction<SelectedDiscoverType[]>
  >
}

const ADiscoverCard: React.FC<IProps> = ({
  media,
  selectedDiscovers,
  setSelectedDiscovers,
}) => {
  const isSelected = selectedDiscovers.some(
    (discover) => discover.id === media.id
  )
  const onAdd = () => {
    setSelectedDiscovers((prev) => {
      const isSelected = prev.some((item) => item.id === media.id)
      if (isSelected) {
        return prev.filter((item) => item.id !== media.id)
      } else {
        return [...prev, media]
      }
    })
  }

  return (
    <ADiscoverCardBase media={media} isDisabled={isSelected} onAdd={onAdd} />
  )
}

export default ADiscoverCard

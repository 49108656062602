import { CheckIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Box, Text } from "@chakra-ui/react"
import { Link } from "react-router-dom"

// HELPERS
interface getLinkAriaLabelProps {
  date: string
  name: string
  academie?: AcademieType
  center?: CenterType
}
const getLinkAriaLabel = ({
  date,
  name,
  academie,
  center,
}: getLinkAriaLabelProps) => {
  if (academie) {
    return `Voir la consultation du ${date} par ${name} de l'Académie ${academie.name}`
  }
  if (center) {
    return `Voir la consultation du ${date} par ${name} du Centre ${center.name}`
  }
  return `Voir la consultation du ${date} par ${name}`
}

// COMPONENTS
interface AppointmentCardProps extends getLinkAriaLabelProps {
  link: string
  isSelected: boolean
}

const AppointmentCard = ({
  date,
  name,
  academie,
  center,
  link,
  isSelected,
}: AppointmentCardProps) => {
  const linkAriaLabel = getLinkAriaLabel({ date, name, academie, center })
  return (
    <Link to={link} aria-label={linkAriaLabel}>
      <Box
        mb={4}
        py={4}
        px={2}
        bg={isSelected ? "secondary.200" : "white"}
        _hover={{ bg: "primary.100" }}
        borderRadius={9}
        pos="relative"
      >
        <Text fontSize={12} color="tertiary.500" mb={2}>
          {date}
        </Text>
        <Text fontSize={16} fontWeight={700}>
          {name}
        </Text>
        {academie && <Text fontSize={16}>Académie : {academie.name}</Text>}
        {center && <Text fontSize={16}>Centre : {center.name}</Text>}
        {isSelected ? (
          <CheckIcon
            pos="absolute"
            fontSize={16}
            right={3}
            top="50%"
            transform="translateY(-50%)"
            color="valid.500"
          />
        ) : (
          <ChevronRightIcon
            pos="absolute"
            fontSize={24}
            right={3}
            top="50%"
            transform="translateY(-50%)"
          />
        )}
      </Box>
    </Link>
  )
}

export default AppointmentCard

import { Container, Flex, Heading, Image } from "@chakra-ui/react"
import { useAppContext } from "AppContext"
import AndrewThinking from "assets/illustrations/andrew-interrogateur.png"
import AButton from "components/AButton"
import AGeolocationLoader from "components/Geolocation/AGeolocationLoader"
import useStudentGeolocationOnClick from "hooks/useStudentGeolocation/onClick"
import getCurrentCenter from "utils/getCurrentCenter"

const BadGeolocation = () => {
  const { currentScope, user } = useAppContext()

  const currentCenter = getCurrentCenter({ currentScope, user })

  const { onClick, isLoading, isImprovingAccuracy, shouldWait } =
    useStudentGeolocationOnClick()

  if (isLoading) {
    return <AGeolocationLoader isImprovingAccuracy={isImprovingAccuracy} />
  }

  return (
    <Container maxW={500} minH="100vh" p={0}>
      <Flex
        backgroundColor={"primary.100"}
        direction={"column"}
        alignItems="center"
        h="100%"
        gap={10}
      >
        <Image
          src={AndrewThinking}
          w={159}
          h={214}
          alt="Andrew Interrogateur"
        />
        <Heading
          as="h1"
          color={"tertiary.500"}
          fontSize={28}
          fontWeight={700}
          textAlign="center"
        >
          Vous n'êtes pas dans les locaux de votre établissement{" "}
          {currentCenter?.name}
        </Heading>
        <AButton
          variant="primary"
          text="Rafraîchir la géolocalisation"
          onClick={onClick}
          isLoading={shouldWait}
        />
        <Heading as="h2" fontSize={18} fontWeight={400} textAlign="center">
          Vous n'êtes pas autorisé à accéder à votre compte Académie en dehors
          de votre établissement
        </Heading>
      </Flex>
    </Container>
  )
}

export default BadGeolocation

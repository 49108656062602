import React from "react"
import { SVGProps } from "../type"

const Cervicales: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="20"
      fill="none"
      viewBox="0 0 23 20"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M20.5 3c-7.2 3.6-15 1.5-18 0L5 18.5h14L20.5 3z"
      ></path>
    </svg>
  )
}

export default Cervicales

import {
  SelectedExerciceType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import { Formik, Form } from "formik"
import { ProgramType } from "api/program"
import * as Yup from "yup"
import { Flex, Heading, Text } from "@chakra-ui/react"
import AButton from "components/AButton"
import FullExerciceCard from "./FullExerciceCard"
import { useQuery } from "@tanstack/react-query"

import { getAllTrainingVideoExerciceType } from "api/trainingVideoExerciceType"

interface AddEditableProgramModalProps {
  selectedProgram: ProgramType
  setSelectedProgram: React.Dispatch<React.SetStateAction<ProgramType | null>>
  setSelectedExercices?: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  setSelectedUploadedExercices?: React.Dispatch<
    React.SetStateAction<SelectedUploadedExerciceType[]>
  >
  setSelectedVideoOrder?: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
}

const AddEditableProgramModal = ({
  selectedProgram,
  setSelectedProgram,
  setSelectedExercices,
  setSelectedUploadedExercices,
  setSelectedVideoOrder,
}: AddEditableProgramModalProps) => {
  const { data: trainingVideoExerciceType } = useQuery(
    ["trainingVideoExerciceType"],
    getAllTrainingVideoExerciceType
  )

  const initialValues = {
    trainingVideoExercices: selectedProgram.trainingVideoPrograms.map(
      (tvp) => ({
        id: tvp.id,
        duration: tvp.duration === null ? null : tvp.duration,
        series: tvp.series || 1,
        repetitions: tvp.repetitions || 10,
        restDuration: tvp.restDuration || 0,
        type: tvp.type,
        side: tvp.side !== null ? tvp.side : null,
        weight: tvp.weight !== null ? tvp.weight : null,
        recommendation: tvp.recommendation,
        trainingVideo: tvp.trainingVideo,
        trainingVideoUploaded: tvp.trainingVideoUploaded,
      })
    ),
  }

  const validationSchema = Yup.object().shape({
    trainingVideoExercices: Yup.array().of(
      Yup.object().shape({
        series: Yup.number()
          .min(1, "Le nombre de séries doit faire au minimum 1")
          .required("Series requis"),
        repetitions: Yup.number()
          .min(1, "Le nombre de répétitions doit être au minimum 1")
          .nullable()
          .optional(),
        restDuration: Yup.number()
          .min(0, "Le temps de repos ne peut être négatif")
          .nullable()
          .optional(),
        type: Yup.object().nullable().optional(),
        side: Yup.string().nullable(),
        weight: Yup.number().nullable().optional(),
        recommendation: Yup.string().nullable().optional(),
      })
    ),
  })

  const handleSubmit = (values: any) => {
    if (
      !values ||
      !setSelectedExercices ||
      !setSelectedUploadedExercices ||
      !setSelectedVideoOrder
    )
      return
    const videosToAdd: SelectedExerciceType[] = values.trainingVideoExercices
      .filter(
        (tvp: any) => tvp.trainingVideoUploaded === null && tvp.trainingVideo
      )
      .map((tvp: any) => ({
        ...tvp.trainingVideo,
        duration: tvp.duration,
        series: tvp.series || 1,
        repetitions: tvp.repetitions,
        restDuration: tvp.restDuration || 0,
        type: tvp.type ?? 1,
        side: tvp.side,
        weight: tvp.weight,
        recommendation: tvp.recommendation,
        videoGroup: tvp.trainingVideo.group?.[0].id,
      }))

    const videosUploadedToAdd: SelectedUploadedExerciceType[] =
      values.trainingVideoExercices
        .filter(
          (tvp: any) => tvp.trainingVideo === null && tvp.trainingVideoUploaded
        )
        .map((tvp: any) => ({
          ...tvp.trainingVideoUploaded,
          duration: tvp.duration,
          series: tvp.series || 1,
          repetitions: tvp.repetitions,
          restDuration: tvp.restDuration || 0,
          type: tvp.type ?? 1,
          side: tvp.side,
          weight: tvp.weight,
          recommendation: tvp.recommendation,
        }))

    const videosOrderToAdd = values.trainingVideoExercices.map(
      (tvp: any, index: number) => ({
        id: tvp.trainingVideoUploaded
          ? tvp.trainingVideoUploaded.id
          : tvp.trainingVideo.id,
        type: tvp.trainingVideoUploaded ? "uploaded_video" : "video",
      })
    )

    setSelectedExercices((prev) => [...prev, ...videosToAdd])
    setSelectedUploadedExercices((prev) => [...prev, ...videosUploadedToAdd])
    setSelectedVideoOrder((prev) => [...prev, ...videosOrderToAdd])
    setSelectedProgram(null)
  }

  if (!trainingVideoExerciceType) return null

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top="0px"
      left="0px"
      width="full"
      height="100vh"
      bg="rgba(0, 0, 0, 0.4)"
      zIndex={100}
    >
      <Flex
        bg="white"
        zIndex={100}
        p="32px"
        flexDirection="column"
        gap="32px"
        // width="728px"
        w={"500px"}
        rounded="8px"
        maxH="90vh"
        overflow="auto"
      >
        <Flex w="full" direction={"column"} justify={"flex-start"}>
          <Heading as="h6" fontSize="18px" fontWeight={700}>
            {selectedProgram.title}
          </Heading>
          <Text>niveau {selectedProgram.level}</Text>
        </Flex>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Flex
                  justifyContent="flex-start"
                  gap="12px"
                  direction={"column"}
                >
                  {values.trainingVideoExercices.map((_, index) => (
                    <FullExerciceCard
                      key={index}
                      values={values.trainingVideoExercices[index]}
                      setFieldValue={(field: string, value: any) =>
                        setFieldValue(
                          `trainingVideoExercices[${index}].${field}`,
                          value
                        )
                      }
                      trainingVideoExerciceType={trainingVideoExerciceType}
                    />
                  ))}
                </Flex>

                <Flex justifyContent="end" gap="16px" w="full" mt="16px">
                  <AButton
                    text={!setSelectedExercices ? "Fermer" : "Annuler"}
                    variant="tertiary"
                    onClick={() => {
                      setSelectedProgram(null)
                    }}
                  />
                  {setSelectedExercices && (
                    <AButton type="submit" text="Ajouter" />
                  )}
                </Flex>
              </Form>
            )
          }}
        </Formik>
      </Flex>
    </Flex>
  )
}

export default AddEditableProgramModal

import { CurrentScope } from "AppContext"

function getCurrentScopeParams(currentScope: CurrentScope | null) {
  if (currentScope?.type === "academy") {
    return { academieId: currentScope.id }
  }

  if (currentScope?.type === "center") {
    return { centerId: currentScope.id }
  }

  return {}
}

export default getCurrentScopeParams

import React from "react"
import { SVGProps } from "../type"

const Sciatique: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="252"
      fill="none"
      viewBox="0 0 28 252"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M3 57C-.2 25.4 8.667 7.167 13.5 2c14.5-5 7.5 50 9 64.5 1.2 11.6.5 53.833 0 73.5-.333 9.167-.6 28.5 1 32.5s1.667 35.333 1.5 50.5c.5 6.333 1.2 20.1 0 24.5-1.2 4.4-4.833 2.5-6.5 1-3.833-18.667-10.8-60-8-76 2.8-16 1.167-40 0-50C9.333 113.833 6.2 88.6 3 57z"
      ></path>
    </svg>
  )
}

export default Sciatique

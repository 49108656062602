import { Box, Flex, Heading } from "@chakra-ui/react"
import { BabyMediaTag } from "api/media"
import React from "react"
import SelectedTag from "./SelectedTag"

interface IProps {
  selectedBabyMediaTag: BabyMediaTag | null
  setSelectedBabyMediaTag: React.Dispatch<
    React.SetStateAction<BabyMediaTag | null>
  >
}

const SelectedBabyMediaTags: React.FC<IProps> = ({
  selectedBabyMediaTag,
  setSelectedBabyMediaTag,
}) => {
  return (
    <Box
      w={868}
      borderRadius={9}
      p={4}
      border="1px solid"
      borderColor="primary.500"
      my={8}
    >
      <Heading as="h3" fontSize={18} mb={4}>
        Catégories sélectionnées
      </Heading>
      <Flex gap={3} flexWrap="wrap" alignItems="center">
        {selectedBabyMediaTag && (
          <SelectedTag
            key={selectedBabyMediaTag.key}
            name={selectedBabyMediaTag.name}
            itemId={selectedBabyMediaTag.key}
            setSelectedBabyMediaTag={setSelectedBabyMediaTag}
          />
        )}
      </Flex>
    </Box>
  )
}

export default SelectedBabyMediaTags

import { Box, Flex, MenuItem, Text, useDisclosure } from "@chakra-ui/react"
import React, { useRef } from "react"
import { AddIcon } from "@chakra-ui/icons"
import AInvite from "../../AInvite"
import useStudentsCannotAddPatient from "hooks/useStudentsCannotAddPatient"

const ATransferPatientMenuItem = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)
  const studentsCannotAddPatient = useStudentsCannotAddPatient()

  if (studentsCannotAddPatient) {
    return null
  }
  return (
    <MenuItem
      minH="48px"
      background="primary.300"
      _hover={{ background: "primary.400" }}
      _focus={{ background: "primary.400" }}
      onClick={onOpen}
    >
      <Flex>
        <Box w={6}>
          <AddIcon w={3} h={3} ml={1} />
        </Box>
        <Text ml={2} fontSize={16}>
          Transfert de patient
        </Text>
      </Flex>
      <AInvite onClose={onClose} isOpen={isOpen} cancelRef={cancelRef} />
    </MenuItem>
  )
}

export default ATransferPatientMenuItem

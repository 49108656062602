const studentsQueryKeys = {
  all: ["getStudents"] as const,
  lists: (academieId: number) =>
    [...studentsQueryKeys.all, academieId] as const,
  list: (
    academieId: number,
    showDisabled: boolean,
    search: string,
    page: number
  ) =>
    [
      ...studentsQueryKeys.lists(academieId),
      showDisabled,
      search,
      page,
    ] as const,
}

export default studentsQueryKeys

import { Box } from "@chakra-ui/react"
import React from "react"
import { SelectedDiscoverType } from "api/appointmentReason"
import ADiscoverCard from "components/ADiscoverCard/ADiscoverCard"
import { MediaType } from "api/media"

interface IProps {
  media: MediaType
  selectedDiscovers: SelectedDiscoverType[]
  setSelectedDiscovers: React.Dispatch<
    React.SetStateAction<SelectedDiscoverType[]>
  >
  index?: number
}

const DiscoverMedia: React.FC<IProps> = ({
  media,
  selectedDiscovers,
  setSelectedDiscovers,
  index,
}) => {
  return (
    <Box
      w="full"
      backgroundColor={
        index === undefined ? "none" : index % 2 === 0 ? "primary.200" : "none"
      }
      h={media.type === "video" ? "110px" : "80px"}
    >
      <ADiscoverCard
        key={`media_${media.id}`}
        media={media}
        selectedDiscovers={selectedDiscovers}
        setSelectedDiscovers={setSelectedDiscovers}
      />
    </Box>
  )
}

export default DiscoverMedia

import { Flex, Image, Checkbox, Text } from "@chakra-ui/react"
import { BabyMediaTag } from "api/media"
import React from "react"
import babyBack from "../../../../../../assets/babyMediaTag/baby-back.svg"
import portage from "../../../../../../assets/babyMediaTag/portage.svg"
import moteur from "../../../../../../assets/babyMediaTag/moteur.svg"
import AZoneMarker from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/components/AZonePathologieMarker"

interface IProps {
  selectedBabyMediaTag: BabyMediaTag | null
  setSelectedBabyMediaTag: React.Dispatch<
    React.SetStateAction<BabyMediaTag | null>
  >
  babyMediaTags: BabyMediaTag[]
}

const BabyMediaTags: React.FC<IProps> = ({
  selectedBabyMediaTag,
  setSelectedBabyMediaTag,
  babyMediaTags,
}) => {
  const handleMediaTag = (newTag: BabyMediaTag) => {
    if (selectedBabyMediaTag?.key === newTag.key) {
      setSelectedBabyMediaTag(null)
    } else {
      setSelectedBabyMediaTag(newTag)
    }
  }
  const firstTags = babyMediaTags.slice(0, Math.ceil(babyMediaTags.length / 2))
  const lastTags = babyMediaTags.slice(Math.ceil(babyMediaTags.length / 2))

  return (
    <Flex
      direction={"column"}
      alignItems="center"
      gap={"32px"}
      maxW="160px"
      pl={10}
    >
      <Flex h={"230px"} pos="relative" alignItems="center">
        <Image src={babyBack} alt="Bébé de dos" h={"230px"} />
        {firstTags.map((tag: BabyMediaTag, index) => (
          <AZoneMarker
            key={tag.key}
            name={tag.name}
            left={index === 0 ? "4" : "2.5"}
            top={index === 0 ? "-3" : "70px"}
            code={`TAG_${tag.key.toUpperCase()}`}
            state={selectedBabyMediaTag?.key === tag.key ? "active" : "default"}
            onClick={() => handleMediaTag(tag)}
          />
        ))}
      </Flex>
      {lastTags.map((tag: BabyMediaTag, index) => (
        <Flex
          h={"230px"}
          direction="column"
          alignItems={"center"}
          key={index}
          onClick={() => handleMediaTag(tag)}
          cursor={"pointer"}
          pl={2}
        >
          <Image
            src={index === 0 ? portage : moteur}
            alt={tag.name}
            maxH={"230px"}
          />
          <Flex alignItems={"center"} gap={2}>
            <Checkbox
              colorScheme="orange"
              borderColor="black"
              isChecked={selectedBabyMediaTag?.key === tag.key}
            />
            <Text>{tag.name}</Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

export default BabyMediaTags

import { useEffect, useRef } from "react"

const useMountEffect = (fn: () => void) => {
  const fnRef = useRef(fn)
  const isMounted = useRef(false)

  useEffect(() => {
    fnRef.current = fn
  }, [fn, fnRef])

  useEffect(() => {
    if (isMounted.current === false) {
      fnRef.current()
      isMounted.current = true
    }
  }, [])
}

export default useMountEffect

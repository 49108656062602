import {
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react"
import { ModalOverlay } from "@chakra-ui/react"
import { ModalContent } from "@chakra-ui/react"
import AButton from "components/AButton"

interface CGSModalProps {
  isOpen: boolean
  onClose: () => void
  acceptCGS: () => void
}
const CGSModal = ({ isOpen, onClose, acceptCGS }: CGSModalProps) => {
  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay backgroundColor={isOpen ? "primary.200" : undefined} />
      <ModalContent maxW={724} borderRadius={8} p={8}>
        <ModalHeader mb={8} p={0} fontSize={18}>
          Modification des CGS
        </ModalHeader>
        <ModalBody>
          Les conditions générales de service ont été mise à jour. Nous vous
          invitons à les{" "}
          <Link onClick={onClose} textDecoration="underline">
            consulter
          </Link>{" "}
          et à les accepter.
        </ModalBody>

        <ModalFooter gap={3}>
          <AButton text="Accepter les CGS" onClick={acceptCGS} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CGSModal

import { MeType } from "api/user"
import { CurrentScope } from "AppContext"
import isCenterAdminCurrentScope from "utils/currentScope/isCenterAdmin"

const userAdminsCenterGroupInCurrentScope = (
  user: MeType | null,
  currentScope: CurrentScope | null
) =>
  isCenterAdminCurrentScope(currentScope) &&
  (user?.osteo.adminCenterGroups?.some(
    (centerGroup) => centerGroup.id === currentScope.centerGroupId
  ) ??
    false)

export default userAdminsCenterGroupInCurrentScope

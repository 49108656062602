import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useNumberInput,
} from "@chakra-ui/react"
import React, { useState } from "react"
import {
  TrainingVideoGroupsLightType,
  TrainingVideoGroupsType,
  VideoType,
} from "api/appointmentReason"
import { FaPlay, FaPlus } from "react-icons/fa"
import { LazyLoadImage } from "react-lazy-load-image-component"

interface IProps {
  video: VideoType
  videoGroup: TrainingVideoGroupsType | TrainingVideoGroupsLightType
  title?: string
  isSelecting?: boolean
  isSelected?: boolean
  isDisabled?: boolean
  onClick: () => void
  onAdd?: (value: number) => void
  videosCount?: number
  isAddCard?: boolean
}

const AExerciceCardBase: React.FC<IProps> = ({
  video,
  videoGroup,
  title,
  isSelecting,
  isSelected,
  isDisabled,
  onClick,
  onAdd,
  videosCount,
  isAddCard = true,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { valueAsNumber } = useNumberInput({
    step: 5,
    defaultValue: video.defaultDuration || 30,
    min: video.defaultDuration || 0,
    max: 300,
    isDisabled,
  })

  return (
    <Box
      w={
        videosCount === 1
          ? "100%"
          : videosCount === 2
          ? "calc(50% - 6px)"
          : "calc(33% - 6px)"
      }
    >
      <Box
        bg="white"
        w="100%"
        overflow="hidden"
        cursor={isDisabled ? "not-allowed" : undefined}
        opacity={isDisabled ? 0.4 : 1}
      >
        <AspectRatio ratio={videosCount === 3 ? 16 / 9 : 2.37}>
          <Box
            borderRadius={9}
            pos="relative"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <LazyLoadImage
              alt={video.preview?.alternativeText ?? ""}
              src={video.bunnyCDN?.preview}
              width="100%"
              height="100%"
              style={{ objectFit: "cover" }}
            />
            {isHovering && !isDisabled && (
              <Flex
                bg="rgba(17, 23, 45, 0.5)"
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={
                  isDisabled
                    ? () => null
                    : () => {
                        if (isAddCard) {
                          onAdd?.(valueAsNumber)
                        } else {
                          onOpen()
                        }
                      }
                }
              >
                <Image
                  src={video.bunnyCDN?.previewWebP}
                  alt={video.title}
                  width="100%"
                  height="100%"
                  objectFit="cover"
                />
                <Flex pos="absolute" bottom={2} right={2}>
                  <Flex
                    cursor="pointer"
                    opacity={0.5}
                    _hover={{ opacity: 1 }}
                    bg="#11172D"
                    p={3}
                    borderRadius={"100%"}
                    justifyContent="center"
                    alignItems="center"
                    h={10}
                    w={10}
                    onClick={
                      isDisabled
                        ? () => null
                        : (e) => {
                            e.stopPropagation()
                            onOpen()
                          }
                    }
                  >
                    <FaPlay
                      className="button-icon"
                      fontSize={16}
                      color="#fdd7c0"
                    />
                  </Flex>
                  {isAddCard && (
                    <Flex
                      cursor="pointer"
                      opacity={0.5}
                      _hover={{ opacity: 1 }}
                      bg="#11172D"
                      p={3}
                      borderRadius={"100%"}
                      justifyContent="center"
                      alignItems="center"
                      h={10}
                      w={10}
                      onClick={
                        onAdd
                          ? (e) => {
                              e.stopPropagation()
                              onAdd(valueAsNumber)
                            }
                          : undefined
                      }
                      ml={1}
                    >
                      <FaPlus
                        className="button-icon"
                        fontSize={20}
                        color="#fdd7c0"
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            )}
          </Box>
        </AspectRatio>

        <Flex
          pt={2}
          alignItems="center"
          onClick={onClick}
          cursor="pointer"
          as="button"
        >
          <Box
            onClick={
              isAddCard && !isDisabled
                ? () => onAdd?.(valueAsNumber)
                : undefined
            }
          >
            {title ? (
              <div>
                <Text fontSize={14} fontWeight={700} color="tertiary.500">
                  {title}
                </Text>
                <Text fontSize={12}>Niveau {video.level}</Text>
              </div>
            ) : (
              <Text fontSize={14} fontWeight={700} color="tertiary.500">
                Niveau {video.level}
              </Text>
            )}
          </Box>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent maxW="724">
          <Box
            pos="relative"
            borderRadius={9}
            bg="white"
            w={724}
            h={469}
            py="32px"
            px="54px"
          >
            <ModalCloseButton aria-label="Fermer" />
            <Heading fontSize={22} fontWeight="bold" textAlign="center" mb={7}>
              {title ? title : videoGroup.title}
            </Heading>

            <Box pos="relative" pt="56.25%">
              <iframe
                src={`${video.bunnyCDN?.fullVideo}?autoplay=true&loop=false&muted=true&preload=true&responsive=true`}
                loading="lazy"
                style={{
                  border: 0,
                  position: "absolute",
                  top: 0,
                  height: "100%",
                  width: "100%",
                }}
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
                title={video.title}
              />
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default AExerciceCardBase

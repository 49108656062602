import { useQuery } from "@tanstack/react-query"
import { getCenterSubscriptionState } from "api/subscriptions"
import { useAppContext } from "AppContext"
import centerSubscriptionStateQueryKeys from "constants/queryKeys/centerSubscriptionState"
import useCenterIsTester from "hooks/useCenterIsTester"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import getCurrentCenter from "utils/getCurrentCenter"

const useCenterSubscription = () => {
  const { user, currentScope } = useAppContext()
  const centerIsTester = useCenterIsTester()

  const currentCenter = getCurrentCenter({ currentScope, user })
  const hasCurrentCenter = currentCenter !== undefined
  const {
    data: centerSubscription,
    isLoading,
    isError,
  } = useQuery(
    centerSubscriptionStateQueryKeys.all,
    () => getCenterSubscriptionState({ centerId: currentCenter!.id }),
    {
      retry: retryCollaboratorUnauthorized,
      enabled: !centerIsTester && hasCurrentCenter,
    }
  )

  return {
    hasCurrentCenter: centerIsTester || hasCurrentCenter,
    active: centerSubscription?.active,
    isLoading: !centerIsTester && isLoading,
    isError: !centerIsTester && isError,
  }
}

export default useCenterSubscription

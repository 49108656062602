import { Flex, Text, Icon } from "@chakra-ui/react"
import { useAppContext } from "../../../AppContext"
import { MdSchool } from "react-icons/md"
import getCurrentAcademy from "utils/getCurrentAcademy"
import AUserAvatar from "components/AAvatar/AUserAvatar"
import getCurrentCenter from "utils/getCurrentCenter"
import ACenterAvatar from "components/AAvatar/ACenterAvatar"
import troncateString from "utils/troncateString"

// COMPONENTS
const ACurrentProfile = () => {
  const { currentScope, user } = useAppContext()
  if (!currentScope)
    return (
      <Flex alignItems="center">
        <AUserAvatar user={user} />
        <Flex
          fontWeight={700}
          fontSize={14}
          flexWrap="wrap"
          maxW="full"
          gap="4px"
          flex={1}
        >
          <Text>{user?.osteo.firstname}</Text>
          <Text>{user?.osteo.lastname}</Text>
        </Flex>
      </Flex>
    )

  const currentAcademy = getCurrentAcademy({ currentScope, user })

  if (currentAcademy)
    return (
      <Flex alignItems="center">
        <Flex
          w={8}
          h={8}
          justifyContent="center"
          alignItems="center"
          bg="tertiary.500"
          borderRadius="50%"
          mr={2}
        >
          <Icon fontSize={20} as={MdSchool} color="white" />
        </Flex>
        <Flex flexDir="column" alignItems="flex-start" flex={1}>
          <Text fontWeight={700} fontSize={16}>
            {troncateString(currentAcademy.name, 15)}
          </Text>
          <Text fontWeight={400} fontSize={14}>
            {troncateString(
              `${user?.osteo.firstname} ${user?.osteo.lastname}`,
              15
            )}
          </Text>
        </Flex>
      </Flex>
    )

  const currentCenter = getCurrentCenter({ currentScope, user })
  if (currentCenter)
    return (
      <Flex alignItems="center">
        <ACenterAvatar avatar={currentCenter.avatar} />
        <Flex flexDir="column" alignItems="flex-start">
          <Text fontWeight={700} fontSize={16}>
            {troncateString(currentCenter.name, 15)}
          </Text>
          <Text fontWeight={400} fontSize={14}>
            {troncateString(
              `${user?.osteo.firstname} ${user?.osteo.lastname}`,
              15
            )}
          </Text>
        </Flex>
      </Flex>
    )

  return null
}

export default ACurrentProfile

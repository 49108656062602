import {
  Modal,
  ModalContent,
  ModalOverlay,
  Center,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Link,
  Text,
} from "@chakra-ui/react"

interface AWelcomeModalProps {
  isOpen: boolean
  onClose: () => void
}
const AWelcomeModal = ({ isOpen, onClose }: AWelcomeModalProps) => {
  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW={724} borderRadius={8} p={8}>
        <ModalCloseButton aria-label="Fermer" />
        <ModalHeader mb={8} p={0} fontSize={18}>
          Un petit message de bienvenue...
        </ModalHeader>
        <ModalBody>
          <Center>
            <video
              autoPlay
              loop
              muted
              playsInline
              controls
              width="300px"
              src="https://andrew-osteow-s3.s3.eu-west-3.amazonaws.com/Andrew_Therapeutes_master_V2_05b4b620e7.mp4?updated_at=2023-01-30T09:18:09.311Z"
            ></video>
          </Center>
        </ModalBody>

        <ModalFooter>
          <Text>
            Si vous n’avez pas fait de webinaire nous vous conseillons de
            regarder{" "}
            <Link
              as={"a"}
              href="https://www.youtube.com/embed/moRsroq_MH8"
              target={"_blank"}
              textDecor={"underline"}
            >
              celui-ci
            </Link>{" "}
            ! Andrew est tres simple d’utilisation mais nous vous en expliquons
            tous les détails, ainsi que les nouvelles options que nous avons
            ajoutées !
          </Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AWelcomeModal

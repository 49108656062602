import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
} from "@chakra-ui/react"
import StripeConnectButton from "components/StripeConnect/StripeConnectButton"

interface StripeConnectModalProps {
  isOpen: boolean
  onClose: () => void
}
const StripeConnectModal = ({ isOpen, onClose }: StripeConnectModalProps) => {
  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW={724} borderRadius={8} p={8}>
        <ModalCloseButton aria-label="Fermer" />
        <ModalHeader mb={8} p={0} fontSize={18}>
          <Text fontSize={18} fontWeight="bold" mb={4}>
            Créez votre profil de paiement
          </Text>
        </ModalHeader>
        <ModalBody p={0} mb={4}>
          <Text mb={4}>
            Pour permettre à vos patients de régler leurs téléconsultations,
            vous devez d’abord créer un compte Stripe Connect. Cela vous
            permettra d’envoyer des liens de paiement directement à vos patients
            et de recevoir vos paiements à distance.
          </Text>
          <Text>
            <b>Note :</b> Les paiements sont gérés par Stripe, sans transiter
            par Andrew®. Ils sont directement versés sur votre compte Stripe,
            avec des frais de 1,5 % appliqués par Stripe.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Flex mt={4} justifyContent="flex-end">
            <StripeConnectButton />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default StripeConnectModal

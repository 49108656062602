import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import { Flex, Input, InputProps, useNumberInput } from "@chakra-ui/react"
import React, { useEffect } from "react"

interface ANumberInputProps extends InputProps {
  name: string
  step?: number
  defaultValue?: number
  min?: number
  max?: number
  value: number
  setValue: React.Dispatch<React.SetStateAction<number>>
}

const ANumberInput = ({
  name,
  step = 5,
  defaultValue = 30,
  min = 0,
  max = 300,
  value,
  setValue,
  ...props
}: ANumberInputProps) => {
  const {
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
    valueAsNumber,
  } = useNumberInput({
    step,
    defaultValue,
    min,
    max,
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  useEffect(() => {
    setValue(valueAsNumber)
  }, [valueAsNumber, setValue])

  return (
    <Flex alignItems="center">
      <MinusIcon {...dec} w={3} color="primary.500" cursor={"pointer"}>
        -
      </MinusIcon>
      <Input
        {...input}
        bg="common.100"
        w="60px"
        h="25px"
        p={2}
        textAlign="center"
        mx={2}
        fontWeight={700}
        fontSize={14}
        name={name}
        {...props}
      />
      <AddIcon {...inc} w={3} color="primary.500" cursor={"pointer"}>
        -
      </AddIcon>
    </Flex>
  )
}

export default ANumberInput

import { getSubscriptions, StripeProductType } from "api/subscriptions"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "AppContext"
import userAdminsCenterGroupInCurrentScope from "utils/currentScope/userAdminsCenterGroupInCurrentScope"
import userHasSubscription from "utils/subscription/userHasSubscription"
import useJobIsOsteo from "hooks/useJobIsOsteo"
import OsteoSubscription from "components/Subscription/Osteo/OsteoSubscription"
import CenterGroupSubscription from "components/Subscription/CenterGroup/CenterGroupSubscription"
import KineSubscription from "components/Subscription/Kine/KineSubscription"
import TeleconsultationSubscription from "components/Subscription/Teleconsultation/TeleconsultationSubscription"
import CenterGroupTeleconsultationSubscription from "components/Subscription/CenterGroupTeleconsultation/CenterGroupTeleconsultationSubscription"
import CenterGroupKineSubscription from "components/Subscription/CenterGroupKine/CenterGroupKineSubscription"

interface SubscriptionProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}
const Subscription = (props: SubscriptionProps) => {
  const { user, currentScope, hasTeleconsultationAccess } = useAppContext()

  const { data: stripeProducts, isLoading: isLoadingStripeProducts } = useQuery<
    StripeProductType[]
  >(["getSubscriptions"], getSubscriptions)

  const userHasIndividualSubscription = userHasSubscription(user)
  const isCenterAdmin = userAdminsCenterGroupInCurrentScope(user, currentScope)
  const isOsteo = useJobIsOsteo()

  if (isOsteo) {
    if (userHasIndividualSubscription) {
      return (
        <OsteoSubscription
          {...props}
          stripeProducts={stripeProducts}
          isLoadingStripeProducts={isLoadingStripeProducts}
        />
      )
    }

    if (isCenterAdmin) {
      return (
        <CenterGroupSubscription
          {...props}
          stripeProducts={stripeProducts}
          isLoadingStripeProducts={isLoadingStripeProducts}
        />
      )
    }
  }
  if (hasTeleconsultationAccess) {
    if (userHasIndividualSubscription) {
      return (
        <TeleconsultationSubscription
          {...props}
          stripeProducts={stripeProducts}
          isLoadingStripeProducts={isLoadingStripeProducts}
        />
      )
    }
    if (isCenterAdmin) {
      return (
        <CenterGroupTeleconsultationSubscription
          {...props}
          stripeProducts={stripeProducts}
          isLoadingStripeProducts={isLoadingStripeProducts}
        />
      )
    }
  }

  if (userHasIndividualSubscription) {
    return (
      <KineSubscription
        {...props}
        stripeProducts={stripeProducts}
        isLoadingStripeProducts={isLoadingStripeProducts}
      />
    )
  }
  if (isCenterAdmin) {
    return (
      <CenterGroupKineSubscription
        {...props}
        stripeProducts={stripeProducts}
        isLoadingStripeProducts={isLoadingStripeProducts}
      />
    )
  }

  return null
}

export default Subscription

import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { ChangeEvent, Dispatch, SetStateAction, useId, useState } from "react"
import { useAppContext } from "../../../AppContext"
import { MdOutlineDeleteForever, MdOutlinePersonAdd } from "react-icons/md"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import ATableSkeleton from "../../../components/Table/ATableSkeleton"
import ATableEmpty from "../../../components/Table/ATableEmpty"
import {
  GetStudentsResponse,
  AcademieOsteoType,
  updateStudentStatus,
} from "../../../api/academie"
import studentsQueryKeys from "constants/queryKeys/students"
import AAlertDialogConfirm from "../../../components/AAlertDialogConfirm"
import useToast from "../../../hooks/useToast"
import ATablePagination from "../../../components/Table/ATablePagination/ATablePagination"
import AInputSearch from "components/AInputSearch"

// COMPONENTS
interface StudentsProps {
  list: AcademieOsteoType[]
  isLoading: boolean
  showDisabled: boolean
  setShowDisabled: Dispatch<SetStateAction<boolean>>
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageCount: number
  search: string
  setSearch: Dispatch<SetStateAction<string>>
}
const Students = ({
  list,
  isLoading,
  setShowDisabled,
  showDisabled,
  page,
  setPage,
  pageCount,
  search,
  setSearch,
}: StudentsProps) => {
  const { currentScope } = useAppContext()
  const switchStatusId = useId()
  const toast = useToast()
  const [isConfirmToggleOpen, setIsConfirmToggleOpen] = useState(false)
  const [studentToToggle, setStudentToToggle] = useState<AcademieOsteoType>()
  const toggleActiveHeaderContent = studentToToggle?.active
    ? "Désactiver l'étudiant"
    : "Activer l'étudiant"
  const destructiveActionText = studentToToggle?.active
    ? "Désactiver"
    : "Activer"

  const queryClient = useQueryClient()
  const toggleActiveMutationQueryKey = studentsQueryKeys.list(
    currentScope?.id!,
    showDisabled,
    search,
    page
  )
  const toggleActiveMutation = useMutation({
    mutationFn: async ({ active, id }: AcademieOsteoType) => {
      const newStatus = active ? "disable" : "enable"
      const params = {
        newStatus,
        academieId: currentScope?.id!,
        studentId: id,
      } as const
      return updateStudentStatus(params)
    },
    // When mutate is called:
    onMutate: async (studentToMutate: AcademieOsteoType) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: toggleActiveMutationQueryKey,
      })

      // Snapshot the previous value
      const prevAcademyPage = queryClient.getQueryData<GetStudentsResponse>(
        toggleActiveMutationQueryKey
      )

      // Optimistically update to the new value
      queryClient.setQueryData(
        toggleActiveMutationQueryKey,
        (studentsPage?: GetStudentsResponse) => {
          if (studentsPage) {
            return {
              ...studentsPage,
              result: (studentsPage.result || []).filter(
                ({ id }) => id !== studentToMutate.id
              ),
            }
          }
        }
      )

      // Return a context object with the snapshotted value
      return { prevAcademyPage }
    },
    onSuccess: (updatedStudent) => {
      toast({
        status: "success",
        title: updatedStudent.active ? "Etudiant activé" : "Etudiant désactivé",
      })
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        toggleActiveMutationQueryKey,
        context?.prevAcademyPage
      )
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
  })

  const onShowInactiveChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowDisabled(e.target.checked)
  }

  const onAskToggleActive = (student: AcademieOsteoType) => {
    setStudentToToggle(student)
    setIsConfirmToggleOpen(true)
  }

  const onCancelToggleActive = () => {
    setStudentToToggle(undefined)
    setIsConfirmToggleOpen(false)
  }

  const onConfirmToggleActive = () => {
    if (studentToToggle) {
      return toggleActiveMutation.mutate(studentToToggle, {
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: toggleActiveMutationQueryKey,
          })
          onCancelToggleActive()
        },
      })
    }
    // Cela ne devrait jamais arriver
    throw new Error("Unexpected: studentToToggle is undefined")
  }

  return (
    <>
      <Flex
        alignItems="center"
        gap={5}
        justifyContent="space-between"
        width="100%"
      >
        <AInputSearch
          placeholder="Rechercher des étudiants"
          inputGroupProps={{ minWidth: 72 }}
          setSearch={setSearch}
        />
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor={switchStatusId} mb={0}>
            Membres activés
          </FormLabel>
          <Switch
            as="div"
            id={switchStatusId}
            whiteSpace="nowrap"
            colorScheme="orange"
            isChecked={showDisabled}
            onChange={onShowInactiveChange}
          >
            Membres désactivés
          </Switch>
        </FormControl>
      </Flex>
      <Flex direction="column" gap={4}>
        <TableContainer>
          <Table size="lg" background="white" borderRadius={10}>
            <Thead>
              <Tr>
                <Th
                  color="common.700"
                  textTransform="none"
                  fontFamily="Mulish"
                  textAlign="left"
                  minW={52}
                >
                  Nom
                </Th>

                <Th
                  color="common.700"
                  textTransform="none"
                  fontFamily="Mulish"
                  textAlign="left"
                  minW={56}
                >
                  Prénom
                </Th>
                <Th
                  color="common.700"
                  textTransform="none"
                  fontFamily="Mulish"
                  textAlign="left"
                  minW={203}
                >
                  Mail
                </Th>
                <Th
                  color="common.700"
                  textTransform="none"
                  fontFamily="Mulish"
                  textAlign="left"
                  minW={40}
                >
                  État
                </Th>
                <Th
                  color="common.700"
                  textTransform="none"
                  fontFamily="Mulish"
                  textAlign="left"
                  aria-label="Activer / Désactiver"
                  minW={10}
                />
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <ATableSkeleton
                  rows={3}
                  cols={5}
                  cellWidths={[52, 56, 203, 40, 10]}
                />
              ) : (
                <>
                  {list.length === 0 ? (
                    <ATableEmpty
                      colSpan={5}
                      fontSize={14}
                      border="none"
                      textAlign="center"
                    />
                  ) : (
                    list.map((item) => (
                      <Tr key={item.id} border="none">
                        <Td fontSize={14} border="none" textAlign="left">
                          {item.lastname}
                        </Td>
                        <Td fontSize={14} border="none" textAlign="left">
                          {item.firstname}
                        </Td>
                        <Td fontSize={14} border="none" textAlign="left">
                          {item.account.email}
                        </Td>
                        <Td fontSize={14} border="none" textAlign="left">
                          {item.active ? "Activé" : "Désactivé"}
                        </Td>
                        <Td fontSize={14} border="none" textAlign="left">
                          <IconButton
                            onClick={() => onAskToggleActive(item)}
                            variant="ghost"
                            aria-label="Modifier"
                            color="primary.500"
                            icon={
                              item.active ? (
                                <MdOutlineDeleteForever width={6} height={6} />
                              ) : (
                                <MdOutlinePersonAdd />
                              )
                            }
                          />
                        </Td>
                      </Tr>
                    ))
                  )}
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        {!isLoading && (
          <ATablePagination
            pageCount={pageCount}
            onPageChange={setPage}
            page={page}
          />
        )}
      </Flex>
      <AAlertDialogConfirm
        isOpen={isConfirmToggleOpen}
        onClose={onCancelToggleActive}
        onConfirm={onConfirmToggleActive}
        isLoading={toggleActiveMutation.isLoading}
        headerContent={toggleActiveHeaderContent}
        destructiveActionText={destructiveActionText}
        destructiveActionVariant="primary"
        bodyContent="Êtes-vous sûr de vouloir modifier le statut de l'étudiant ?"
      />
    </>
  )
}

export default Students

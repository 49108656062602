import TimePicker, { TimePickerProps } from "react-time-picker"
import "./index.css"

const ATimePicker = (props: TimePickerProps) => (
  <TimePicker
    disableClock
    clearIcon={null}
    className="time-picker"
    {...props}
  />
)

export default ATimePicker

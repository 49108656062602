import { CheckIcon } from "@chakra-ui/icons"
import {
  Flex,
  Heading,
  Icon,
  List,
  ListIcon,
  ListItem,
  Skeleton,
  Text,
} from "@chakra-ui/react"
import { ReactNode, useId } from "react"
import { MdOutlineGroupAdd, MdOutlinePerson } from "react-icons/md"

// CONSTANTS
const INDIVIDUAL_INFO = {
  title: "Forfait Individuel",
  icon: MdOutlinePerson,
}

const CENTER_INFO = {
  title: "Forfait Centre",
  icon: MdOutlineGroupAdd,
}

// COMPONENTS
interface SubscriptionCardProps {
  variant: "individual" | "center"
  price: string
  children?: ReactNode
  action?: ReactNode
}

const KineSubscriptionCard = ({
  variant,
  price,
  children,
  action,
}: SubscriptionCardProps) => {
  const headingId = useId()

  const { title, icon } =
    variant === "individual" ? INDIVIDUAL_INFO : CENTER_INFO

  return (
    <Flex
      direction="column"
      px={10}
      py={6}
      alignItems="flex-start"
      gap={8}
      bg="white"
      borderRadius={9}
    >
      <Heading fontSize={22} fontWeight={700}>
        Mon abonnement
      </Heading>
      <Flex direction="column" alignItems="flex-start" gap={6}>
        <Flex direction="column" alignItems="flex-start" gap={2}>
          <Icon
            aria-labelledby="headingId"
            fontSize={22}
            as={icon}
            color="tertiary.700"
          />
          <Heading id={headingId} as="h3" fontSize={18} color="tertiary.700">
            {title}
          </Heading>
          <Skeleton isLoaded={price !== undefined}>
            <Text color="common.800">{price}</Text>
          </Skeleton>
        </Flex>
        <List spacing={2} maxW={262}>
          <ListItem textAlign="left" display="flex" alignItems="center">
            <ListIcon as={CheckIcon} color="secondary.800" />
            Accès à l'algorithme de recommandation d&apos;exercices.
          </ListItem>
          <ListItem textAlign="left" display="flex" alignItems="center">
            <ListIcon as={CheckIcon} color="secondary.800" />
            Suivi et analyse de vos patients.
          </ListItem>
          <ListItem textAlign="left" display="flex" alignItems="center">
            <ListIcon as={CheckIcon} color="secondary.800" />
            Accès complet à tous les contenus de l&apos;Explorer.
          </ListItem>
          <ListItem textAlign="left" display="flex" alignItems="center">
            <ListIcon as={CheckIcon} color="secondary.800" />
            Nombre de patients illimité.
          </ListItem>
          {variant === "center" && (
            <>
              <ListItem textAlign="left" display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="secondary.800" />
                Communication à l’intégralité de la patientèle du centre
              </ListItem>
              <ListItem textAlign="left" display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="secondary.800" />
                Gestion des collaborateurs
              </ListItem>
              <ListItem textAlign="left" display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="secondary.800" />
                Suivi des patients partagé entre tous les thérapeutes du centre.
              </ListItem>
            </>
          )}
        </List>
        {action}
      </Flex>
      {children}
    </Flex>
  )
}

export default KineSubscriptionCard

import { Tr, Td, TableCellProps } from "@chakra-ui/react"

interface ATableEmptyProps extends TableCellProps {
  colSpan: number
}
const ATableEmpty = ({ colSpan, ...props }: ATableEmptyProps) => {
  return (
    <Tr border="none">
      <Td {...props} colSpan={colSpan}>
        Aucun résultat
      </Td>
    </Tr>
  )
}

export default ATableEmpty

import { SpinnerIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  List,
  ListItem,
} from "@chakra-ui/react"
import React, { useState, useTransition } from "react"
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getDetails,
} from "use-places-autocomplete"
import AButton from "./AButton"

interface IProps extends InputProps {
  setAddress:
    | React.Dispatch<React.SetStateAction<AddressType | undefined>>
    | ((value: AddressType) => void)
  defaultValue?: string
  setAddressError?: React.Dispatch<React.SetStateAction<string | undefined>>
}

const AAdressInput: React.FC<IProps> = ({
  setAddress,
  defaultValue,
  setAddressError,
  ...props
}) => {
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false)
  const [isPending, startTransition] = useTransition()
  const [isFocused, setIsFocused] = useState(false)
  const [searchResultFocused, setSearchResultFocused] = useState(-1)

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue,
    cache: 0,
  })

  const handleKeyInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowUp" && searchResultFocused !== -1) {
      setSearchResultFocused(searchResultFocused - 1)
    } else if (
      (e.key === "ArrowDown" || e.key === "Tab") &&
      searchResultFocused < data.length - 1
    ) {
      e.preventDefault()
      setSearchResultFocused(searchResultFocused + 1)
    } else if (
      e.key === "Enter" &&
      searchResultFocused !== -1 &&
      status === "OK"
    ) {
      handleSelect(
        data[searchResultFocused].place_id,
        data[searchResultFocused].description
      )
    }
  }

  const handleSelect = async (placeId: string, address: any) => {
    setIsUpdatingAddress(true)
    setValue(address, false)
    setIsFocused(false)
    setSearchResultFocused(-1)
    clearSuggestions()

    const [results, details] = await Promise.all([
      getGeocode({ address }),
      getDetails({
        placeId,
        fields: ["address_components"],
      }),
    ])
    const { lat, lng } = await getLatLng(results[0])

    const countryComponent =
      typeof details !== "string"
        ? details.address_components?.find(({ types }) =>
            types.includes("country")
          )
        : undefined
    startTransition(() => {
      setAddress({
        name: address,
        country: countryComponent?.short_name,
        latitude: lat,
        longitude: lng,
      })
      setIsUpdatingAddress(false)
    })
  }

  return (
    <Box position={"relative"}>
      <InputGroup>
        <Input
          placeholder="Renseignez votre adresse"
          borderRadius={"10px"}
          type={"text"}
          fontSize={"sm"}
          isDisabled={!ready}
          bg="common.100"
          onChange={(e) => {
            setValue(e.target.value)
            setIsFocused(true)
            setSearchResultFocused(0)
          }}
          value={value}
          onBlur={() =>
            setTimeout(() => {
              setIsFocused(false)
            }, 200)
          }
          onKeyDown={handleKeyInput}
          onFocus={() => setIsFocused(true)}
          {...props}
        />
        {(isPending || isUpdatingAddress) && (
          <InputRightElement>
            <SpinnerIcon aria-label="Chargement" color="gray.300" />
          </InputRightElement>
        )}
      </InputGroup>
      {isFocused && (
        <List position={"absolute"} zIndex={1} top={"50px"} width="100%">
          <Flex direction={"column"}>
            {status === "OK" &&
              data.map(({ place_id, description }, index) => (
                <ListItem key={place_id}>
                  <AButton
                    variant="custom"
                    onClick={() => {
                      handleSelect(place_id, description)
                      if (setAddressError) {
                        setAddressError(undefined)
                      }
                    }}
                    cursor={"pointer"}
                    _hover={{ backgroundColor: "primary.100" }}
                    _focus={{ backgroundColor: "primary.100", zIndex: 1 }}
                    _active={{ backgroundColor: "primary.100" }}
                    width={"100%"}
                    justifyContent={"start"}
                    borderWidth={1}
                    bg={searchResultFocused === index ? "primary.200" : "white"}
                    text={description}
                  />
                </ListItem>
              ))}
          </Flex>
        </List>
      )}
    </Box>
  )
}

export default AAdressInput

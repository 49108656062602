import { Flex, Heading, Text } from "@chakra-ui/react"
import AButton from "components/AButton"
import { ReactNode } from "react"

interface BillingCardProps {
  subscription: StripeSubscription
  isLoading?: boolean
  discount: string
  onClick: () => void
  children?: ReactNode
}

const BillingCard = ({
  subscription,
  isLoading,
  discount,
  onClick,
  children,
}: BillingCardProps) => {
  const currentInterval = subscription.plan.interval
  const isBillingYearly = currentInterval === "year"

  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      gap={2}
      px={6}
      py={4}
      bg="white"
      borderRadius={9}
    >
      <Heading fontSize={22} fontWeight={700}>
        Périodicité de facturation
      </Heading>
      <Flex direction="column" gap={6}>
        <Flex direction="column" gap={2}>
          {isBillingYearly ? (
            <>
              <Text fontWeight={700}>Annuelle</Text>
              <Text as="p">
                Vous profitez{" "}
                <Text as="span" fontWeight={700} color="secondary.800">
                  du meilleur tarif
                </Text>{" "}
                grâce à la facturation annuelle
              </Text>
            </>
          ) : (
            <>
              <Text fontWeight={700}>Mensuelle</Text>
              <Text as="p" maxW={384}>
                Économisez{" "}
                <Text as="span" color="secondary.800" fontWeight={700}>
                  {discount}
                </Text>{" "}
                en passant à la facturation annuelle
              </Text>
            </>
          )}
        </Flex>
        <AButton
          isLoading={isLoading}
          variant="quaternary"
          text={
            isBillingYearly ? "Changer la périodicité" : "Simuler mes économies"
          }
          onClick={onClick}
        />
      </Flex>
      {children}
    </Flex>
  )
}

export default BillingCard

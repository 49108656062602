import EditIcon from "assets/edit.svg"
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Image,
} from "@chakra-ui/react"
import { useField } from "formik"
import { ReactNode, useEffect, useRef, useState } from "react"
import { RiCloseFill } from "react-icons/ri"
import AButton from "components/AButton"
import { AddIcon } from "@chakra-ui/icons"

interface ARegisterAvatarFieldProps {
  name: string
  label?: ReactNode
}
const ARegisterAvatarField = ({
  name,
  label = "Avatar",
}: ARegisterAvatarFieldProps) => {
  const [{ value }, { error }, { setValue }] = useField<File | undefined>({
    name,
    type: "file",
  })
  const inputRef = useRef<HTMLInputElement>(null)

  const [preview, setPreview] = useState<string | undefined>()

  const onButtonClick = () => {
    if (!inputRef.current) return
    inputRef.current.click()
  }

  useEffect(() => {
    if (!(value instanceof File)) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(value)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl)
      setPreview(undefined)
    }
  }, [value])

  return (
    <FormControl isInvalid={error !== undefined}>
      <FormLabel mb="8px">{label}</FormLabel>
      <Flex alignItems="center" mb={8}>
        <Box pos="relative" w={86}>
          {preview ? (
            <>
              <Image
                src={preview}
                w={86}
                h={86}
                borderRadius="50%"
                objectFit="cover"
              />
              <Flex
                bg="primary.500"
                w={10}
                h={10}
                borderRadius="50%"
                pos="absolute"
                bottom={-2}
                right={-2}
                onClick={onButtonClick}
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
              >
                <Image src={EditIcon} />
              </Flex>
              <Flex
                bg="common.300"
                w={8}
                h={8}
                borderRadius="50%"
                pos="absolute"
                top={-2}
                right={-2}
                onClick={() => {
                  if (!inputRef.current) return
                  inputRef.current.value = ""
                  setValue(undefined)
                }}
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
              >
                <Icon fontSize={20} as={RiCloseFill} color="common:800" />
              </Flex>
            </>
          ) : (
            <AButton
              text="Ajouter une image de profil"
              variant="tertiary"
              leftIcon={<AddIcon w={3} h={3} />}
              onClick={onButtonClick}
            />
          )}
        </Box>
        <input
          id="avatar"
          name="avatar"
          type="file"
          accept="image/*"
          onChange={(e) => {
            if (!e.currentTarget?.files || !e.currentTarget?.files[0]) return

            setValue(e.currentTarget?.files && e.currentTarget?.files[0])
          }}
          ref={inputRef}
          style={{ display: "none" }}
        />
      </Flex>

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default ARegisterAvatarField

import { Text } from "@chakra-ui/react"
import React from "react"
import { MediaDifficutType } from "../../../api/media"
import getDifficulty from "../../../utils/getDifficulty"

interface IProps {
  difficulty: MediaDifficutType
  setDifficultyFilter: React.Dispatch<
    React.SetStateAction<MediaDifficutType | null>
  >
  isSelected: boolean
}

const DifficultyFilter: React.FC<IProps> = ({
  difficulty,
  setDifficultyFilter,
  isSelected,
}) => {
  return (
    <Text
      p={3}
      cursor="pointer"
      bg={isSelected ? "secondary.500" : "secondary.300"}
      _hover={{ bg: isSelected ? "secondary.500" : "secondary.400" }}
      border="1px solid"
      borderColor="secondary.500"
      py={2}
      px={3}
      borderRadius={24}
      onClick={() =>
        setDifficultyFilter((prev) => (prev === difficulty ? null : difficulty))
      }
    >
      {getDifficulty(difficulty)}
    </Text>
  )
}

export default DifficultyFilter

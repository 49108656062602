import { ButtonProps, Image, useDisclosure } from "@chakra-ui/react"
import React, { useRef } from "react"
import AButton, { VariantType } from "./AButton"
import Logout from "../assets/logout.svg"
import ALogoutModal from "./ALogoutModal"

interface IProps extends ButtonProps {
  variant?: VariantType
}

const ALogoutButton: React.FC<IProps> = ({ variant = "custom", ...props }) => {
  const cancelRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <AButton
        variant={variant}
        leftIcon={<Image src={Logout} w={3.5} />}
        _hover={{ background: "primary.400" }}
        onClick={onOpen}
        text="Se déconnecter"
        {...props}
      />
      <ALogoutModal cancelRef={cancelRef} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default ALogoutButton

import { defineStyleConfig } from "@chakra-ui/react"

// define the base component styles
const baseStyle = {
  zIndex: 99,
}

// export the component theme
export const tooltipTheme = defineStyleConfig({
  baseStyle,
})

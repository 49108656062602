import { Heading, Text } from "@chakra-ui/react"
import useStoreStripeConnectAccount from "hooks/useStoreStripeConnectAccount"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"

const StripeConnectSuccess = () => {
  const { clearStripeConnectAccount, isDone } = useStoreStripeConnectAccount()

  useEffect(() => {
    if (isDone) clearStripeConnectAccount()
  }, [isDone, clearStripeConnectAccount])

  if (isDone)
    return (
      <div>
        <Heading as="h1" color="tertiary.500">
          Félicitations !
        </Heading>
        <Text my={4}>Votre compte Stripe a été créé avec succès.</Text>
        <Text>
          Vous pouvez maintenant créer des téléconsultations et recevoir des
          paiements.
        </Text>
      </div>
    )

  return (
    <div>
      <Heading as="h1" color="tertiary.500">
        Votre compte Stripe n'a pas été créé
      </Heading>
      <Text my={4}>Veuillez réessayer de vous connecter à Stripe.</Text>
      <Link to="/teleconsultation">
        <u>Retourner à la téléconsultation</u>
      </Link>
    </div>
  )
}

export default StripeConnectSuccess

import React from "react"
import { SVGProps } from "../type"

const PathologieGenou: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="44"
      fill="none"
      viewBox="0 0 38 44"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M35 3.568c-10.4-3.6-26.333-.167-33 2 2.4 7.6 1 26.833 0 35.5 3.2-1.2 23.333-.5 33 0-2.8-6.8-1.167-15.834 0-19.5 2-6.4.833-14.667 0-18z"
      ></path>
    </svg>
  )
}

export default PathologieGenou

import { Flex, Text, FlexProps } from "@chakra-ui/react"
import React from "react"

interface IProps extends FlexProps {
  label: string
  value?: string
}

const ALabelValue: React.FC<IProps> = ({ label, value = "", ...props }) => {
  return (
    <Flex fontSize={14} {...props} lineHeight="18px">
      <Text fontWeight={700}>{label}</Text>
      <Text ml={1}>: {value}</Text>
    </Flex>
  )
}

export default ALabelValue

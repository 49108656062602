import { CheckIcon } from "@chakra-ui/icons"
import {
  Box,
  FormLabel,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  Flex,
  Button,
} from "@chakra-ui/react"
import React, { useId } from "react"

interface IProps {
  label: string
  subtitle: string
  value: number | undefined
  handleChange: React.Dispatch<React.SetStateAction<number | undefined>>
}

const ASlider: React.FC<IProps> = ({
  label,
  subtitle,
  value,
  handleChange,
}) => {
  const labelId = useId()

  const buttons = [
    { size: "32px", color: "danger.600", value: 9 },
    { size: "32px", color: "danger.600", value: 8 },
    { size: "28px", color: "danger.400", value: 7 },
    { size: "28px", color: "danger.400", value: 6 },
    { size: "24px", color: "warning.400", value: 5 },
    { size: "24px", color: "warning.400", value: 4 },
    { size: "28px", color: "valid.300", value: 3 },
    { size: "28px", color: "valid.300", value: 2 },
    { size: "32px", color: "valid.400", value: 1 },
    { size: "32px", color: "valid.400", value: 0 },
  ]

  return (
    <Flex direction="column" gap="12px" mb="24px">
      <Text zIndex={10} fontSize="18px">
        {subtitle}
      </Text>
      <Flex alignItems="center" gap="8px">
        {buttons
          .sort((a, b) => a.value - b.value)
          .map((button, index) => (
            <Button
              rounded="full"
              w={button.size}
              h={button.size}
              bgColor={button.color}
              onClick={() => handleChange(button.value)}
              key={index}
              p={0}
              minW={0}
              _hover={{
                opacity: 0.7,
              }}
              position="relative"
              opacity={value === undefined || value === button.value ? 1 : 0.4}
            >
              {value === button.value && (
                <CheckIcon boxSize="16px" position="absolute" color="white" />
              )}
            </Button>
          ))}
      </Flex>
    </Flex>
  )
}

export default ASlider

import {
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import ACenterAvatar from "components/AAvatar/ACenterAvatar"
import { RiPencilLine } from "react-icons/ri"

interface CentersProps {
  onEditCenter: (center: CenterType) => void
  list?: CenterType[]
}
const Centers = ({ onEditCenter, list }: CentersProps) => {
  return (
    <TableContainer>
      <Table size="lg" background="white" borderRadius={10}>
        <Thead>
          <Tr>
            <Th
              color="common.700"
              textTransform="none"
              fontFamily="Mulish"
              textAlign="left"
            >
              Avatar
            </Th>
            <Th
              color="common.700"
              textTransform="none"
              fontFamily="Mulish"
              textAlign="left"
            >
              Nom
            </Th>

            <Th
              color="common.700"
              textTransform="none"
              fontFamily="Mulish"
              textAlign="left"
            >
              Adresse
            </Th>
            <Th
              color="common.700"
              textTransform="none"
              fontFamily="Mulish"
              textAlign="left"
            >
              URL
            </Th>

            <Th
              color="common.700"
              textTransform="none"
              fontFamily="Mulish"
              textAlign="left"
              aria-label="Modifier"
            />
          </Tr>
        </Thead>
        <Tbody>
          {(list || []).map((center) => (
            <Tr key={center.id} border="none">
              <Td
                fontSize={14}
                border="none"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <ACenterAvatar avatar={center.avatar} />
              </Td>
              <Td fontSize={14} border="none" textAlign="left">
                {center.name}
              </Td>
              <Td fontSize={14} border="none" textAlign="left">
                {center.address?.name}
              </Td>
              <Td fontSize={14} border="none" textAlign="left">
                {center.url}
              </Td>

              <Td fontSize={14} border="none" textAlign="left">
                <IconButton
                  onClick={() => onEditCenter(center)}
                  variant="ghost"
                  aria-label="Modifier"
                  color="primary.500"
                  icon={<RiPencilLine />}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default Centers

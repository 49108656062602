import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Link,
  Text,
} from "@chakra-ui/react"
import React from "react"

interface IProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
}

const GianniModal = ({ isOpen, onClose, cancelRef }: IProps) => {
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent maxW={724} borderRadius={8} py={8}>
        <AlertDialogHeader fontSize={18}>Gianni Marangelli</AlertDialogHeader>
        <AlertDialogBody>
          <Link
            fontSize={15}
            as={"a"}
            href="http://osteopathes-lyon3.fr/gianni-marangelli/"
            target={"_blank"}
            textDecor={"underline"}
          >
            http://osteopathes-lyon3.fr/gianni-marangelli/
          </Link>
          <br />
          <Link
            fontSize={15}
            as={"a"}
            target="_blank"
            textDecor={"underline"}
            href="http://osteopathes-lyon3.fr/formations-osteopathie-pediatrique-torticolis-et-plagiocephalie/"
          >
            http://osteopathes-lyon3.fr/formations-osteopathie-pediatrique-torticolis-et-plagiocephalie/
          </Link>
          <Text fontWeight={"bold"} mt={4} mb={2} textDecor="underline">
            Références :
          </Text>
          <Text fontSize={15}>
            FOUBERT Marion, MARANGELLI Gianni, PLOTON Marie-Caroline.
            <br />
            Les déformations crâniennes positionnelles du nourrisson ; une prise
            en charge pluridisciplinaire, Issy les Moulineaux , Elsevier Masson,
            2022. Haute Autorité de Santé. Prévention des déformations
            crâniennes positionnelles (DCP) et mort inattendue du nourrisson ;
            2020. Mars, Disponible sur :<br />
            <Link
              as="a"
              target="_blank"
              href="https://www.has-sante.fr/jcms/p_3151574/fr/prevention-des-deformations-craniennes-positionnelles-dcp-et-mort-inattendue-du-nourrisson"
              textDecor={"underline"}
            >
              https://www.has-sante.fr/jcms/p_3151574/fr/prevention-des-deformations-craniennes-positionnelles-dcp-et-mort-inattendue-du-nourrisson
            </Link>
            <br />
            Persing J, James H, Swanson J, Kattwinkel J. Prevention and
            management of positional skull deformities in infants. American
            Academy of Pediatrics Committee on Practice and Ambulatory Medicine,
            Section on Plastic Surgery and Section on Neurological Surgery.
            Pediatrics 2003 ;112(1 Pt 1):199–202. Cummings C. Canadian
            Paediatric Society, Community Paediatrics Committee. Positional
            plagiocephaly Paediatrics & Child Health 2011 ;16(8):493–4.
            Organisation mondiale de la Santé. Lignes directrices sur l'activité
            physique, la sédentarité et le sommeil chez les enfants de moins de
            5 ans ; 2020. Forestier M. De la naissance aux premiers pas. 2e
            édition.Toulouse: Erès ; 2018. p. 320.
          </Text>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default GianniModal

import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import arrowBack from "../../../../../../assets/arrow_back.svg"
import React from "react"
import useJobIsKine from "hooks/useIsJobKine"

interface IProps {
  setStep?: React.Dispatch<React.SetStateAction<number>>
  onBack?: () => void
  reason?: "zones" | "pathologies" | null
  isForBaby?: boolean
}

const Header: React.FC<IProps> = ({
  setStep,
  onBack,
  reason,
  isForBaby = false,
}) => {
  const jobIsKine = useJobIsKine()
  const onReturn = () => {
    if (setStep) {
      if (jobIsKine) setStep(2)
      else if (isForBaby) setStep(0)
      else setStep(1)
    } else if (onBack) {
      onBack()
    }
  }

  return (
    <>
      <Flex w="full">
        <Box w={"full"} maxW={"360px"} pos="relative">
          <Flex
            mb={6}
            cursor="pointer"
            onClick={onReturn}
            p="8px"
            bgColor="primary.200"
            w="fit-content"
            display="block"
            rounded="9px"
            pos="sticky"
            top={4}
          >
            <Image src={arrowBack} width="24px" height="24px" />
          </Flex>
        </Box>
        <Flex direction={"column"} pt={"16px"} minW={"540px"}>
          {isForBaby ? (
            <Heading fontSize={26} color="tertiary.500" mb={6}>
              Contenu nourrisson
            </Heading>
          ) : (
            <>
              <Heading fontSize={26} color="tertiary.500" mb={6}>
                Conseiller du contenu Explorer
              </Heading>
              <Text fontSize="18px">
                Mettez en avant le contenu Explorer qui vous semble le plus
                pertinent pour votre patient. Notez qu'il pourra dans tous les
                cas retrouver la totalité du contenu sur son application
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default Header

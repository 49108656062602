import { AddIcon, EmailIcon } from "@chakra-ui/icons"
import { Flex, Heading } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import studentsQueryKeys from "constants/queryKeys/students"
import EditGlobal from "pages/Academy/components/EditGlobal"
import Security from "pages/Academy/components/Security"
import { academieQueryKeys } from "pages/Academy/constants/queryKeys"
import { useState } from "react"
import {
  AcademieDetailsType,
  getAcademie,
  getStudents,
} from "../../api/academie"
import { useAppContext } from "../../AppContext"
import AButton from "../../components/AButton"
import EditPlace from "./components/EditPlace"
import InviteStudent from "./components/InviteStudent"
import Places from "./components/Places"
import Students from "./components/Students"

const Academy = () => {
  const { currentScope } = useAppContext()
  const [placeEditing, setPlaceEditing] = useState<AcademieLocationType>()
  const [isEditPlaceOpen, setIsEditPlaceOpen] = useState<boolean>(false)
  const [isInviteStudentOpen, setIsInviteStudentOpen] = useState<boolean>(false)
  const [showDisabled, setShowDisabled] = useState<boolean>(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")

  const { data, isLoading } = useQuery<AcademieDetailsType>(
    academieQueryKeys.current(currentScope!.id),
    () => getAcademie({ academieId: currentScope?.id! }),
    { enabled: Boolean(currentScope?.id) }
  )
  // TODO: skeleton for academie loading
  const { data: students, isLoading: isLoadingStudents } = useQuery(
    studentsQueryKeys.list(currentScope?.id!, showDisabled, search, page),
    () =>
      getStudents({
        academieId: currentScope?.id!,
        showDisabled,
        search,
        page,
      }),
    { enabled: Boolean(currentScope?.id) }
  )

  const onEditPlace = (place: AcademieLocationType) => {
    setPlaceEditing(place)
    setIsEditPlaceOpen(true)
  }
  const onAddPlace = () => {
    setIsEditPlaceOpen(true)
  }
  const onCloseEditPlace = () => {
    setIsEditPlaceOpen(false)
    setPlaceEditing(undefined)
  }

  const onInviteStudent = () => {
    setIsInviteStudentOpen(true)
  }
  const onCloseInviteStudent = () => {
    setIsInviteStudentOpen(false)
  }

  const list = students?.result || []
  const pageCount = students?.count.totalPage || 0

  return (
    <Flex direction="column" alignItems="flex-start">
      <Heading as="h1" fontSize={28} color="tertiary.500" mb={10}>
        Mon académie
      </Heading>

      <EditGlobal academie={data} isLoading={isLoading} />
      <Heading as="h2" fontSize={18} mb={8} mt={10}>
        Lieux
      </Heading>
      <Flex direction="column" gap={4} mb={14} alignItems="flex-start">
        <AButton
          text="Ajouter un lieu"
          px={35}
          py={5}
          leftIcon={<AddIcon w={3} h={3} />}
          fontFamily="Montserrat"
          onClick={onAddPlace}
        />
        <EditPlace
          isOpen={isEditPlaceOpen}
          place={placeEditing}
          onClose={onCloseEditPlace}
        />
        <Places list={data?.locations} onEditPlace={onEditPlace} />
      </Flex>
      <Heading as="h2" fontSize={18} mb={8}>
        Étudiants
      </Heading>
      <Flex direction="column" gap={6} alignItems="flex-start" mb={14}>
        <AButton
          text="Inviter un étudiant"
          px={35}
          py={5}
          leftIcon={<EmailIcon w={4} h={4} />}
          fontFamily="Montserrat"
          onClick={onInviteStudent}
        />
        <InviteStudent
          isOpen={isInviteStudentOpen}
          onClose={onCloseInviteStudent}
        />
        <Students
          showDisabled={showDisabled}
          setShowDisabled={setShowDisabled}
          list={list}
          isLoading={isLoadingStudents}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          search={search}
          setSearch={setSearch}
        />
      </Flex>
      <Heading as="h2" fontSize={18} mb={8}>
        Sécurité
      </Heading>
      <Flex direction="column" alignItems="flex-start">
        <Security
          isLoading={isLoading}
          locationRestriction={data?.locationRestriction}
        />
      </Flex>
    </Flex>
  )
}

export default Academy

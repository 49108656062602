import { Flex, MenuItem, Text } from "@chakra-ui/react"
import React from "react"
import { useAppContext } from "../../../AppContext"
import { useNavigate } from "react-router-dom"
import ACenterAvatar from "components/AAvatar/ACenterAvatar"
import troncateString from "utils/troncateString"

interface IProps {
  center: CenterType
}

const ACenterItem: React.FC<IProps> = ({ center }) => {
  const { user, setCurrentScope, setCenterAdminCurrentScope } = useAppContext()
  const navigate = useNavigate()

  const chooseCurrentScope = () => {
    const centerGroupId = center?.centerGroup?.id
    const userIsCenterGroupAdmin = user?.osteo.adminCenterGroups?.some(
      ({ id }) => id === centerGroupId
    )
    if (centerGroupId && userIsCenterGroupAdmin) {
      return setCenterAdminCurrentScope({
        type: "center",
        id: center.id,
        centerGroupId,
      })
    }
    return setCurrentScope({ type: "center", id: center.id })
  }

  return (
    <MenuItem
      minH="48px"
      background="primary.300"
      _hover={{ background: "primary.400" }}
      onClick={() => {
        chooseCurrentScope()
        navigate("/dashboard")
      }}
    >
      <Flex alignItems="center">
        <ACenterAvatar isSmall avatar={center.avatar} />
        <Text ml={2} fontSize={16}>
          {troncateString(center.name, 15)}
        </Text>
      </Flex>
    </MenuItem>
  )
}

export default ACenterItem

import React, { useRef } from "react"
import { PatientType } from "../../../api/patient"
import ABox from "../../../components/ABox"
import AEditButton from "../../../components/AEditButton"
import ALabelValue from "../../../components/ALabelValue"
import ASkeleton from "../../../components/ASkeleton"
import { format } from "date-fns"
import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react"
import EditInfos from "./EditInfos"
import NewPassword from "./NewPassword"
import AButton from "components/AButton"
import getUtcDate from "utils/getUtcDate"

interface IProps {
  isLoading?: boolean
  patient?: PatientType
  setPatient?: React.Dispatch<React.SetStateAction<PatientType | undefined>>
  marginRight?: number
}

const Infos: React.FC<IProps> = ({
  isLoading = false,
  patient,
  setPatient,
  marginRight = 0,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenNewPassword,
    onOpen: onOpenNewPassword,
    onClose: onCloseNewPassword,
  } = useDisclosure()

  const cancelRef = useRef(null)
  return (
    <Box flex={1}>
      <ABox flex={1} mr={marginRight} pos="relative">
        {!isLoading && (
          <AEditButton pos="absolute" top={2} right={2} onClick={onOpen} />
        )}
        <ASkeleton isLoaded={!isLoading}>
          <ALabelValue
            label="Date de naissance"
            value={
              patient?.birthday
                ? format(getUtcDate(patient?.birthday), "dd/MM/yyyy")
                : ""
            }
            mb={3}
          />
        </ASkeleton>
        <ASkeleton isLoaded={!isLoading}>
          <ALabelValue label="Email" value={patient?.account?.email} mb={3} />
        </ASkeleton>
        <ASkeleton isLoaded={!isLoading}>
          <ALabelValue label="Téléphone" value={patient?.telephone} mb={3} />
        </ASkeleton>
        <ASkeleton isLoaded={!isLoading}>
          <ALabelValue label="Code postal" value={patient?.postalCode} />
        </ASkeleton>
        {!isLoading && (
          <Flex direction="column" mt={2} gap={3}>
            <Flex w={"full"} justify="flexStart">
              <AButton
                text="Renvoyer les identifiants"
                onClick={onOpenNewPassword}
                variant="custom"
                bg={"transparent"}
                color="primary.500"
                textDecor="underline"
                padding={0}
                height="auto"
                fontSize={14}
                _hover={{
                  bg: "transparent",
                }}
              />
            </Flex>
          </Flex>
        )}
      </ABox>
      <NewPassword
        onClose={onCloseNewPassword}
        isOpen={isOpenNewPassword}
        cancelRef={cancelRef}
        patient={patient}
      />
      <EditInfos
        onClose={onClose}
        isOpen={isOpen}
        cancelRef={cancelRef}
        patient={patient}
        setPatient={setPatient}
      />
    </Box>
  )
}

export default Infos

import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import { Flex, IconButton, Text } from "@chakra-ui/react"
import { MIN_SEATS } from "constants/subscription"
import { Dispatch, SetStateAction } from "react"

interface ASeatsButtonGroupProps {
  seats: number
  setSeats: Dispatch<SetStateAction<number>>
  minSeats?: number
}
const ASeatsButtonGroup = ({
  seats,
  setSeats,
  minSeats = MIN_SEATS,
}: ASeatsButtonGroupProps) => {
  const increment = () => {
    setSeats((prevSeats) => prevSeats + 1)
  }

  const decrement = () => {
    setSeats((prevSeats) => prevSeats - 1)
  }
  return (
    <Flex bg="white" borderRadius={9} align="center">
      <IconButton
        aria-label="Décrémenter"
        bg="transparent"
        isDisabled={seats <= minSeats}
        onClick={decrement}
        borderRadius="inherit"
        borderRightRadius={0}
      >
        <MinusIcon color="primary.500" />
      </IconButton>
      <Text
        align="center"
        h="100%"
        p={1}
        borderRadius={4}
        fontWeight={700}
        fontFamily="Mulish"
        fontSize={20}
        color="common.900"
        bg="common.200"
        minW={14}
      >
        {seats}
      </Text>
      <IconButton
        aria-label="Incrémenter"
        bg="transparent"
        onClick={increment}
        borderRadius="inherit"
        borderLeftRadius={0}
      >
        <AddIcon color="primary.500" />
      </IconButton>
    </Flex>
  )
}

export default ASeatsButtonGroup

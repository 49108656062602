import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import useToast from "../../../hooks/useToast"
import { useMutation } from "@tanstack/react-query"
import React, { useState } from "react"
import { PatientType, newTemporaryPassword } from "../../../api/patient"
import AButton from "../../../components/AButton"
import DisclosePatientTempPassword from "pages/Patient/components/DisclosePatientTempPassword"

interface IProps {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
  patient?: PatientType
  setPatient?: React.Dispatch<React.SetStateAction<PatientType | undefined>>
}

const NewPassword: React.FC<IProps> = ({
  isOpen,
  onClose,
  cancelRef,
  patient,
}) => {
  const newTemporaryPasswordMutation = useMutation(newTemporaryPassword)
  const toast = useToast()
  const [tempPassword, setTempPassword] = useState<string>("")
  const {
    isOpen: passwordModalIsOpen,
    onOpen: passwordModalOnOpen,
    onClose: passwordModalOnClose,
  } = useDisclosure()

  const onPatientSuccess = (password?: string) => {
    if (!password) {
      return
    }
    toast({
      status: "success",
      title: "Modifications enregistrées",
    })
    setTempPassword(password)
    passwordModalOnOpen()
  }

  const onSubmit = () => {
    if (!patient || !patient.id) return
    const id = patient.id.toString()
    if (patient.account?.passwordInitiated === true) {
      onClose()
      toast({
        status: "success",
        title: "Modifications enregistrées",
      })
      return
    }
    newTemporaryPasswordMutation.mutate(
      { id },
      {
        onSuccess: async (data) => {
          onClose()
          onPatientSuccess(data.temp_password)
        },
        onError: () => {
          toast({
            status: "error",
            title: "Une erreur s'est produite",
          })
        },
      }
    )
  }

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent maxW={500} borderRadius={8} p={6}>
          <AlertDialogHeader mb={3} p={0} fontSize={18}>
            Renvoyer les identifiants
          </AlertDialogHeader>
          <AlertDialogBody p={0}>
            Un mail sera envoyé au patient avec un nouveau mot de passe
            temporaire
          </AlertDialogBody>
          <AlertDialogFooter justifyContent="flex-end" p={0} mt={3}>
            <AButton
              ref={cancelRef}
              onClick={onClose}
              text="Annuler"
              variant="tertiary"
              py={3}
              px={27}
            />
            <AButton ml={3} text="Renvoyer" py={3} px={4} onClick={onSubmit} />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <DisclosePatientTempPassword
        patient={patient}
        onClose={passwordModalOnClose}
        isOpen={passwordModalIsOpen}
        password={tempPassword}
        isReinvitation={true}
      />
    </>
  )
}

export default NewPassword

import React from "react"
import { SVGProps } from "../type"

const TendiniteCoudeInterne: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="34"
      fill="none"
      viewBox="0 0 13 34"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M11.128 5.627c-.4-3.2-5-5.5-5-1.5 1 5.5-3 13.5-4 22-.8 6.8 2.334 6.167 4 5l2.5-7c1-4.833 2.9-15.3 2.5-18.5z"
      ></path>
    </svg>
  )
}

export default TendiniteCoudeInterne

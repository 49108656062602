import { parse } from "date-fns"

// CONSTANTS
const FORMATS = ["yyyy-MM-dd", "yyyy/MM/dd", "dd/MM/yyyy", "dd-MM-yyyy"]

// HELPERS
const isValidDate = (date: Date) => date.toString() !== "Invalid Date"

const parseBirthday = (stringBirthday: string) => {
  let i = 0
  let birthday
  do {
    birthday = parse(stringBirthday, FORMATS[i], new Date())
    i++
  } while (!isValidDate(birthday) && i < FORMATS.length)

  return birthday
}

export default parseBirthday

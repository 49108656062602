import { Box, BoxProps } from "@chakra-ui/react"
import React from "react"

interface IProps extends BoxProps {}

const ABox: React.FC<IProps> = ({ children, ...props }) => {
  return (
    <Box
      p={4}
      border="1px solid"
      borderColor="primary.500"
      borderRadius={9}
      {...props}
    >
      {children}
    </Box>
  )
}

export default ABox

import axiosInstance from "./axiosInstance"

export type CreateInvitationType = {
  osteoId: number
  patientEmail: string
  academieId?: number
  centerId?: number
}

export const createInvitation = async (data: CreateInvitationType) => {
  const response = await axiosInstance.post(`/osteo/me/invitation/new`, data)
  return response.data
}

export const acceptInvitation = async (token: string) => {
  const response = await axiosInstance.get(
    `/osteo/me/invitation/accept/${token}`
  )
  return response.data
}

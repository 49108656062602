import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react"
import { useRef, useState } from "react"
import AButton from "components/AButton"
import { useAppContext } from "AppContext"
import { StripeProductType } from "api/subscriptions"
import { getCenterPerSeatPrice } from "utils/price/getCenterPrice"
import formatPrice from "utils/price/formatPrice"
import ASeatsButtonGroup from "components/ASeatsButtonGroup"
import formatCurrency from "utils/price/formatCurrency"

interface CenterSubscribeDialogProps {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onSubscribe: (product: StripeProductType, seats: number) => void
  moveToAnnual?: () => void
  product: StripeProductType
}
const CenterSubscribeDialog = ({
  isOpen,
  isLoading,
  onClose,
  onSubscribe,
  moveToAnnual,
  product,
}: CenterSubscribeDialogProps) => {
  const [seats, setSeats] = useState<number>(2)

  const pricePerSeat = product ? getCenterPerSeatPrice(product) : 0

  const cancelRef = useRef(null)
  const { user } = useAppContext()
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="primary.100" minW="764">
          <AlertDialogHeader
            fontSize={22}
            fontWeight={700}
            fontFamily="Montserrat"
            color="tertiary.500"
            textAlign="center"
          >
            Combien de thérapeutes collaborent dans votre centre ?
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text
              fontSize={16}
              align="start"
              fontFamily="Montserrat"
              color="black"
            >
              Indiquez le nombre de thérapeutes qui utiliseront Andrew® Pro dans
              votre centre.
            </Text>
            <Text
              fontSize={16}
              align="start"
              fontFamily="Montserrat"
              color="black"
            >
              Vous pourrez modifier ce nombre et ajuster votre forfait à tout
              moment.
            </Text>
            <Flex mt={10} mb={4} justify="center">
              <ASeatsButtonGroup seats={seats} setSeats={setSeats} />
            </Flex>
            <Heading as="p" fontSize={18} fontWeight={700} textAlign="center">
              {/* we always want default price and currency */}
              Soit {formatPrice(seats * pricePerSeat)}{" "}
              {formatCurrency(product.price.currency)} / mois
            </Heading>
            {moveToAnnual ? (
              <Flex direction="column" align="center">
                <Text fontSize={16}>
                  <Text
                    as="span"
                    color="primary.500"
                    fontWeight={700}
                    fontFamily="Montserrat"
                    fontSize={18}
                  >
                    Économisez 300 € / an{" "}
                  </Text>
                  avec la facturation annuelle.
                </Text>
                <Link
                  as="button"
                  onClick={moveToAnnual}
                  textDecoration="underline"
                >
                  Passez en facturation annuelle
                </Link>
              </Flex>
            ) : (
              <Text fontSize={16} textAlign="center">
                facturé annuellement, soit{" "}
                {/* we always want default price and currency */}
                {formatPrice(seats * pricePerSeat * 12)}{" "}
                {formatCurrency(product.price.currency)} par an
              </Text>
            )}
          </AlertDialogBody>

          <AlertDialogFooter
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <AButton
              text={
                user?.osteo.sessionId ? "Choisir" : "Démarrer mon essai gratuit"
              }
              type="button"
              onClick={() => onSubscribe(product, seats)}
              isLoading={isLoading}
              variant="primary"
            />
            {!user?.osteo.sessionId && (
              <Flex justifyContent="center" mt={4}>
                <Text
                  maxW={404}
                  fontSize={14}
                  textAlign="center"
                  fontWeight={700}
                >
                  Vous ne serez débité•e qu&apos;à la fin de votre période
                  d&apos;essai.
                  <br /> Vous pourrez annuler sans frais à tout moment si vous
                  n&apos;êtes pas satisfait•e.
                </Text>
              </Flex>
            )}
            <AButton
              text="Annuler"
              ref={cancelRef}
              onClick={onClose}
              variant="tertiary"
              mt={8}
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default CenterSubscribeDialog

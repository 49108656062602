import { useAppContext } from "AppContext"
import getCurrentAcademy from "utils/getCurrentAcademy"

const useStudentsCannotAddPatient = () => {
  const { currentScope, user } = useAppContext()

  const currentAcademy = getCurrentAcademy({ currentScope, user })

  return currentAcademy?.studentsCanAddPatients === false
}

export default useStudentsCannotAddPatient

import { Flex, Link, Switch, Text } from "@chakra-ui/react"
import { useState } from "react"
import { Link as RouterLink } from "react-router-dom"

const RgpdCustomService = ({ service }) => {
  const [isAllowed, setIsAllowed] = useState(false)

  return (
    <Flex
      mb={"20px"}
      alignItems={"center"}
      justifyContent={"space-between"}
      bg={"white"}
      p={4}
      borderRadius={9}
    >
      <Flex direction={"column"}>
        <Flex alignItems={"center"}>
          <Text>{service.name}</Text>
          <Text color={"red"}>*</Text>
        </Flex>
        <Link
          as={RouterLink}
          href={"https://policies.google.com/privacy?hl=fr"}
          isExternal
          fontSize={"xs"}
          fontWeight={"bold"}
          textDecoration={"underline"}
          _hover={{ textDecoration: "underline", opacity: 0.7 }}
        >
          En savoir plus
        </Link>
      </Flex>

      <Flex alignItems={"center"}>
        <Text mr={4}>{isAllowed ? "Activé" : "Désactivé"}</Text>
        <Switch
          colorScheme={"orange"}
          onChange={() => {
            if (service.status === "denied") {
              service.status = "allowed"
              setIsAllowed(true)
            } else if (service.status === "allowed") {
              service.status = "denied"
              setIsAllowed(false)
            } else {
              service.status = "allowed"
              setIsAllowed(true)
            }
          }}
          //   _hover={{ cursor: "pointer" }}
          fontSize={34}
          //   as={
          //     service.status === "allowed"
          //       ? MdOutlineToggleOn
          //       : MdOutlineToggleOff
          //   }
          mr={"4%"}
        />
      </Flex>
    </Flex>
  )
}

export default RgpdCustomService

import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import AButton from "../../../../../../components/AButton"
import {
  SelectedExerciceType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import SelectedExerciceCard from "./components/SelectedExerciceCard"
import { TimeIcon } from "@chakra-ui/icons"
import { getTotalDuration } from "utils/getTotalDuration"
import CreateProgramModal from "pages/NewAppointment/components/SelectExercices/components/SelectedExercices/components/CreateProgramModal"
import SelectedUploadedExerciceCard from "pages/NewAppointment/components/SelectExercices/components/SelectedExercices/components/SelectedUploadedExerciceCard"
import useJobIsOsteo from "hooks/useJobIsOsteo"

interface IProps {
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  selectedUploadedExercices: SelectedUploadedExerciceType[]
  setSelectedUploadedExercices: React.Dispatch<
    React.SetStateAction<SelectedUploadedExerciceType[]>
  >
  selectedVideoOrder: SelectedVideoOrderType[]
  setSelectedVideoOrder: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
  setStep?: React.Dispatch<React.SetStateAction<number>>
  onNext?: () => void
  isCreatingProgram?: boolean
  isEditingAppointment?: boolean
  showOnlyDuration?: boolean
}

const SelectedExercices: React.FC<IProps> = ({
  selectedExercices,
  setSelectedExercices,
  selectedUploadedExercices,
  setSelectedUploadedExercices,
  selectedVideoOrder,
  setSelectedVideoOrder,
  setStep,
  onNext,
  isCreatingProgram = false,
  isEditingAppointment = false,
  showOnlyDuration = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isJobOsteo = useJobIsOsteo()
  const onNextOrStepOver = () => {
    if (isCreatingProgram) {
      setIsModalOpen(true)
      return
    }
    if (setStep || isCreatingProgram) {
      if (setStep) setStep(2)
    } else if (onNext) {
      onNext()
    }
  }
  const textButton = setStep
    ? "Suivant"
    : isCreatingProgram
    ? "Créer le programme"
    : "Enregistrer les modifications"

  return (
    <div>
      {isCreatingProgram && isModalOpen && (
        <CreateProgramModal
          selectedExercices={selectedExercices}
          selectedUploadedExercices={selectedUploadedExercices}
          selectedExercicesOrder={selectedVideoOrder}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <Flex
        pos="sticky"
        top={4}
        maxH={"calc(100vh - 110px)"}
        w="320px"
        px={1}
        flexDirection="column"
        gap={4}
      >
        {!isEditingAppointment && (
          <Box
            w="full"
            h="100vh"
            pos="absolute"
            top="-100px"
            borderLeft="1px solid"
            borderColor="primary.300"
            left={-4}
            pointerEvents="none"
          />
        )}

        <Flex justifyContent="space-between">
          {showOnlyDuration ? (
            <Heading as={"h2"} fontSize="16px">
              Contenu du programme
            </Heading>
          ) : (
            <Heading as={"h2"} fontSize="16px">
              Contenu du traitement
            </Heading>
          )}

          {selectedExercices.length > 0 && showOnlyDuration && (
            <Flex
              alignItems="center"
              gap={1}
              fontSize="14px"
              lineHeight="100%"
              bg="common.200"
              border="1px solid"
              borderColor="common.300"
              rounded="24px"
              py="4px"
              px="6px"
            >
              <TimeIcon />
              <Text>{getTotalDuration(selectedExercices)}</Text>
            </Flex>
          )}
        </Flex>

        {selectedVideoOrder.length > 4 && isJobOsteo && (
          <Box p={4} bg="secondary.400" borderRadius={9} w="100%" left={0}>
            <Text fontSize={14}>
              Nous vous recommandons de limiter la sélection à 4 exercices. Cela
              optimise les chances de voir votre patient réaliser réellement sa
              séance
            </Text>
          </Box>
        )}

        <Flex
          flexDir="column"
          gap={2}
          as="ul"
          aria-label="Liste des vidéos sélectionnées"
          overflowY="scroll"
          maxH="calc(100vh - 110px)"
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "primary.300",
              borderRadius: "24px",
            },
          }}
        >
          {selectedVideoOrder.length > 0 ? (
            <>
              {selectedVideoOrder.map((selectedVideoOrder, i) => {
                if (selectedVideoOrder.type === "video") {
                  const selectedExercice = selectedExercices.find(
                    (exercice) => exercice.id === selectedVideoOrder.id
                  )
                  if (!selectedExercice) {
                    return null
                  }
                  return (
                    <SelectedExerciceCard
                      key={`${i}_${selectedExercice.id}`}
                      selectedExercice={selectedExercice}
                      setSelectedExercices={setSelectedExercices}
                      setSelectedVideoOrder={setSelectedVideoOrder}
                      position={i + 1}
                      size={selectedExercices.length}
                      showOnlyDuration={showOnlyDuration}
                    />
                  )
                }
                if (selectedVideoOrder.type === "uploaded_video") {
                  const selectedUploadedExercice =
                    selectedUploadedExercices.find(
                      (exercice) => exercice.id === selectedVideoOrder.id
                    )
                  if (!selectedUploadedExercice) {
                    return null
                  }
                  return (
                    <SelectedUploadedExerciceCard
                      key={`${i}_${selectedUploadedExercice.id}`}
                      selectedUploadedExercice={selectedUploadedExercice}
                      setSelectedUploadedExercices={
                        setSelectedUploadedExercices
                      }
                      setSelectedVideoOrder={setSelectedVideoOrder}
                      position={i + 1}
                      size={selectedUploadedExercices.length}
                    />
                  )
                }
              })}
            </>
          ) : (
            <Box
              p={"8px"}
              borderRadius={9}
              bg={"primary.200"}
              opacity={"1"}
              fontSize={14}
            >
              <Text fontWeight="bold">Aucune vidéo</Text>
              <Text>
                Cliquez sur les videos pour les ajouter dans la liste du
                programme, ou sélectionnez directement un de vos programmes.
              </Text>
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={90}
      >
        <AButton
          text={textButton}
          px={120}
          py={3}
          fontFamily="Montserrat"
          onClick={onNextOrStepOver}
        />
      </Flex>
    </div>
  )
}

export default SelectedExercices

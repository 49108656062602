import { TrainingVideoGroupsType } from "api/appointmentReason"
import axiosInstance from "api/axiosInstance"

type LikeTrainingVideoGroup = {
  id: number
  like: boolean
}

export const like = async (params: LikeTrainingVideoGroup) => {
  const response = await axiosInstance.post<TrainingVideoGroupsType>(
    `training-video-group/like`,
    params
  )
  return response.data
}

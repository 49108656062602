import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { useMutation } from "@tanstack/react-query"
import {
  SelectedExerciceType,
  SelectedUploadedExerciceType,
  SelectedVideoOrderType,
} from "api/appointmentReason"
import { createProgram } from "api/program"
import AAutocompleteMultiple from "components/AAutocompleteMultiple/AAutocompleteMultiple"
import AButton from "components/AButton"
import useJobIsKine from "hooks/useIsJobKine"
import {
  getZones,
  PATHOLOGIES_MOCK,
  ZonePathologieType,
} from "pages/NewAppointment/newAppointment.mock"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
interface CreateProgramModalProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedExercices: SelectedExerciceType[]
  selectedUploadedExercices: SelectedUploadedExerciceType[]
  selectedExercicesOrder: SelectedVideoOrderType[]
}

const renderItem = ({ name }: ZonePathologieType) => name

const getKey = ({ id }: ZonePathologieType) => id.toString()

const LEVELS = ["Facile", "Moyen", "Difficile"]

const CreateProgramModal: FC<CreateProgramModalProps> = ({
  setIsModalOpen,
  selectedExercices,
  selectedUploadedExercices,
  selectedExercicesOrder,
}) => {
  const [level, setLevel] = useState(1)
  const [input, setInput] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedItems, setSelectedItems] = useState<ZonePathologieType[]>([])
  const isJobKine = useJobIsKine()
  const navigate = useNavigate()

  const createProgramMutation = useMutation(createProgram)

  const handleClick = async () => {
    if (
      selectedExercicesOrder &&
      selectedExercicesOrder.length > 1 &&
      input !== ""
    ) {
      setIsSubmitting(true)

      createProgramMutation.mutate(
        {
          program: {
            appointmentReasons: selectedItems.map(({ id }) => id),
            level,
            title: input,
          },
          videoPrograms: selectedExercices.map((exercice) => ({
            ...exercice,
            weight: exercice.canUseWeight ? exercice.weight : null,
          })),
          videoUploadedPrograms: selectedUploadedExercices.map((exercice) => ({
            ...exercice,
            weight: exercice.canUseWeight ? exercice.weight : null,
          })),
          videoOrder: selectedExercicesOrder,
        },
        {
          onSuccess: () => {
            navigate(`/exercices/`)
            setIsSubmitting(false)
          },
          onError: () => {
            setIsSubmitting(false)
          },
        }
      )
    }
  }

  const firstExerciceOrder = selectedExercicesOrder[0]
  const getFirstExercice = () => {
    if (firstExerciceOrder.type === "uploaded_video") {
      return selectedUploadedExercices.find(
        (exercice) => exercice.id === firstExerciceOrder.id
      )
    }
    return selectedExercices.find(
      (exercice) => exercice.id === firstExerciceOrder.id
    )
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top="0px"
      left="0px"
      width="full"
      height="100vh"
      bg="rgba(0, 0, 0, 0.4)"
      zIndex={100}
    >
      <Flex
        direction="column"
        w="432px"
        bg="white"
        rounded="8px"
        // overflow="hidden"
      >
        <Box bg="common.300" w="full" h="206px" position="relative">
          {selectedExercicesOrder.length > 0 && (
            <Box>
              {selectedExercicesOrder[0].type === "uploaded_video" ? (
                <Image
                  src={
                    (getFirstExercice() as SelectedUploadedExerciceType)
                      .bunnyCDN.webp
                  }
                  h="206px"
                  w="full"
                  objectFit="cover"
                />
              ) : (
                <Image
                  src={
                    (getFirstExercice() as SelectedExerciceType).bunnyCDN
                      ?.previewWebP
                  }
                  h="206px"
                  w="full"
                  objectFit="cover"
                />
              )}
            </Box>
          )}
          <Flex
            position="absolute"
            bottom="16px"
            left="16px"
            gap="8px"
            overflowX="scroll"
          >
            {selectedExercicesOrder.map((selectedExercicesOrder, index) => {
              if (index === 0) return

              if (selectedExercicesOrder.type === "uploaded_video") {
                const exercice = selectedUploadedExercices.find(
                  (exercice) => exercice.id === selectedExercicesOrder.id
                )
                if (exercice)
                  return (
                    <Image
                      src={exercice.bunnyCDN.preview}
                      width="64px"
                      height="40px"
                      objectFit="cover"
                      rounded="4px"
                      border="1px solid"
                      borderColor="white"
                      key={index}
                    />
                  )
              }

              if (selectedExercicesOrder.type === "video") {
                const exercice = selectedExercices.find(
                  (exercice) => exercice.id === selectedExercicesOrder.id
                )
                if (exercice)
                  return (
                    <Image
                      src={exercice.bunnyCDN?.preview}
                      width="64px"
                      height="40px"
                      objectFit="cover"
                      rounded="4px"
                      border="1px solid"
                      borderColor="white"
                      key={index}
                    />
                  )
              }
            })}
          </Flex>
        </Box>
        <Image />
        <Flex direction="column" p="16px" gap="24px">
          {selectedExercicesOrder.length > 1 && (
            <>
              <Input
                placeholder="Titre du programme"
                border="none"
                p={0}
                fontSize="18px"
                fontWeight={700}
                _focusVisible={{ border: "none" }}
                onChange={(evt) => setInput(evt.target.value)}
                value={input}
              />
              <Flex direction="column" gap="4px">
                <Text fontSize="14px" fontWeight={700}>
                  Zones / Symptomatologies
                </Text>
                <Flex>
                  <AAutocompleteMultiple
                    bg="white"
                    border="1px solid black"
                    width="100%"
                    placeholder="Sélectionner..."
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    items={[...getZones(isJobKine), ...PATHOLOGIES_MOCK]}
                    renderItem={renderItem}
                    inputValue=""
                    getKey={getKey}
                    isLoading={false}
                  />
                </Flex>
              </Flex>

              <Flex direction="column" gap="4px">
                <Text fontSize="14px" fontWeight={700}>
                  Niveau
                </Text>
                <Menu>
                  <MenuButton
                    w="fit-content"
                    px="16px"
                    py="8px"
                    border="1px solid"
                    rounded="4px"
                  >
                    <Flex alignItems="center" gap="6px">
                      <Text fontSize="14px">{LEVELS[level - 1]}</Text>
                      <ChevronDownIcon boxSize="20px" />
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    {LEVELS.map((currLevel, index) => (
                      <MenuItem
                        key={index + 1}
                        onClick={() => setLevel(index + 1)}
                      >
                        {currLevel}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
            </>
          )}
          {selectedExercicesOrder && selectedExercicesOrder.length < 2 && (
            <Text>
              Sélectionnez au moins deux exercices pour créer un programme.
            </Text>
          )}
          {selectedItems && selectedItems.length < 1 ? (
            <Text>Séléctionnez au moins une zone ou symptomatologie.</Text>
          ) : input === "" ? (
            <Text>Veuillez choisir un titre</Text>
          ) : (
            ""
          )}

          <Flex justifyContent="end" gap="16px">
            <AButton
              text="Annuler"
              variant="tertiary"
              onClick={() => {
                setIsModalOpen(false)
              }}
            />
            {selectedItems.length > 0 &&
              selectedExercicesOrder.length > 1 &&
              input !== "" && (
                <AButton
                  text="Enregistrer"
                  onClick={() => handleClick()}
                  isLoading={isSubmitting}
                />
              )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CreateProgramModal

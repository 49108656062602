const getMonthsAbrev = (data: { yearMonth: string; value: number }[]) => {
  const monthsMap: { [key: string]: string } = {
    "01": "Jan",
    "02": "Fev",
    "03": "Mar",
    "04": "Avr",
    "05": "Mai",
    "06": "Jui",
    "07": "Jui",
    "08": "Aou",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  }

  return data.map((item) => {
    const [year, month] = item.yearMonth.split("-")
    return `${monthsMap[month]} ${year}` || ""
  })
}
export default getMonthsAbrev

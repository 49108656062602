import useToast from "hooks/useToast"

const useCopy = (text: string) => {
  const toast = useToast()

  const copy = () => {
    navigator.clipboard.writeText(text)
    toast({
      status: "success",
      title: "Copié",
    })
  }

  return copy
}

export default useCopy

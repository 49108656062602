import { Flex, useDisclosure } from "@chakra-ui/react"
import { useAppContext } from "../../AppContext"
import userHasAcademiesOrCenters from "utils/user/userHasAcademiesOrCenters"
import ThreeParty from "pages/CGS/components/ThreeParty"
import TwoParty from "pages/CGS/components/TwoParty"
import AButton from "components/AButton"
import { OsteoResponseType, updateOsteo } from "api/osteo"
import { useRef } from "react"
import { useInView } from "framer-motion"
import CGSModal from "pages/CGS/components/CGSModal"
import useHasAcceptedCGS from "hooks/useHasAcceptedCGS"
import { useMutation } from "@tanstack/react-query"
import useToast from "hooks/useToast"
import { UserOsteoType } from "api/user"
import { useNavigate } from "react-router-dom"

const CGS = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { user, setUser } = useAppContext()
  const updateOsteoMutation = useMutation(updateOsteo)
  const hasAcceptedCGS = useHasAcceptedCGS()
  const acceptedCGSRef = useRef(null)
  const isInView = useInView(acceptedCGSRef, { once: false })
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  if (!user) return null
  const scrollToEnd = () => {
    const endElement = document.getElementById("end")

    if (endElement) {
      endElement.scrollIntoView({ behavior: "smooth" })
    }
  }

  const acceptCGS = () => {
    updateOsteoMutation.mutate(
      {
        id: user?.osteo?.id?.toString(),
        data: {
          hasAcceptedCGS: true,
        },
      },
      {
        onSuccess: (data: OsteoResponseType) => {
          onClose()
          setUser(
            (prev) =>
              prev && {
                ...prev,
                osteo: {
                  ...prev.osteo,
                  ...formatData(data),
                },
              }
          )
          toast({
            status: "success",
            title: "Modifications enregistrées",
          })
          //redirects to home page to skip CGS page
          navigate("/")
        },
        onError: () => {
          toast({
            status: "error",
            title: "Une erreur s'est produite",
          })
        },
      }
    )
  }

  const formatData = (data: OsteoResponseType): UserOsteoType => ({
    ...data,
    avatar: { url: data.avatar?.data?.attributes?.url },
  })

  return (
    <Flex justify="center" w="full" align="center">
      <Flex
        direction="column"
        w="full"
        px={6}
        align="stretch"
        mt={20}
        position="relative"
      >
        {userHasAcademiesOrCenters(user) ? <ThreeParty /> : <TwoParty />}
        {!isInView && (
          <Flex
            position={"fixed"}
            bottom={"0"}
            justify="center"
            py={14}
            w="full"
          >
            <AButton
              text="Aller en fin de page"
              onClick={scrollToEnd}
              mr={10}
            />
          </Flex>
        )}
        <Flex
          id="end"
          ref={acceptedCGSRef}
          py={10}
          justify={"center"}
          w={"full"}
        >
          {!hasAcceptedCGS && (
            <Flex justify={"center"} mb={10}>
              <AButton
                text="Accepter les CGS"
                variant="secondary"
                onClick={acceptCGS}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      {!hasAcceptedCGS && isOpen && (
        <CGSModal isOpen={isOpen} onClose={onClose} acceptCGS={acceptCGS} />
      )}
    </Flex>
  )
}
export default CGS

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
} from "@chakra-ui/react"
import ASelectColor from "components/ASelectColor/ASelectColor"
import { useField } from "formik"
import { ComponentProps } from "react"

interface ASelectColorFieldProps
  extends Omit<ComponentProps<typeof ASelectColor>, "setValue" | "value"> {
  name: string
  isRequired?: FormControlProps["isRequired"]
}
const ASelectColorField = ({
  name,
  isRequired,
  ...props
}: ASelectColorFieldProps) => {
  const [field, { error, touched }, { setValue }] = useField<string>({ name })

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={error !== undefined && touched}
    >
      <ASelectColor {...field} {...props} setValue={setValue} />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default ASelectColorField

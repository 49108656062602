import EditIcon from "assets/edit.svg"
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
} from "@chakra-ui/react"
import { useField } from "formik"
import { ReactNode, useEffect, useRef, useState } from "react"
import ACenterAvatar from "components/AAvatar/ACenterAvatar"

interface ACenterAvatarFieldProps {
  name: string
  label?: ReactNode
  avatar?: AvatarType
}
const ACenterAvatarField = ({
  name,
  label = "Avatar",
  avatar,
}: ACenterAvatarFieldProps) => {
  const [{ value }, { error }, { setValue }] = useField<File>({
    name,
    type: "file",
  })
  const inputRef = useRef<HTMLInputElement>(null)

  const [preview, setPreview] = useState<string | undefined>()

  useEffect(() => {
    if (!(value instanceof File)) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(value)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl)
      setPreview(undefined)
    }
  }, [value])

  return (
    <FormControl isInvalid={error !== undefined}>
      <FormLabel mb="8px">{label}</FormLabel>
      <Flex alignItems="center" mb={8}>
        <Box pos="relative" w={86}>
          {preview ? (
            <Image
              src={preview}
              w={86}
              h={86}
              borderRadius="50%"
              objectFit="cover"
              alt="Avatar du centre"
            />
          ) : (
            <ACenterAvatar avatar={avatar} w={86} h={86} />
          )}
          <Flex
            bg="secondary.500"
            w={10}
            h={10}
            borderRadius="50%"
            pos="absolute"
            bottom={-2}
            right={-2}
            onClick={() => {
              if (!inputRef || !inputRef.current) return
              inputRef.current.click()
            }}
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
          >
            <Image src={EditIcon} />
          </Flex>
        </Box>
        <input
          id="avatar"
          name="avatar"
          type="file"
          accept="image/*"
          onChange={(e) => {
            if (!e.currentTarget?.files || !e.currentTarget?.files[0]) return

            setValue(e.currentTarget?.files && e.currentTarget?.files[0])
          }}
          ref={inputRef}
          style={{ display: "none" }}
        />
      </Flex>

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default ACenterAvatarField

import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { MediaType } from "api/media"
import { PatientAppointmentType } from "api/patient"
import ADiscoverCardBase from "components/ADiscoverCard/Base"
import AEditButton from "components/AEditButton"
import React from "react"
import { useNavigate } from "react-router-dom"

interface IProps {
  selectedMedias: MediaType[] | []
  appointment: PatientAppointmentType
  patientId: string | undefined
}

const AppointmentMedias: React.FC<IProps> = ({
  selectedMedias,
  appointment,
  patientId,
}) => {
  const navigate = useNavigate()

  const onEdit = () => {
    navigate(
      `/patients/${patientId}/appointments/${appointment.id}/edit-explorer`
    )
  }

  return (
    <Box mb={6}>
      <Box mt={6} p={6} bg="white" borderRadius={9} position="relative">
        <AEditButton
          pos="absolute"
          top={2}
          right={2}
          onClick={onEdit}
          ariaLabel="Modifier les contenus Explorer sélectionnés"
        />
        <Heading as="h3" fontSize={18} mb={5}>
          Contenu Explorer sélectionnés
        </Heading>
        <Flex direction={"row"} gap={"16px"} flexWrap="wrap">
          {selectedMedias.length > 0 &&
            selectedMedias.map((media, index) => (
              <ADiscoverCardBase
                media={media}
                onAppointmentRecap={true}
                key={index}
              />
            ))}
          {selectedMedias.length === 0 && (
            <Text>Aucun contenu Explorer sélectionné</Text>
          )}
        </Flex>
      </Box>
    </Box>
  )
}

export default AppointmentMedias

import React from "react"
import { SVGProps } from "../type"

const SacroIliaque: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="23"
      fill="none"
      viewBox="0 0 55 23"
    >
      <rect
        width="18.31"
        height="8.56"
        x="8.531"
        y="1.634"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        fillOpacity="0.6"
        strokeWidth="3"
        rx="2"
        transform="rotate(54.062 8.531 1.634)"
      ></rect>
      <rect
        width="18.31"
        height="8.56"
        x="53.453"
        y="6.868"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        fillOpacity="0.6"
        strokeWidth="3"
        rx="2"
        transform="rotate(124.658 53.453 6.868)"
      ></rect>
    </svg>
  )
}

export default SacroIliaque

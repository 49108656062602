import {
  Box,
  Center,
  Flex,
  FormControl,
  Heading,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  checkTeleconsultation,
  getPatientTeleconsultationById,
} from "api/teleconsultation"
import AButton from "components/AButton"
import { Field, Form, Formik } from "formik"
import useToast from "hooks/useToast"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

const PatientTeleconsultation = () => {
  const { id } = useParams()
  const [isValid, setIsValid] = useState(false)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [displayname, setDisplayname] = useState("")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const checkTeleconsultationMutation = useMutation({
    mutationFn: (data: { name: string; consent: boolean }) =>
      checkTeleconsultation(id!, data),
    onSuccess: ({ success, displayname }) => {
      if (success) {
        setDisplayname(displayname)
        onClose()
        toast({
          status: "success",
          title: "Vous avez été ajouté à la téléconsultation",
        })
      } else {
        toast({
          status: "error",
          title: "Nom incorrect",
        })
      }
      setIsValid(success)
    },
  })

  const { data: teleconsultation } = useQuery({
    queryKey: ["getPatientTeleconsultationById", id],
    queryFn: () => getPatientTeleconsultationById(id!),
    enabled: !!id,
  })

  useEffect(() => {
    if (!isValid && teleconsultation) onOpen()
  }, [!isValid && teleconsultation])

  useEffect(() => {
    if (isValid && displayname !== "" && !scriptLoaded) {
      const script = document.createElement("script")
      script.src = "/linkello/linkello.loader-1.0.0.js"
      document.body.appendChild(script)
      setScriptLoaded(true)
    }
  }, [isValid, displayname, scriptLoaded])

  if (!teleconsultation)
    return (
      <Center>
        <Flex direction={"column"} gap={4} mt={10}>
          <Heading color="tertiary.500" as="h1">
            Teleconsultation introuvable
          </Heading>
          <Text>
            La teleconsultation que vous recherchez n'existe pas ou n'est pas
            accessible.
          </Text>
        </Flex>
      </Center>
    )

  const onSubmit = async (
    values: { name: string; consent: boolean },
    { setSubmitting }: { setSubmitting: (submitting: boolean) => void }
  ) => {
    await checkTeleconsultationMutation.mutate(values)
    setSubmitting(false)
  }

  return (
    <div>
      <Box
        w={"100%"}
        h={"100vh"}
        id="root"
        data-app={process.env.REACT_APP_LINKELLO_APP_ID}
        data-key={teleconsultation.linkelloKey}
        data-name={displayname}
        data-version="1.4.1"
        data-language="fr"
        data-signature={teleconsultation.linkelloSignature}
        data-exit-url="/"
        data-chat="true"
        data-domain="api.linkello.com"
        data-logo-url="/andrew.png"
      ></Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w={"480px"} maxW={"480px"} mx={"16px"}>
          <ModalHeader>
            <Text fontWeight={"bold"} fontSize="18px" mb={4}>
              Bienvenue
            </Text>
            <Text fontWeight={"normal"} fontSize="16px">
              Vous êtes sur le point de rejoindre une téléconsultation. Veuillez
              saisir les 3 premières lettres de votre nom de famille pour
              continuer
            </Text>
            <Formik
              initialValues={{ name: "", consent: false }}
              onSubmit={onSubmit}
              validationSchema={Yup.object().shape({
                name: Yup.string().required().min(3),
                consent: Yup.boolean().oneOf([true], "Vous devez accepter"),
              })}
            >
              {({ isSubmitting, setFieldValue, isValid: isFormValid }) => (
                <Form>
                  <Field name="name">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <Input
                          {...field}
                          placeholder="___"
                          bg="common.100"
                          type="text"
                          w={"85px"}
                          ml={`calc(50% - ${77 / 2}px)`}
                          my={8}
                          letterSpacing={"4px"}
                          textTransform={"uppercase"}
                          maxLength={3}
                          textAlign={"center"}
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="consent">
                    {({ field, form }: { field: any; form: any }) => (
                      <Flex
                        alignItems={"center"}
                        justify={"center"}
                        my={4}
                        gap={2}
                      >
                        <Switch {...field} colorScheme="orange" />
                        <Text fontSize={"18px"} fontWeight={"normal"}>
                          Je consens à participer à la téléconsultation
                        </Text>
                      </Flex>
                    )}
                  </Field>
                  <AButton
                    type="submit"
                    isLoading={isSubmitting}
                    w={"100%"}
                    mt={4}
                    text="Rejoindre"
                    isDisabled={!isFormValid}
                  />
                </Form>
              )}
            </Formik>
          </ModalHeader>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default PatientTeleconsultation

import { Box, Flex, Heading } from "@chakra-ui/react"
import React from "react"
import ASelectableButton from "../../../../../components/ASelectableButton"
import { BabyMediaTag } from "api/media"

interface IProps {
  reason: "zones" | "pathologies" | null
  setReason: (reason: "zones" | "pathologies" | null) => void
  isBabySwitchOn: boolean
}

const Reason: React.FC<IProps> = ({ reason, setReason, isBabySwitchOn }) => {
  return (
    <Box mb={8}>
      {isBabySwitchOn ? (
        <></>
      ) : (
        <>
          <Flex gap={6} w="320px" p="4px" bg="primary.300" rounded="8px">
            <ASelectableButton
              text="Zones"
              size="lg"
              state={reason === "zones" ? "active" : "default"}
              onClick={() => setReason("zones")}
            />
            <ASelectableButton
              text="Symptomatologies"
              size="lg"
              state={reason === "pathologies" ? "active" : "default"}
              onClick={() => setReason("pathologies")}
            />
          </Flex>
        </>
      )}
    </Box>
  )
}

export default Reason

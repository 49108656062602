import { AddIcon, DeleteIcon } from "@chakra-ui/icons"
import { Flex, Text, Checkbox, IconButton } from "@chakra-ui/react"
import React from "react"
import { TraininScheduleTimeType, TraininScheduleType } from "api/patient"
import { v4 as uuidv4 } from "uuid"
import AButton from "components/AButton"
import { CopyIcon } from "@chakra-ui/icons"
import DuplicateDayPopover from "pages/NewAppointment/components/SelectSchedule/components/DuplicateDayPopover/DuplicateDayPopover"
import { Form, Formik } from "formik"
import ATimePickerField from "components/Field/ATimePickerField"

interface IProps {
  day: {
    label: string
    value: number
  }
  state: "default" | "selected"
  setTrainingSchedule: React.Dispatch<
    React.SetStateAction<TraininScheduleType[]>
  >
  times: TraininScheduleTimeType[]
}

const theme = {
  default: {
    bg: "white",
    fontWeight: 400,
    fontSize: 14,
    borderColor: "white",
    checkboxBorderColor: "common.400",
    checkboxBg: "white",
  },
  selected: {
    bg: "primary.200",
    fontWeight: 700,
    fontSize: 16,
    borderColor: "primary.500",
    checkboxBorderColor: "primary.800",
    checkboxBg: "primary.500",
  },
}

const INITIAL_NEW_TIME = "00:00"
const INITIAL_VALUES = {
  time: INITIAL_NEW_TIME,
}

// HELPERS
const areTimesIdentical = (
  a: TraininScheduleTimeType,
  b: TraininScheduleTimeType
) => {
  return a.hour === b.hour && a.minute === b.minute
}

// COMPONENTS
const ScheduleDay: React.FC<IProps> = ({
  day,
  state,
  setTrainingSchedule,
  times,
}) => {
  const [isEditing, setIsEditing] = React.useState(false)

  const handleRemoveTime = (timeId: string) => {
    setTrainingSchedule((prev) =>
      prev
        .filter((schedule) => {
          const cond1 = schedule.weekday === day.value
          const cond2 = schedule.times.length === 1
          if (cond1) {
            if (cond2) {
              return false
            }
          }
          return true
        })
        .map((schedule) =>
          schedule.weekday === day.value
            ? {
                ...schedule,
                times: schedule.times.filter((time) => time.id !== timeId),
              }
            : schedule
        )
    )
  }

  const handleState = () => {
    if (state === "default") {
      setTrainingSchedule((prev) => [
        ...prev,
        {
          weekday: day.value,
          times: [],
        },
      ])
      setIsEditing(true)
    } else {
      setTrainingSchedule((prev) =>
        prev.filter((schedule) => schedule.weekday !== day.value)
      )
    }
  }

  const setNewTimeOrHandleState = () => {
    if (state === "selected") {
      setIsEditing(true)
      return
    }
    handleState()
  }

  const onDuplicate = (targets: number[]) => {
    if (times.length === 0) {
      return
    }
    setTrainingSchedule((prev) => [
      ...prev.filter((schedule) => !targets.includes(schedule.weekday)),
      ...targets.map((weekdayId) => ({
        weekday: weekdayId,
        times: times.map((time) => ({
          ...time,
          id: uuidv4(),
        })),
      })),
    ])
  }

  return (
    <div>
      <Flex
        alignItems="center"
        justify="space-between"
        bg={theme[state].bg}
        border="2px solid"
        borderColor={theme[state].borderColor}
        py={3.5}
        px={3}
        borderRadius={6}
        mb={2}
        mt={4}
      >
        <Flex alignItems="center" w={28}>
          <Checkbox
            w={18}
            h={18}
            mr={3}
            colorScheme="orange"
            borderColor="primary.500"
            onChange={handleState}
            isChecked={state === "selected"}
          />
          <Text
            fontSize={theme[state].fontSize}
            fontWeight={theme[state].fontWeight}
          >
            {day.label}
          </Text>
        </Flex>
        <Flex>
          {state === "default" ? (
            <Text>Indisponible</Text>
          ) : (
            <Flex direction="column" w={72} alignItems="end" gap={2}>
              {times.map((time) => (
                <Flex
                  alignItems="center"
                  bg="primary.400"
                  w="full"
                  justify="end"
                  borderRadius="10px"
                  py={2}
                  key={time.id}
                >
                  <Text
                    key={time.id}
                    borderRadius={9}
                    px={2}
                    py={1}
                    fontWeight="semibold"
                  >
                    {time.hour.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                    :
                    {time.minute.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                  </Text>
                  <Flex justify="center">
                    <IconButton
                      aria-label="Supprimer un créneau"
                      onClick={() => handleRemoveTime(time.id)}
                      ml={16}
                      mr={10}
                      _hover={{ bg: "none" }}
                      background="none"
                      icon={<DeleteIcon />}
                    />
                  </Flex>
                </Flex>
              ))}

              {isEditing && (
                <Formik
                  initialValues={INITIAL_VALUES}
                  onSubmit={({ time }, { setSubmitting, resetForm }) => {
                    if (time) {
                      const newScheduledTime: TraininScheduleTimeType = {
                        id: uuidv4(),
                        hour: parseInt(time.toString().split(":")[0]),
                        minute: parseInt(time.toString().split(":")[1]),
                      }
                      if (
                        times.every(
                          (existingTime) =>
                            !areTimesIdentical(existingTime, newScheduledTime)
                        )
                      ) {
                        setTrainingSchedule((prev) =>
                          prev.map((schedule) =>
                            schedule.weekday === day.value
                              ? {
                                  ...schedule,
                                  times: [...schedule.times, newScheduledTime],
                                }
                              : schedule
                          )
                        )
                      }

                      setIsEditing(false)
                      setSubmitting(false)
                      resetForm({ values: INITIAL_VALUES })
                    }
                  }}
                >
                  <Form>
                    <Flex alignItems="center">
                      <ATimePickerField name="time" />
                      <AButton text="Valider" ml={3} type="submit" />
                    </Flex>
                  </Form>
                </Formik>
              )}
            </Flex>
          )}
        </Flex>
        <Flex alignItems="center" mr={4} gap={6}>
          <IconButton
            aria-label="Ajouter un créneau"
            onClick={setNewTimeOrHandleState}
            _hover={{ bg: "none" }}
            background="none"
            icon={<AddIcon />}
          />
          <DuplicateDayPopover currentDay={day.value} onDuplicate={onDuplicate}>
            <IconButton
              isDisabled={times.length === 0}
              aria-label="Dupliquer"
              _hover={{ bg: "none" }}
              background="none"
              icon={<CopyIcon />}
            />
          </DuplicateDayPopover>
        </Flex>
      </Flex>
    </div>
  )
}

export default ScheduleDay

import React from "react"
import { SVGProps } from "../type"

const Estomac: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="36"
      fill="none"
      viewBox="0 0 30 36"
    >
      <path
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        fillOpacity="0.6"
        strokeWidth="3"
        d="M28.5 18c0 9.38-6.289 16.5-13.5 16.5S1.5 27.38 1.5 18C1.5 8.62 7.789 1.5 15 1.5S28.5 8.62 28.5 18z"
      ></path>
    </svg>
  )
}

export default Estomac

import { Flex, FlexProps, Icon } from "@chakra-ui/react"
import AAvatar from "components/AAvatar/AAvatar"
import { MdHome } from "react-icons/md"

interface ACenterAvatarProps extends FlexProps {
  isSmall?: boolean
  avatar?: AvatarType
}

const ACenterAvatar = ({
  isSmall = false,
  avatar,
  ...props
}: ACenterAvatarProps) => {
  return (
    <AAvatar
      isSmall={isSmall}
      alt="Avatar du centre"
      avatar={avatar}
      fallback={
        <Flex
          w={isSmall ? 6 : 8}
          h={isSmall ? 6 : 8}
          justifyContent="center"
          alignItems="center"
          bg="primary.500"
          borderRadius="50%"
          mr={2}
          {...props}
        >
          <Icon
            fontSize={isSmall ? 16 : 20}
            as={MdHome}
            color="white"
            aria-label="Avatar par défaut du centre"
          />
        </Flex>
      }
      {...props}
    />
  )
}

export default ACenterAvatar

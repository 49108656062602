const validateAddress = {
  name: "address",
  message: "Ce champ est obligatoire",
  test: (address?: AddressType) => {
    const addressName = address?.name?.trim() || ""
    return addressName.length > 0
  },
} as any

export default validateAddress

import {
  Box,
  useToast as useChakraToast,
  UseToastOptions,
} from "@chakra-ui/react"

const STATUS_TO_BG = {
  success: "valid.400",
  error: "danger.400",
  warning: "warning.400",
  info: "primary.400",
  loading: "primary.400",
} as const

const DefaultToast: UseToastOptions["render"] = ({
  status = "success",
  title,
}) => (
  <Box
    color="white"
    textAlign="center"
    p={4}
    borderRadius={9}
    bg={STATUS_TO_BG[status]}
  >
    {title}
  </Box>
)

const useToast = (overrideOptions?: UseToastOptions) =>
  useChakraToast({
    position: "top",
    render: DefaultToast,
    ...(overrideOptions ?? {}),
  })

export default useToast

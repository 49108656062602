import {
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Spinner,
  Switch,
} from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  AcademieDetailsType,
  updateAcademieLocationRestriction,
} from "api/academie"
import { useAppContext } from "AppContext"
import useToast from "hooks/useToast"
import { academieQueryKeys } from "pages/Academy/constants/queryKeys"
import { ChangeEvent, useId } from "react"

interface SecurityProps {
  locationRestriction?: boolean
  isLoading?: boolean
}
const Security = ({ locationRestriction, isLoading }: SecurityProps) => {
  const switchLocationRestrictionId = useId()
  const queryClient = useQueryClient()
  const toast = useToast()
  const { currentScope } = useAppContext()

  const queryKey = academieQueryKeys.current(currentScope!.id)
  const locationRestrictionMutation = useMutation({
    mutationFn: async (nextLocationRestriction: boolean) => {
      // TODO: api call
      return updateAcademieLocationRestriction({
        academieId: currentScope!.id,
        locationRestriction: nextLocationRestriction,
      })
    },
    // When mutate is called:
    onMutate: async (nextLocationRestriction: boolean) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      })

      // Snapshot the previous value
      const prevPage = queryClient.getQueryData<AcademieDetailsType>(queryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(
        queryKey,
        (academiePage?: AcademieDetailsType) => {
          if (academiePage) {
            return {
              ...academiePage,
              locationRestriction: nextLocationRestriction,
            }
          }
        }
      )

      // Return a context object with the snapshotted value
      return { prevPage }
    },
    onSuccess: () => {
      toast({
        status: "success",
        title: "Restriction modifiée",
      })
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context?.prevPage)
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
  })

  const onLocationRestrictionChange = (e: ChangeEvent<HTMLInputElement>) => {
    locationRestrictionMutation.mutate(e.target.checked)
  }
  return (
    <FormControl mb={10}>
      <Flex alignItems="center">
        <FormLabel htmlFor={switchLocationRestrictionId} mb={0}>
          Interdire l'accès aux étudiants hors de l'académie
        </FormLabel>
        {isLoading ? (
          <Spinner color="primary.500" />
        ) : (
          <Switch
            as="div"
            id={switchLocationRestrictionId}
            whiteSpace="nowrap"
            colorScheme="orange"
            isChecked={locationRestriction}
            onChange={onLocationRestrictionChange}
          />
        )}
      </Flex>
      <FormHelperText>
        Quand actif, seuls les appareils à proximité des lieux de l'école seront
        autorisés à utiliser l'application Andrew® Pro.
      </FormHelperText>
    </FormControl>
  )
}

export default Security

export default [
  {
    id: "gtm",
    name: "Google Tag Manager",
    uri: "https://adssettings.google.com/",
    /* remove cookies needs auto-reload */
    cookies: [
      "_ga",
      "_gat_UA-4662679-1",
      "1P_JAR",
      "CONSENT",
      "IDE",
      "NID",
      "_gid",
      "_gat",
      "__utma",
      "__utmb",
      "__utmc",
      "__utmt",
      "__utmz",
      "__gads",
      "_drt_",
      "FLC",
      "exchange_uid",
      "id",
      "fc",
      "rrs",
      "rds",
      "rv",
      "uid",
      "UIDR",
      "UID",
      "clid",
      "ipinfo",
      "acs",
      "test_cookie",
    ],
    status: "pending", // pending | allowed | denied
  },

  // {
  //   id: 'YouTube',
  //   name: 'YouTube',
  //   uri: 'https://policies.google.com/privacy',
  //   status: 'pending',
  // },

  // {
  //   id: 'Vimeo',
  //   name: 'Vimeo',
  //   uri: 'https://vimeo.com/privacy',
  //   status: 'pending',
  // },
]

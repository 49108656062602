import { Center, Heading } from "@chakra-ui/react"
import React from "react"

const Canceled = () => {
  return (
    <Center>
      <Heading as="h1" mt={7} mb={8} fontSize={28} color="tertiary.500">
        Votre paiement a été annulé
      </Heading>
    </Center>
  )
}

export default Canceled

import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react"
import React from "react"

const ELearningDatas = [
  {
    title: "1 - Introduction au développement de cabinet",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/NwilQK_2mSE?si=6sFIj5Jcy_cfbbX1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
  },
  {
    title: "2 - Mesurer son activité fiablement",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/IBFMFNWfmqU?si=Pg6g6A6HxF3JGbUK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
  },
  {
    title: "3 - Optimiser & Référencer sa fiche google",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/5ghehsN-fi4?si=v9PPuW-oflrpWOEu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
  },
  {
    title: "4 - Faut-il un site internet ?",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/bibwaV086UE?si=5itJZ_P8vL2JKhLG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
  },
  {
    title: "5 - Optimiser son référencement",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/nQRHmRIbbgo?si=_Iqp0uQlLxSMOopr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
  },
  {
    title: "6 - Lancer sa campagne Google Ads",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/MHWLZbwPbj8?si=9rZgji-mYRwMVRgc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
  },
  {
    title: "7 - Fidéliser d'une manière éthique",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/ZK7S7-MV7HA?si=4JZUguteU6pKg-Qd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
  },
]

const DevelopperPatientele = () => {
  return (
    <Box>
      <Heading
        as="h1"
        fontWeight={700}
        fontSize={28}
        mb={8}
        color="tertiary.500"
      >
        Développer sa patientèle
      </Heading>
      <Text fontSize={18} mb={8}>
        Cette section a été créée par{" "}
        <Link
          target={"_blank"}
          as={"a"}
          href="https://www.instagram.com/theo.gkf/"
          textDecoration={"underline"}
          fontWeight={"bold"}
        >
          Théo Gorbinkoff
        </Link>{" "}
        de{" "}
        <Link
          as={"a"}
          href="https://www.instagram.com/boost.ton.cab/?locale=en_gb"
          target={"_blank"}
          textDecoration={"underline"}
          fontWeight={"bold"}
        >
          BoostTonCab
        </Link>
        .<br />
        <Link
          as={"a"}
          href="https://www.boosttoncab.fr/"
          target={"_blank"}
          textDecoration={"underline"}
          fontWeight={"bold"}
        >
          Voir leur site internet
        </Link>
      </Text>

      <Flex flexDir="column" gap={4}>
        {ELearningDatas.map((data, index) => (
          <Box key={index}>
            <Heading as="h2" fontSize={22} color="tertiary.500" mb={2}>
              {data.title}
            </Heading>
            <Box dangerouslySetInnerHTML={{ __html: data.iframe }} />
          </Box>
        ))}
      </Flex>
    </Box>
  )
}

export default DevelopperPatientele

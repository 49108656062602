import { ViewIcon } from "@chakra-ui/icons"
import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useState } from "react"
import AButton from "../AButton"
import { EducationalContentType } from "api/educationalContent"

interface IProps {
  content: EducationalContentType
}

const EducationalContentCard: React.FC<IProps> = ({ content }) => {
  const [isHovering, setIsHovering] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box w="full" h={"full"}>
      <Flex
        overflow="hidden"
        w={"full"}
        align={"center"}
        h={"full"}
        gap={"16px"}
        padding={"8px"}
      >
        <AspectRatio ratio={16 / 9} w="160px">
          <Box
            pos="relative"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            borderRadius={"4px"}
          >
            <Image
              alt={content.name}
              src={content.bunnyCDN?.preview}
              w="100%"
              h="100%"
              objectFit="cover"
            />
            {isHovering && (
              <Flex
                bg="rgba(17, 23, 45, 0.5)"
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                alignItems="center"
                justifyContent="center"
              >
                <AButton
                  text="Aperçu"
                  variant="tertiary"
                  fontSize={12}
                  leftIcon={<ViewIcon />}
                  onClick={onOpen}
                />
              </Flex>
            )}
          </Box>
        </AspectRatio>
        <Text
          alignSelf={"self-start"}
          fontSize={14}
          fontWeight={700}
          marginTop={2}
        >
          {content.name}
        </Text>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent maxW="724">
          <Box
            pos="relative"
            borderRadius={9}
            bg="white"
            w={724}
            h={469}
            py="32px"
            px="54px"
          >
            <ModalCloseButton aria-label="Fermer" />
            <Heading fontSize={22} fontWeight="bold" textAlign="center" mb={7}>
              {content.name}
            </Heading>
            <Flex
              justify={"center"}
              align={"center"}
              w={"full"}
              h={"full"}
              overflow={"hidden"}
              pb="46px"
            >
              <iframe
                src={`${content.bunnyCDN?.video}?autoplay=true&loop=false&muted=true&preload=true&responsive=true`}
                loading="lazy"
                style={{
                  border: "none",
                  width: "100%",
                  height: "100%",
                }}
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
                title={content.name}
              />
            </Flex>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default EducationalContentCard

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  Input,
  InputProps,
} from "@chakra-ui/react"
import { useField } from "formik"
import { ReactNode } from "react"

interface AInputFieldProps extends InputProps {
  name: string
  label?: ReactNode
  isRequired?: FormControlProps["isRequired"]
  helperText?: ReactNode
}
const AInputField = ({
  name,
  label,
  isRequired,
  type,
  helperText,
  ...props
}: AInputFieldProps) => {
  const [field, { error, touched }] = useField<string>({ name, type })

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={error !== undefined && touched}
    >
      {label}
      <Input type={type} {...field} {...props} />
      <FormErrorMessage>{error}</FormErrorMessage>
      {!error && helperText}
    </FormControl>
  )
}

export default AInputField

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
} from "@chakra-ui/react"
import useToast from "../../hooks/useToast"
import * as Yup from "yup"
import AButton from "../../components/AButton"
import { initiatePassword, MeType } from "../../api/user"
import { useMutation } from "@tanstack/react-query"
import { Field, Form, Formik } from "formik"
import { useAppContext } from "../../AppContext"
import Cover from "../../assets/andrew-cover.png"
import ALogoutButton from "components/ALogoutButton"
import ALogo from "../../components/ALogo"
import AInputPassword from "components/AInputPassword"

interface MyFormValues {
  password: string
  confirmPassword: string
}
const InitiatePassword = () => {
  const initiatePasswordMutation = useMutation(initiatePassword)
  const { user, setUser } = useAppContext()
  const toast = useToast()
  const initialValues: MyFormValues = { password: "", confirmPassword: "" }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(8, "Le mot de passe doit faire 8 caractères minimum")
          .matches(
            new RegExp(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!&$*?_\-()+#=~@%,]{8,}$/
            ),
            `Pas si vite ! Votre mot de passe doit contenir au minimum 8 caractères, dont au moins :
1 majuscule ;
1 minuscule ;
1 chiffre.
Voici les caractères spéciaux autorisés : ! & $ * ? - _ ( ) + # = @ %`
          )
          .required("Ce champ est obligatoire"),
        confirmPassword: Yup.string()
          .min(8, "Le mot de passe doit faire 8 caractères minimum")
          .oneOf(
            [Yup.ref("password"), null],
            "Les mots de passe ne correspondent pas"
          ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { password, confirmPassword } = values
        if (!user) return
        if (password !== confirmPassword) {
          toast({
            status: "error",
            title: "Les mots de passe ne correspondent pas",
          })
          setSubmitting(false)
          return
        }

        initiatePasswordMutation.mutate(
          { password },
          {
            onSuccess: (data: { user: MeType }) => {
              toast({
                status: "success",
                title: "Votre mot de passe a bien été défini",
              })
              setSubmitting(false)
              setUser(data.user)
            },
            onError: () => {
              setSubmitting(false)
              toast({
                status: "error",
                title: "Une erreur s'est produite",
              })
            },
          }
        )
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <Flex alignItems="center">
            <Flex
              flex={1}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt={16}
            >
              <Flex w="67%" flexDirection="column" alignItems="center">
                <ALogo />
                <Heading
                  as="h1"
                  mb={12}
                  maxW={440}
                  textAlign="center"
                  color="tertiary.500"
                  fontSize={22}
                >
                  Veuillez définir un nouveau un mot de passe
                </Heading>
                <Box w="100%" mb={5}>
                  <Field name="password">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.password && form.touched.password
                        }
                      >
                        <FormLabel mb="8px" fontSize={14}>
                          Mot de passe (8 caractère minimum, incluant au moins
                          une minuscule, majuscule et un chiffre)
                        </FormLabel>
                        <AInputPassword
                          autoComplete="new-password"
                          {...field}
                        />
                        <FormErrorMessage whiteSpace="pre-wrap">
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box w="100%">
                  <Field name="confirmPassword">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.confirmPassword &&
                          form.touched.confirmPassword
                        }
                      >
                        <FormLabel mb="8px" fontSize={14}>
                          Confirmation du mot de passe
                        </FormLabel>
                        <AInputPassword {...field} />
                        <FormErrorMessage>
                          {form.errors.confirmPassword}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </Flex>

              <AButton
                type="submit"
                text="Définir le mot de passe"
                px={109}
                py={3}
                mt={152}
                isLoading={isSubmitting}
              />

              <ALogoutButton
                mt={8}
                bg="none"
                _hover={{ bg: "none", textDecor: "underline" }}
                _focus={{ bg: "none", textDecor: "underline" }}
              />
            </Flex>
            <Box flex={1}>
              <Image
                src={Cover}
                alt="Andrew"
                w="100%"
                h="100vh"
                objectFit="cover"
              />
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default InitiatePassword

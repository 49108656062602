import React from "react"
import { SVGProps } from "../type"

const TendiniteEpaule: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="48"
      fill="none"
      viewBox="0 0 35 48"
    >
      <path
        fillOpacity="0.6"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        strokeWidth="3"
        d="M4.5 24.025c2-15.6 19.833-21.166 28.5-22 .8 36.4-20.333 44.167-31 43.5 0-.666.5-5.9 2.5-21.5z"
      ></path>
    </svg>
  )
}

export default TendiniteEpaule

import {
  Container,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react"
import AndrewThinking from "assets/illustrations/andrew-interrogateur.png"
import AButton from "components/AButton"
import AGeolocationLoader from "components/Geolocation/AGeolocationLoader"
import { GEOLOCATION_NOT_SUPPORTED } from "components/Geolocation/GeolocationContext"
import useStudentGeolocationOnClick from "hooks/useStudentGeolocation/onClick"

const EnableGeolocation = () => {
  const { onClick, isLoading, isImprovingAccuracy, shouldWait, error } =
    useStudentGeolocationOnClick()

  if (isLoading) {
    return <AGeolocationLoader isImprovingAccuracy={isImprovingAccuracy} />
  }

  return (
    <Container maxW={500} minH="100vh" p={0}>
      <Flex
        backgroundColor={"primary.100"}
        direction={"column"}
        alignItems="center"
        h="100%"
        gap={10}
      >
        <Image
          src={AndrewThinking}
          w={159}
          h={214}
          alt="Andrew Interrogateur"
        />
        <Heading
          as="h1"
          color={"tertiary.500"}
          fontSize={28}
          fontWeight={700}
          textAlign="center"
        >
          Veuillez autoriser la géolocalisation dans votre navigateur pour
          accéder à votre compte Académie.
        </Heading>
        <AButton
          variant="primary"
          text="Rafraîchir la géolocalisation"
          onClick={onClick}
          isLoading={shouldWait}
        />
        <Heading as="h2" fontSize={18} fontWeight={400} textAlign="center">
          Vous n'êtes pas autorisé à accéder à votre compte Académie en dehors
          de votre établissement
        </Heading>
        <Flex direction="column" gap={4} alignSelf="start">
          {error === GEOLOCATION_NOT_SUPPORTED && (
            <Text color="danger.500">
              Il semblerait que la géolocalisation ne soit pas supportée sur
              votre appareil
            </Text>
          )}
          <Heading as="h3" fontSize={18} mb={5}>
            Liens utiles
          </Heading>
          <List>
            <ListItem>
              <Link
                isExternal
                href="https://support.google.com/chrome/answer/142065?hl=fr"
                color="tertiary.500"
                textDecoration="underline"
              >
                Chrome - Partager votre position
              </Link>
            </ListItem>
            <ListItem>
              <Link
                isExternal
                href="https://support.mozilla.org/fr/kb/firefox-partage-t-il-ma-position-avec-sites-web"
                color="tertiary.500"
                textDecoration="underline"
              >
                Firefox partage-t-il ma position avec des sites web ?
              </Link>
            </ListItem>
          </List>
        </Flex>
      </Flex>
    </Container>
  )
}

export default EnableGeolocation

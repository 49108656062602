import { Tr, Td } from "@chakra-ui/react"
import { ComponentProps } from "react"
import ASkeleton from "../ASkeleton"

interface ATableSkeletonProps {
  rows: number
  cols: number
  cellWidths?: ComponentProps<typeof Td>["width"][]
}
const ATableSkeleton = ({ rows, cols, cellWidths }: ATableSkeletonProps) => {
  const rowsList = Array.from({ length: rows }, (_, index) => index)
  const colsList = Array.from({ length: cols }, (_, index) => index)
  return (
    <>
      {rowsList.map((rowKey) => (
        <Tr key={rowKey} border="none">
          {colsList.map((colKey) => (
            <Td
              key={`${rowKey}${colKey}`}
              fontSize={14}
              border="none"
              textAlign="left"
            >
              <ASkeleton width={cellWidths?.[colKey]} height="18px" />
            </Td>
          ))}
        </Tr>
      ))}
    </>
  )
}

export default ATableSkeleton

import { useQueryClient } from "@tanstack/react-query"
import centerSubscriptionStateQueryKeys from "constants/queryKeys/centerSubscriptionState"
import { isCollaboratorUnauthorizedError } from "utils/collaboratorUnauthorized"

const useOnCollaboratorUnauthorized = () => {
  const queryClient = useQueryClient()

  const onCollaboratorUnauthorized = (error: any) => {
    if (isCollaboratorUnauthorizedError(error)) {
      queryClient.invalidateQueries({
        queryKey: centerSubscriptionStateQueryKeys.all,
      })
    }
  }

  return onCollaboratorUnauthorized
}

export default useOnCollaboratorUnauthorized

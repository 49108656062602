export const SCHEDULE_DAYS_MOCK = [
  {
    label: "Lundi",
    value: 2,
  },
  {
    label: "Mardi",
    value: 3,
  },
  {
    label: "Mercredi",
    value: 4,
  },
  {
    label: "Jeudi",
    value: 5,
  },
  {
    label: "Vendredi",
    value: 6,
  },
  {
    label: "Samedi",
    value: 7,
  },
  {
    label: "Dimanche",
    value: 1,
  },
]

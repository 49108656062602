interface getHighlightDataParams {
  text: string
  inputValue: string
}
const getHighlightData = ({ text, inputValue }: getHighlightDataParams) => {
  const startHighlightIndex = text
    .toLowerCase()
    .indexOf(inputValue.toLowerCase())
  const hasHighlight = startHighlightIndex !== -1
  const endHighlightIndex = hasHighlight
    ? startHighlightIndex + inputValue.length
    : startHighlightIndex

  return {
    startHighlightIndex,
    endHighlightIndex,
    hasHighlight,
  }
}

export default getHighlightData

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import arrowBack from "../../assets/arrow_back.svg"
import React, { useEffect, useRef, useState } from "react"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import AButton from "components/AButton"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import {
  deleteScheduledGroupMessage,
  getScheduledGroupMessage,
  getScheduledGroupMessages,
  ScheduledGroupMessageType,
  updateScheduledGroupMessage,
} from "api/scheduledGroupMessage"
import { format } from "date-fns"
import { useAppContext } from "AppContext"
import { findPatients } from "api/patient"

const EditMessage = () => {
  const { currentScope, user } = useAppContext()
  const { messageId } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const [isDeleting, setIsDeleting] = useState(false)

  const [message, setMessage] = useState<ScheduledGroupMessageType | undefined>(
    undefined
  )

  const [messages, setMessages] = useState<
    ScheduledGroupMessageType[] | undefined
  >(undefined)

  const [sendNumber, setSendNumber] = useState<number | undefined>(undefined)

  const currentCenter =
    currentScope?.type === "center"
      ? user?.osteo.centers?.find((center) => center.id === currentScope.id)
      : null

  const fetchMessage = async () => {
    if (!messageId) return
    const fetchedMessage = await getScheduledGroupMessage(parseInt(messageId))
    setMessage(fetchedMessage)
  }

  const fetchMessages = async () => {
    const fetchedMessages = await getScheduledGroupMessages(
      currentScope?.type === "center" ? currentScope.id : undefined
    )
    setMessages(fetchedMessages)
  }

  const fetchSendNumber = async () => {
    const fetchedPatients = await findPatients(
      currentScope?.type === "center" ? { centerId: currentScope.id } : {}
    )
    setSendNumber(fetchedPatients.length)
  }

  useEffect(() => {
    fetchMessage()
  }, [messageId])

  useEffect(() => {
    fetchMessages()
    fetchSendNumber()
  }, [])

  const handleDelete = async () => {
    if (!message) return
    setIsDeleting(true)
    await deleteScheduledGroupMessage(message.id)
    setIsDeleting(false)
    toast({
      status: "success",
      title: "Message supprimé avec succès",
    })
    navigate("/messages")
  }

  if (!message) return null
  return (
    <Flex p="16px" direction="column">
      <Link href="/messages" w="fit-content" as={RouterLink} to="/messages">
        <Flex
          mb={6}
          cursor="pointer"
          p="8px"
          bgColor="primary.200"
          w="fit-content"
          display="block"
          rounded="9px"
        >
          <Image src={arrowBack} width="24px" height="24px" />
        </Flex>
      </Link>
      <Flex w="100%" justifyContent="center">
        <Flex direction="column" gap="40px" minW="640px">
          <Flex direction="column" gap="12px">
            <Heading as="h1" fontSize="32px">
              Editer le message
            </Heading>
            <Flex
              bgColor="primary.200"
              p="12px"
              rounded="4px"
              gap="10px"
              fontSize="18px"
            >
              <InfoOutlineIcon />
              <Flex direction="column" gap={2}>
                {currentCenter && (
                  <Text lineHeight="100%">
                    Message envoyé aux patients du profil :{" "}
                    <b>{currentCenter?.name}</b>
                  </Text>
                )}
                <Text lineHeight="100%">
                  Nombre d’envois estimé : <b>{sendNumber}</b>
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Box width="100%">
            <Formik
              validationSchema={Yup.object().shape({
                title: Yup.string().min(2).required("Titre requis"),
                sendDateTime: Yup.date()
                  .required("Date et heure d'envoi requises")
                  .test(
                    "one-message-per-month-test",
                    "Vous ne pouvez envoyer qu'un message à vos patients par mois",
                    (value, context) => {
                      if (value) {
                        const oneMonthBefore = new Date(value)
                        oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1)

                        const oneMonthAfter = new Date(value)
                        oneMonthAfter.setMonth(oneMonthAfter.getMonth() + 1)

                        if (!messages || messages.length === 0) return true

                        const messageInRange = messages.find(
                          (currMessage: ScheduledGroupMessageType) => {
                            const messageDate = new Date(
                              currMessage.sendDateTime
                            )
                            return (
                              messageDate >= oneMonthBefore &&
                              messageDate <= oneMonthAfter &&
                              currMessage.id !== message.id
                            )
                          }
                        )

                        return messageInRange === undefined
                      }
                      return false
                    }
                  ),
                text: Yup.string()
                  .max(256, "Maximum 256 caractères")
                  .required("Texte requis"),
              })}
              initialValues={{
                title: message.title,
                sendDateTime:
                  message.sendDateTime.split(":")[0] +
                  ":" +
                  message.sendDateTime.split(":")[1],
                text: message.text,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                // Ajouter ici la logique pour envoyer le formulaire
                await updateScheduledGroupMessage({
                  id: message.id,
                  ...values,
                  center:
                    currentScope?.type === "center"
                      ? currentScope.id
                      : undefined,
                })
                setSubmitting(false)
                onClose()
                toast({
                  status: "success",
                  title: "Message modifié avec succès",
                })
                navigate("/messages")
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Flex alignItems="center" gap="16px">
                    <Field name="title">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={form.errors.title && form.touched.title}
                        >
                          <FormLabel fontWeight={700}>Titre *</FormLabel>
                          <Input
                            {...field}
                            placeholder="Titre"
                            bg="common.100"
                          />
                          <FormErrorMessage>
                            {form.errors.title}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="sendDateTime">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.sendDateTime &&
                            form.touched.sendDateTime
                          }
                        >
                          <FormLabel fontWeight={700}>
                            Date et heure d'envoi *
                          </FormLabel>
                          <Input
                            {...field}
                            type="dateTime-local"
                            bg="common.100"
                            placeholder="YYYY-MM-DD HH:MM"
                          />
                          <FormErrorMessage>
                            {form.errors.sendDateTime}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>

                  <Field name="text">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.text && form.touched.text}
                      >
                        <FormLabel mt={6} fontWeight={700}>
                          Texte *
                        </FormLabel>
                        <Textarea
                          {...field}
                          bg="common.100"
                          placeholder="Votre message"
                          height="223px"
                        />
                        <FormErrorMessage>{form.errors.text}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex
                    direction="row"
                    align="center"
                    justify="center"
                    w="full"
                    gap={2}
                    fontFamily="Montserrat"
                    bg="primary.200"
                    py={6}
                    position="fixed"
                    bottom={0}
                    left={0}
                    zIndex={90}
                  >
                    <AButton
                      text="Programmer"
                      px={120}
                      py={3}
                      fontFamily="Montserrat"
                      type="submit"
                      isLoading={isSubmitting}
                    />
                    <AButton
                      text="Supprimer"
                      px={12}
                      py={3}
                      fontFamily="Montserrat"
                      type="button"
                      variant="destructive"
                      isLoading={isDeleting}
                      onClick={handleDelete}
                    />
                  </Flex>
                </Form>
              )}
            </Formik>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default EditMessage

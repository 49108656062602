import { Flex, Heading, Spinner, Text } from "@chakra-ui/react"

interface AGeolocationLoaderProps {
  isImprovingAccuracy?: boolean
}
const AGeolocationLoader = ({
  isImprovingAccuracy,
}: AGeolocationLoaderProps) => (
  <Flex gap={20} direction="column" align="center">
    <Spinner color="primary.500" />
    <Flex direction="column" align="center" gap={4}>
      <Heading
        color={"tertiary.500"}
        fontSize={28}
        fontWeight={700}
        textAlign="center"
      >
        Chargement de votre géolocalisation
      </Heading>
      {isImprovingAccuracy ? (
        <Heading fontSize={18} as="h2" textAlign="center">
          Amélioration de la précision, veuillez patienter
        </Heading>
      ) : (
        <Text align="center">
          Veuillez autoriser la géolocalisation dans votre navigateur pour
          accéder à votre compte Académie.
        </Text>
      )}
    </Flex>
  </Flex>
)

export default AGeolocationLoader

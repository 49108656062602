import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import React from "react"
import { getAllPodcasts } from "../../api/podcast"
import arrowBack from "../../assets/arrow-back.svg"
import { Link as RouterLink } from "react-router-dom"
import { ChevronRightIcon } from "@chakra-ui/icons"

const Podcasts = () => {
  const { data, isLoading } = useQuery(["getAllPodcasts"], getAllPodcasts)

  if (isLoading)
    return (
      <Center h="calc(100vh - 140px)">
        <Spinner color="primary.500" />
      </Center>
    )

  return (
    <Box>
      <Link as={RouterLink} to="/">
        <Flex alignItems="center">
          <Image src={arrowBack} mr={2} />
          <Text>Retour</Text>
        </Flex>
      </Link>

      <Heading as="h1" mt={7} mb={8} fontSize={28} color="tertiary.500">
        Podcasts
      </Heading>
      {data?.map((podcast) => (
        <Link as={RouterLink} to={`/podcasts/${podcast.id}`} key={podcast.id}>
          <Flex
            justifyContent="space-between"
            alignItems="flex-end"
            cursor="pointer"
            bg="primary.200"
            p={3}
            borderRadius={9}
            mb={4}
          >
            <Flex alignItems="center">
              <Heading fontSize={22} fontWeight={700}>
                {podcast.attributes.title}
              </Heading>
              <ChevronRightIcon color="black" fontSize={26} fontWeight={700} />
            </Flex>
            <Image
              src={podcast.attributes.illustrationUrl}
              w={280}
              h={280}
              borderRadius={9}
            />
          </Flex>
        </Link>
      ))}
    </Box>
  )
}

export default Podcasts

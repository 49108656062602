import axiosInstance from "./axiosInstance"

export type MediaDifficutType = "easy" | "normal" | "hard"

export type BabyMediaTag = {
  key: string
  name: string
}

export type BabyMediaTagListType = {
  tag: BabyMediaTag
  medias: MediaType[]
}

export type MediaAuthorType = {
  id: number
  displayName: string
  description: string
  avatar: {
    id: number
    formats: {
      thumbnail: { url: string }
    }
  }
  redirectUrl?: string
}

export type MediaTypeType = "audio" | "video" | "podcast"

export type MediaType = {
  id: number
  title: string
  type: MediaTypeType
  topic: { id: number }
  duration: number
  author: MediaAuthorType
  cover: {
    id: number
    url: string
  }
  media: {
    id: number
    url: string
  }
  difficulty: MediaDifficutType
  tag?: BabyMediaTag
}

export const getFreshestMedia = async () => {
  const { data } = await axiosInstance.get<MediaType[]>(
    `/medias/getFreshestMedias`
  )
  return data
}

export const getAllMedias = async (isBaby: boolean = false) => {
  const { data } = await axiosInstance.get<MediaType[]>(
    `/medias/getAllMedias?isBaby=${isBaby}`
  )
  return data
}

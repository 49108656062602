import { useAppContext } from "AppContext"
import haversine from "haversine"
import useToast from "hooks/useToast"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import getCurrentAcademy from "utils/getCurrentAcademy"

// CONSTANTS
// Rayon max autorisant l'accès en mètres
export const ACCESS_THRESHOLD = 500
// Précision minimale en mètres
export const MINIMAL_ACCURACY = 100 // NB: chrome can emulate a location, with accuracy 150

const TOAST_ID = "geolocation"
const TIMEOUT = 10_000

export const GEOLOCATION_OPTIONS = {
  maximumAge: Infinity,
  enableHighAccuracy: true,
  timeout: TIMEOUT,
}

export const GEOLOCATION_NOT_SUPPORTED = Symbol("GEOLOCATION_NOT_SUPPORTED")
export const GEOLOCATION_NOT_ACCURATE = Symbol("GEOLOCATION_NOT_ACCURATE")

// CONTEXT
interface GeolocationContextValues {
  can: Boolean
  error: symbol | GeolocationPositionError | undefined
  isLoading: boolean
  setLoading: (loading: boolean) => void
  isImprovingAccuracy: boolean
  isStudent?: boolean
  hasGeolocationCheck: boolean
  shouldWait: boolean
  onGeolocationNotSupported: () => void
  onSuccess: (position: GeolocationPosition) => void
  onError: (error: GeolocationPositionError) => void
}
const GeolocationContext = createContext<GeolocationContextValues | undefined>(
  undefined
)

// HOOKS
export const useGeolocationContext = () => {
  const context = useContext(GeolocationContext)
  if (!context) {
    throw new Error(
      "useGeolocationContext must be used within a GeolocationContextProvider"
    )
  }
  return context
}

// COMPONENTS
interface GeolocationContextProviderProps {
  children?: ReactNode
}
const GeolocationContextProvider = ({
  children,
}: GeolocationContextProviderProps) => {
  const [isLoading, setLoading] = useState(false)
  const [isImprovingAccuracy, setIsImprovingAccuracy] = useState(false)
  const [can, setCan] = useState(false)
  const toast = useToast()
  const [error, setError] = useState<
    | typeof GEOLOCATION_NOT_SUPPORTED
    | typeof GEOLOCATION_NOT_ACCURATE
    | GeolocationPositionError
  >()
  const { currentScope, user } = useAppContext()
  const improvingAccuracyTrials = useRef(0)

  const currentAcademy = getCurrentAcademy({ currentScope, user })
  const isAcademyAdmin = user?.osteo.adminAcademies?.some(
    (academy) => academy.id === currentScope?.id
  )

  const shouldWait = !user
  const isStudent = currentAcademy && !isAcademyAdmin
  const hasGeolocationCheck = currentAcademy?.locationRestriction ?? false

  const onAccessUpdate = useCallback((nextCan: boolean) => {
    setLoading(false)
    setError(undefined)
    setCan(nextCan)
  }, [])

  const onAccuracyImprovementFailed = useCallback(() => {
    setIsImprovingAccuracy(false)
    onAccessUpdate(true)
  }, [setIsImprovingAccuracy, onAccessUpdate])

  const onImprovingAccuracy = useCallback(() => {
    if (improvingAccuracyTrials.current < 5) {
      improvingAccuracyTrials.current += 1
      setIsImprovingAccuracy(true)
      setLoading(true)
      setCan(false)
      return
    }

    // give access when improving accuracy failed 5 times
    onAccuracyImprovementFailed()
  }, [improvingAccuracyTrials, onAccuracyImprovementFailed])

  const onGeolocationNotSupported = useCallback(() => {
    setLoading(false)
    setCan(false)
    setError(GEOLOCATION_NOT_SUPPORTED)
  }, [])

  const onError = useCallback((error: GeolocationPositionError) => {
    setLoading(false)
    setCan(false)
    setError(error)
  }, [])
  const onSuccess = useCallback(
    (position: GeolocationPosition) => {
      const { accuracy, latitude, longitude } = position.coords
      if (accuracy > MINIMAL_ACCURACY) {
        return onImprovingAccuracy()
      }
      setIsImprovingAccuracy(false)
      const nextCan = currentAcademy!.locations.some(({ address }) => {
        const distanceInMeter = haversine({ latitude, longitude }, address, {
          unit: "meter",
        })
        return distanceInMeter < ACCESS_THRESHOLD
      })
      onAccessUpdate(nextCan)
    },
    [currentAcademy, onImprovingAccuracy, onAccessUpdate]
  )

  useEffect(() => {
    if (isImprovingAccuracy) {
      const watch = navigator.geolocation.watchPosition(
        onSuccess,
        onError,
        GEOLOCATION_OPTIONS
      )
      const timeout = setTimeout(() => {
        // give access when improving accuracy failed for TIMEOUT
        onAccuracyImprovementFailed()
      }, TIMEOUT)
      return () => {
        navigator.geolocation.clearWatch(watch)
        clearTimeout(timeout)
      }
    }
  }, [isImprovingAccuracy, onError, onSuccess, onAccuracyImprovementFailed])

  useEffect(() => {
    if (isStudent && hasGeolocationCheck && can && !toast.isActive(TOAST_ID)) {
      toast({
        id: TOAST_ID,
        status: "success",
        title: "Géolocalisation valide",
      })
    }
  }, [isStudent, hasGeolocationCheck, can, toast])

  const value = {
    can,
    error,
    isLoading,
    setLoading,
    isImprovingAccuracy,
    isStudent,
    hasGeolocationCheck,
    shouldWait,
    onGeolocationNotSupported,
    onSuccess,
    onError,
  }

  return (
    <GeolocationContext.Provider value={value}>
      {children}
    </GeolocationContext.Provider>
  )
}

export default GeolocationContextProvider

import React, { useEffect, useState } from "react"
import {
  SelectedVideoOrderType,
  TrainingVideoGroupsLightType,
  TrainingVideoGroupsType,
  VideoType,
} from "api/appointmentReason"
import { SelectedExerciceType } from "api/appointmentReason"
import AExerciceCardBase from "components/AExerciceCard/Base"
import { useDisclosure } from "@chakra-ui/react"
import AddExerciceModal from "pages/NewAppointment/components/SelectExercices/components/AddExerciceModal"

interface IProps {
  video: VideoType
  videoGroup: TrainingVideoGroupsType | TrainingVideoGroupsLightType
  selectedExercices?: SelectedExerciceType[]
  setSelectedExercices?: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  setSelectedVideoOrder?: React.Dispatch<
    React.SetStateAction<SelectedVideoOrderType[]>
  >
  title?: string
  videosCount?: number
  isForKine?: boolean
}

const AExerciceCard: React.FC<IProps> = ({
  video,
  videoGroup,
  selectedExercices,
  setSelectedExercices,
  setSelectedVideoOrder,
  title,
  videosCount,
  isForKine = false,
}) => {
  const [isSelecting, setIsSelecting] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isSelected = !selectedExercices
    ? false
    : selectedExercices.some((exercice) => exercice.id === video.id)

  const onClickSelection = isSelected ? () => null : () => setIsSelecting(true)

  const onAdd = (value: number) => {
    if (!value || !setSelectedExercices) return
    if (isForKine) {
      onOpen()
      return
    }
    setSelectedExercices((prev) => [
      ...prev,
      {
        ...video,
        duration: value,
        videoGroup: videoGroup.id,
        defaultDuration: video.defaultDuration,
        series: null,
        repetitions: null,
        side: null,
        weight: null,
        restDuration: null,
        type: null,
        recommendation: null,
      },
    ])
    if (setSelectedVideoOrder) {
      setSelectedVideoOrder((prev) => [
        ...prev,
        {
          id: video.id,
          type: "video",
        },
      ])
    }
  }
  useEffect(() => {
    setIsSelecting(false)
  }, [isSelected])

  return (
    <>
      <AExerciceCardBase
        video={video}
        videoGroup={videoGroup}
        title={title}
        isSelecting={isSelecting}
        isSelected={isSelected}
        isDisabled={isSelected}
        onClick={onClickSelection}
        onAdd={onAdd}
        videosCount={videosCount}
        isAddCard={selectedExercices !== undefined}
      />
      {isOpen && setSelectedExercices && (
        <AddExerciceModal
          isOpen={isOpen}
          onClose={onClose}
          video={video}
          videoGroupId={videoGroup.id}
          setSelectedExercices={setSelectedExercices}
          setSelectedVideoOrder={setSelectedVideoOrder}
        />
      )}
    </>
  )
}

export default AExerciceCard

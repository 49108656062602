import { useDisclosure } from "@chakra-ui/react"
import { CurrencyCode, StripeProductType } from "api/subscriptions"
import { useAppContext } from "AppContext"
import BillingCard from "components/Billing/Card"
import OsteoBillingProration from "components/Billing/Osteo/BillingProration"
import getCurrencyCodeForCountry from "utils/price/getCurrencyCodeForCountry"

const getProductMetadataId = (
  type: "osteo" | "kine" | "kine_teleconsultation",
  interval: "year" | "month"
) => {
  if (type === "osteo") {
    if (interval === "year") return "individual_yearly"
    return "individual_monthly"
  }
  if (type === "kine") {
    return "kine"
  }
  return "kine_teleconsultation"
}

const getProductPrice = (
  product: StripeProductType,
  type: "osteo" | "kine" | "kine_teleconsultation",
  currencyCode: CurrencyCode,
  interval: "year" | "month"
) => {
  if (type === "osteo") {
    return product.pricesPerCurrency[currencyCode]
  }

  return product.allPrices?.find(
    (price) => price.recurring.interval === interval
  )
}
// HELPERS
const getOtherBillingSubscription = (
  type: "osteo" | "kine" | "kine_teleconsultation",
  currencyCode: CurrencyCode,
  currentInterval?: "year" | "month",
  stripeProducts?: StripeProductType[]
) => {
  if (!currentInterval) {
    return undefined
  }
  const nextInterval = currentInterval === "month" ? "year" : "month"
  const productMetadataId = getProductMetadataId(type, nextInterval)
  const stripeProduct = stripeProducts?.find(
    ({ metadata }) => metadata.id === productMetadataId
  )
  if (!stripeProduct) {
    return undefined
  }
  const price = getProductPrice(stripeProduct, type, currencyCode, nextInterval)
  return price
}

// COMPONENTS
interface OsteoSubscriptionBillingProps {
  stripeProducts?: StripeProductType[]
  isLoadingStripeProducts: boolean
  type: "osteo" | "kine" | "kine_teleconsultation"
}
const OsteoSubscriptionBilling = ({
  stripeProducts,
  isLoadingStripeProducts,
  type = "osteo",
}: OsteoSubscriptionBillingProps) => {
  const { user } = useAppContext()
  const currencyCode = getCurrencyCodeForCountry(
    user?.osteo.mainAddress?.country
  ) as CurrencyCode

  const subscription = user?.stripeSubscription

  const { isOpen, onOpen, onClose } = useDisclosure()

  const currentInterval = subscription?.plan.interval

  const price = getOtherBillingSubscription(
    type,
    currencyCode,
    currentInterval,
    stripeProducts
  )

  if (!subscription) {
    return null
  }

  return (
    <BillingCard
      discount={`10€ / ${
        subscription.plan.interval === "year" ? "an" : "mois"
      }`}
      subscription={subscription}
      isLoading={isLoadingStripeProducts}
      onClick={onOpen}
    >
      {price && (
        <OsteoBillingProration
          isOpen={isOpen}
          onClose={onClose}
          subscription={subscription!}
          price={price}
        />
      )}
    </BillingCard>
  )
}

export default OsteoSubscriptionBilling

import { Flex, MenuItem, Text } from "@chakra-ui/react"
import AUserAvatar from "components/AAvatar/AUserAvatar"
import ACenterItem from "components/AProfileButton/components/ACenterItem"
import { useNavigate } from "react-router-dom"
import troncateString from "utils/troncateString"
import userHasNoPersonalAccount from "utils/user/userHasNoPersonalAccount"
import { useAppContext } from "../../../AppContext"
import AAcademyItem from "./AAcademyItem"

const AOtherProfilesMenuItem = () => {
  const { currentScope, setCurrentScope, user } = useAppContext()
  const navigate = useNavigate()
  const hasPersonalAccount = !userHasNoPersonalAccount(user)

  return currentScope ? (
    <>
      {hasPersonalAccount && (
        <MenuItem
          minH="48px"
          background="primary.300"
          _hover={{ background: "primary.400" }}
          _focus={{ background: "primary.400" }}
          onClick={() => {
            setCurrentScope(null)
            navigate("/dashboard")
          }}
        >
          <Flex alignItems="center">
            <AUserAvatar user={user} isSmall />
            <Text fontSize={16}>
              {troncateString(
                `${user?.osteo.firstname} ${user?.osteo.lastname}`,
                15
              )}
            </Text>
          </Flex>
        </MenuItem>
      )}
      {(user?.osteo.centers ?? [])
        .filter((center) => center.id !== currentScope.id)
        .map((center) => (
          <ACenterItem key={center.id} center={center} />
        ))}
      {(user?.osteo.academies ?? [])
        .filter((academy) => academy.id !== currentScope.id)
        .map((academy) => (
          <AAcademyItem key={academy.id} academy={academy} />
        ))}
    </>
  ) : (
    <>
      {(user?.osteo.centers ?? []).map((center) => (
        <ACenterItem key={center.id} center={center} />
      ))}
      {user?.osteo.academies?.map((academy) => (
        <AAcademyItem key={academy.id} academy={academy} />
      ))}
    </>
  )
}

export default AOtherProfilesMenuItem

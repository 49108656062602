import React, { useState, useContext, useEffect } from "react"
import RgpdContext from "./RgpdContext"
import { Box, Button, Container, Flex, Heading, Text } from "@chakra-ui/react"
import AButton from "../AButton"

export default function RgpdBanner() {
  const { allowAllServices, bannerStatus, showCustomServices } =
    useContext(RgpdContext)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [])

  if (!ready) return null

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      opacity={bannerStatus === "visible" ? 1 : 0}
      visibility={bannerStatus === "visible" ? "visible" : "hidden"}
      transition="all 300ms ease"
      color="black"
      zIndex={bannerStatus === "visible" ? 500000 : -1}
      boxShadow={"0 0 44px 0 rgba(34, 34, 34, 0.37)"}
    >
      <Box
        pos="relative"
        padding={{ base: 4, lg: 10 }}
        bg={"primary.100"}
        transform={
          bannerStatus === "visible" ? "translateY(0%)" : "translateY(100%)"
        }
        transition="transform 300ms ease"
      >
        <Container maxW={"1200px"}>
          <Flex direction={"column"}>
            <Heading as={"h2"} size={"sm"} mb={2}>
              Gestion des cookies
            </Heading>
            <Text fontSize={"sm"}>
              Nous utilisons des cookies pour vous garantir la meilleure
              expérience sur notre site web.
            </Text>
            <Flex justifyContent={"end"} mt={6}>
              <Button
                variant={"link"}
                onClick={() => showCustomServices()}
                color={"primary.500"}
                textDecoration={"underline"}
                mr={4}
                _hover={{ textDecoration: "underline", opacity: 0.6 }}
              >
                <span>Paramétrer les cookies</span>
              </Button>
              <AButton
                variant="primary"
                onClick={allowAllServices}
                px={8}
                text="Accepter"
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

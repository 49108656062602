import { Box, Flex, Heading } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { getFreshestMedia } from "../../api/media"
import { getTopics, TopicCategoryEnum } from "../../api/topics"
import ASkeleton from "../../components/ASkeleton"
import getCategory from "../../utils/getCategory"
import AudioItem from "./components/AudioItem"
import FilterItem from "./components/FilterItem"
import TopicItem from "./components/TopicItem"
import VideoItem from "./components/VideoItem"
import MindIllustration from "../../assets/illustrations/mind-illustration.png"
import BodyIllustration from "../../assets/illustrations/body-illustration.png"
import PodcastsIllustration from "../../assets/illustrations/podcasts-illustration.png"

const Explorer = () => {
  const { data: topicsData, isLoading: topicsLoading } = useQuery(
    ["getTopics"],
    getTopics
  )
  const { data: freshestMedias, isLoading: freshestMediasLoading } = useQuery(
    ["getFreshestMedia"],
    getFreshestMedia
  )
  const allCategories: TopicCategoryEnum[] = ["mind", "body", "podcast"]

  const [selectedCategory, setSelectedCategory] =
    useState<TopicCategoryEnum | null>(null)

  return (
    <Box>
      <Heading as="h1" mb={8} fontSize={28} color="tertiary.500">
        Explorer
      </Heading>
      <Flex gap={2} mb={8}>
        {(selectedCategory === null || selectedCategory === "mind") && (
          <FilterItem
            img={MindIllustration}
            category="mind"
            setSelectedCategory={setSelectedCategory}
          />
        )}
        {(selectedCategory === null || selectedCategory === "body") && (
          <FilterItem
            img={BodyIllustration}
            category="body"
            setSelectedCategory={setSelectedCategory}
          />
        )}
        {(selectedCategory === null || selectedCategory === "podcast") && (
          <FilterItem
            img={PodcastsIllustration}
            category="podcast"
            setSelectedCategory={setSelectedCategory}
          />
        )}
      </Flex>

      {!selectedCategory && (
        <Box bg="tertiary.500" borderRadius={9} py={8} px={6}>
          <Heading mb={4} fontSize={22} color="primary.200">
            Nouveautés
          </Heading>

          {freshestMediasLoading ? (
            <Flex
              alignItems="center"
              flexWrap="nowrap"
              overflowX="auto"
              gap={6}
            >
              <ASkeleton w={265} h={230}></ASkeleton>
              <ASkeleton w={265} h={230}></ASkeleton>
              <ASkeleton w={265} h={230}></ASkeleton>
              <ASkeleton w={265} h={230}></ASkeleton>
            </Flex>
          ) : (
            <Flex
              alignItems="center"
              flexWrap="nowrap"
              overflowX="auto"
              gap={6}
            >
              {freshestMedias?.map((media) => {
                if (media.type === "audio" || media.type === "podcast")
                  return <AudioItem key={media.id} media={media} size="small" />
                return <VideoItem key={media.id} media={media} size="small" />
              })}
            </Flex>
          )}
        </Box>
      )}
      {allCategories
        .filter(
          (category) =>
            selectedCategory === null || selectedCategory === category
        )
        .map((category) => (
          <Box key={category} mt={10}>
            <Heading mb={4} fontSize={22} color="tertiary.500">
              {getCategory(category)}
            </Heading>
            {topicsLoading ? (
              <Flex gap={4} flexWrap="wrap">
                <ASkeleton w={708} h={236}></ASkeleton>
                <ASkeleton w={708} h={236}></ASkeleton>
                <ASkeleton w={708} h={236}></ASkeleton>
                <ASkeleton w={708} h={236}></ASkeleton>
              </Flex>
            ) : (
              <Flex gap={4} flexWrap="wrap">
                {topicsData
                  ?.filter(
                    (topic) =>
                      topic.attributes.type === category &&
                      topic.attributes.key !== "coherence_cardiaque"
                  )
                  .map((topic) => (
                    <TopicItem key={topic.id} topic={topic} />
                  ))}
              </Flex>
            )}
          </Box>
        ))}
    </Box>
  )
}

export default Explorer
